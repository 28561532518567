import { Fade } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  SystemUserResourceList,
  addSystemUser,
} from "../../../../api/systemuser";
import images from "../../../../utils/images";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";
import FileUpload from "../../../common/FileUpload/FileUpload";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import { UploadButtonComponent } from "../common/UploadButton";
import "./AddSystemUser.scss";

const AddSystemUser = ({ setOpenAddSystemUser, setSystemUser }) => {
  const [systemDispalyUserName, setSystemDisplayUserName] = useState("");
  const [systemUserName, setSystemUserName] = useState("");
  const [password, setPassword] = useState("");
  const [certificate, setCertificate] = useState("");
  const [iscomplete, setIscomplete] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetoptions, setAssetoptions] = useState([]);
  const [appoptions, setAppoptions] = useState([]);
  const [webappoptions, setWebappoptions] = useState([]);
  const [assets, setAssets] = useState([]);
  const [apps, setApps] = useState([]);
  const [webapps, setWebapps] = useState([]);
  const animatedComponents = makeAnimated();
  const [showHidden1, setShowHidden1] = useState(false);
  const [showHidden2, setShowHidden2] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const [passphrase, setPassphrase] = useState("");
  const [selectedCredOption, setSelectedCredOption] = useState("password");
  const [certificateInputMethod, setCertificateInputMethod] = useState("text");

  const [openFileImport, setOpenFileImport] = useState(false);
  const fileInvalidMessage = "Selected file does not contain a valid data";
  const maxFileSize = {
    label: "2MB",
    value: 2e6,
  };
  const handleFileSubmit = (fileData, handleClose) => {
    setCertificate(fileData);
    handleClose();
    setCertificateInputMethod("text");
  };

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "3px",
      paddingBottom: "3px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  };

  const getSystemUsers = async () => {
    const { data } = await SystemUserResourceList({
      isresource: 0,
      new: true,
      id: 0,
    });
    if (data !== null) {
      setAssetoptions(
        data.assets.map((asset) => {
          asset.label = asset.asset_name;
          asset.value = asset.id;
          return asset;
        })
      );
      setAppoptions(
        data.apps.map((app) => {
          app.label = app.app_name;
          app.value = app.id;
          return app;
        })
      );
      setWebappoptions(
        data.web_apps.map((webapp) => {
          webapp.label = webapp.app_name;
          webapp.value = webapp.id;
          return webapp;
        })
      );
    }
  };

  const updateAssets = (e) => {
    setAssets(e.map((asset) => asset.value));
  };
  const updateApps = (e) => {
    setApps(e.map((app) => app.value));
  };
  const updateWebapps = (e) => {
    setWebapps(e.map((webapp) => webapp.value));
  };

  useEffect(() => {
    getSystemUsers();
  }, []);

  const addSystemUserFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      const { data, error } = await addSystemUser({
        ...(systemDispalyUserName !== "" && {
          system_display_username: systemDispalyUserName,
        }),
        ...(systemUserName !== "" && { system_username: systemUserName }),
        ...(password !== "" ? { password: password } : { password: null }),
        ...(passphrase !== ""
          ? { passphrase: passphrase }
          : { passphrase: null }),
        ...(certificate !== ""
          ? { certificate: certificate }
          : { certificate: null }),
        ...{ assets: assets },
        ...{ apps: apps },
        ...{ web_apps: webapps },
        ...{ iscomplete: iscomplete },
      });
      if (data !== null) {
        showSuccess(data.message);
        setSystemUser(Object.create(null));
        setLoading(false);
        setOpenAddSystemUser((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Container
          fluid
          className=" mx-auto add_systemuser_container main_content_container"
        >
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h2 className="main_content_heading">Add System User</h2>
            <CrossButton
              onClick={() => setOpenAddSystemUser((o) => !o)}
            ></CrossButton>
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={addSystemUserFormHandler}
            className="add_systemuser_form"
          >
            <Stack gap={1}>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextNameDisplay"
              >
                <Form.Label column md={3} className="input_label">
                  Display Username<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    className="form_input_field"
                    type="text"
                    value={systemDispalyUserName}
                    onChange={(event) =>
                      setSystemDisplayUserName(event.target.value)
                    }
                    placeholder="Enter your system display username"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid display username
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextName"
              >
                <Form.Label column md={3} className="input_label">
                  Username<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    className="form_input_field"
                    type={"text"}
                    value={systemUserName}
                    onChange={(event) => setSystemUserName(event.target.value)}
                    placeholder="Enter your system username"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a username
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <div className="d-flex justify-content-start align-items-center border-bottom mb-3">
                <h5
                  className={`input_label me-4 pb-1 mb-0 cursor_pointer ${
                    selectedCredOption === "password"
                      ? "border-bottom border-2 active_sidebar_text active_sidebar_border"
                      : ""
                  }`}
                  onClick={() => setSelectedCredOption("password")}
                >
                  Password
                </h5>
                <h5
                  className={`input_label me-4 pb-1 mb-0 cursor_pointer ${
                    selectedCredOption === "key"
                      ? "border-bottom border-2 active_sidebar_text active_sidebar_border"
                      : ""
                  }`}
                  onClick={() => setSelectedCredOption("key")}
                >
                  Key
                </h5>
              </div>
              <div className="my-3">
                {selectedCredOption === "password" && (
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-between "
                    controlId="formPlainPassword"
                  >
                    <Form.Label column md={3} className="input_label">
                      Password
                    </Form.Label>
                    <Col md={9}>
                      <InputGroup className="pb-2 form_label">
                        <Form.Control
                          type={showHidden2 ? "text" : "password"}
                          onChange={(event) => setPassword(event.target.value)}
                          value={password}
                          placeholder="Enter your Password"
                          className="form_input_field border-end-0 border_bottom_right_radius_0 border_top_right_radius_0"
                        />
                        <InputGroup.Text
                          className="bg-white form_input_group_text"
                          onClick={() => setShowHidden2(!showHidden2)}
                        >
                          {showHidden2 === false ? (
                            <Image
                              src={images.Show}
                              height="20px"
                              className="px-2"
                            ></Image>
                          ) : (
                            <Image
                              src={images.Hide}
                              height="20px"
                              className="px-2"
                            ></Image>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                )}
                {selectedCredOption === "key" && (
                  <>
                    <Form.Group
                      as={Row}
                      className=" mb-3 justify-content-between"
                      controlId="formPlainTextAreaCertificate"
                    >
                      <Form.Label column md={3} className="input_label">
                        Certificate/Token
                      </Form.Label>
                      <Col md={9} className>
                        <div className="d-flex mb-3">
                          <Form.Check
                            type="radio"
                            id="radio-text"
                            name="radioGroup"
                            label="Text"
                            className="me-3 ps-1"
                            checked={certificateInputMethod === "text"}
                            onChange={() => setCertificateInputMethod("text")}
                          />
                          <Form.Check
                            type="radio"
                            id="radio-upload"
                            name="radioGroup"
                            label="Upload"
                            className=""
                            checked={certificateInputMethod === "upload"}
                            onChange={() => setCertificateInputMethod("upload")}
                          />
                        </div>
                        {certificateInputMethod === "text" && (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={certificate}
                            onChange={(event) =>
                              setCertificate(event.target.value)
                            }
                            placeholder="Certificate/Token"
                            className="form_input_field"
                          />
                        )}
                        {certificateInputMethod === "upload" && (
                          <>
                            <UploadButtonComponent
                              setOpenUpload={setOpenFileImport}
                            />
                            <FileUpload
                              width={"60%"}
                              openModal={openFileImport}
                              setOpenModal={setOpenFileImport}
                              maxFileSize={maxFileSize}
                              formLabel={"Select Certificate file"}
                              heading={"Upload Certificate file"}
                              fileInvalidMessage={fileInvalidMessage}
                              invalidFileDataMessage={
                                "Selected file does not contain valid data"
                              }
                              fileType={["pem"]}
                              handleFileSubmit={handleFileSubmit}
                            />
                          </>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3 justify-content-between"
                      controlId="formPlainPassword"
                    >
                      <Form.Label column md={3} className="input_label">
                        Passphrase
                      </Form.Label>
                      <Col md={9}>
                        <InputGroup className="pb-2 form_label">
                          <Form.Control
                            type={showHidden1 ? "text" : "password"}
                            onChange={(event) =>
                              setPassphrase(event.target.value)
                            }
                            value={passphrase}
                            placeholder="Enter your Passphrase"
                            className="form_input_field border-end-0 border_bottom_right_radius_0 border_top_right_radius_0"
                          />
                          <InputGroup.Text
                            className="bg-white form_input_group_text"
                            onClick={() => setShowHidden1(!showHidden1)}
                          >
                            {showHidden1 === false ? (
                              <Image
                                src={images.Show}
                                height="20px"
                                className="px-2"
                              ></Image>
                            ) : (
                              <Image
                                src={images.Hide}
                                height="20px"
                                className="px-2"
                              ></Image>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </div>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="assets"
              >
                <Form.Label column md={3} className="input_label">
                  Assets
                </Form.Label>
                <Col md={9}>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    styles={selectStyles}
                    onChange={(e) => updateAssets(e)}
                    options={assetoptions}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="apps"
              >
                <Form.Label column md={3} className="input_label">
                  Apps
                </Form.Label>
                <Col md={9}>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => updateApps(e)}
                    isMulti
                    styles={selectStyles}
                    options={appoptions}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="apps"
              >
                <Form.Label column md={3} className="input_label">
                  Web Apps
                </Form.Label>
                <Col md={9}>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => updateWebapps(e)}
                    isMulti
                    styles={selectStyles}
                    options={webappoptions}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="switch"
              >
                <Form.Label column md={3} className="input_label">
                  Mark as Complete
                </Form.Label>
                <Col md={9}>
                  <Form.Check
                    type="switch"
                    id="iscomplete"
                    checked={iscomplete}
                    onChange={() => setIscomplete(!iscomplete)}
                  />
                </Col>
              </Form.Group>
              <Row className="pt-3 mb-3 justify-content-end">
                <Col sm="auto">
                  <GeneralButton
                    onClickEvent={() => setOpenAddSystemUser((o) => !o)}
                    className="me-1"
                    value="Cancel"
                    color="#505050"
                    variant="outlined"
                    size="large"
                  ></GeneralButton>
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {loading ? (
                    <GeneralButton
                      variant="contained"
                      disabled={true}
                      className="me-1"
                      value={
                        <img src={images.Loader} width="26px" height="26px" />
                      }
                      size="large"
                    />
                  ) : (
                    <GeneralButton
                      className="me-1"
                      value="Add"
                      variant="contained"
                      size="large"
                    />
                  )}
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      </div>
    </Fade>
  );
};

export default AddSystemUser;
