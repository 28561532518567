import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions'

export const myAppsList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/apps/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}
export const deleteMyApp = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/apps/app/`;
        const response = await axios.delete(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}
export const getMyAppDetail = async ({app_id}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/apps/app/?app_id=${app_id}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}
export const updateMyApp = async ({app_id, updatedApp}) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/apps/app/?app_id=${app_id}`;
        const response = await axios.put(url, updatedApp, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const addApp = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/apps/app/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const nonAllocatedAppGroupList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/allocate-group/?app_id=${data.app_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const allocatedAppGroupList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/deallocate-group/?app_id=${data.app_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const allocateAppToGroupApi = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/apps/allocate-group/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const deallocateAppFromGroup = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/apps/deallocate-group/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const myAppsListUser = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/userapp/?resourcetype=apps&userid=${data.userid}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const myAppsAllocatedSystemUsers = async (app_id) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/apps/userapp/?resourcetype=systemuser&app_id=${app_id}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}





export const getToken = async () => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/autheticate/resource_authenticate/`;
        const response = await axios.get(url, config);
        return {tokenData: response.data, tokenError: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const notAllocatedToAppUsersList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/apps/not-allocated-users/?app_id=${data.app_id}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const allocateUsersToApp = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const body = {
            user_ids: data.ids,
            app_id: data.app_id,
            allocation_from: data.allocation_from,
            allocation_till: data.allocation_till,
            policy: data.policy,
            systemuser_id: data.systemuser_id,
            allocation_type: data.allocation_type,
        }
        const url = `/apps/allocate-user/?app_id=${data.app_id}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.post(url, body, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const AllocatedUsersToAppList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/apps/allocated-users/?app_id=${data.app_id}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const DeAllocateUsers = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: {
                user_ids: data.ids,
                app_id: data.app_id

            },
        };
        const url = `/apps/allocated-users/?app_id=${data.app_id}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.delete(url, config, data);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const ExtendAppAllocation = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/apps/allocate-user/`;
        const response = await axios.put(url, data, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const getAppAllocationInfo = async (data) => {
    try{
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            params: {
                entity_id: data.entity_id,
                app_id: data.app_id,
                entity_type: data.entity_type
            }
        };
        const response = await axios.get('/apps/app-allocation-window/', config);
        return {data: response.data, error: null}
    }
    catch (e){
        return {data: null, error: e.response.data.errors};
    }
};


export const notAllocatedToResourceRolesList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/roles/resource-role-allocate/?resource_id=${data.resource_id}&resource_type=${data.resource_type}&allocated=${data.allocated}&page=${data.page}&page_size=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const allocateRolesToResource = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const body = {
            role_ids: data.ids,
            resource_id: data.resource_id,
            resource_type: data.resource_type,
            allocate: data.allocate,
            allocation_from: data.allocation_from,
            allocation_till: data.allocation_till,
            systemuser_id: data.systemuser_id,
            policy: data.policy,
            allocation_type: data.allocation_type,
        }
        const url = `/roles/resource-role-allocate/`;
        const response = await axios.post(url, body, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
};

export const ExtendRoleAllocation = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/roles/resource-role-allocate/`;
        const response = await axios.put(url, data, config);
        return {data: response.data, error: null};
    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}