import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allocateAssetsToUser,
  notAllocatedUserAssetsList,
} from "../../../../api/users";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import { SystemUserResourceList } from '../../../../api/systemuser';
import {Slide, Fade} from "@mui/material";
import {delayTime, slideDirection, fadedelayTime} from "../../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";

const AllocateUserToAsset = ({ userName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateUserToAsset") !== null
      ? localStorage.getItem("rowsPerPage_AllocateUserToAsset")
      : 5
  );
  const [assetsList, setAssetsList] = useState([]);
  const [assets, setAssets] = useState(null);
  const [totalApps, setTotalApps] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateUser, setOpenAllocateUser] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const [checkedState, setCheckedState] = useState([]);
  const pages_url = "dashboard/userAssets";
  const columnList = ["Asset Type", "ID"];
  const customizeColumnDict = {
    "Asset Id": true,
    "Asset Type": true,
    Port: true,
    "Ignore-Certificate": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const assetColumns = [
    { label: "Asset Id", value: "id" },
    { label: "Asset Name", value: "asset_name" },
    { label: "IP Address", value: "ip_host" },
    { label: "Asset Type", value: "asset_type" },
    { label: "Port", value: "port" },
  ];
  const title_heading = useSelector((state) => state.header.title);
  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    dispatch(changeTitle("AllocateAssets"));
  }, []);

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, assets]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedUserAssetsList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      type: "assets",
    });
    if (data !== null) {
      setAssetsList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalApps(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = assetsList.filter((object) => {
      if (!filterBy) {
        return (
          object.asset_name.toLowerCase().match(s.toLowerCase()) ||
          object.ip_host.toLowerCase().match(s.toLowerCase()) ||
          object.asset_type.toLowerCase().match(s.toLowerCase()) ||
          object.id.toString().toLowerCase().match(s.toLowerCase()) ||
          object.port.toString().toLowerCase().match(s.toLowerCase())
        );
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateUserToAsset", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

    const handleAllocate = async (row) => {
        let rowsToAllocate = [];
        if (row) {
            rowsToAllocate = row;
        } else {
            rowsToAllocate = selectedRows;
        }
        setToBeAllocate(rowsToAllocate);
        let temp = [];
        temp = rowsToAllocate.map((item) => ({"id": item.id, "name" :item.asset_name}));
        setColName(temp);
        setOpenAllocateUser((o) => !o);
    };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy, systemuser_id, allocation_type) => {
    let ids = toBeAllocate.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await allocateAssetsToUser({
      ids: ids,
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      user_id: user_id,
      systemuser_id: systemuser_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      type: "assets",
      allocation_type: allocation_type,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalApps % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setAssets(Object.create(null));
      setOpenAllocateUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Asset Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["Asset Id"],
      sortable: true,
    },
    {
      name: "Asset Name",
      selector: (row) => row.asset_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (row) => row.ip_host,
      grow: 1,
      sortable: true,
    },
    {
      name: "Asset Type",
      selector: (row) => row.asset_type,
      grow: 1,
      omit: !checkedStateDict["Asset Type"],
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      grow: 1,
      omit: !checkedStateDict["Port"],
      sortable: true,
    },
    {
      name: "Ignore-Certificate",
      selector: (row) =>
        row.asset_type === "rdp"
          ? row.ignore_certificate
            ? "Yes"
            : "No"
          : "-",
      grow: 1,
      omit: !checkedStateDict["Ignore-Certificate"],
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.assets.allocate_assets && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "AllocateAssets":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Allocate Assets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateUser}
                    handleClose={() => {
                      setOpenAllocateUser((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateUser}
                      handleAlertAction={handleAllocateAction}
                      SystemUserResourceList={SystemUserResourceList}
                      colName={colName}
                      target_entity_name={userName}
                      target_type="Assets"
                      resource_type={"assets"}
                      alertMsg="Below assets will be allocated for user"
                      headingMsg="Allocate Assets"
                      reverse_allocate = {true}
                    />
                  </CustomModal>
                )}

                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`User : ${userName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalApps}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="asset"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={assetColumns}
                        actionOptions={
                          isCapable && isCapable.assets.allocate_assets
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        setCheckedStateDict={setCheckedStateDict}
                        checkedStateDict={checkedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateUserToAsset;