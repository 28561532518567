import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  groupWebAppAllocationList,
  webAppAllocateToGroups,
} from "../../../../api/web_app";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import images from "../../../../utils/images";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import '../../../common/css/RDT_common.scss';
import { SystemUserResourceList } from "../../../../api/systemuser";


const AllocateWebAppToGroups = ({ webAppName, ActionComponent }) => {
  const { web_app_id } = useParams();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [totalGroups, setTotalGroups] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateWebAppToGroup") !== null
      ? localStorage.getItem("rowsPerPage_AllocateWebAppToGroup")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowsToAllocate, setRowsToAllocate] = useState([]);
  const [allocateGroupNames, setAllocateGroupNames] = useState([]);
  const [openAllocateGroup, setOpenAllocateGroup] = useState(false);
  const [changeState, setChangeState] = useState(null);
  const pages_url = "dashboard/WebAppsTogroups";
  const customizeColumnDict = { Id: true, "Display Name": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const groupColumns = [
    { label: "ID", value: "group_id" },
    { label: "Name", value: "group_name" },
    { label: "Display Name", value: "group_display_name" },
  ];
  const actionOptions = [{ label: "Allocate", value: "allocate" }];
  const getGroups = async () => {
    const { data } = await groupWebAppAllocationList({
      web_app_id: web_app_id,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filter_by: filterBy,
      action: "get_not_allocated",
    });
    if (data !== null) {
      setGroups(data.groups);
      setTotalGroups(data.totalGroups);
      setFilteredData(data.groups);
    }
    setPending(false);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    getGroups();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("WebApps"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getGroups();
  }, [page, rowsPerPage, changeState]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = groups.filter((object) => {
      return (
        object.group_name.toLowerCase().match(s.toLowerCase()) ||
        object.group_display_name.toLowerCase().match(s.toLowerCase()) ||
        object.group_id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateWebAppToGroup", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleGroupAllocate();
    }
  };
  const handleGroupAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    let temp = rowsToAllocate.map((row) => ({
      id: row.group_id,
      name: row.group_name,
    }));
    setAllocateGroupNames(temp);
    setRowsToAllocate(rowsToAllocate);
    setOpenAllocateGroup((o) => !o);
  };
  const handleAllocateWebAppAction = async (finalFromDate, finalToDate, policy, systemuser_id, allocation_type) => {
    let group_ids = rowsToAllocate.map((item) => item.group_id);
    const { data, error } = await webAppAllocateToGroups({
      web_app_ids: [web_app_id],
      group_ids: group_ids,
      systemuser_id: systemuser_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      allocation_type: allocation_type,
    });
    if (data !== null) {
      showSuccess(data.message);
      setChangeState(Object.create(null));
      setOpenAllocateGroup((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.group_id,
      grow: 1,
      omit: !checkedStateDict["Id"],
    },
    {
      name: "Name",
      selector: (row) => row.group_name,
    },
    {
      name: "Display Name",
      selector: (row) => row.group_display_name,
      omit: !checkedStateDict["Display Name"],
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.web_apps.allocate_web_apps && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleGroupAllocate([row]);
              }}
            >
              <AiOutlineUsergroupAdd></AiOutlineUsergroupAdd> Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "WebApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={images.WebAppHeading}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Web Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {allocateGroupNames.length > 0 && (
                  <CustomModal
                    open={openAllocateGroup}
                    handleClose={() => {
                      setOpenAllocateGroup((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateGroup}
                      handleAlertAction={handleAllocateWebAppAction}
                      SystemUserResourceList={SystemUserResourceList}
                      resource_id={web_app_id}
                      resource_type={"webapps"}
                      colName={allocateGroupNames}
                      alertMsg="Below groups will be allocated for web app"
                      headingMsg="Allocate Groups"
                      target_entity_name={webAppName}
                      resource_name={webAppName}
                      target_type="Groups"
                      show_policy={false}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`WebApp : ${webAppName}`}
                        dataTableHeadingIcon={images.WebAppHeading}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={3}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalGroups}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="groups"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={groupColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateWebAppToGroups;
