import React, { useEffect, useState } from "react";
import "./MFAList.scss";
import images from "../../../../utils/images";
import {
  enableAdminMfa,
  enableMFAMethod,
  fetchMiniorangeMFAConfiguration,
  fetchQrCodeAndSecret,
  getMFAMethodList,
} from "../../../../api/two_fa.js";
import GoogleAuthenticator from "../GoogleAuthenticator/GoogleAuthenticator";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomSwitch from "../../../common/CustomSwitch/CustomSwitch";
import MiniorangeAuthenticator from "../MiniorangeAuthenticator/MiniorangeAuthenticator";
import SMS from "../SMS/SMS";
import Loader from "../../../common/Loader/Loader";
import { showError, showSuccess } from "../../../../utils/showMessage";
import KnowledgeBasedQuestion from "../KnowledgeBasedQuestion/KnowledgeBasedQuestion";
import Button from "@mui/material/Button";
import {
  MdTextsms,
  MdLink,
  MdEmail,
  MdGeneratingTokens,
  MdCable,
  MdOutlineQrCode,
} from "react-icons/md";
import { HiPhoneIncoming } from "react-icons/hi";
import { BsQuestionLg } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import getWindowDimensions from "../../../../utils/getHeightWidth";
import DisplayToken from "../DisplayToken/DisplayToken";
import MFAListSkeleton from "../Skeleton2FA/MFAListSkeleton";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../../state/slices/header";
import NotAllowed from "../NotAllowed/NotAllowed";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import YubikeyToken from "../YubikeyToken/YubikeyToken";
import {
  delayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";

import { useSelector } from "react-redux";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import GetCapabilities from "../../../../utils/getCapabilities";

const MFAList = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 190 + "px";
  const [methods, setMethods] = useState([]);
  const [currentSelectedMethod, setCurrentSelectedMethod] =
    useState("Authenticator App");
  const [currentSelectedCategoryUpdated, setCurrentSelectedCategoryUpdated] =
    useState(null);
  const [openGoogleAuthenticator, setOpenGoogleAuthenticator] = useState(false);
  const [openMiniorangeAuthenticator, setOpenMiniorangeAuthenticator] =
    useState(false);
  const [openYubikeyToken, setOpenYubikeyToken] = useState(false);
  const [openKBA, setOpenKBA] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [txid, setTxid] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [configuredMethod, setConfiguredMethod] = useState("");
  const [editProcessMethodName, setEditProcessMethodName] = useState("");
  const [editProcessMethodHeading, setEditProcessMethodHeading] = useState("");
  const [editProcessMethodNote, setEditProcessMethodNote] = useState("");
  const [email, setEmail] = useState();
  const [phone_number, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDisplayToken, setOpenDisplayToken] = useState(false);
  const [isAdminMfaConf, setIsAdminMfaConf] = useState(false);
  const [isMethodConfigured, setIsMethodConfigured] = useState({});
  const [configured_questions, setConfiguredQuestions] = useState(null);
  const [slideChecked, setSlideChecked] = useState(false);
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  let icons = {
    "OTP over SMS": <MdTextsms size={"1.7em"} style={{ color: "#5177FF" }} />,
    "SMS Link": <MdLink size={"1.7em"} style={{ color: "#5177FF" }} />,
    "OTP over SMS and Email": (
      <MdEmail size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "OTP over Email": <MdTextsms size={"1.7em"} style={{ color: "#5177FF" }} />,
    "Email Link": <MdLink size={"1.7em"} style={{ color: "#5177FF" }} />,
    "Soft Token": (
      <MdGeneratingTokens size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "Push Notification": (
      <IoMdNotificationsOutline size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "QR Code Authentication": (
      <MdOutlineQrCode size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "OTP over Call": (
      <HiPhoneIncoming size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "Yubikey Token": <MdCable size={"1.7em"} style={{ color: "#5177FF" }} />,
    "Display Token": (
      <MdGeneratingTokens size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "Security Questions": (
      <BsQuestionLg size={"1.7em"} style={{ color: "#5177FF" }} />
    ),
    "Google Authenticator": (
      <img src={images.GoogleAuthenticator} style={{ width: "1.7em" }} />
    ),
  };
  const isMethodAllowToDisplay = (method) => {
    if (method.isAllowed && !isCapable) {
      return true;
    }
    if (isCapable) {
      return true;
    }
    return false;
  };
  const isSidebarAllowToDisplay = (category) => {
    let result = false;
    if (isCapable) {
      return true;
    }
    for (let index in category) {
      if (category[index].isAllowed === true) {
        result = true;
        break;
      }
    }
    return result;
  };
  let methodObj;

  const setInitialCategory = (methods) => {
    for (let index in Object.keys(methods)) {
      let cate = Object.keys(methods)[index];
      if (isSidebarAllowToDisplay(methods[cate])) {
        setCurrentSelectedMethod(
          currentSelectedCategoryUpdated !== null
            ? currentSelectedCategoryUpdated
            : cate
        );
        break;
      }
    }
  };
  const getMFAMethods = async () => {
    const { data, error } = await getMFAMethodList();
    if (data !== null) {
      setInitialCategory(data.methods);
      setMethods(data.methods);
      setIsAdminMfaConf(data.is_admin_two_fa_enabled);
      setConfiguredMethod(data.configured_method);
    }
  };
  useEffect(() => {
    getMFAMethods();
    dispatch(changeTitle("TWOFA"));
  }, [isMethodConfigured]);

  const handleChangeMethodCategory = (key) => {
    setCurrentSelectedMethod(key);
    setCurrentSelectedCategoryUpdated(key);
  };

  const getMethodObjectByName = (methodName) => {
    for (const methodArr of Object.values(methods)) {
      const methodObj = methodArr.find((obj) => obj.method_name === methodName);
      if (methodObj) {
        return methodObj;
      }
    }
    return null;
  };
  const handleSwitchCheck = async (event, methodName) => {
    const { data, error } = await enableMFAMethod({ method: methodName });
    if (data != null) {
      showSuccess(data.message);
      setIsAdminMfaConf(data.is_admin_two_fa_enabled);
      setConfiguredMethod(data.method);
    }
    if (error != null) {
      showError(error);
    }
  };
  const handleEnableAdminMFA = async (event) => {
    // event.preventDefault();
    const { data, error } = await enableAdminMfa();
    if (data != null) {
      showSuccess(data.message);
      setIsAdminMfaConf((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
  };
  const handleMethodEdit = (method) => {
    switch (method) {
      case "google_authenticator":
        fetchQrCode();
        break;
      case "soft_token":
      case "push_notification":
      case "qr_code_authentication":
        getConfiguration(method);
        setEditProcessMethodName(method);
        methodObj = getMethodObjectByName(method);
        setEditProcessMethodHeading(methodObj.name);
        setEditProcessMethodNote(methodObj.note);
        break;
      case "otp_over_sms":
      case "otp_over_email":
      case "sms_link":
      case "email_link":
      case "otp_over_sms_email":
      case "otp_over_call":
        getConfiguration(method);
        setEditProcessMethodName(method);
        methodObj = getMethodObjectByName(method);
        setEditProcessMethodHeading(methodObj.name);
        setEditProcessMethodNote(methodObj.note);
        break;
      case "knowledge_base_question":
        getConfiguration(method);
        break;
      case "yubikey_token":
        setEditProcessMethodName(method);
        methodObj = getMethodObjectByName(method);
        setEditProcessMethodHeading(methodObj.name);
        setEditProcessMethodNote(methodObj.note);
        setOpenYubikeyToken((o) => !o);
        break;

      case "display_token":
        setOpenDisplayToken((o) => !o);
        break;
    }
  };

  const fetchQrCode = async () => {
    setLoading(true);
    const { data, error } = await fetchQrCodeAndSecret();
    if (data !== null) {
      setQrCode(data.qr_code);
      setSecretKey(data.secret_key);
      setOpenGoogleAuthenticator((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
    setLoading(false);
  };
  const getConfiguration = async (methodName) => {
    setLoading(true);
    const { data, error } = await fetchMiniorangeMFAConfiguration(methodName);
    if (data != null) {
      switch (methodName) {
        case "otp_over_sms":
        case "otp_over_email":
        case "sms_link":
        case "email_link":
        case "otp_over_sms_email":
        case "otp_over_call":
          setCountryCode(data.user.country_code);
          setPhoneNumber(data.user.country_code + data.user.phone_number);
          setEmail(data.user.email);
          setLoading(false);
          setOpenSMS((o) => !o);
          break;
        case "soft_token":
        case "push_notification":
        case "qr_code_authentication":
          setQrCode("data:image/png;base64," + data.content.qrCode);
          setTxid(data.content.txId);
          setLoading(false);
          setOpenMiniorangeAuthenticator((o) => !o);
          break;
        case "knowledge_base_question":
          setQuestions(data.questions);
          setConfiguredQuestions(data.configured_questions);
          setOpenKBA((o) => !o);
          setLoading(false);
          break;
      }
    }
    if (error != null) {
      setLoading(false);
      showError(error);
    }
  };

  const displayNotAllowed = () => {
    let showDisplay = true;
    for (const key in Object.keys(methods)) {
      if (isSidebarAllowToDisplay(methods[Object.keys(methods)[key]])) {
        return false;
      }
    }
    return showDisplay;
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "TWOFA":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={images.Key} alt="users round icon" /> &nbsp;
                        Two Factor Authentication
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      {loading ? (
        <Loader loading={loading} bg_papper={true} />
      ) : (
        <>
          <CustomModal
            open={openGoogleAuthenticator}
            handleClose={() => {
              setOpenGoogleAuthenticator((o) => !o);
            }}
          >
            <GoogleAuthenticator
              qrCode={qrCode}
              secretKey={secretKey}
              setConfiguredMethod={setConfiguredMethod}
              setOpenModal={setOpenGoogleAuthenticator}
              setIsMethodConfigured={setIsMethodConfigured}
            />
          </CustomModal>
          <CustomModal
            open={openMiniorangeAuthenticator}
            handleClose={() => {
              setOpenMiniorangeAuthenticator((o) => !o);
            }}
          >
            <MiniorangeAuthenticator
              setOpenModal={setOpenMiniorangeAuthenticator}
              methodName={editProcessMethodName}
              methodHeading={editProcessMethodHeading}
              methodNote={editProcessMethodNote}
              setConfiguredMethod={setConfiguredMethod}
              qrCode={qrCode}
              txid={txid}
              setIsMethodConfigured={setIsMethodConfigured}
            />
          </CustomModal>
          <CustomModal
            open={openSMS}
            handleClose={() => {
              setOpenSMS((o) => !o);
            }}
          >
            <SMS
              email={email}
              phone_number={phone_number}
              setPhoneNumber={setPhoneNumber}
              setCountryCode={setCountryCode}
              countryCode={countryCode}
              setOpenModal={setOpenSMS}
              methodName={editProcessMethodName}
              methodHeading={editProcessMethodHeading}
              methodNote={editProcessMethodNote}
              setConfiguredMethod={setConfiguredMethod}
              setIsMethodConfigured={setIsMethodConfigured}
            />
          </CustomModal>
          <CustomModal
            open={openKBA}
            handleClose={() => {
              setOpenKBA((o) => !o);
            }}
          >
            <KnowledgeBasedQuestion
              questions={questions}
              configured_questions={configured_questions}
              setConfiguredMethod={setConfiguredMethod}
              setOpenModal={setOpenKBA}
              setIsMethodConfigured={setIsMethodConfigured}
            />
          </CustomModal>
          <CustomModal
            open={openDisplayToken}
            handleClose={() => {
              setOpenDisplayToken((o) => !o);
            }}
          >
            <DisplayToken setOpenModal={setOpenDisplayToken} />
          </CustomModal>
          <CustomModal
            open={openYubikeyToken}
            handleClose={() => {
              setOpenYubikeyToken((o) => !o);
            }}
          >
            <YubikeyToken
              setOpenModal={setOpenYubikeyToken}
              methodName={editProcessMethodName}
              methodHeading={editProcessMethodHeading}
              methodNote={editProcessMethodNote}
              setConfiguredMethod={setConfiguredMethod}
              setIsMethodConfigured={setIsMethodConfigured}
            />
          </CustomModal>
        </>
      )}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  <div className="configure_mfa_container_main bg-white">
                    <div className="configure_mfa_heading d-flex flex-row justify-content-start align-items-center py-1">
                      <img
                        src={images.Key}
                        alt="EGC"
                        className="mfa_heading_image"
                      />
                      <h2 className="main-heading">Authentication Methods</h2>
                    </div>
                    <div className="configure_mfa_body mt-5">
                      {configuredMethod && (
                        <div className="d-flex flex-row justify-content-start align-items-center mb-4">
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            <p className="m-0 admin_active_method">
                              Active Method
                            </p>
                            <p className="m-0 mx-4 admin_active_method badge bg-light text-dark">
                              {getMethodObjectByName(configuredMethod).name}
                            </p>
                          </div>
                          {isCapable && (
                            <div className="d-flex flex-row justify-content-start align-items-center">
                              <CustomSwitch
                                checked={isAdminMfaConf ? true : false}
                                loading={true}
                                onClick={handleEnableAdminMFA}
                                className="me-4"
                              />
                              <p className="m-0 admin_active_method">
                                Enable Two Factor Authentication
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {methods.length === 0 ? (
                        <MFAListSkeleton />
                      ) : displayNotAllowed() ? (
                        <NotAllowed />
                      ) : (
                        <div className="mfa-methods-list">
                          <div className="row justify-content-around h-75">
                            <div
                              id="sidebar-method"
                              className="col-12 col-lg-3 px-0 d-flex flex-column justify-content-start align-items-start"
                            >
                              <ul
                                className={`${
                                  width >= 992 ? "border-end" : "border-none"
                                } w-100 px-0  mfa_sidebar_container d-lg-block d-flex justify-content-start align-items-center overflow-auto`}
                              >
                                {Object.keys(methods).map((key) => (
                                  <>
                                    {isSidebarAllowToDisplay(methods[key]) && (
                                      <li
                                        key={key}
                                        className={`mfa_sidebar cursor_pointer d-flex flex-row justify-content-start align-items-center ${
                                          key === currentSelectedMethod &&
                                          `${
                                            width >= 992
                                              ? "border-end"
                                              : "border-bottom mx-4"
                                          } border-2 border-primary mfa_sidebar_active`
                                        }`}
                                        onClick={() => {
                                          handleChangeMethodCategory(key);
                                        }}
                                      >
                                        <img
                                          src={images[key.replace(" ", "")]}
                                          alt="ICON"
                                        />
                                        <p className="mx-3 my-0">{key}</p>
                                      </li>
                                    )}
                                  </>
                                ))}
                              </ul>
                            </div>

                            <div
                              id="main-content-mfa"
                              className="col-12 col-lg-9 d-flex flex-column justify-content-start align-items-center"
                            >
                              <ul
                                id={`${currentSelectedMethod
                                  .toLowerCase()
                                  .replace(" ", "_")}`}
                                className="mfa_container w-100 ps-0 ps-lg-4"
                              >
                                {methods.length != 0 &&
                                  methods[currentSelectedMethod].map(
                                    (method) => (
                                      <>
                                        {isMethodAllowToDisplay(method) && (
                                          <li
                                            key={method.method_name}
                                            id={`${method.method_name}`}
                                            className="card"
                                          >
                                            <div className="card-body py-3">
                                              <div className="d-flex flex-row justify-content-between align-items-center">
                                                <div className="d-flex flex-row justify-content-start align-items-center">
                                                  {icons[method.name]}
                                                  <h3
                                                    className="my-0 mx-2 method-heading"
                                                    id="{{ method.method_name }}_heading"
                                                  >
                                                    {method.name}
                                                  </h3>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                  {method.isConfigured && (
                                                    <CustomSwitch
                                                      checked={
                                                        configuredMethod ===
                                                        method.method_name
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={(event) => {
                                                        handleSwitchCheck(
                                                          event,
                                                          method.method_name
                                                        );
                                                      }}
                                                      className="me-4"
                                                    />
                                                  )}
                                                  <p
                                                    className="method-configure-link myassetslink"
                                                    onClick={() => {
                                                      handleMethodEdit(
                                                        method.method_name
                                                      );
                                                    }}
                                                    id={`${method.method_name}_configure`}
                                                  >
                                                    {method.isConfigured
                                                      ? "Reconfigure"
                                                      : "Configure"}{" "}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-footer bg-white text-start border-top">
                                              <p
                                                className="py-3 my-0 method-description"
                                                id="{{ method.method_name }}_description"
                                              >
                                                {method.note}
                                              </p>
                                            </div>
                                          </li>
                                        )}
                                      </>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};
export default MFAList;
