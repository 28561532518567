import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ImportGroups = ({groupSearchBase, setGroupSearchBase, importgroups, creategroup, editgroup, deletegroup, setImportgroups, setCreategroup, setEditgroup, setDeletegroup}) => {
    return (
    <>
        <Container fluid className="mx-auto add_user_container main_content_container">
            <Form.Group as={Row} className="mb-3 justify-content-between">
            <Col md={2}>
            <Form.Label className="input_lable">
                Search Base
            </Form.Label>
            </Col>
            <Col md={4}>
            <Form.Control
                type="text"
                className="form_input_field"
                value={groupSearchBase}
                onChange={(event) => setGroupSearchBase(event.target.value)}
                placeholder="Search Base of Directory"
                autoComplete="off"
                />
            </Col>
            <Col md={6}></Col>
            </Form.Group>
            <div className='d-flex ps-1 pb-3 align-items-center'>
                <Form.Check
                    type="switch"
                    checked={importgroups}
                    onChange={(e) => {setImportgroups(e.target.checked)}}
                />
                <div className='ps-3 input_lable'>
                    <div>Import Groups</div>
                    <div className='fw-400'>Enabling this option will import the groups and its users in the PAM database</div>
                </div>
            </div>
            {/* TODO: These options are related to provisioning and are yet to be implemented */}
            {/* <div className='d-flex ps-1 pb-3 align-items-center'>
                <Form.Check
                    type="switch"
                    checked={creategroup}
                    onChange={(e) => {setCreategroup(e.target.checked)}}
                />
                <div className='ps-3 input_lable'>
                    <div>Create Group</div>
                    <div className='fw-400'>Enabling this option will create the group in the selected application upon group creation in PAM</div>
                </div>
            </div>
            <div className='d-flex ps-1 pb-3 align-items-center'>
                <Form.Check
                    type="switch"
                    checked={editgroup}
                    onChange={(e) => {setEditgroup(e.target.checked)}}
                />
                <div className='ps-3 input_lable'>
                    <div>Edit Group</div>
                    <div className='fw-400'>Enabling this option will update the group in the selected application if updated in PAM</div>
                </div>
            </div>
            <div className='d-flex ps-1 pb-3 align-items-center'>
                <Form.Check
                    type="switch"
                    checked={deletegroup}
                    onChange={(e) => {setDeletegroup(e.target.checked)}}
                />
                <div className='ps-3 input_lable'>
                    <div>Delete Groups</div>
                    <div className='fw-400'>Enabling this option will delete the group from the selected application if the group is deleted from PAM</div>
                </div>
            </div> */}
        </Container>
    </>)
    
}

export default ImportGroups;