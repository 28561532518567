import React from "react";
import { styled } from "@mui/material";
import image from "../../../../utils/images";
import MuiButton from "@mui/material/Button";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PasswordLightToolTip } from "../../../common/CustomTooltip/CustomTooltip";
import {
  updatePassPolicy,
  createPassPolicy,
  getPasswordPolicyAdd,
  getEmailConfigBoolean
} from "../../../../api/vault";
import { showError, showSuccess } from "../../../../utils/showMessage";
import "./passwordpolicy.scss";
import Select, { components } from "react-select";
import { SettingDarkToolTip } from "../../../common/CustomTooltip/CustomTooltip";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import GetCapabilities from "../../../../utils/getCapabilities";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../../state/slices/header";
import { useNavigate } from "react-router";
import DataTable from "react-data-table-component";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import GeneralButton from "../../../common/SaveButton/GeneralButton"
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const SavePasswordPolicyButtonComponent = () => {
  return (
    <GeneralButton
    className="me-1"
    value="Save"
    variant="outlined"
    size="large"
    type="submit"
    form="password-policy-form"
  ></GeneralButton>
  );
};

export const PasswordPolicyAdd = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 195 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const dispatch = useDispatch();
  const [policyId, setPolicyId] = useState(null);
  const userExpireOptions = [
    {
      label: "Update password in login flow",
      value: "login",
      enabled: true,
    },
    {
      label: "Update password through email",
      value: "email",
      enabled: true,
    },
  ];
  useEffect(() => {
    dispatch(changeTitle("Policy"));
    setSlideChecked(true);
    setUserExpireOptionsState(userExpireOptions);
    if (password_policy_id !== null) {
      getPassPolicy();
    } else {
      getEmailConfig();
    }
  }, []);

  const getEmailConfig = async () => {
    const { data, error } = await getEmailConfigBoolean(password_policy_id);
    if (data !== null) {
      email_configuration_exist = data.email_config_exist
      updateUserExpiryEmailOption(userExpireOptions,email_configuration_exist)
      setGetPolicyDataComplete(true);
    }
  }

  let { password_policy_id } = useParams();
  if (password_policy_id === "Add") {
    password_policy_id = null;
  }
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [minimumLength, setMinimumLength] = useState(4);
  const [maximumLength, setMaximumLength] = useState(7);
  const [expiryDays, setExpiryDays] = useState(30);
  const [lastnpasswords, setLastnpasswords] = useState(10);
  const [lowercase, setLowercase] = useState(true);
  const [uppercase, setUppercase] = useState(true);
  const [number, setNumber] = useState(true);
  const [containUsername, setContainUsername] = useState(false);
  const [containEmail, setContainEmail] = useState(false);
  const [containFirstname, setContainFirstname] = useState(false);
  const [containLastname, setContainLastname] = useState(false);
  const [requireSpecial, setRequireSpecial] = useState(false);
  const [specialSymbols, setSpecialSymbols] = useState("");
  const [userExpireOptionsState, setUserExpireOptionsState] = useState([]);
  const [userExpireOption, setUserExpireOption] = useState("");

  const userExpireValue = () => {
    let a = {};
    userExpireOptionsState.map((value, index) => {
      if (value.value === String(userExpireOption)) {
        a = userExpireOptionsState[index];
      }
    });
    return a;
  };
  const [requireOneFailed, setRequireOneFailed] = useState(false);

  // associated-groups react select logic
  const [isClearable, setIsClearable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getPolicyDataComplete, setGetPolicyDataComplete] = useState(false);
  const associatedGroupsOptions = [
    { value: "pam_user", label: "PAM user", selected: false },
  ];
  const [associatedGroup, setAssociatedGroup] = useState(
    associatedGroupsOptions
  );
  const [associatedGroupList, setAssociatedGroupList] = useState([]);
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {props.children || "Associated-group is not available."}
      </components.NoOptionsMessage>
    );
  };
  // associated-groups react select logic ends

  const updateUserExpiryEmailOption=(tempVariable,email_configuration_exist)=>{
    if (tempVariable.length > 0 || userExpireOptionsState.length > 0 ) {
      let userExpireOptionBackupValues= []
      if (userExpireOptionsState.length === 0){
        userExpireOptionBackupValues= tempVariable
      }
      else{
        userExpireOptionBackupValues= userExpireOptionsState
      }
      const updatedUserExpiredOptions = userExpireOptionBackupValues.map((value,index)=>{
        if(value.value==="email"){
          value.enabled=email_configuration_exist;
          return value;
        }else{
          return value;
        }
      })
      setUserExpireOptionsState(updatedUserExpiredOptions);
    }
  }

  let email_configuration_exist=false;
  const getPassPolicy = async () => {
    const { data, error } = await getPasswordPolicyAdd(password_policy_id);
    if (data !== null) {
      const policy = data.policy;
      setName(policy["name"]);
      setDescription(policy["description"]);
      setMinimumLength(policy["min_password_length"]);
      setMaximumLength(policy["max_password_length"]);
      setExpiryDays(policy["expire_in_days"]);
      setLastnpasswords(policy["last_n_password"]);
      setLowercase(policy["require_lowercase_letter"]);
      setUppercase(policy["require_uppercase_letter"]);
      setNumber(policy["require_number"]);
      setContainEmail(policy["can_contain_email_or_part"]);
      setContainUsername(policy["can_contain_username_or_part"]);
      setContainFirstname(policy["can_contain_firstname_or_part"]);
      setContainLastname(policy["can_contain_lastname_or_part"]);
      setRequireSpecial(policy["require_special_symbols"]);
      setSpecialSymbols(policy["password_special_symbols"]);
      if (policy["user_password_expire_flow"] === "email") {
        setUserExpireOption("email");
      } else {
        setUserExpireOption("login");
      }
      const associated_group_list_from_db = data.association_group_list;
      if (associated_group_list_from_db.length) {
        associated_group_list_from_db.map((item, index) => {
          associatedGroup.map((field) => {
            if (field.value === item) {
              field["selected"] = true;
            }
          });
        });
        setAssociatedGroup([...associatedGroup]);
      }
      email_configuration_exist = data.email_config_exist
      updateUserExpiryEmailOption(userExpireOptions,email_configuration_exist)
      setGetPolicyDataComplete(true);
    }
    if (error !== null) {
      showError(error);
    }
  };

  useEffect(() => {
    if (userExpireOptionsState.length > 0) {
      setUserExpireOption(userExpireOptionsState[0].value);    
    }
  }, [userExpireOptionsState]);

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let require_array = [number, lowercase, uppercase];
      if (requireSpecial === true && specialSymbols.length === 0) {
        setValidated(false);
      } else if (require_array.includes(true) === false) {
        setValidated(false);
      } else {
        setValidated(true);
        let login = "login";
        if (userExpireOption === "email") {
          login = "email";
        }
        if (password_policy_id !== null) {
          updatePasswordPolicy(login);
        } else {
          createPasswordPolicy(login);
        }
      }
    }
  };
  useEffect(() => {
    let x = [];
    if (getPolicyDataComplete) {
      associatedGroup.map((field) => {
        if (field["selected"] === true) {
          x.push(field.value);
        }
      });
      setAssociatedGroupList((o) => x);
    }
  }, [associatedGroup]);

  const createPasswordPolicy = async (login) => {
    const { data, error } = await createPassPolicy(
      {
        ...{ name: name },
        ...{ description: description },
        ...{ min_password_length: minimumLength },
        ...{ max_password_length: maximumLength },
        ...(requireSpecial === true
          ? { password_special_symbols: specialSymbols }
          : { password_special_symbols: "" }),
        ...{ require_special_symbols: requireSpecial },
        ...{ require_lowercase_letter: lowercase },
        ...{ require_uppercase_letter: uppercase },
        ...{ require_number: number },
        ...{ can_contain_email_or_part: containEmail },
        ...{ can_contain_username_or_part: containUsername },
        ...{ can_contain_firstname_or_part: containFirstname },
        ...{ can_contain_lastname_or_part: containLastname },
        ...{ last_n_password: lastnpasswords },
        ...{ expire_in_days: expiryDays },
        ...{ association_groups: associatedGroupList },
        ...{ user_password_expire_flow: login },
      },
      password_policy_id
    );
    if (data !== null) {
      showSuccess(data.message);
      navigate(`/dashboard/policies/password`);
    } else {
      showError(error);
    }
  };
  const updatePasswordPolicy = async (login) => {
    const { data, error } = await updatePassPolicy(
      {
        ...{id: password_policy_id},
        ...{ name: name },
        ...{ description: description },
        ...{ min_password_length: minimumLength },
        ...{ max_password_length: maximumLength },
        ...(requireSpecial === true
          ? { password_special_symbols: specialSymbols }
          : { password_special_symbols: "" }),
        ...{ require_special_symbols: requireSpecial },
        ...{ require_lowercase_letter: lowercase },
        ...{ require_uppercase_letter: uppercase },
        ...{ require_number: number },
        ...{ can_contain_email_or_part: containEmail },
        ...{ can_contain_username_or_part: containUsername },
        ...{ can_contain_firstname_or_part: containFirstname },
        ...{ can_contain_lastname_or_part: containLastname },
        ...{ last_n_password: lastnpasswords },
        ...{ expire_in_days: expiryDays },
        ...{ association_groups: associatedGroupList },
        ...{ user_password_expire_flow: login },
      },
      password_policy_id
    );
    if (data !== null) {
      showSuccess(data.message);
      navigate(`/dashboard/policies/password`);
    } else {
      showError(error);
    }
  };
  const validateSpecialSymbol = () => {
    const regex = new RegExp(/^(?!.*(.).*\1)[^A-Za-z0-9\s]+$/);
    let st = specialSymbols;
    if (regex.exec(st) !== null) {
      return false;
    } else {
      return true;
    }
  };
  const validateRequire = () => {
    if ([lowercase, uppercase, number].includes(true) === false) {
      setRequireOneFailed(true);
    } else {
      setRequireOneFailed(false);
    }
  };
  useEffect(() => {
    validateSpecialSymbol();
  }, [specialSymbols]);
  useEffect(() => {
    validateRequire();
  }, [lowercase, uppercase, number]);
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Policy":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={image.PasswordPolicyIcon}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Policy
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  {/* password policy form starts. */}
                  <div className="heading_title rdt_TableHeader p-0">
                    <div className="d-flex flex-row justify-content-between align-items-center py-3">
                      <div className="abc position-sticky top-0 d-flex align-items-center">
                        <img src={image.PasswordPolicyIcon} alt="password-policy-logo"></img>
                        <h2 className="main-heading ps-3">Password Policy</h2>
                      </div>
                      <SavePasswordPolicyButtonComponent />
                    </div>
                  </div>
                  <div className="px-5 pt-3 pb-3">
                    <Form
                      id="password-policy-form"
                      noValidate
                      validated={validated}
                      onSubmit={handleSave}
                    >
                      <Row className="mt-1">
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Name
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="user_input_field"
                              required
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="input password policy name"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid name.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Description
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              className="user_input_field description"
                              as="textarea"
                              required
                              rows={2}
                              name="description"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              placeholder="input password policy description."
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter password policy description.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Min length (in Characters)
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              className="user_input_field"
                              required
                              name="min_pass_length"
                              min={4}
                              value={minimumLength}
                              onChange={(e) =>
                                setMinimumLength(parseInt(e.target.value))
                              }
                              placeholder="input minimum password length"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid minimum password length.
                              <PasswordLightToolTip
                                className="cursor_pointer"
                                title="Minimum length should be 4."
                              />
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Max length (in Characters)
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              className="user_input_field"
                              type="number"
                              name="max_pass_length"
                              min={4}
                              value={maximumLength}
                              isInvalid={
                                maximumLength >= minimumLength
                                  ? false
                                  : true
                              }
                              onChange={(e) => {
                                setMaximumLength(parseInt(e.target.value));
                              }}
                              placeholder="input maximum password length"
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid maximum password length.
                              <PasswordLightToolTip
                                className="cursor_pointer"
                                title="Maximum length should more than minimum length."
                              />
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Password expiry (in days)
                              <span className="text-danger">*</span>
                              <SettingDarkToolTip
                                className="lh-lg"
                                styles={{ fontSize: "3em" }}
                                title="Password will be expired after selected days."
                              />
                            </Form.Label>
                            <Form.Control
                              className="user_input_field"
                              type="number"
                              required
                              name="password_expire_in_days"
                              min="1"
                              value={expiryDays}
                              onChange={(e) =>
                                setExpiryDays(parseInt(e.target.value))
                              }
                              placeholder="input password expiry in days."
                            />
                          </Form.Group>
                        </Col>
                        <Col className="my-2">
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Store last n Passwords
                              <span className="text-danger">*</span>
                              <SettingDarkToolTip
                                className="lh-lg"
                                styles={{ fontSize: "3em" }}
                                title="Last n passwords (including the current one) will be stored, to avoid password repetition."
                              />
                            </Form.Label>
                            <Form.Control
                              className="user_input_field"
                              type="number"
                              required
                              name="last_n_passwords"
                              min="1"
                              value={lastnpasswords}
                              onChange={(e) =>
                                setLastnpasswords(parseInt(e.target.value))
                              }
                              placeholder="input last n password number."
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="my-3">
                        <span className="policy-condition-label">
                          Password complexity
                        </span>
                      </div>
                      <Row className="mb-2">
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="lowercase"
                              inline
                              type="switch"
                              isInvalid={requireOneFailed}
                              checked={lowercase ? true : false}
                              onChange={() => {
                                setLowercase((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Require Lowercase Letter
                            </Form.Label>
                            {requireOneFailed && (
                              <PasswordLightToolTip
                                className="cursor_pointer"
                                title="Please select at least one require condition out of below.
                                                        1. Require Lowercase Letter.
                                                        2. Require Uppercase Letter.
                                                        3. Require Number."
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="uppercase"
                              inline
                              type="switch"
                              checked={uppercase ? true : false}
                              onChange={() => {
                                setUppercase((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Require Uppercase Letter
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="number"
                              inline
                              type="switch"
                              checked={number ? true : false}
                              onChange={() => {
                                setNumber((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Require Number (0-9)
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="username"
                              inline
                              type="switch"
                              checked={containUsername ? true : false}
                              onChange={() => {
                                setContainUsername((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Can contain Username
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="email"
                              inline
                              type="switch"
                              checked={containEmail ? true : false}
                              onChange={() => {
                                setContainEmail((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Can contain Email or part of it
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="firstname"
                              inline
                              type="switch"
                              checked={containFirstname ? true : false}
                              onChange={() => {
                                setContainFirstname((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Can contain First Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="lastname"
                              inline
                              type="switch"
                              checked={containLastname ? true : false}
                              onChange={() => {
                                setContainLastname((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Can contain Last Name
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="ms-1">
                          <Form.Group>
                            <Form.Check
                              name="special"
                              inline
                              type="switch"
                              checked={requireSpecial ? true : false}
                              onChange={() => {
                                setRequireSpecial((o) => !o);
                              }}
                            />
                            <Form.Label className="policy-condition-label">
                              Require special symbols
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      {requireSpecial && (
                        <Row className="mt-1">
                          <Col lg={6} md={6} sm={12}>
                            <Form.Group>
                              <Form.Label className="policy-condition-label">
                                Special symbols allowed
                              </Form.Label>
                              <Form.Control
                                className="user_input_field"
                                type="text"
                                required
                                name="special symbols"
                                value={specialSymbols}
                                onChange={(e) => {
                                  setSpecialSymbols(e.target.value);
                                }}
                                pattern="^(?!.*(.).*\1)[^A-Za-z0-9]+$"
                                isInvalid={validateSpecialSymbol()}
                                placeholder="input special symbols without any spaces."
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter valid special symbols.
                                <PasswordLightToolTip
                                  className="cursor_pointer"
                                  title="No spaces are allowed. No repeated special characters are allowed."
                                />
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col lg={6} md={6} sm={12}>
                          <Form.Group>
                            <Form.Label className="policy-condition-label">
                              Apply To
                              <SettingDarkToolTip
                                className="lh-lg"
                                styles={{ fontSize: "3em" }}
                                title="Policy will be applied for selected groups."
                              />
                            </Form.Label>
                            {getPolicyDataComplete && (
                              <Select
                                components={{
                                  NoOptionsMessage,
                                }}
                                defaultValue={() => {
                                  let x = [];
                                  associatedGroup.map((field) => {
                                    if (field["selected"] === true) {
                                      x.push(field);
                                    }
                                  });
                                  return x;
                                }}
                                isMulti
                                isClearable={isClearable}
                                name="associated-groups"
                                options={associatedGroup}
                                className="password-policy-associatedgroup"
                                classNamePrefix="password_policy_select"
                                onChange={(
                                  selectedOption,
                                  { action, prevInputValue }
                                ) => {
                                  if (action === "select-option") {
                                    // action is select.
                                    for (let entryIndexInSelectedOption in selectedOption) {
                                      const obj =
                                        selectedOption[
                                          entryIndexInSelectedOption
                                        ];
                                      const temp_association_list =
                                        associatedGroup.map((entry) => {
                                          if (entry.value === obj.value) {
                                            return {
                                              ...entry,
                                              selected: true,
                                            };
                                          } else {
                                            return {
                                              ...entry,
                                            };
                                          }
                                        });
                                      setAssociatedGroup(
                                        (o) => temp_association_list
                                      );
                                    }
                                  } else if (
                                    action === "deselect-option" ||
                                    action === "remove-value" ||
                                    action === "pop-value "
                                  ) {
                                    const selectedAssociationValueArray =
                                      selectedOption.map((obj) => obj.value);
                                    if (selectedOption.length > 0) {
                                      associatedGroup.map((entry, index) => {
                                        if (
                                          selectedAssociationValueArray.includes(
                                            entry.value
                                          )
                                        ) {
                                          entry["selected"] = true;
                                        } else {
                                          entry["selected"] = false;
                                        }
                                      });
                                      setAssociatedGroup([...associatedGroup]);
                                    } else {
                                      setAssociatedGroup(
                                        associatedGroupsOptions
                                      );
                                    }
                                  }
                                }}
                                isLoading={isLoading}
                                closeMenuOnSelect={true}
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-4">
                            <Form.Label className="vault-labels setting-element-sub-heading">
                              User password expire action
                              <SettingDarkToolTip
                                className="lh-lg"
                                styles={{ fontSize: "3em" }}
                                title="After user password expires, selected action will be performed to update user password."
                              />
                            </Form.Label>
                            <Select
                              className="vault-select"
                              classNamePrefix="password_expire_select"
                              closeMenuOnSelect={true}
                              components={{ Placeholder }}
                              defaultValue={userExpireValue()}
                              value={userExpireValue()}
                              placeholder={
                                "Choose an user password expire option"
                              }
                              options={userExpireOptionsState}
                              isOptionDisabled={(option)=>option.enabled !== true}
                              onChange={(event) => {
                                setUserExpireOption(event.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};
export default PasswordPolicyAdd;
