import React from 'react'
import Images from '../../../../../../utils/images';
import { RxCross1 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import CrossButton from "../../../../../common/ButtonSpinner/CrossButton";

const Sidebar = ({heading, policySubNavbarName, setPolicySubNavbarName, setOpenPolicy, appSystemUser}) => {
    return (
        <>
            <div className='d-flex justify-content-between align-items-center  mb-4'>
                <h2 className="main_content_heading">{heading}</h2>
                <CrossButton onClick={() => setOpenPolicy(o => !o)}></CrossButton>
            </div>
            <div className="d-flex justify-content-start align-items-center border-bottom overflow-auto">
                <p className={`m-0  me-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${policySubNavbarName === "General" ? "active_border" : ""}`} onClick={() => { setPolicySubNavbarName("General") }}>
                    General
                </p>
                {appSystemUser !== null && <p className={`m-0 mx-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${policySubNavbarName === "Commands" ? "active_border" : ""}`} onClick={() => { setPolicySubNavbarName("Commands") }}>
                    Commands
                </p>}
                <p className={`m-0 ms-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${policySubNavbarName === "Advance" ? "active_border" : ""}`} onClick={() => { setPolicySubNavbarName("Advance") }}>
                    Advance
                </p>
            </div>
        </>
    )
}

export default Sidebar;
