import React from "react";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "../ProductSettings.scss";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import SettingsIcon from "../icons/settings-icon.svg";
import InfoIcon from "../icons/icon-info.svg";
import {
  updateProductSettings,
  getProductSettings,
} from "../../../../api/customer";
import CustomModal from "../../../common/CustomModal/CustomModal";
import EditFieldModal from "./EditFieldModal";
import AddFieldModal from "./AddFieldModal";
import { useSelector, useDispatch } from "react-redux";
import {
  showError,
  showErrorMsg,
  showSuccess,
} from "../../../../utils/showMessage";
import { setRecordSession } from "../../../../state/slices/customer";
import { changeTitle } from "../../../../state/slices/header";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { DarkTooltip } from "../../../common/CustomTooltip/CustomTooltip";
import { URLReg, URLReg2 } from "../../../common/regex/Regex";
import GetCapabilities from "../../../../utils/getCapabilities";
import Select from "react-select";
import {
  getTicketResourceSettings,
  putTicketApprovalSettings,
  putTicketResourceSettings,
} from "../../../../api/ticketing";
import TicketsColumnConfig from "./TicketsColumnConfig";
import {
  setTicketColumnSettingConfig,
  GetTicketColumnSettingConfig,
} from "../../../../api/ticketing";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import { AiFillPlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";
import ApprovalSettings from "./ApprovalSettings";

const TicketSettings = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const dispatch = useDispatch();
  const tabs = {
    resource_setting: true,
    approval_setting: false,
    column_setting: false,
  };
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [tabList, setTabList] = useState(tabs);
  const [validated, setValidated] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const [slideChecked, setSlideChecked] = useState(false);
  const [resourceTicketSetting, setResourceTicketSetting] = useState();
  const [addFieldModal, setAddFieldModal] = useState(false);
  const [editFieldModal, setEditFieldModal] = useState(false);
  const [field, setField] = useState(null);
  const [fieldEditData, setFieldEditData] = useState(null);
  const [editField, setEditField] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  useEffect(() => {
    setSlideChecked(true);
    getTicketSettings();
    dispatch(changeTitle("Settings"));
    GetTicketColumnSetting();
  }, []);

  const app_options = [
    { value: "show_all_apps", label: "Show All Apps" },
    { value: "show_allocated_apps", label: "Show Allocated Apps" },
  ];

  const asset_options = [
    { value: "show_all_assets", label: "Show All Assets" },
    { value: "show_allocated_assets", label: "Show Allocated Assets" },
  ];

  const web_app_options = [
    { value: "show_all_webapps", label: "Show All Web Apps" },
    { value: "show_allocated_webapps", label: "Show Allocated Web Apps" },
  ];

  const getTicketSettings = async () => {
    const { data, error } = await getTicketResourceSettings();
    if (data !== null) {
      setResourceTicketSetting(JSON.parse(data.ticket_resource_action.value));
    } else showError(error);
  };

  const GetTicketColumnSetting = async () => {
    const { data, error } = await GetTicketColumnSettingConfig();
    if (data !== null) {
      setFieldList(data.fields);
    }
  };

  const addTicketResourceSettingsFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const { data, error } = await putTicketResourceSettings({
        ticket_resource_action: resourceTicketSetting,
      });

      if (data !== null) {
        showSuccess(data.message);
      } else showError(error);
      setValidated("");
    }
  };

  const TicketColumnSetting = async (event) => {
    event.preventDefault();
    const { data, error } = await setTicketColumnSettingConfig({
      fields: fieldList,
    });
    if (data !== null) {
      showSuccess(data.message);
      GetTicketColumnSetting();
    }
    if (error !== null) {
      showError(error);
    }
  };

  const addTicketApprovalSettingsFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let approval_settings_data = selectedRoles
      .filter(({ role_id }) => role_id !== "")
      .map(
        ({
          role_id,
          ticket_approvers,
          role_name,
          display_name,
          minimum_approvers,
        }) => ({
          role_id,
          ticket_approvers,
          role_name,
          display_name,
          minimum_approvers,
        })
      );
    const { data, error } = await putTicketApprovalSettings(
      approval_settings_data
    );
    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Settings":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/settings-icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Settings
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal
        open={addFieldModal}
        handleClose={() => {
          setAddFieldModal((o) => !o);
        }}
        width="35%"
      >
        {" "}
        {
          <AddFieldModal
            setAddFieldModal={setAddFieldModal}
            field={field}
            setField={setField}
            fieldList={fieldList}
            setFieldList={setFieldList}
          ></AddFieldModal>
        }
      </CustomModal>
      <CustomModal
        open={editFieldModal}
        handleClose={() => {
          setEditFieldModal((o) => !o);
        }}
        width="35%"
      >
        {" "}
        {
          <EditFieldModal
            setEditFieldModal={setEditFieldModal}
            fieldEditData={fieldEditData}
            setFieldEditData={setFieldEditData}
            field={field}
            setField={setField}
            fieldList={fieldList}
            setFieldList={setFieldList}
          ></EditFieldModal>
        }
      </CustomModal>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  <div id="setting-container">
                    <div id="setting-heading" className="fixed-top">
                      <hr id="setting-line-break" className="m-0" />
                      <div className="configure_mfa_heading p-3 d-flex flex-row justify-content-between align-items-center">
                        <div>
                          <img
                            src={SettingsIcon}
                            alt="EGC"
                            className="smtp_heading_image"
                          />
                          <h2 className="main-heading d-inline ms-2">
                            &nbsp; Ticket Settings
                          </h2>
                        </div>

                        {tabList["resource_setting"] && (
                          <GeneralButton
                            form="setting-form"
                            className="me-3"
                            value="Save Resource Settings"
                            variant="outlined"
                            size="large"
                          />
                        )}
                        {tabList["approval_setting"] && (
                          <GeneralButton
                            form="approval-setting-form"
                            className="me-3"
                            value="Save Approval Settings"
                            variant="outlined"
                            size="large"
                          />
                        )}
                        {tabList["column_setting"] && (
                          <div>
                            <GeneralButton
                              onClickEvent={() => {
                                GetTicketColumnSetting();
                              }}
                              className="me-3"
                              value="Set to Default"
                              variant="outlined"
                              size="large"
                              color="#dc3545"
                            />
                            <GeneralButton
                              form="column-setting-form"
                              className="me-3"
                              value="Save Column Settings"
                              variant="outlined"
                              size="large"
                            />
                          </div>
                        )}
                      </div>

                      {/* settings categorized. */}
                      <div className="mx-5 mt-3 mb-3 p-0">
                        <div className="four-tabs">
                          <div className="tab-div d-flex flex-row justify-content-start align-items-end">
                            <div
                              id="resource_setting"
                              className={
                                tabList["resource_setting"]
                                  ? "tab-heading tab-active-heading active-below-border me-3"
                                  : "tab-heading tab-inactive-heading me-3"
                              }
                              onClick={() =>
                                setTabList((tabs) => ({
                                  resource_setting: true,
                                  column_setting: false,
                                  approval_setting: false,
                                }))
                              }
                            >
                              Resource Setting
                            </div>

                            {isCapable &&
                              isCapable.miscellaneous.ticket
                                .add_ticket_column && (
                                <div
                                  id="column_setting"
                                  className={
                                    tabList["column_setting"]
                                      ? "tab-heading tab-active-heading active-below-border me-3"
                                      : "tab-heading tab-inactive-heading me-3"
                                  }
                                  onClick={() =>
                                    setTabList((tabs) => ({
                                      resource_setting: false,
                                      column_setting: true,
                                      approval_setting: false,
                                    }))
                                  }
                                >
                                  Column Settings
                                </div>
                              )}
                            <div
                              id="approval_setting"
                              className={
                                tabList["approval_setting"]
                                  ? "tab-heading tab-active-heading active-below-border me-3"
                                  : "tab-heading tab-inactive-heading me-3"
                              }
                              onClick={() =>
                                setTabList((tabs) => ({
                                  resource_setting: false,
                                  column_setting: false,
                                  approval_setting: true,
                                }))
                              }
                            >
                              Approval Setting
                            </div>
                          </div>
                        </div>
                        {/* Tab elements */}
                        {tabList["resource_setting"] && (
                          <Form
                            noValidate
                            validated={validated}
                            id="setting-form"
                            autocomplete="off"
                            onSubmit={addTicketResourceSettingsFormHandler}
                          >
                            <Form.Group
                              id=""
                              className="w-100"
                              controlId="formServerUrl"
                            >
                              {tabList["resource_setting"] && (
                                <Slide
                                  timeout={delayTime}
                                  className="h-100"
                                  direction={slideDirection}
                                  in={slideChecked}
                                >
                                  <div>
                                    <Fade
                                      timeout={fadedelayTime}
                                      in={slideChecked}
                                    >
                                      <div>
                                        <div className="mt-4 main_content_container">
                                          <>
                                            <div className="setting-element-heading">
                                              App List
                                            </div>
                                            <Form.Group
                                              id="setting-form-elements"
                                              className="mb-3 w-50"
                                              controlId="formSsoUrl"
                                            >
                                              <Form.Label className="setting-element-sub-heading">
                                                App list to raise ticket
                                              </Form.Label>
                                              <DarkTooltip
                                                className="lh-lg"
                                                title="The chosen value will determine the list of apps that users can view when creating a ticket."
                                              >
                                                <img
                                                  src={InfoIcon}
                                                  alt="info"
                                                  width="15"
                                                  height="15"
                                                  className="mx-2 cursor_pointer"
                                                />
                                              </DarkTooltip>
                                              {resourceTicketSetting && (
                                                <Select
                                                  options={app_options}
                                                  className="fs-16px"
                                                  defaultValue={
                                                    app_options[
                                                      app_options.findIndex(
                                                        (item) =>
                                                          item.value ===
                                                          resourceTicketSetting.APP
                                                      )
                                                    ]
                                                  }
                                                  onChange={(event) => {
                                                    setResourceTicketSetting(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        APP: event.value,
                                                      })
                                                    );
                                                  }}
                                                  required
                                                />
                                              )}
                                            </Form.Group>
                                            <div className="mt-4 setting-element-heading">
                                              Asset List
                                            </div>
                                            <Form.Group
                                              id="setting-form-elements"
                                              className="mb-3 w-50"
                                              controlId="formSsoUrl"
                                            >
                                              <Form.Label className="setting-element-sub-heading">
                                                Asset list to raise ticket
                                              </Form.Label>
                                              <DarkTooltip
                                                className="lh-lg"
                                                title="The chosen value will determine the list of assets that users can view when creating a ticket."
                                              >
                                                <img
                                                  src={InfoIcon}
                                                  alt="info"
                                                  width="15"
                                                  height="15"
                                                  className="mx-2 cursor_pointer"
                                                />
                                              </DarkTooltip>
                                              {resourceTicketSetting && (
                                                <Select
                                                  options={asset_options}
                                                  className="fs-16px"
                                                  defaultValue={
                                                    asset_options[
                                                      asset_options.findIndex(
                                                        (item) =>
                                                          item.value ===
                                                          resourceTicketSetting.ASSET
                                                      )
                                                    ]
                                                  }
                                                  onChange={(event) => {
                                                    setResourceTicketSetting(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        ASSET: event.value,
                                                      })
                                                    );
                                                  }}
                                                  required
                                                />
                                              )}
                                            </Form.Group>
                                            <div className="mt-4 setting-element-heading">
                                              Web App List
                                            </div>
                                            <Form.Group
                                              id="setting-form-elements"
                                              className="mb-3 w-50"
                                              controlId="formSsoUrl"
                                            >
                                              <Form.Label className="setting-element-sub-heading">
                                                Web App list to raise ticket
                                              </Form.Label>
                                              <DarkTooltip
                                                className="lh-lg"
                                                title="The chosen value will determine the list of web apps that users can view when creating a ticket."
                                              >
                                                <img
                                                  src={InfoIcon}
                                                  alt="info"
                                                  width="15"
                                                  height="15"
                                                  className="mx-2 cursor_pointer"
                                                />
                                              </DarkTooltip>
                                              {resourceTicketSetting && (
                                                <Select
                                                  options={web_app_options}
                                                  className="fs-16px"
                                                  defaultValue={
                                                    web_app_options[
                                                      web_app_options.findIndex(
                                                        (item) =>
                                                          item.value ===
                                                          resourceTicketSetting.WEB_APP
                                                      )
                                                    ]
                                                  }
                                                  onChange={(event) => {
                                                    setResourceTicketSetting(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        WEB_APP: event.value,
                                                      })
                                                    );
                                                  }}
                                                  required
                                                />
                                              )}
                                            </Form.Group>
                                          </>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                </Slide>
                              )}
                            </Form.Group>
                          </Form>
                        )}
                        {tabList["column_setting"] && (
                          <Form
                            noValidate
                            validated={validated}
                            id="column-setting-form"
                            autocomplete="off"
                            onSubmit={TicketColumnSetting}
                          >
                            <Form.Group
                              id=""
                              className="w-100"
                              controlId="formServerUrl"
                            >
                              {tabList["column_setting"] && (
                                <Slide
                                  timeout={delayTime}
                                  className="h-100"
                                  direction={slideDirection}
                                  in={slideChecked}
                                >
                                  <div>
                                    <Fade
                                      timeout={fadedelayTime}
                                      in={slideChecked}
                                    >
                                      <div>
                                        <div className="mt-4 main_content_container">
                                          <TicketsColumnConfig
                                            addFieldModal={addFieldModal}
                                            setAddFieldModal={setAddFieldModal}
                                            editFieldModal={editFieldModal}
                                            setEditFieldModal={
                                              setEditFieldModal
                                            }
                                            field={field}
                                            setField={setField}
                                            fieldEditData={fieldEditData}
                                            setFieldEditData={setFieldEditData}
                                            editField={editField}
                                            setEditField={setEditField}
                                            fieldList={fieldList}
                                            setFieldList={setFieldList}
                                          ></TicketsColumnConfig>
                                        </div>
                                      </div>
                                    </Fade>
                                  </div>
                                </Slide>
                              )}
                            </Form.Group>
                          </Form>
                        )}
                        {tabList["approval_setting"] && (
                          <Form
                            noValidate
                            validated={validated}
                            id="approval-setting-form"
                            autocomplete="off"
                            onSubmit={addTicketApprovalSettingsFormHandler}
                          >
                            <Form.Group
                              id=""
                              className="w-100"
                              controlId="formServerUrl"
                            >
                              {tabList["approval_setting"] && (
                                <Slide
                                  timeout={delayTime}
                                  className="h-100"
                                  direction={slideDirection}
                                  in={slideChecked}
                                >
                                  <div>
                                    <Fade
                                      timeout={fadedelayTime}
                                      in={slideChecked}
                                    >
                                      <div>
                                        <ApprovalSettings
                                          selectedRoles={selectedRoles}
                                          setSelectedRoles={setSelectedRoles}
                                        ></ApprovalSettings>
                                      </div>
                                    </Fade>
                                  </div>
                                </Slide>
                              )}
                            </Form.Group>
                          </Form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};
export default TicketSettings;
