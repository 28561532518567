import React, { useState, useEffect, useCallback } from "react";
import {
  deletePolicies,
  getDatabaseApps,
  getPolicyDetails,
  getPolicyList,
} from "../../../../../api/database_policy";
import Images from "../../../../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import SubHeaderComponent from "../../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../../../common/RDTListHeading/ListHeading";
import DataTable from "react-data-table-component";
import { Nav, NavDropdown } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import { showError, showSuccess } from "../../../../../utils/showMessage";
import styled from "@emotion/styled";
import MuiButton from "@mui/material/Button";
import AddDatabasePolicy from "../AddDatabasePolicy/AddDatabasePolicy";
import CustomModal from "../../../../common/CustomModal/CustomModal";
import ShowAlert from "../../../../common/ShowAlert/ShowAlert";
import { GetColmn } from "../../../../common/CustomColumn/customize_column";
import { changeTitle } from "../../../../../state/slices/header";
import EditDatabasePolicy from "../EditDatabasePolicy/EditDatabasePolicy";
import GetCapabilities from "../../../../../utils/getCapabilities";
import Slide from "@mui/material/Slide";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../../utils/transitionEffectParams";
import { Fade } from "@mui/material";
import useWindowDimensions from "../../../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../../../common/DelaySkeleton/CustomDelaySkeleton";
import GeneralButton from "../../../../common/SaveButton/GeneralButton";

const ActionComponent = ({ setApps, setOpenAddPolicy }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const handleGetAppsName = async () => {
    const { data, error } = await getDatabaseApps();
    if (data !== null) {
      setApps(data.apps);
    }
  };
  return (
    <Stack direction="row" spacing={2} className="py-3">
      {isCapable && isCapable.policies.manage_database_policy.add && (
        <GeneralButton
          startIcon={
            <img src={Images.Plus} alt="icon" id="center-blueShadow" />
          }
          onClickEvent={() => {
            handleGetAppsName();
            setOpenAddPolicy((o) => !o);
          }}
          className="me-1"
          value="Add Policy"
          variant="outlined"
          size="large"
        />
      )}
    </Stack>
  );
};
const DatabasePolicy = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [policies, setPolices] = useState([]);
  const [totalPolcies, setTotalPolicies] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [pending, setPending] = useState(true);
  const [policy, setPolicy] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_MySystemUsers") !== null
      ? localStorage.getItem("rowsPerPage_MySystemUsers")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [policyData, setPolicyData] = useState(null);
  const [openEditPolicy, setOpenEditPolicy] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const pages_url = "dashboard/database-policy";
  const customizeColumnDict = {
    "Policy ID": true,
    "Policy Name": true,
    Action: true,
    App: true,
    "Audit Event": true,
    "Record Session": true,
    "Live Stream": true,
    "Session Timeout": true,
    "Enforce MFA": true,
    "DEFAULT DATABASE OPERATION": false,
    "DEFAULT TABLE OPERATION": false,
    "DEFAULT COLUMN OPERATION": false,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [openAddPolicy, setOpenAddPolicy] = useState(false);
  const [deletePolicyNames, setDeletePolicyNames] = useState([]);
  const [openDeletePolicy, setOpenDeletePolicy] = useState(false);
  const [apps, setApps] = useState([]);
  const title_heading = useSelector((state) => state.header.title);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const policyColumns = [
    { label: "Policy ID", value: "id" },
    { label: "Policy Name", value: "policy_name" },
    { label: "App Name", value: "targeted_app__app_name" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];

  const getPolicies = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await getPolicyList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setPolices(data.policies);
      setTotalPolicies(data.total_policies);
      setFilteredData(data.policies);
    }
    setPending(false);
  };

  useEffect(() => {
    dispatch(changeTitle("Policy"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getPolicies({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, policy]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = policies.filter((object) => {
      return (
        object.policy_name.toLowerCase().match(s.toLowerCase()) ||
        object.targeted_app.app_name.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_DatabasePolicies", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    getPolicies({ page, rowsPerPage, search, filterBy });
  };
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };
  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.policy_name);
    setDeletePolicyNames(t);
    setOpenDeletePolicy((o) => !o);
  };
  const handleDeleteAction = async () => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await deletePolicies({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setPolicy(Object.create(null));
      setOpenDeletePolicy((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const handlePolicyEdit = async (policy_id) => {
    const { data, error } = await getPolicyDetails(policy_id);
    if (data !== null) {
      setPolicyData(data.data);
      setOpenEditPolicy((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };
  const disabledCriteria = (row) => {
    return row.policy_name.toLowerCase().startsWith("default_");
  };
  const columns = [
    {
      name: "Policy ID",
      selector: (row) => row.id,
      omit: !checkedStateDict["Policy ID"],
      grow: 1,
    },
    {
      name: "Policy Name",
      selector: (row) => row.policy_name,
      omit: !checkedStateDict["Policy Name"],
    },
    {
      name: "App",
      selector: (row) => row.targeted_app.app_name,
      omit: !checkedStateDict["App"],
    },
    {
      name: "Session Timeout",
      selector: (row) => row.default_session_timeout,
      omit: !checkedStateDict["Session Timeout"],
    },
    {
      name: "Audit Events",
      selector: (row) => (row.audit_events ? "True" : "False"),
      omit: !checkedStateDict["Audit Event"],
    },
    {
      name: "Enforce MFA",
      selector: (row) => (row.enforce_mfa ? "True" : "False"),
      omit: !checkedStateDict["Enforce MFA"],
    },
    {
      name: "Live Stream",
      selector: (row) => (row.live_stream ? "True" : "False"),
      omit: !checkedStateDict["Live Stream"],
    },
    {
      name: "Record Session",
      selector: (row) => (row.record_session ? "True" : "False"),
      omit: !checkedStateDict["Record Session"],
    },
    {
      name: "DEFAULT DATABASE OPERATION",
      selector: (row) => row.default_database_operation,
      omit: !checkedStateDict["DEFAULT DATABASE OPERATION"],
    },
    {
      name: "DEFAULT TABLE OPERATION",
      selector: (row) => row.default_table_operation,
      omit: !checkedStateDict["DEFAULT TABLE OPERATION"],
    },
    {
      name: "DEFAULT COLUMN OPERATION",
      selector: (row) => row.default_column_operation,
      omit: !checkedStateDict["DEFAULT COLUMN OPERATION"],
    },

    isCapable &&
      (isCapable.policies.manage_database_policy.edit ||
        isCapable.policies.manage_database_policy.delete) && {
        name: "Action",
        cell: (row) => (
          <>
          {!row.policy_name.startsWith("DEFAULT") &&
            <Nav>
              <NavDropdown
                id="nav-dropdown-systemuser"
                title={<img src={Images.ThreeDotIcon} alt="action" />}
                menuVariant="primary"
              >
                {isCapable &&
                  isCapable.policies.manage_database_policy.edit && (
                    <NavDropdown.Item onClick={() => handlePolicyEdit(row.id)}>
                      Edit
                    </NavDropdown.Item>
                  )}

              {isCapable && isCapable.policies.manage_database_policy.delete && !disabledCriteria(row) && <NavDropdown.Item
                onClick={async () => {
                  handleDelete([row]);
                }}
              >
                Delete
              </NavDropdown.Item>}
            </NavDropdown>
          </Nav>}
        {row.policy_name.startsWith("DEFAULT") && <span>No Action</span>}
        </>
      ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Policy":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={Images.SystemUserHeadingIcon}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Policy
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {apps.length !== 0 && (
                  <CustomModal
                    open={openAddPolicy}
                    width={"70%"}
                    handleClose={() => {
                      setOpenAddPolicy((o) => !o);
                    }}
                  >
                    <AddDatabasePolicy
                      apps={apps}
                      setOpenAddPolicy={setOpenAddPolicy}
                      setPolicy={setPolicy}
                    />
                  </CustomModal>
                )}
                {policyData && (
                  <CustomModal
                    open={openEditPolicy}
                    width={"70%"}
                    handleClose={() => {
                      setOpenEditPolicy((o) => !o);
                    }}
                  >
                    <EditDatabasePolicy
                      policy={policyData}
                      setOpenEditPolicy={setOpenEditPolicy}
                      setPolicy={setPolicy}
                    />
                  </CustomModal>
                )}
                {deletePolicyNames.length > 0 && (
                  <CustomModal
                    open={openDeletePolicy}
                    handleClose={() => {
                      setOpenDeletePolicy((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenDeletePolicy}
                      handleAlertAction={handleDeleteAction}
                      colName={deletePolicyNames}
                      alertMsg="Below polices will be deleted"
                      headingMsg="Delete Policy"
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Database Policy"
                        dataTableHeadingIcon={Images.SystemUserHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      <CustomDelaySkeleton
                        rowsPerPage={rowsPerPage}
                        colCount={0}
                        totalColumns={6}
                      />
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPolcies}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    selectableRowDisabled={disabledCriteria}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={
                      <ActionComponent
                        setApps={setApps}
                        setOpenAddPolicy={setOpenAddPolicy}
                      />
                    }
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="database policy"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={policyColumns}
                        actionOptions={
                          isCapable &&
                          isCapable.policies.manage_database_policy.delete
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={Images.FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={Images.LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={
                      <img src={Images.NextPageIcon} alt="next" />
                    }
                    paginationIconPrevious={
                      <img src={Images.PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default DatabasePolicy;
