import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getScannedresults, onboardAssets } from "../../../api/networkanalyzer";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showErrors, showSuccess } from "../../../utils/showMessage";
import { delayTime, fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import CustomModal from "../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../common/ShowAlert/ShowAlert";
import GetCapabilities from "../../../utils/getCapabilities";
import { useLocation } from 'react-router-dom';
import images from "../../../utils/images";
import { GetColmn } from "../../common/CustomColumn/customize_column";


function ScannedSystemUsers() {
  const { state } = useLocation();
  const asset_id = state && state.id;
  const config_id = state && state.config_id;
  const config_name = state && state.config_name;
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const [configurations, setConfigurations] = useState([]);
  const [configuration, setConfiguration] = useState(null);
  const [totalConfigurations, setTotalConfigurations] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_ScannedSystemUsers") !== null
      ? localStorage.getItem("rowsPerPage_ScannedSystemUsers")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deleteConfNames, setDeleteConfNames] = useState([]);
  const [openDeleteConf, setOpenDeleteConf] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const title_heading = useSelector((state) => state.header.title); 
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [trigger, setTrigger] = useState(true);
  const customizeColumnDict = { "Id": true, "Username": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)
  const pages_url = "dashboard/scannedsystemusers";

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const systemUserColumns = [
    { label: "Id", value: "id" },
    { label: "Display Name", value: "system_display_username" },
    { label: "Username", value: "system_username" },
  ];

  const actionOptions = [{ label: "Onboard", value: "onboard" }];

  useEffect(() => {
    dispatch(changeTitle("SSO"));
  }, []);

  
  const getScanresults = async () => {
    const { data } = await getScannedresults({
      get_systemusers: 1,
      config_id: asset_id,
      page: page,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });

    if (data !== null) {
      setConfigurations(data.results);
      setFilteredData(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalConfigurations(data.count);
      setPending(false);
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getScanresults();
  }, [page, rowsPerPage, dispatch, configuration, trigger]);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = configurations.filter((object) => {
      return (
        object.system_username.toLowerCase().match(s.toLowerCase()) ||
        object.system_display_username.toLowerCase().match(s.toLowerCase())
  )});
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_ScannedSystemUsers", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAction = async () => {
    if (action === "onboard") {
      handleOnboard(null);
    }
  };

  const handleOnboard = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.system_display_username);
    setDeleteConfNames(t);
    setOpenDeleteConf((o) => !o);
  };

  const handleOnboardAction = async (row) => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await onboardAssets({ ids: ids, config_id: config_id, resource_type: 'system_user' });
    if (data !== null) {
      showSuccess(data.message);
      setConfiguration(Object.create(null));
      setOpenDeleteConf((o) => !o);
      setTrigger(o => !o);
    }
    if (error !== null) {
      showErrors(error);
      setTrigger(o => !o);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const rowDisabledCriteria = (row) => row.onboarded;

  const handleSearch = (event) => {
    event.preventDefault();
    getScanresults();
  };


  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "100px",
      omit: !checkedStateDict["Id"],
      sortable: true,
    },
    {
        name: "Status",
        cell: (row) => (
        <>
        {row.onboarded == true ? 
            <img style={{cursor: 'pointer'}} src={images.Onboarded} /> : 
            <img style={{cursor: 'pointer'}} src={images.Onboard} onClick={async () => {handleOnboard([row]);}} />}
        </>
        ),
        grow: 1,
        sortable: true,
    },
    {
        name: "Display Name",
        selector: (row) => row.system_display_username,
        grow: 1,
        sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.system_username,
      grow: 1,
      omit: !checkedStateDict["Username"],
      sortable: true,
    },

  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "SSO":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            images.NetworkAnalyzer
                            }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Network Analyzer
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {deleteConfNames.length > 0 && (
                  <CustomModal open={openDeleteConf} >
                    <ShowAlert
                      setOpenAlert={setOpenDeleteConf}
                      handleAlertAction={handleOnboardAction}
                      colName={deleteConfNames}
                      alertMsg="Below System Users will be Onboarded :"
                      headingMsg="Onboard Assets"
                    />
                  </CustomModal>)}
                
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`${config_name}: System User Scan Results`}
                        dataTableHeadingIcon={images.NetworkAnalyzer}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={6}
                        />
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalConfigurations}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    selectableRowDisabled={rowDisabledCriteria}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={systemUserColumns}
                        actionOptions={(isCapable && isCapable.apps.delete)?actionOptions:null}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        placeholder="all system users"
                        selectedRows={selectedRows}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                        pages_url={pages_url}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={images.FirstPageIcon} alt="first page" />}
                    paginationIconLastPage={<img src={images.LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={images.NextPageIcon} alt="next" />}
                    paginationIconPrevious={<img src={images.PreviousPageIcon} alt="previous" />}
                  />
                </div>

              </div>
            </div>
          </Fade>

        </div>
      </Slide>



    </>
  );
}

export default ScannedSystemUsers;