import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { showErrorMsg } from "../../../../utils/showMessage";
import { GetTicketColumnSettingConfig } from "../../../../api/ticketing";
import TicketColumnComponents from "./TicketColumnComponents";
import { GoPencil } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import { DarkTooltip } from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";

const TicketsColumnConfig = ({
  addFieldModal,
  setAddFieldModal,
  editFieldModal,
  setEditFieldModal,
  field,
  setField,
  fieldEditData,
  setFieldEditData,
  editField,
  setEditField,
  fieldList,
  setFieldList,
}) => {
  const { width, height } = useWindowDimensions();
  let inner_height = height - 300 + "px";

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, id) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("text/plain");
    if (draggedId !== id && !isNaN(parseInt(draggedId))) {
      const newDivs = [...fieldList];
      const fromIndex = fieldList.findIndex(
        (div) => div.id === parseInt(draggedId)
      );
      const toIndex = fieldList.findIndex((div) => div.id === id);
      [newDivs[fromIndex], newDivs[toIndex]] = [
        newDivs[toIndex],
        newDivs[fromIndex],
      ];
      setFieldList(newDivs);
    }
  };
  const handleAddFieldDrop = (e) => {
    e.preventDefault();
    const draggedField = e.dataTransfer.getData("text/plain");
    if (
      [
        "SingleLineText",
        "ParagraphText",
        "Number",
        "Dropdown",
        "Checkbox",
        "RadioButton",
      ].includes(draggedField)
    ) {
      setField(draggedField);
      setAddFieldModal((o) => !o);
    }
  };

  const removeDraggedField = (e) => {
    const draggedId = e.dataTransfer.getData("text/plain");
    if (!isNaN(parseInt(draggedId))) {
      const removeFieldData = fieldList.find((item) => item.id == draggedId);
      let updatedData = [];
      if (removeFieldData[Object.keys(removeFieldData)[1]]["modalDefault"]) {
        updatedData = fieldList;
        showErrorMsg("Default Field - Cannot edit or remove this field");
      } else {
        updatedData = fieldList.filter((item) => item.id != draggedId);
      }
      setFieldList(updatedData);
    }
  };
  const removeField = (id) => {
    const updatedData = fieldList.filter((item) => item.id != id);
    setFieldList(updatedData);
  };
  return (
    <>
      <Row className="mx-0">
        <Col
          className="mx-0 pe-4 overflow-auto"
          md={8}
          style={{
            height: `${inner_height}`,
            backgroundColor: "white",
          }}
          onDragOver={handleDragOver}
          onDrop={(e) => handleAddFieldDrop(e)}
        >
          <Row className="mb-3">
            {fieldList &&
              fieldList.map((field, index) => {
                const isDateColumn =
                  field[Object.keys(field)[1]]["ColumnType"] === "Date";
                return isDateColumn ? (
                  <div className="px-4 my-1">
                    <Row
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #CBCBCB",
                      }}
                      key={field.id}
                      onDragStart={(e) => handleDragStart(e, field.id)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, field.id)}
                      draggable
                      className=" pt-2 pb-3 justify-content-between"
                    >
                      <Col md={6}>
                        <Form.Label column md={6} className="input_label">
                          Access From <span className="text-danger">*</span>
                        </Form.Label>
                        <Row>
                          <Col md={6}>
                            <Form.Control
                              value={new Date().toISOString().substr(0, 10)}
                              className="form_date_input_field py-2 pe-2"
                              type="date"
                              placeholder="MyTickets"
                            />
                          </Col>
                          <Col md={6}>
                            <Form.Control
                              value={new Date().toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                              className="form_date_input_field py-2 pe-2"
                              type="time"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="left_border_date_ticketing" md={6}>
                        <Form.Label column md={6} className="input_label">
                          Access Until <span className="text-danger">*</span>
                        </Form.Label>
                        <Row>
                          <Col md={6}>
                            <Form.Control
                              value={new Date().toISOString().substr(0, 10)}
                              className="form_date_input_field py-2 pe-2"
                              type="date"
                            />
                          </Col>
                          <Col md={6}>
                            <Form.Control
                              value={new Date().toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                              className="form_date_input_field py-2 pe-2"
                              type="time"
                              placeholder="MyTickets"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <Col
                    md={field[Object.keys(field)[1]]["col"]}
                    className="my-1 cursor_pointer"
                    key={field.id}
                    onDragStart={(e) => handleDragStart(e, field.id)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, field.id)}
                    draggable
                  >
                    {editField == index &&
                      !field[Object.keys(field)[1]]["modalDefault"] && (
                        <div className="float-end mt-1">
                          <GoPencil
                            onClick={() => {
                              setFieldEditData(field);
                              setField(
                                field[Object.keys(field)[1]]["ColumnType"]
                              );
                              setEditFieldModal((o) => !o);
                            }}
                            className="text-primary text-hover-edit ms-2 mt-2 cursor_pointer"
                          ></GoPencil>
                          <RxCross1
                            className="text-danger text-hover-remove ms-2 mt-2 cursor_pointer"
                            onClick={() => removeField(field.id)}
                          ></RxCross1>
                        </div>
                      )}
                    {editField == index &&
                      field[Object.keys(field)[1]]["modalDefault"] && (
                        <div className="float-end">
                          <DarkTooltip
                            id="setting-info"
                            title="Default Field - Cannot edit or remove this field"
                          >
                            <img
                              src={InfoIcon}
                              alt="info"
                              width="15"
                              height="15"
                              className="mx-2 mt-3 cursor_pointer"
                            />
                          </DarkTooltip>
                        </div>
                      )}
                    <div
                      onMouseEnter={() => setEditField(index)}
                      className="mt-2 pb-2"
                    >
                      {
                        <Form.Label
                          column
                          md={10}
                          className="input_label label_word_wrap"
                        >
                          {Object.keys(field)[1]}{" "}
                          {field[Object.keys(field)[1]]["required"] && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                      }
                      {
                        <TicketColumnComponents
                          columnType={
                            field[Object.keys(field)[1]]["ColumnType"]
                          }
                          options={field[Object.keys(field)[1]]["Options"]}
                          defaultValue={
                            field[Object.keys(field)[1]]["DefaultValue"]
                          }
                          labelName={Object.keys(field)[1]}
                        ></TicketColumnComponents>
                      }
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Col>
        <Col className="mx-0 ps-4" md={4}>
          <h2 className="main-heading d-inline">
            Ticket Attributes{" "}
            <DarkTooltip
              id="setting-info"
              title="Drag the box to left or click on the box to add the new field"
            >
              <img
                src={InfoIcon}
                alt="info"
                width="15"
                height="15"
                className="mx-2 cursor_pointer"
              />
            </DarkTooltip>
          </h2>
          <Row className="pt-3">
            <Col
              onDragStart={(e) => handleDragStart(e, "SingleLineText")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("SingleLineText");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/SingleLineText.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Single Line Text
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              onDragStart={(e) => handleDragStart(e, "ParagraphText")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("ParagraphText");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/paratext.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Paragraph Text
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col
              onDragStart={(e) => handleDragStart(e, "Dropdown")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("Dropdown");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/dropdown.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Dropdown
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              onDragStart={(e) => handleDragStart(e, "Number")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("Number");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/number.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Number
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col
              onDragStart={(e) => handleDragStart(e, "Checkbox")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("Checkbox");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/checkbox.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Checkbox
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              onDragStart={(e) => handleDragStart(e, "RadioButton")}
              draggable
              md={6}
            >
              <Card
                className="cursor_pointer"
                onClick={() => {
                  setField("RadioButton");
                  setAddFieldModal((o) => !o);
                }}
              >
                <Card.Body>
                  <Card.Title className="d-flex justify-content-center">
                    {" "}
                    <img
                      src={require("../icons/radio.svg").default}
                      alt="settings icon"
                    />
                  </Card.Title>
                  <Card.Text className="d-flex justify-content-center">
                    Radio Button
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={12}>
              <Card
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDraggingOver(true);
                }}
                onDragLeave={() => {
                  setIsDraggingOver(false);
                }}
                onDrop={(e) => {
                  removeDraggedField(e);
                  setIsDraggingOver(false);
                }}
                className={
                  isDraggingOver
                    ? "border border-danger bg-light"
                    : "border border-danger"
                }
              >
                <Card.Body>
                  <Card.Text className="text-danger d-flex justify-content-center">
                    <img
                      src={require("../icons/deletefield.svg").default}
                      alt="settings icon"
                    />
                    &nbsp; Drag and Drop field here to delete
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TicketsColumnConfig;
