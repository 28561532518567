import { default as React, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getToken, myAppsListUser } from "../../../../api/apps";
import { myAssetsListUser } from "../../../../api/asset";
import { CreateTicketDetails, getTicketingResources } from "../../../../api/ticketing";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import "../ticketing.css";
import Spinner from "react-bootstrap/Spinner";
import CrossIcon from "../../../../images/cross.svg";
import { ThreeDots } from "react-loader-spinner";
import { myWebAppsListUser } from "../../../../api/web_app";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";
import { GetTicketColumnSettingConfig } from "../../../../api/ticketing";

const createOption = (label) => ({
  label,
  value: label,
});

function CreateTicket({
  setTicketAddModal,
  getMyTicketsList,
  getTicketListData,
}) {
  const [loading, setLoading] = useState(false);
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const ws_endpoint = `${serverURL.replace(
    /http:|https:/gi,
    ws_protocol + ":"
  )}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const user_id = useSelector((state) => state.user?.user?.id || "");
  const [sessionkey, setSessionkey] = useState(null);
  const [resourcesList, setResourcesList] = useState([]);
  const options = [
    { value: "APP", label: "App" },
    { value: "ASSET", label: "Asset" },
    { value: "WEB_APP", label: "Web App" },
  ];
  const [resourceType, setResourceType] = useState("");
  const [resourceId, setResourceId] = useState(null);
  const [reason, setReason] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [nowDate, setNowDate] = useState();
  const [nowTime, setNowTime] = useState();
  const [additionalFields, setAdditionalFields] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [fieldList, setFieldList] = useState();
  useEffect(() => {
    dispatch(changeTitle("Ticket"));
    const now = new Date();
    setNowDate(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() < 9 ? "0" + now.getDate() : now.getDate())
    );
    setNowTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );
    setFrom(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() < 9 ? "0" + now.getDate() : now.getDate())
    );
    setFromTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );
    setTo(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() < 9 ? "0" + now.getDate() : now.getDate())
    );
    setToTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );

    handleTicketing();
    GetTicketColumnSetting();
  }, []);

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };

  const getAppsList = async (resource_type) => {
    const { data, error } = await getTicketingResources({
      resource_type: resource_type,
    });
    if (data !== null) {
      setShowLoader(false);
      setResourcesList(
        data.resources.map((app) => {
          app.label = `ID : ${app.id} , Name : ${app.app_name}`;
          app.value = app.id;
          return app;
        })
      );
    }
    if (error !== null) {
      setShowLoader(false);
      showError(error);
    }
  };

  const getAssetsList = async (resource_type) => {
    const { data, error } = await getTicketingResources({
      resource_type: resource_type,
    });
    if (data !== null) {
      setShowLoader(false);
      setResourcesList(
        data.resources.map((asset) => {
          asset.label = `ID : ${asset.id} , Name : ${asset.asset_name}`;
          asset.value = asset.id;
          return asset;
        })
      );
    }
    if (error !== null) {
      setShowLoader(false);
      showError(error);
    }
  };

  const getWebAppsList = async (resource_type) => {
    const { data, error } = await getTicketingResources({
      resource_type: resource_type,
    });
    if (data !== null) {
      setShowLoader(false);
      setResourcesList(
        data.resources.map((web_app) => {
          web_app.label = `ID : ${web_app.id} , Name : ${web_app.app_name}`;
          web_app.value = web_app.id;
          return web_app;
        })
      );
    }
    if (error !== null) {
      setShowLoader(false);
      showError(error);
    }
  };

  const GetTicketColumnSetting = async () => {
    const { data, error } = await GetTicketColumnSettingConfig();
    if (data !== null) {
      setFieldList(data.fields);
    }
  };

  useEffect(() => {
    if (fieldList) {
      const result = fieldList
        .filter((item) => item[Object.keys(item)[0]] > 4)
        .reduce((acc, item) => {
          const key = Object.keys(item)[1];
          if(item[Object.keys(item)[1]]["ColumnType"]=='Checkbox' && item[Object.keys(item)[1]]["DefaultValue"] == null){
            acc[key] = []
          }
          else{
          acc[key] = item[Object.keys(item)[1]]["DefaultValue"];
          }
          return acc;
        }, {});
      setAdditionalFields(result);
    }
  }, [fieldList]);

  useEffect(() => {
    setShowLoader(true);
    setResourcesList([]);
    if (resourceType == "APP") {
      getAppsList("APP");
    }
    if (resourceType == "ASSET") {
      getAssetsList("ASSET");
    }
    if (resourceType == "WEB_APP") {
      getWebAppsList("WEB_APP");
    }
  }, [resourceType]);

  const sendTicket = async (post_data) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_true/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: post_data,
          session_key: sessionkey,
        })
      );
      ticketing_ws_connection.close();
    };
  };

  const CreateTicketFormHandler = async (event) => {
    if (!loading) {
      setShowFeedback(true);
      const form = event.currentTarget;
      let finalFromDate, finalToDate;
      finalFromDate = new Date(from + " " + fromTime + ":00");
      finalToDate = new Date(to + " " + toTime + ":59");

      event.preventDefault();
      if (
        form.checkValidity() === false ||
        from === null ||
        to == null ||
        resourceType === "" ||
        resourceId === null ||
        reason === null ||
        fromTime === null ||
        toTime === null
      ) {
        event.stopPropagation();
      } else {
        setLoading(true);
        let post_data = {
          ...(resourceType !== "" && { resource_type: resourceType }),
          ...(resourceId !== "" && { resource_id: resourceId.value }),
          ...(reason !== "" && { reason: reason }),
          ...(from !== "" && { access_from: Date.parse(finalFromDate) / 1000 }),
          ...(to !== "" && { access_until: Date.parse(finalToDate) / 1000 }),
          ...(additionalFields && { additional_fields: additionalFields }),
        };
        const { data, error } = await CreateTicketDetails(post_data);
        if (data !== null) {
          sendTicket(post_data);
          showSuccess(data.message);
          getMyTicketsList(getTicketListData);
          setTicketAddModal((o) => !o);
        }
        if (error !== null) {
          showError(error);
        }
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form onSubmit={CreateTicketFormHandler}>
          <div className="d-flex justify-content-between mb-3">
            <span className="main_content_heading">Create Ticket</span>
            <CrossButton onClick={() => setTicketAddModal((o) => !o)}></CrossButton>
          </div>
          <Stack gap={1}>
            <Row className="mb-3">
              {fieldList &&
                fieldList.map((field, index) => {
                  const isDateColumn =
                    field[Object.keys(field)[1]]["ColumnType"] === "Date";
                  return isDateColumn ? (
                    <div className="mt-3 mx-0">
                      <Row
                        className="date_component_ticketing mx-0 mb-2 pt-2 pb-3 justify-content-between"
                      >
                        <Col md={6}>
                          <Form.Label column md={6} className="input_label">
                            Access From <span className="text-danger">*</span>
                          </Form.Label>
                          <Row>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="date"
                                value={from}
                                min={nowDate}
                                onChange={(event) =>
                                  setFrom(event.target.value)
                                }
                                placeholder="MyTickets"
                                isInvalid={from === null}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="time"
                                min={from > nowDate ? "00:00" : nowTime}
                                value={fromTime}
                                onChange={(event) =>
                                  setFromTime(event.target.value)
                                }
                                isInvalid={fromTime === null}
                              />
                            </Col>
                            {showFeedback &&
                              (fromTime === null || from === null) && (
                                <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                                  Please Enter a Valid Access From Date-Time
                                </span>
                              )}
                          </Row>
                        </Col>
                        <Col className="left_border_date_ticketing" md={6}>
                          <Form.Label column md={6} className="input_label">
                            Access Until <span className="text-danger">*</span>
                          </Form.Label>
                          <Row>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="date"
                                min={from}
                                value={to}
                                onChange={(event) => setTo(event.target.value)}
                                isInvalid={to === null}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="time"
                                value={toTime}
                                min={to > from ? "00:00" : fromTime}
                                onChange={(event) =>
                                  setToTime(event.target.value)
                                }
                                placeholder="MyTickets"
                                isInvalid={toTime === null}
                              />
                            </Col>
                            {showFeedback &&
                              (toTime === null || to === null) && (
                                <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                                  Please Enter a Valid Access Until Date-Time
                                </span>
                              )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : Object.keys(field)[1] == "Resource Type" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Resource Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          onChange={(event) => {
                            setResourceId(null);
                            setResourceType(event.value);
                          }}
                          options={options}
                          required
                        />
                        {showFeedback && !resourceType && (
                          <span className="text-danger fs-14px p-0 m-0">
                            Please select a Resource Type.
                          </span>
                        )}
                      </Col>
                    </>
                  ) : Object.keys(field)[1] == "Resource Name" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Resource Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          required
                          value={resourceId}
                          onChange={(event) => {
                            setResourceId(event);
                          }}
                          options={resourcesList}
                          isInvalid={resourceId === null}
                          placeholder={
                            resourceType == "APP" ||
                            resourceType == "ASSET" ||
                            resourceType == "WEB APP"
                              ? `Select an ${resourceType.toLowerCase()}`
                              : `Select..`
                          }
                          noOptionsMessage={() =>
                            (resourceType === "APP" ||
                              resourceType === "ASSET" ||
                              resourceType === "WEB APP") &&
                            showLoader ? (
                              <div className="d-flex justify-content-center">
                                <ThreeDots
                                  height="20"
                                  width="40"
                                  color="#5177FF"
                                  ariaLabel="three-dots-loading"
                                  visible={true}
                                />
                              </div>
                            ) : resourceType === "APP" ||
                              resourceType === "ASSET" ||
                              resourceType === "WEB APP" ? (
                              `No ${resourceType} found`
                            ) : (
                              `Select Resource Type`
                            )
                          }
                        />
                      </Col>
                    </>
                  ) : Object.keys(field)[1] == "Reason" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Reason <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          className="bg-white py-2 form_input_field_slt_modal"
                          as="textarea"
                          rows={1}
                          value={reason}
                          onChange={(event) => {
                            setReason(event.target.value);
                          }}
                          placeholder="Enter your reason here"
                        />
                      </Col>
                    </>
                  ) : (
                    <Col md={field[Object.keys(field)[1]][
                      "col"
                    ]} className="my-2">
                      <Form.Label column md={10} className="input_label label_word_wrap">
                        {Object.keys(field)[1]}{" "}
                        {field[Object.keys(field)[1]][
                                          "required"
                                        ] && (
                                          <span className="text-danger">*</span>
                                        )}
                      </Form.Label>
                      {field[Object.keys(field)[1]]["ColumnType"] ==
                      "Dropdown" ? (
                        <>
                          <Select
                            onChange={(event) => {
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.value,
                              }));
                            }}
                            defaultValue={field[Object.keys(field)[1]]["DefaultValue"] ? createOption(
                              field[Object.keys(field)[1]]["DefaultValue"]
                            ) : null}
                            options={field[Object.keys(field)[1]][
                              "Options"
                            ].map((option, index) => {
                              return createOption(option);
                            })}
                            required = {field[Object.keys(field)[1]]["required"]}
                            placeholder="Select.."
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "Number" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="bg-white py-2 form_input_field_slt_modal"
                            type="number"
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "ParagraphText" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="bg-white py-2 form_input_field_slt_modal"
                            as="textarea"
                            rows={1}
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "SingleLineText" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="bg-white py-2 form_input_field_slt_modal"
                            type="text"
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "Checkbox" ? (
                        <div key={`inline-checkbox`} className="mt-1">
                          {field[Object.keys(field)[1]]["Options"].map(
                            (optionitem, index) => (
                              <>
                                <Form.Check
                                className="pt-0 mt-0"
                                  defaultChecked={field[Object.keys(field)[1]][
                                    "DefaultValue"
                                  ] ? field[Object.keys(field)[1]][
                                    "DefaultValue"
                                  ].includes(optionitem) : false}
                                  inline
                                  label={optionitem}
                                  name={optionitem}
                                  type="checkbox"
                                  id={`inline-checkbox-1`}
                                  onChange={(event) => {
                                    const { name, id, checked } = event.target;
                                    if (checked) {
                                      additionalFields[
                                        Object.keys(field)[1]
                                      ].push(name);
                                      setAdditionalFields((prevData) => ({
                                        ...prevData,
                                        [Object.keys(field)[1]]:
                                          additionalFields[
                                            Object.keys(field)[1]
                                          ],
                                      }));
                                    } else {
                                      let selectedValues = additionalFields[
                                        Object.keys(field)[1]
                                      ].filter((item) => item !== name);
                                      setAdditionalFields((prevData) => ({
                                        ...prevData,
                                        [Object.keys(field)[1]]: selectedValues,
                                      }));
                                    }
                                  }}
                                />
                              </>
                            )
                          )}
                        </div>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "RadioButton" ? (
                        <div key={`inline-radio`} className="mt-3">
                          {field[Object.keys(field)[1]]["Options"].map(
                            (optionitem, index) => (
                              <>
                                <Form.Check
                                  inline
                                  defaultChecked={
                                    field[Object.keys(field)[1]][
                                      "DefaultValue"
                                    ] == optionitem
                                  }
                                  label={optionitem}
                                  name="group1"
                                  type="radio"
                                  id={`inline-radio-${index + 1}`}
                                  onChange={() =>
                                    setAdditionalFields((prevData) => ({
                                      ...prevData,
                                      [Object.keys(field)[1]]: optionitem,
                                    }))
                                  }
                                />
                              </>
                            )
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  );
                })}
            </Row>

            <Row className="pt-4 pb-2  justify-content-end">
              <Col sm="auto">
                <GeneralButton
                  onClickEvent={() => setTicketAddModal((o) => !o)}
                  className="me-1"
                  value="Cancel"
                  color="#505050"
                  variant="outlined"
                  size="large"
                ></GeneralButton>
              </Col>
              <Col sm="auto" className="me-sm-2 p-0">
                {loading ? (
                  <GeneralButton
                    variant="contained"
                    disabled={true}
                    className="me-1"
                    value={<img src={images.Loader} width="26px" height="26px" />}
                    size="large"
                  ></GeneralButton>
                ) : (
                  <GeneralButton
                    className="me-1"
                    value="Create"
                    variant="contained"
                    size="large"
                  ></GeneralButton>
                )}
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
}
export default CreateTicket;
