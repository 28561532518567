import { Fade } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";
import { MdPending, MdArrowForwardIos } from "react-icons/md";
import { RxCross2, RxDotsHorizontal } from "react-icons/rx";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { get_jwt_token, loginRedirect } from "../../../api/helper_funtions";
import { getTicketDetails } from "../../../api/ticketing";
import {
  default as TicketImage,
  default as ticketingIcon,
} from "../../../images/ticket.svg";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showError } from "../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import CustomModal from "../../common/CustomModal/CustomModal";
import ApproveTicket from "./ChangeTicketStatus/ApproveTicket";
import RejectTicket from "./ChangeTicketStatus/RejectTicket";
const ActionButton = styled(MuiButton)({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: 600,
  fontSize: 14,
  fontStyle: "normal",
  lineHeight: "21px",
  fontFamily: ['"DM Sans"'].join(","),
  letterSpacing: "0.5px",
});

function TicketDetails(props) {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const [approvalModal, setApprovalModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [approvalType, setApprovalType] = useState(null);
  const [approvalTicket, setApprovalTicket] = useState(null);
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const isCapableEnduser = my_capabilities[1];
  const { ticket_id } = useParams();
  const [ticket, setTicket] = useState();
  const [resource, setResourceType] = useState();
  const dispatch = useDispatch();
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const user_id = useSelector((state) => state.user?.user?.id || "");
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const getTicketDetail = async () => {
    const token = get_jwt_token();
    loginRedirect(token);
    const response = await getTicketDetails(ticket_id);
    if (response.data != null) {
      setTicket(response.data.ticket);
      setResourceType(response.data.resource);
    }
    if (response.error != null) {
      showError(response.error);
    }
  };

  useEffect(() => {
    getTicketDetail();
  }, [ticket_id]);

  useEffect(() => {
    dispatch(changeTitle("Ticket"));
  }, []);

  const formatDate = (date) => {
    const today = new Date();
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: "true",
    };
    const formattedDate = new Date(date).toDateString("en-US", options);
    const formattedTime = new Date(date).toLocaleTimeString("en-US", options);
    const [weekday, month, day, year] = formattedDate.split(" ");
    const [, , , hour, period] = formattedTime.split(" ");
    const capitalizedMonth = month.toUpperCase();
    return `${day} ${capitalizedMonth} ${year}, ${weekday} - ${hour} ${period}`;
  };
  const CollapsibleItem = ({ item, expandedId, onToggle }) => {
    const isExpanded = expandedId === item.id;
    return (
      <div>
        <Row
          className="pt-2"
          style={{ cursor: "pointer" }}
          onClick={() => onToggle(item.id)}
        >
          <Col md={5}>
            <p className="ff-pam fs-20 text-color-h3  mb-1">
              {
                ticket.responded_by.find(
                  (user) => user.id === Number(item.user_id)
                ).username
              }
            </p>
            <p className="ff-pam fs-16px profile_info_item ">
              {
                ticket.responded_by.find(
                  (user) => user.id === Number(item.user_id)
                ).email
              }
            </p>
          </Col>
          <Col md={5} className="d-flex align-items-center">
            <div className="ms-auto">
              <p className="ff-pam fs-16px profile_info_item">
                {ticket.responded_by.find(
                  (user) => user.id === Number(item.user_id)
                ).status == "APPROVED" && (
                  <span className="ticket_status_approved">
                    <AiOutlineCheck /> Accepted
                  </span>
                )}
                {ticket.responded_by.find(
                  (user) => user.id === Number(item.user_id)
                ).status == "DENIED" && (
                  <span className="ticket_status_rejected">
                    <RxCross2 /> Rejected
                  </span>
                )}
              </p>
            </div>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <div className="ms-auto">
              <p className="ff-pam profile_info_heading text-color-h3 ">
                <MdArrowForwardIos />
              </p>
            </div>
          </Col>
        </Row>
        {isExpanded && (
          <div>
            <Row className="pt-2">
              <Col md={12}>
                <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                  Response Time
                </p>
                <p className="ff-pam fs-20 profile_info_item pt-1">
                  {formatDate(new Date(ticket.response_time[item.user_id]))}
                </p>
              </Col>
              <Col md={12}>
                <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                  Reason
                </p>
                <p className="ff-pam fs-20 profile_info_item pt-1">
                  {ticket.response[item.user_id]}
                </p>
              </Col>
            </Row>
          </div>
        )}
        <hr className="m-0 p-1"></hr>
      </div>
    );
  };

  const [expandedId, setExpandedId] = useState(null);

  const handleToggle = (itemId) => {
    setExpandedId((prevId) => (prevId === itemId ? null : itemId));
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Ticket":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={ticketingIcon} alt="users round icon" />{" "}
                        &nbsp; Tickets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal
        open={rejectionModal}
        handleClose={() => {
          setRejectionModal((o) => !o);
        }}
      >
        {" "}
        <RejectTicket
          setRejectionModal={setRejectionModal}
          approvalTicket={approvalTicket}
          resource={resource}
          approvalType={approvalType}
          getTicketDetail={getTicketDetail}
        ></RejectTicket>
      </CustomModal>
      <CustomModal
        open={approvalModal}
        handleClose={() => {
          setApprovalModal((o) => !o);
        }}
      >
        {" "}
        <ApproveTicket
          setApprovalModal={setApprovalModal}
          approvalTicket={approvalTicket}
          resource={resource}
          approvalType={approvalType}
          getTicketDetail={getTicketDetail}
        ></ApproveTicket>
      </CustomModal>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              {
                <div className="main_content_container p-3 mx-auto w-100">
                  <div
                    className="position-relative bg-white overflow-auto"
                    style={{ height: `${datatable_height}` }}
                  >
                    {
                      <div className="position-relative bg-white overflow-auto">
                        <div className="heading_datable bg-white">
                          <span
                            style={{ color: "#50514F" }}
                            className="ff-poppins fs-24px fw-600"
                          >
                            <div className="heading_title rdt_TableHeader p-0">
                              <div className="d-flex flex-row justify-content-between align-items-center py-3">
                                {ticket && (
                                  <div className="abc d-flex align-items-center">
                                    <img
                                      src={TicketImage}
                                      alt="EGC"
                                      className=""
                                    />
                                    <h2 className="ps-3 pt-2 fs-20px text-color-h1 fw-500 d-inline">
                                      Ticket Details : {ticket.id}
                                    </h2>
                                  </div>
                                )}
                                {(ticket &&
                                  ticket.ticket_status == "OPEN" &&
                                  ticket.responded_by.some(
                                    (dict) => dict.id === user_id
                                  ) &&
                                  !Object.keys(ticket.response).includes(
                                    String(user_id)
                                  )) ||
                                (ticket && ticket.responded_by.length == 0) ? (
                                  <>
                                    {(isCapableEnduser &&
                                      isCapableEnduser.allow_ticket_view) ||
                                    (isCapable &&
                                      isCapable.miscellaneous.ticket
                                        .view_ticket) ? (
                                      <div>
                                        <ActionButton
                                          onClick={() => {
                                            setApprovalTicket(ticket);
                                            setApprovalType("DENIED");
                                            setRejectionModal((o) => !o);
                                          }}
                                          className="me-3 px-3 py-2"
                                          variant="outlined"
                                          color="error"
                                        >
                                          <RxCross2 />
                                          &nbsp; Reject
                                        </ActionButton>
                                        <ActionButton
                                          onClick={() => {
                                            setApprovalTicket(ticket);
                                            setApprovalType("APPROVED");
                                            setApprovalModal((o) => !o);
                                          }}
                                          className="me-3 px-3 py-2"
                                          variant="outlined"
                                          color="success"
                                        >
                                          <AiOutlineCheck></AiOutlineCheck>
                                          &nbsp; Accept
                                        </ActionButton>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="fs-20px text-color-h1 fw-400 ff-pam text-warning">
                                          <MdPending></MdPending> Pending
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ticket &&
                                  ticket.ticket_status !== "CLOSED" && (
                                    <span className="ticket_status_pending">
                                      <RxCross2 /> Pending
                                    </span>
                                  )
                                )}
                                {ticket && ticket.ticket_status == "CLOSED" && (
                                  <div>
                                    {ticket.approval_type == "APPROVED" ? (
                                      <span className="ticket_status_approved">
                                        <AiOutlineCheck /> Accepted
                                      </span>
                                    ) : (
                                      <span className="ticket_status_rejected">
                                        <RxCross2 /> Rejected
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </span>

                          <div className="select-storage border-top-0 ticket_details overflow-auto p-0 pb-3">
                            {ticket && (
                              <Row>
                                <Col md={7} className="pe-5">
                                  <Row className="pt-2">
                                    <Col md={6}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                        Requested By
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && (
                                          <>{ticket.requested_by.username}</>
                                        )}
                                      </p>
                                    </Col>
                                    <Col md={6}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                        Request Time
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && (
                                          <>
                                            {formatDate(
                                              new Date(ticket.request_time)
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row className="pt-2">
                                    <Col md={6}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                        Resource Type
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && <>{ticket.resource_type}</>}
                                      </p>
                                    </Col>
                                    <Col md={6}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                        Resource Name
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket &&
                                        (ticket.resource_type == "APP" ||
                                          ticket.resource_type == "WEB_APP") ? (
                                          <>
                                            {resource && (
                                              <>{resource.app_name}</>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {resource && (
                                              <>{resource.asset_name}</>
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row className="pt-2">
                                    <Col md={12}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                        reason
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && <>{ticket.reason}</>}
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row className="pt-2">
                                    <Col md={12}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                        Access From
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && (
                                          <>
                                            {formatDate(
                                              new Date(
                                                ticket.access_from * 1000
                                              )
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </Col>
                                    <Col md={12}>
                                      <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1">
                                        Access Until
                                      </p>
                                      <p className="ff-pam fs-20 profile_info_item pt-1">
                                        {ticket && (
                                          <>
                                            {formatDate(
                                              new Date(
                                                ticket.access_until * 1000
                                              )
                                            )}
                                          </>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                  {ticket &&
                                    Object.entries(
                                      ticket.additional_fields
                                    ).map(([key, value]) => (
                                      <Col lg={6}>
                                        <p className="ff-pam fs-20 profile_info_heading text-color-h3  mb-1 ">
                                          {key}
                                        </p>
                                        <p className="ff-pam fs-20 profile_info_item pt-1">
                                          {!Array.isArray(value) && (
                                            <div>{value}</div>
                                          )}
                                          {Array.isArray(value) &&
                                            value.map((item, index) => (
                                              <p className="d-inline">
                                                &nbsp; {index + 1}) {item}
                                              </p>
                                            ))}
                                        </p>
                                      </Col>
                                    ))}
                                </Col>
                                <Col
                                  md={5}
                                  className={
                                    ticket.approval_type == "APPROVED"
                                      ? "ticket_approved_status_details"
                                      : ticket.approval_type == "DENIED"
                                      ? "ticket_denied_status_details"
                                      : "ticket_pending_status_details"
                                  }
                                >
                                  <div className="d-flex flex-row justify-content-between align-items-center py-3 mt-2">
                                    <div className="ff-pam fs-22px text-color-h1 ps-4 fw-600 d-inline ff-poppins">
                                      Status Details
                                      <br></br>
                                      <span className="ff-pam  fs-14px profile_info_item pt-1">
                                        Minimum Approval Required :{" "}
                                        {ticket.minimum_approvals_required}
                                      </span>
                                    </div>
                                  </div>
                                  {ticket && (
                                    <div className="px-4">
                                      {Object.entries(ticket.response)
                                        .map(([key, value], index) => ({
                                          id: index + 1,
                                          user_id: key,
                                          response: value,
                                        }))
                                        .map((item) => (
                                          <CollapsibleItem
                                            key={item.id}
                                            item={item}
                                            expandedId={expandedId}
                                            onToggle={handleToggle}
                                          />
                                        ))}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            )}
                            <Row></Row>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
}
export default TicketDetails;
