import React, { useState } from 'react'
import { verifyOtpSaveConfig } from '../../../../api/two_fa'
import OTPInput from "otp-input-react";
import './GoogleAuthenticator.scss';
import { showError, showSuccess } from '../../../../utils/showMessage';
import images from '../../../../utils/images';
import { RxCross1 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";


const GoogleAuthenticator = ({ qrCode, secretKey, setOpenModal, setConfiguredMethod, setIsMethodConfigured}) => {
  
  const [otp, setOTP] = useState("");

  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await verifyOtpSaveConfig({ "otp": otp, "secret_key": secretKey });
    if (data != null) {
      setConfiguredMethod("google_authenticator");
      showSuccess(data.message);
      setIsMethodConfigured(Object.create(null));
      setOpenModal(o => !o);
    }
    if (error != null) {
      showError(error)
    }

  }

  return (
    <>
      <div class="google_authenticator_container">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="mfa_method_heading border-0 customize-column-name mb-0 me-5">Google Authenticator</h3>
            <CrossButton onClick={() => { setOpenModal(o => !o) }}></CrossButton>
          </div>
        <div>
          <p class="mfa_method_description pb-3">
            In this method, you need to scan the QR code from the<span
              class="method_name_highlight"> Google Authenticator App.</span>This App
            will provide the 6 digit OTP token to complete the MFA challenge.
          </p>
        </div>
        <form method="post" action="/login/configure_2fa/{{ state }}" onSubmit={handleFormSubmit}>
          <div class="mfa_method_content d-flex flex-column align-items-center justify-content-between">
            <p class="mfa_method_note pt-4 m-0">
              Scan the QR Code or Use the following secret key
            </p>
            <div class="qr_secret_block d-flex flex-row justify-content-center align-items-center w-100 py-4">
              <div>
                <img src={qrCode} alt="qrcode" id="qrcode" />
              </div>
              <div class="divider mx-5"></div>
              <div class="secret_key_block rounded d-flex justify-content-center align-items-center">
                <p id="secret_key" class="my-0 p-3">{[...secretKey].map((d, i) => (i) % 4 == 0 ? ' ' + d : d).join('').trim()}</p>
                <img src={images.CopyToClipboard} alt="Copy"
                  id="copy_to_clipboard"
                  class="p-3"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="copy"
                />
              </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center py-4">
              <p class="mfa_method_note">Enter the 6-digit authentication code generated by your
                app</p>
              <OTPInput value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={false} className="google_authenticator_otp_input" />
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-end">
            <div class="d-flex justify-content-end">
              <button type="button" id="google_authenticator_cancel_button"
                class="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
              </button>
              <button type="submit" class="btn btn-primary form_submit_button">Confirm</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default GoogleAuthenticator
