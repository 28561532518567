import React from "react";
import { useEffect, useState } from "react";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch } from "react-redux";
import sso from "../../../images/sso.svg";
import { Button, Form, Row, Col } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { testLdapConf, postLdapConf, getLdapConf, triggerImportResources } from "../../../api/ldap";
import { showError, showSuccess } from "../../../utils/showMessage";
import "./sso.scss"

const Ldap = () => {
  const ldapServerUrl = [
    { "value": "ldap://", "label": "ldap://" },
    { "value": "ldaps://", "label": "ldaps://" },
  ]
  const searchFilterType = [
    { "value": "value", "label": "Label" }
  ]

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [searchBase, setSearchBase] = useState("")
  const [ldapDomain, setLdapDomain] = useState("")
  const [searchFilter, setSearchFilter] = useState("(objectClass=computer)")
  const [serverProtocol, setServerProtocol] = useState(ldapServerUrl[0])
  const [ldapServerDomain, setLdapServerDomain] = useState(ldapServerUrl)
  const [searchFilterTypes, setSearchFilterTypes] = useState(searchFilterType)
  const [searchFilterTypeValue, setSearchFilterTypeValue] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeTitle("SSO"));
  }, [])
  const [isSearchable, setIsSearchable] = useState(true);

  const getLdapConfiguration = async () => {
    const {data, error} = await getLdapConf()
    setLdapDomain(data.url.split("//")[1])
    setServerProtocol({ "value": data.url.split("//")[0] + "//", "label": "ldap://" })
    setUsername(data.username)
    setPassword(data.password)
    setSearchBase(data.search_base)
    setSearchFilter(data.search_filter)
  }


  useEffect(() => {
    getLdapConfiguration();
  }, [])

  const handleSubmitLdap = async (event) => {
    event.preventDefault();
    const {data , error} = await postLdapConf({
      "url": serverProtocol.value + ldapDomain,
      "username": username,
      "password": password,
      "search_base": searchBase,
      "search_filter": searchFilter,
      "test": 0,
    })
    if (data != null) showSuccess(data.message)
    else showError(error)
  }
  
  const testLdapDomain = async (e) => {
    const {data , error} = await testLdapConf({
      "url": serverProtocol.value + ldapDomain,
    })
    if (data != null) showSuccess(data.message)
    else showError(error)
  }

  const importResources = async (e) => {
    const {data, error} = await triggerImportResources({
      "import_devices": true,
    })
    if (data!= null) showSuccess(data.message)
    else showError(error)
  }
  
  const testLdapBindAccount = async (e) => {
    const {data , error} = await postLdapConf({
      "url": serverProtocol.value + ldapDomain,
      "username": username,
      "password": password,
      "test": 1,
    })
    if (data != null) showSuccess(data.message)
    else showError(error)
  }
  
  return (
    <container className="h-100 w-100 d-inline-block">

      <div className="unscrollable">
        <div className='lines' />
        {/* heading div start */}
        <div className='d-flex flex-lg-row justify-content-between align-items-center py-2 mx-5 my-2'>
          <div className='d-flex flex-lg-row justify-content-center align-items-center'>
            <div className='pe-3'><img className='logo-ldap' src={sso} alt="SSO" /></div>
            <div className='sso-heading'>LDAP</div>
          </div>
          <div>
            <Button type="button" onClick={importResources} className='save-btn save-btn-text my-1 mx-3 py-2 px-4'>Import Resources</Button>
            <Button type="submit" form='ldap-form' className='save-btn save-btn-text my-1 mx-3 py-2 px-4'>Save</Button>
          </div>
        </div>
        <div className='lines' />
      </div>

      {/* select LDAP form start */}
      <div className="overflow-auto">
        <span className="ldap-form-heading mt-4 ms-5">AD Configuration</span>
        <Form id="ldap-form" className="mx-5 pb-3 mt-3" onSubmit={handleSubmitLdap}>
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-3">
              <Form.Group>
                <Form.Label className="ldap-server-url-label">LDAP Server URL</Form.Label>
              </Form.Group>
            </Col>
            <Col lg={12} md={12} sm={12} className="d-flex flex-row justify-content-start align-items-center ps-2">
              <CreatableSelect
                className="ldap_server_url_input_field select-oauth"
                isClearable
                required={true}
                defaultValue={serverProtocol}
                isSearchable={isSearchable}
                options={ldapServerUrl}
                onChange={(e) => {setServerProtocol(e)}}
              />
              <Form.Group>
                <Form.Control
                  style={{ width: '148%', height: '3rem' }}
                  className="ldap_server_url_other_input_field"
                  type="text"
                  required
                  value={ldapDomain}
                  onChange={e => setLdapDomain(e.target.value)}
                />
              </Form.Group>
              <div>
                <Button className="ldap-server-url-test-btn" onClick={testLdapDomain}>
                  <span className="ladp-server-url-test-btn-label px-3">Test Connection</span>
                </Button></div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={6} md={12} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Bind Account Username</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={username}
                  className="ldap-form-input my-3"
                  onChange={(e) => { setUsername(e.target.value) }}
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Bind Account Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  className="ldap-form-input my-3"
                />
              </Form.Group>
            </Col>
            <Col lg={2} md={6} sm={12} className="position-relative">
              <Button className="other-test-btn ps-1" onClick={testLdapBindAccount}> 
                <span className="ladp-server-url-test-btn-label px-3">Test Connection</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Search Base</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="ldap-form-input my-3"
                  value={searchBase}
                  onChange={e => setSearchBase(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Search Filter Type</Form.Label>
                <Select
                  className="my-3"
                  required={true}
                  defaultValue={searchFilterType[0]}
                  options={searchFilterType} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Search Filter Value</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="ldap-form-input my-3"
                  value = {searchFilter}
                  onChange={e => setSearchFilter(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <span className="my-3 att-map">Attribute Mapping</span>
          <Row className="mt-3">
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="ldap-form-input my-3"
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="ldap-form-input my-3"
                />
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Group>
                <Form.Label className="ldap-server-url-label">Email</Form.Label>
                <Form.Control
                  type="text"
                  className="ldap-form-input my-3"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </container>)
}
export default Ldap;
