import Form from "react-bootstrap/Form";
import Select from "react-select";
import "./TicketSettings.scss";
const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const DropdownColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <Select
        className="p-0 form_input_field"
        options={
          options.length > 0
            ? options.map((option, index) => {
                return createOption(option);
              })
            : []
        }
        defaultValue={options.length > 0 && createOption(defaultValue)}
        required
      />
    </>
  );
};
const SingleLineTextColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <Form.Control
        disabled
        type="text"
        className="bg-white mt-2 py-2 form_input_field_slt"
        value={defaultValue}
        placeholder={`Enter your ${labelName} here`}
      />
    </>
  );
};
const ParagraphTextColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <Form.Control
        disabled
        className="bg-white mt-2 py-2 form_input_field_slt"
        as="textarea"
        value={defaultValue}
        rows={1}
        placeholder={`Enter your ${labelName} here`}
      />
    </>
  );
};
const NumberColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <Form.Control
        disabled
        type="number"
        className="bg-white mt-2 py-2 form_input_field_slt"
        value={defaultValue}
        placeholder={`Enter your ${labelName} here`}
      />
    </>
  );
};
const CheckboxColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <div key={`inline-checkbox`} className="mt-3">
        {options.map((field, index) => (
          <>
            <Form.Check
              defaultChecked={
                defaultValue ? defaultValue.includes(field) : null
              }
              inline
              label={field}
              name="group1"
              type="checkbox"
              id={`inline-checkbox-1`}
            />
          </>
        ))}
      </div>
    </>
  );
};
const RadioButtonColumn = ({ options, defaultValue, labelName }) => {
  return (
    <>
      <div key={`inline-radio`} className="mt-3">
        {options.map((field, index) => (
          <>
            <Form.Check
              defaultChecked={defaultValue == field}
              inline
              label={field}
              name="group1"
              type="radio"
              id={`inline-radio-1`}
            />
          </>
        ))}
      </div>
    </>
  );
};

const TicketColumnComponents = ({
  columnType,
  options,
  defaultValue,
  labelName,
}) => {
  return (
    <>
      {columnType == "Dropdown" && (
        <DropdownColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></DropdownColumn>
      )}
      {columnType == "SingleLineText" && (
        <SingleLineTextColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></SingleLineTextColumn>
      )}
      {columnType == "ParagraphText" && (
        <ParagraphTextColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></ParagraphTextColumn>
      )}
      {columnType == "Number" && (
        <NumberColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></NumberColumn>
      )}
      {columnType == "Checkbox" && (
        <CheckboxColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></CheckboxColumn>
      )}
      {columnType == "RadioButton" && (
        <RadioButtonColumn
          options={options}
          defaultValue={defaultValue}
          labelName={labelName}
        ></RadioButtonColumn>
      )}
    </>
  );
};

export default TicketColumnComponents;
