import TablePagination from "@mui/material/TablePagination";
import React, { useEffect, useState } from "react";
import { Col, NavDropdown, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { IoMdNotificationsOutline } from "react-icons/io";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "react-tabs/style/react-tabs.css";
import {
  getToken
} from "../../../api/apps";
import { get_jwt_token, loginRedirect } from "../../../api/helper_funtions";
import {
  getEndUserNotifications, updateTicketDetailsEndUser
} from "../../../api/ticketing";
import GetCapabilities from "../../../utils/getCapabilities";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showSuccess } from "../../../utils/showMessage";
import { BsCheck2All } from "react-icons/bs";

const InitialsAvatar = ({ name, firstName, lastName }) => {
  let InitialName;
  if (firstName != "" && lastName != "") {
    InitialName = firstName + " " + lastName;
  } else {
    InitialName = name;
  }
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };
  const [randomColor, setRandomColor] = useState(null);
  const initials = getInitials(InitialName);
  const colorDict = {
    green: ["#079B6E", "#EBF4D9"],
    blue: ["#5177FF", "#E7ECFF"],
    orange: ["#F56A3F", "#FEF1EB"],
  };
  const getRandomColor = () => {
    const colors = Object.keys(colorDict);
    const randomColorKey = colors[Math.floor(Math.random() * colors.length)];
    setRandomColor(colorDict[randomColorKey]);
  };
  useEffect(() => {
    getRandomColor();
  }, []);

  return (
    <>
      {randomColor && (
        <div
          style={{
            backgroundColor: randomColor[1],
            borderRadius: "50%",
            width: "43px",
            height: "43px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            fontWeight: "600",
            marginTop: "10%",
          }}
        >
          <span style={{ color: randomColor[0] }}>{initials}</span>
        </div>
      )}
    </>
  );
};

const EndUserNotificationIcon = (props) => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const isCapableEnduser = my_capabilities[1];
  const [tickets, setTickets] = useState([]);
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const user_id = useSelector((state) => state.user?.user?.id || "");
  const [enableProvider, setEnableProvider] = useState(false);
  const [ticketsCount, setTicketsCount] = useState();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const ws_endpoint = `${serverURL.replace(/http:|https:/gi, ws_protocol + ':')}/`;
  const [sessionkey, setSessionkey] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };
  useEffect(() => {
    handleTicketing();
    if (sessionkey && customer_id != "" && isCapableEnduser && user_id != "") {
      const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_${user_id}/?session_key=${sessionkey}`;
      const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
      ticketing_ws_connection.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data.type === "ticketData") {
          getTickets();
        }
      };
    }
  }, [sessionkey, customer_id, user_id]);
  const getTickets = async (args) => {
    const token = get_jwt_token();
    loginRedirect(token);
    const data = { ticket_types: args, page: page, limit: 4 };
    const response = await getEndUserNotifications(data);
    if (response.data) {
      setTicketsCount(response.data.total_tickets);
      setTickets(response.data.tickets);
      setNotificationCount(response.data.unread_tickets_count);
    }
  };

  useEffect(() => {
    if (enableProvider) {
      getTickets("unread");
    } else {
      getTickets();
    }
  }, [enableProvider, notificationCount, page]);

  const updateTicket = async (ticket) => {
    if (ticket) {
      const { data, error } = await updateTicketDetailsEndUser({
        id: [ticket.id],
      });
    } else {
      let ids = [];
      ids = tickets.map((item) => item.id);
      const { data, error } = await updateTicketDetailsEndUser({
        id: ids,
      });
      if (data != null) {
        showSuccess("All tickets marked read successfully");
      }
    }
    getTickets();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    const today = new Date();
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: "true",
    };
    const formattedDate = new Date(date).toDateString("en-US", options);
    const formattedTime = new Date(date).toLocaleTimeString("en-US", options);
    const [weekday, month, day, year] = formattedDate.split(" ");
    const [, , , hour, period] = formattedTime.split(" ");
    const capitalizedMonth = month.toUpperCase();
    if (today.toDateString() === date.toDateString()) {
      return `Today, ${hour} ${period}`;
    }
    return `${day} ${capitalizedMonth} ${year}, ${weekday} ${hour} ${period}`;
  };
  return (
    <>
      <NavDropdown
        align="end"
        title={
          <div onClick={() => getTickets()} className="d-inline-block ">
            <span>
              <div className="notification-icon">
                {notificationCount != 0 && (
                  <span className="notification-count">
                    {notificationCount}
                  </span>
                )}
                <IoMdNotificationsOutline
                  className="mt-1 pt-1"
                  style={{
                    height: "30px",
                    width: "30px",
                    backgroundColor: "white",
                  }}
                ></IoMdNotificationsOutline>{" "}
              </div>
            </span>
          </div>
        }
        id="collasible-nav-dropdown"
        className="custom-dropdown"
      >
        <div className="p-4 pb-3 pt-3">
          <div className="ff-poppins pb-2 fw-600 fs-22px">
            Notifications
            <span className="ff-poppins fw-600 float-end">
              <div className="d-flex pt-1 justify-content-start">
                <span className="ff-poppins fw-500 text-color-h3 fs-14px">
                  Show unread
                </span>
                <Form.Check
                  className="fs-16px ms-3"
                  type="switch"
                  id="custom-switch"
                  onChange={() => {
                    setEnableProvider((e) => !e);
                  }}
                />
              </div>
            </span>
          </div>
        </div>
        <div
          className="px-4 overflow-auto"
          style={{ maxHeight: `${height - 400}px` }}
        >
          {tickets.length > 0 ? (
            tickets.map((product, index) => (
              <div
                className="cursor_pointer"
                onClick={async () => {
                  if (product.notification_opened.by_enduser == "no") {
                    updateTicket(product);
                  }
                  navigate(`/dashboard/tickets/${product.id}`);
                }}
              >
                {product.notification_opened.by_enduser == "no" && (
                  <Dropdown.Item
                    className="px-2 pt-2 mb-2 pb-0"
                    style={{ background: "#f7f8f8", color: "black" }}
                  >
                    <div>
                      <Row>
                        <Col md={1} className="pe-0 me-0">
                          <InitialsAvatar
                            name={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).username}
                            firstName={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).first_name}
                            lastName={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).last_name}
                          />
                        </Col>
                        <Col md={10} className="ps-4">
                          <p className="ff-poppins">
                            <span className="fw-600 fs-14px">
                              {product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).username}
                            </span>{" "}
                            <span className="fs-14px">
                              has {product.approval_type} your request to extend
                              an "{product.resource_type}"
                            </span>
                            <br></br>
                            <span className="text-color-h3 fs-12px">
                              {formatDate(new Date(Object.values(product.response_time)[Object.values(product.response_time).length-1]))}
                            </span>
                          </p>
                        </Col>
                        <Col md={1} className="pe-0 me-0">
                          <ul className="fs-20px mt-2 text-danger"><li></li></ul>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Item>
                )}
                {product.notification_opened.by_enduser == "yes" && (
                  <Dropdown.Item
                    className="px-2 pt-2 mb-2 pb-0"
                    style={{ background: "white", color: "black" }}
                  >
                    <div>
                      <Row>
                        <Col md={1}>
                          <InitialsAvatar
                            name={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).username}
                            firstName={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).first_name}
                            lastName={product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).last_name}
                          />
                        </Col>
                        <Col md={10} className="ps-4">
                          <p className="ff-poppins">
                            <span className="fw-600 fs-14px">
                            {product.responded_by.find(item => item.id === Number(Object.keys(product.response_time)[Object.keys(product.response_time).length - 1])).username}
                            </span>{" "}
                            <span className="fs-14px">
                              has {product.approval_type} your request to extend
                              an "{product.resource_type}"
                            </span>
                            <br></br>
                            <span className="text-color-h3 fs-12px">
                            {formatDate(new Date(Object.values(product.response_time)[Object.values(product.response_time).length-1]))}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Item>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 pb-0">
              <div
                style={{ background: "white", color: "black" }}
                href="#action3"
              >
                <p className="ff-poppins me-5 pe-5">No notifications found</p>
              </div>
            </div>
          )}
        </div>
        <hr className="mb-0"></hr>
        <Row className="px-4 pt-0 mt-0" style={{ width: "750px" }}>
          <Col md={5}>
            <div className="d-flex justify-content-inline pt-2 mt-1">
              <div>
                <span
                  className="mark_read cursor_pointer me-5"
                  variant="primary"
                  onClick={() => updateTicket()}
                >
                  <BsCheck2All className="mb-1 me-2"></BsCheck2All>Mark all as read
                </span>
              </div>
            </div>
          </Col>
          <Col md={7}>
            <TablePagination
              component="div"
              count={ticketsCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[]}
            />
          </Col>
        </Row>
      </NavDropdown>
    </>
  );
};

export default EndUserNotificationIcon;
