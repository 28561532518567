import axios from "./axios_interceptor";
import { get_jwt_token, loginRedirect } from "./helper_funtions";

export const getPasswordVaultOptions = async (vault_type) => {
  try {
    let token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url = `/vaults/vault-configurations/${vault_type === null ? "" : `?password_vault_type=${vault_type}`}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    console.log(e);
    return { data: null, error: e.response.data.errors };
  }
};

export const updatePasswordVaultOptions = async (args) => {
  try {
    let token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let url = `/vaults/vault-configurations/`;
    const response = await axios.post(url, args, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
