import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { addAsset } from "../../../../api/asset";
import { SystemUserResourceList } from "../../../../api/systemuser";
import CrossIcon from "../../../../images/cross.svg";
import { showError, showSuccess } from "../../../../utils/showMessage";
import makeAnimated from "react-select/animated";
import Fade from "@mui/material/Fade";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import "./AddAsset.scss";
import { checkIpHostPattern } from "../../../../utils/checkIpPattern";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";


import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";

const AddAsset = ({ open, setOpenAddAsset, setAsset }) => {
  const [name, setName] = useState("");
  const [ip_host, setIpHost] = useState("");
  const [port, setPort] = useState("");
  const [assetType, setAssetType] = useState("ssh");
  const [systemUser, setSystemUser] = useState(null);
  const [validated, setValidated] = useState(false);
  const [systemUsers, setSystemUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isIpCheck, setIsIpCheck] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const animatedComponents = makeAnimated();
  const [systemuseroptions, setSystemuseroptions] = useState([]);
  const [slideChecked, setSlideChecked] = useState(false);
  const [ignoreCertificate, setIgnoreCertificate] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const asset_types = [
    { value: "ssh", label: "SSH" },
    { value: "rdp", label: "RDP" },
    { value: "vnc", label: "VNC" },
  ];

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "3px",
      paddingBottom: "3px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  };

  const getSystemUsers = async () => {
    const { data } = await SystemUserResourceList({
      isresource: 1,
      new: true,
      id: 0,
    });
    if (data !== null) {
      setSystemuseroptions(
        data.map((user) => {
          user.label = user.system_display_username;
          user.value = user.id;
          return user;
        })
      );
    }
  };
  useEffect(() => {
    getSystemUsers();
  }, []);

  useEffect(() => {}, [systemUser]);

  const addAssetFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    setIsSubmited(true);
    if (form.checkValidity() === false && !checkIpHostPattern(ip_host)) {
      setIsIpCheck(true);
    } else if (!checkIpHostPattern(ip_host)) {
      setIsIpCheck(true);
      return;
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      const { data, error } = await addAsset({
        ...(name !== "" && { asset_name: name }),
        ...{ ip_host: ip_host },
        ...(port !== "" && { port: port }),
        ...(assetType !== "" && { asset_type: assetType }),
        ...{ systemusers: systemUsers },
        ...{ ignore_certificate: ignoreCertificate },
      });
      if (data !== null) {
        showSuccess(data.message);
        setLoading(false);
        setOpenAddAsset((o) => !o);
        setAsset(new Object());
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };

  const updateSystemUsers = (e) => {
    setSystemUsers(e.map((system_user) => system_user.value));
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Container fluid className="main_content_container mx-auto">
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h2 className="main_content_heading">Add Asset</h2>
            <CrossButton onClick={() => setOpenAddAsset((o) => !o)}></CrossButton>
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={addAssetFormHandler}
            className="add_asset_form"
          >
            <Stack gap={1}>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextName"
              >
                <Form.Label column md={3} className="input_label">
                  Asset Name<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    className="form_input_field"
                    type="text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a asset name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextAssetType"
              >
                <Form.Label column md={3} className="input_label">
                  Asset Type<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Select
                    className="asset_type_select"
                    classNamePrefix="asset"
                    isClearable={true}
                    isSearchable={true}
                    defaultValue={asset_types[0]}
                    name="asset_type"
                    required={true}
                    options={asset_types}
                    onChange={(selectedOption) =>
                      setAssetType(selectedOption.value)
                    }
                    styles={selectStyles}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextPort"
              >
                <Form.Label column md={3} className="input_label">
                  Port<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="number"
                    required
                    onChange={(event) => setPort(event.target.value)}
                    value={port}
                    placeholder="Port"
                    className="form_input_field"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a port.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextIP"
              >
                <Form.Label column md={3} className="input_label">
                  IP/Host
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setIpHost(event.target.value);
                      if (isSubmited) {
                        setIsIpCheck(!checkIpHostPattern(event.target.value));
                      }
                    }}
                    value={ip_host}
                    placeholder="IP/Host"
                    className="form_input_field"
                    isInvalid={isIpCheck}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid IP/Host.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="sysusers"
              >
                <Form.Label column md={3} className="input_label">
                  System Users
                </Form.Label>
                <Col md={9}>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => updateSystemUsers(e)}
                    isMulti
                    styles={selectStyles}
                    options={systemuseroptions}
                  />
                </Col>
              </Form.Group>
              {assetType == "rdp" && (
                <Form.Group
                  as={Row}
                  className="mb-3 justify-content-between"
                  controlId="switch"
                >
                  <Form.Label column md={3} className="input_label">
                    Ignore-Certificate
                  </Form.Label>
                  <Col md={9}>
                    <Form.Check
                      type="checkbox"
                      id="mfa-checkbox"
                      checked={ignoreCertificate}
                      onChange={() => setIgnoreCertificate(!ignoreCertificate)}
                    />
                  </Col>
                </Form.Group>
              )}
              <Row className="pt-3 mb-3 justify-content-end">
                <Col sm="auto">
                  <GeneralButton
                    onClickEvent={() => setOpenAddAsset((o) => !o)}
                    className="me-1"
                    value="Cancel"
                    color="#505050"
                    variant="outlined"
                    size="large"
                  />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {loading ? (
                    <GeneralButton
                      variant="contained"
                      disabled={true}
                      className="me-1"
                      value={
                        <img src={images.Loader} width="26px" height="26px" />
                      }
                      size="large"
                    ></GeneralButton>
                  ) : (
                    <GeneralButton
                      className="me-1"
                      value="Add"
                      variant="contained"
                      size="large"
                    />
                  )}
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      </div>
    </Fade>
  );
};

export default AddAsset;
