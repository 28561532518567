import { Link } from "@mui/material";
import { React, useEffect, useState } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { getToken } from "../../../api/apps";
import {
  getAppsAccessReportData,
  getAssetsAccessReportData,
  getWebAppsAccessReportData,
} from "../../../api/report";
import ThreeDotIcon from "../../../images/three_dot.svg";
import { showError } from "../../../utils/showMessage";
import "./Dashboard.scss";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import { Slide, Fade } from "@mui/material";
import AssetAccessDetails from "../Reports/AssetAccessDetails/AssetAccessDetails";
import { fadedelayTime } from "../../../utils/transitionEffectParams";
import { challengeMFAForResourceAccess } from "../../../api/two_fa";
import { getEndUserProductSettings } from "../../../api/customer";
import MFAOnResourceAccess from "../TwoFa/MFAOnResourceAccess/MFAOnResourceAccess";
import Loader from "../../common/Loader/Loader";
import CustomModal from "../../common/CustomModal/CustomModal";
import ResourceAllocatedSystemUsers from "../../common/ResourceAllocatedSystemUsers/ResourceAllocatedSystemUsers";
import { myAssetsAllocatedSystemUsers } from "../../../api/asset";
import { myAppsAllocatedSystemUsers } from "../../../api/apps";
import { myWebAppsAllocatedSystemUsers } from "../../../api/web_app";

const EnduserDashboard = () => {
  const navigate = useNavigate();
  const [rowID, setRowID] = useState("");
  const [open, setOpen] = useState(false);
  const [configuredMFAInfo, setConfiguredMFAInfo] = useState("");
  const [accessButtonLoading, setAccessButtonLoading] = useState(false);
  const userid = useSelector((state) => state.user.user.id);

  const APP_CURRENTLY_ALLOCATED = "app_currently_allocated";
  const APP_DELETED_RECENTLY_ACCESSED = "app_deleted_recently_accessed";
  const ASSET_CURRENTLY_ALLOCATED = "asset_currently_allocated";
  const ASSET_DELETED_RECENTLY_ACCESSED = "asset_deleted_recently_accessed";
  const WEB_APP_CURRENTLY_ALLOCATED = "web_app_currently_allocated";
  const WEB_APP_DELETED_RECENTLY_ACCESSED = "web_app_deleted_recently_accessed";

  let filteredData1 = [
    { username: "Active", type: "Asset", email: "2023-01-10/10:27:23" },
    { username: "Inactive", type: "App", email: "2023-01-10/10:27:23" },
    { username: "Active", type: "Asset", email: "2023-01-10/10:27:23" },
  ];
  let filteredData3 = [];
  const [appAccessData, setAppAccessData] = useState([]);
  const [assetAccessData, setAssetAccessData] = useState([]);
  const [webappAccessData, setWebappAccessData] = useState([]);
  const { serverUrl } = useSelector((state) => state.customer);
  const [slideChecked, setSlideChecked] = useState(false);
  const [resource, setResource] = useState("");
  const [systemUserList, setSystemUserList] = useState(null);
  const [systemUserId, setSystemUserId] = useState(null);
  const [openSystemUserSelect, setOpenSystemUserSelect] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  useEffect(() => {
    getAppAccess();
    getAssetAccess();
    getWebAppAccess();
  }, [trigger]);
  const getAppAccess = async () => {
    const { data, error } = await getAppsAccessReportData({
      page: 0,
      limit: 4,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setAppAccessData(data.apps);
    }
    if (error != null) {
      showError(error);
    }
  };
  const getAssetAccess = async () => {
    const { data, error } = await getAssetsAccessReportData({
      page: 0,
      limit: 4,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setAssetAccessData(data.assets);
    }
    if (error != null) {
      showError(error);
    }
  };
  const getWebAppAccess = async () => {
    const { data, error } = await getWebAppsAccessReportData({
      page: 0,
      limit: 4,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setWebappAccessData(data.web_apps);
    }
    if (error != null) {
      showError(error);
    }
  };
  const getConfiguredMFAMethod = async () => {
    const { data, error } = await challengeMFAForResourceAccess(userid);
    if (data != null) {
      setConfiguredMFAInfo(data);
      setOpen((o) => !o);
    }
    if (error != null) {
      showError(error);
    }
    setAccessButtonLoading(false);
  };

  const getSystemUserList = async (resource_id, resource_type) => {
    setResource(resource_type);
    if (resource_type === "asset") {
      const { data, error } = await myAssetsAllocatedSystemUsers(resource_id);
      if (data != null) {
        if (data.systemusers.length < 1) setTrigger((o) => !o);
        else {
          setRowID(resource_id);
          setSystemUserList(data.systemusers);
          setOpenSystemUserSelect(true);
        }
      }
      if (error != null) {
        showError(error);
        setOpenSystemUserSelect(false);
      }
    } else if (resource_type === "app") {
      const { data, error } = await myAppsAllocatedSystemUsers(resource_id);
      if (data != null) {
        if (data.systemusers.length < 1) setTrigger((o) => !o);
        else {
          setRowID(resource_id);
          setSystemUserList(data.systemusers);
          setOpenSystemUserSelect(true);
        }
      }
      if (error != null) {
        showError(error);
        setOpenSystemUserSelect(false);
      }
    } else if (resource_type === "webapp") {
      const { data, error } = await myWebAppsAllocatedSystemUsers(resource_id);
      if (data != null) {
        if (data.systemusers.length < 1) setTrigger((o) => !o);
        else {
          setRowID(resource_id);
          setSystemUserList(data.systemusers);
          setOpenSystemUserSelect(true);
        }
      }
      if (error != null) {
        showError(error);
        setOpenSystemUserSelect(false);
      }
    }
  };

  const openAppTab = async (
    openModal = true,
    row_id = null,
    systemuser_id,
    resource_type = null
  ) => {
    if (row_id == null) {
      row_id = rowID;
    }
    if (resource_type == null) {
      resource_type = resource;
    }
    if (resource_type == "app") {
      const { tokenData, tokenError } = await getToken();
      if (tokenData !== null) {
        const url =
          serverUrl +
          "/?resource_id=" +
          row_id +
          "&systemuser_id=" +
          systemuser_id +
          "&resource_type=" +
          "apps" +
          "&token_key=" +
          tokenData.token_key +
          "&token_value=" +
          tokenData.token_value +
          "&session_key=" +
          tokenData.session_key;
        window.open(url, "_blank");
        if (openModal) {
          setOpen((o) => !o);
        }
      }
      if (tokenError !== null) {
        showError(tokenError);
      }
    } else if (resource_type == "asset") {
      const { tokenData, tokenError } = await getToken();
      if (tokenData !== null) {
        const url =
          serverUrl +
          "/?resource_id=" +
          row_id +
          "&systemuser_id=" +
          systemuser_id +
          "&resource_type=assets" +
          "&token_key=" +
          tokenData.token_key +
          "&token_value=" +
          tokenData.token_value +
          "&session_key=" +
          tokenData.session_key;
        window.open(url, "_blank");
        if (openModal) {
          setOpen((o) => !o);
        }
      }
      if (tokenError !== null) {
        showError(tokenError);
      }
    } else if (resource_type == "webapp") {
      const { tokenData, tokenError } = await getToken();
      if (tokenData !== null) {
        const url =
          serverUrl +
          "/?resource_id=" +
          row_id +
          "&systemuser_id=" +
          systemuser_id +
          "&resource_type=web_apps" +
          "&token_key=" +
          tokenData.token_key +
          "&token_value=" +
          tokenData.token_value +
          "&session_key=" +
          tokenData.session_key;
        window.open(url, "_blank");
        if (openModal) {
          setOpen((o) => !o);
        }
      }
      if (tokenError !== null) {
        showError(tokenError);
      }
    }
  };

  const checkIfMFARequired = async (row_id) => {
    setAccessButtonLoading(true);
    const { data, error } = await getEndUserProductSettings();
    if (data != null) {
      if (data.MFAOverResourceAccess === "false") {
        setAccessButtonLoading(false);
        openAppTab(false, row_id, systemUserId);
      } else {
        getConfiguredMFAMethod();
      }
    }
    if (error != null) {
      showError(error);
      setAccessButtonLoading(false);
    }
    setOpenSystemUserSelect(false);
  };
  const columns = [
    {
      name: "Status",
      cell: (row) =>
        row.username === "Active" ? (
          <p id="endusercolactiveitems">{row.username}</p>
        ) : (
          <p id="endusercolinactiveitems">{row.username}</p>
        ),
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.email,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id="nav-dropdown-user"
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            ></NavDropdown>
          </Nav>
        </>
      ),
      center: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      <Loader loading={accessButtonLoading} bg_papper={true} />
      <CustomModal
        open={open}
        width="48% !important"
        bgcolor="white"
        handleClose={() => {
          setOpen((o) => !o);
        }}
      >
        <MFAOnResourceAccess
          userid={userid}
          setOpen={setOpen}
          configuredMFAInfo={configuredMFAInfo}
          serverUrl={serverUrl}
          rowID={rowID}
          openAppTab={openAppTab}
          systemUserId={systemUserId}
          setSystemUserId={setSystemUserId}
        />
      </CustomModal>
      <Fade timeout={fadedelayTime} in={slideChecked}>
        <div>
          <div className="main_content_container px-4">
            <div className="d-none">
              <p id="enduserp1" className=" ff-pam fw-600 py-3">
                Last Few Sessions{" "}
                <p
                  id="recentseeall"
                  className="float-end ff-pam fs-14px fw-500 text-decoration-none myassetslink"
                >
                  See All
                </p>
              </p>
              {systemUserList && (
                <CustomModal
                  open={openSystemUserSelect}
                  handleClose={() => {
                    setOpenSystemUserSelect(false);
                  }}
                >
                  <ResourceAllocatedSystemUsers
                    systemUserList={systemUserList}
                    setOpenSystemUserSelect={setOpenSystemUserSelect}
                    rowID={rowID}
                    checkIfMFARequired={checkIfMFARequired}
                    systemUserId={systemUserId}
                    setSystemUserId={setSystemUserId}
                    resourceType={resource}
                  />
                </CustomModal>
              )}
              <div className=" main_content_container mx-auto">
                <DataTable
                  columns={columns}
                  data={filteredData1}
                  selectableRows
                  selectableRowsVisibleOnly
                  selectableRowsHighlight
                  highlightOnHover
                />
              </div>
            </div>
            <div>
              <Row className="py-2 ps-2 mb-3 bg-white row-width">
                <p id="enduserp2" className="pt-3 my-0 ms-2 ff-pam fw-600">
                  Recently Used App{" "}
                  <p
                    id="recentseeall"
                    onClick={async () => {
                      navigate("/dashboard/recent-apps");
                    }}
                    className="float-end ff-pam fs-14px fw-500 text-decoration-none myassetslink"
                  >
                    See All
                  </p>
                </p>
                {appAccessData.length > 0 ? (
                  appAccessData.map((product, index) => (
                    <Col
                      lg={6}
                      md={12}
                      sm={12}
                      className="pe-2 text-break mt-3 h-100 py-2"
                      id="endappcol"
                    >
                      <Row className="py-2 border enduseraccess ms-0 me-2">
                        <Col lg={9} md={8} xs={12} className="ps-4">
                          <Row>
                            <Col xs={1} className="d-flex align-items-center">
                              {product.app_type == "MySQL" && (
                                <img
                                  src={require("./icons/mysql.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {product.app_type == "PostgreSQL" && (
                                <img
                                  src={require("./icons/postgres.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {product.app_type == "SAPHanadb" && (
                                <img
                                  src={require("./icons/database.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {product.app_type == "MSSQL" && (
                                <img
                                  src={require("./icons/database.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {product.app_type == "Oracledb" && (
                                <img
                                  src={require("./icons/database.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {/*TODO add the logo for MSSQL and handle related cases here*/}
                            </Col>
                            <Col xs={11} className="d-flex align-items-center">
                              <div className="ps-5">
                                <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                                  {product.app_name}
                                </p>
                                <p className=" my-0 ">
                                  {product.app_type} - {product.user_ip}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg={3}
                          md={4}
                          xs={12}
                          className="ps-5 d-flex align-items-center float-end"
                        >
                          {product.check_allocated ==
                            APP_DELETED_RECENTLY_ACCESSED && (
                            <span
                              class="d-inline-block"
                              tabindex="0"
                              data-bs-toggle="tooltip"
                              title="This App is deallocated"
                            >
                              <Button
                                id="endButton"
                                className="w-100 py-2 text-wrap fs-6"
                                variant="outline-danger"
                                disabled
                              >
                                Access
                              </Button>
                            </span>
                          )}

                          {product.check_allocated ==
                            APP_CURRENTLY_ALLOCATED && (
                            <span>
                              <Button
                                id="endButton"
                                className="w-100 py-2 text-wrap fs-6"
                                variant="outline-primary"
                                onClick={() => {
                                  getSystemUserList(product.app_id, "app");
                                }}
                              >
                                Access
                              </Button>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))
                ) : (
                  <p className="text-center ff-pam pt-3 pb-3">
                    No apps accessed recently
                  </p>
                )}
              </Row>

              <Row className="py-2 ps-2 mb-3 h-totals bg-white row-width">
                <p
                  id="enduserp3"
                  className="pt-3 my-0 bg-white ms-2 ff-pam fw-600"
                >
                  Recently Used Assets{" "}
                  <p
                    id="recentseeall"
                    onClick={async () => {
                      navigate("/dashboard/recent-assets");
                    }}
                    className="float-end ff-pam fs-14px fw-500 text-decoration-none myassetslink"
                  >
                    See All
                  </p>
                </p>
                {assetAccessData.length > 0 ? (
                  assetAccessData.map((product, index) => (
                    <Col
                      lg={6}
                      md={12}
                      sm={12}
                      className="pe-2 text-break mt-3 h-100 py-2"
                      id="endappcol"
                    >
                      <Row className="py-2 border  enduseraccess ms-0 me-2">
                        <Col lg={9} md={7} xs={12} className="ps-4">
                          <Row>
                            <Col xs={1} className="d-flex align-items-center">
                              {product.asset_type == "rdp" && (
                                <img
                                  src={require("./icons/windows.svg").default}
                                  alt="users round icon"
                                />
                              )}
                              {product.asset_type == "ssh" && (
                                <img
                                  src={require("./icons/linux.svg").default}
                                  alt="users round icon"
                                />
                              )}
                            </Col>
                            <Col xs={11} className="d-flex align-items-center">
                              <div className="ps-5">
                                <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                                  {product.asset_name}
                                </p>
                                <p className=" my-0 ">
                                  {product.asset_type} - {product.user_ip}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg={3}
                          md={5}
                          xs={12}
                          className="ps-5 d-flex align-items-center float-end"
                        >
                          {product.check_allocated ==
                            ASSET_DELETED_RECENTLY_ACCESSED && (
                            <span
                              class="d-inline-block"
                              tabindex="0"
                              data-bs-toggle="tooltip"
                              title="This Asset is deallocated"
                            >
                              <Button
                                id="endButton"
                                className=" py-2 text-wrap fs-6"
                                variant="outline-danger"
                                disabled
                              >
                                Access
                              </Button>
                            </span>
                          )}
                          {product.check_allocated ==
                            ASSET_CURRENTLY_ALLOCATED && (
                            <span>
                              <Button
                                id="endButton"
                                className=" py-2 text-wrap fs-6"
                                variant="outline-primary"
                                onClick={() => {
                                  getSystemUserList(product.asset_id, "asset");
                                }}
                              >
                                Access
                              </Button>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))
                ) : (
                  <p className="text-center ff-pam pt-3 pb-3">
                    No assets accessed recently
                  </p>
                )}
              </Row>

              <Row className="py-2 ps-2 h-totals bg-white row-width">
                <p
                  id="enduserp3"
                  className="pt-3 my-0 bg-white ms-2 ff-pam fw-600"
                >
                  Recently Used Web Apps{" "}
                  <p
                    id="recentseeall"
                    onClick={async () => {
                      navigate("/dashboard/recent-webapps");
                    }}
                    className="float-end ff-pam fs-14px fw-500 text-decoration-none myassetslink"
                  >
                    See All
                  </p>
                </p>
                {webappAccessData.length > 0 ? (
                  webappAccessData.map((product, index) => (
                    <Col
                      lg={6}
                      md={12}
                      sm={12}
                      className="pe-2 text-break mt-3 h-100 py-2"
                      id="endappcol"
                    >
                      <Row className="py-2 border  enduseraccess ms-0 me-2">
                        <Col lg={9} md={7} xs={12} className="ps-4">
                          <Row>
                            <Col xs={1} className="d-flex align-items-center">
                              <img
                                width={50}
                                height={50}
                                src={
                                  require("../../../images/web_app_heading.svg")
                                    .default
                                }
                                alt="users round icon"
                              />
                            </Col>
                            <Col xs={11} className="d-flex align-items-center">
                              <div className="ps-5">
                                <p className=" my-0 py-0 fs-14px fw-600 text-colorTitle">
                                  {product.web_app_name}
                                </p>
                                <p className=" my-0 ">
                                  {product.web_app_type} - {product.user_ip}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg={3}
                          md={5}
                          xs={12}
                          className="ps-5 d-flex align-items-center float-end"
                        >
                          {product.check_allocated ==
                            WEB_APP_DELETED_RECENTLY_ACCESSED && (
                            <span
                              class="d-inline-block"
                              tabindex="0"
                              data-bs-toggle="tooltip"
                              title="This WebApp is deallocated"
                            >
                              <Button
                                id="endButton"
                                className=" py-2 text-wrap fs-6"
                                variant="outline-danger"
                                disabled
                              >
                                Access
                              </Button>
                            </span>
                          )}
                          {product.check_allocated ==
                            WEB_APP_CURRENTLY_ALLOCATED && (
                            <span>
                              <Button
                                id="endButton"
                                className=" py-2 text-wrap fs-6"
                                variant="outline-primary"
                                onClick={() => {
                                  getSystemUserList(
                                    product.web_app_id,
                                    "webapp"
                                  );
                                }}
                              >
                                Access
                              </Button>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))
                ) : (
                  <p className="text-center ff-pam pt-3 pb-3">
                    No web apps accessed recently
                  </p>
                )}
              </Row>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default EnduserDashboard;
