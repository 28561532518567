import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";

const ImportUsers = ({importusers, createuser, edituser, deleteuser, setImportusers, setCreateuser, setEdituser, setDeleteuser}) => {
 return (
    <>
        <Container fluid className="mx-auto add_user_container main_content_container">
                <div className='d-flex ps-1 pb-3 align-items-center'>
                    <Form.Check
                        type="switch"
                        checked={importusers}
                        onChange={(e) => {setImportusers(e.target.checked)}}
                    />
                    <div className='ps-3 input_lable'>
                        <div>Import Users</div>
                        <div className='fw-400'>Enabling this option will import the users in the PAM database</div>
                    </div>
                </div>
                {/* TODO: These options are related to provisioning and are yet to be implemented */}
                {/* <div className='d-flex ps-1 pb-3 align-items-center'>
                    <Form.Check
                        type="switch"
                        checked={createuser}
                        onChange={(e) => {setCreateuser(e.target.checked)}}
                    />
                    <div className='ps-3 input_lable'>
                        <div>Create User</div>
                        <div className='fw-400'>Enabling this option will create the user in the selected application upon user creation in PAM</div>
                    </div>
                </div>
                <div className='d-flex ps-1 pb-3 align-items-center'>
                    <Form.Check
                        type="switch"
                        checked={edituser}
                        onChange={(e) => {setEdituser(e.target.checked)}}
                    />
                    <div className='ps-3 input_lable'>
                        <div>Edit User</div>
                        <div className='fw-400'>Enabling this option will update the user profile in the selected application if updated in PAM</div>
                    </div>
                </div>
                <div className='d-flex ps-1 pb-3 align-items-center'>
                    <Form.Check
                        type="switch"
                        checked={deleteuser}
                        onChange={(e) => {setDeleteuser(e.target.checked)}}
                    />
                    <div className='ps-3 input_lable'>
                        <div>Delete Users</div>
                        <div className='fw-400'>Enabling this option will delete the user from the selected application if the user is deleted from PAM</div>
                    </div>
                </div> */}
        </Container>
    </>
 )
    
}

export default ImportUsers;