import React from "react";
import axios from "../../../api/axios_interceptor";
import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { showErrorMsg } from "../../../utils/showMessage";
import "react-toastify/dist/ReactToastify.css";
import "./register.scss";
import InfoIcon from "./icons/icon-info.svg";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import { getMiniOrangeUIs } from "../../../api/customer";
import { useDispatch, useSelector } from 'react-redux';

import { PasswordReg } from "../../common/regex/Regex";
import { PasswordLightToolTip } from "../../common/CustomTooltip/CustomTooltip";
function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [boxChecked, setBoxChecked] = useState(false);
  const pam_domain = window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)
  const { serverUrl } = useSelector((state) => state.customer);
  const getFavicon = async()=>{
    const {data,error} = await getMiniOrangeUIs()
    if(data!== null){
      document.getElementById("favicon").href =serverUrl + data.logo
    }
  }
  useEffect(()=>{
    getFavicon();
  },[])
  //regestering admin
  const [validated,setValidated] = useState(false);
  const [passwordIsInvalid,setPasswordIsInvalid] = useState(false);
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] = useState(false);

  const RegisterAdmin = async (event) => {
    const form = event.currentTarget;
    event.stopPropagation();
    event.preventDefault();
    if(form.checkValidity() === false){
      setValidated(true);
    }
    else{
      let formField = new FormData();
      formField.append("username", username);
      let validation = false
      var email_regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var organisation_regex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/
    if (organisation_regex.test(String(domain).toLowerCase()) === true) {
      validation = true;
      formField.append("domain", `${domain}`)
    } else{
      validation = false
      toast.error("Invalid Domain!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
      if (email_regexp.test(String(email).toLowerCase()) === true) {
        formField.append("email", email);
      } else {
      validation = false
        toast.error("Email Error !", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (password !== confirmpassword) {
       validation=false
       setPasswordIsInvalid(true);
       showErrorMsg("Password and confirm password does not match!",toast.POSITION.TOP_CENTER); 
      }
      formField.append("password", password);
      formField.append("confirmpassword", confirmpassword);
    if(validation){
        try {
          const response = await axios({
            method: "POST",
            url: "/register/",
            data: formField,
          });
          navigate("/login");
        } catch (e) {
          for (let i = 0; i < Object.keys(e.response.data).length; i++) {
            if (Object.keys(e.response.data)[i] === "email") {
              toast.error("email already exist", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            if (Object.keys(e.response.data)[i] === "username") {
              toast.error("username already exist", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            if (Object.keys(e.response.data)[i] === "password") {
              toast.error("password is weak", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            if (Object.keys(e.response.data)[i] === "domain") {
              toast.error("Domain already exist", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
          let toast_message = e.response.data.errors.message[0]
        toast.error(toast_message, {
            position: toast.POSITION.TOP_CENTER,
          });
      }
      }
    }
  };
  return (
    <div id="registerdiv1">
      <div id="registerdiv2">
        <Row>
          <Col id="registercol2" className=" justify-content-center" sm={7}>
            <p id="registerp1">Sign Up</p>
            <p id="registerp2">Get Access to our premium features</p>
            <Form noValidate validated={validated} id="registerform" onSubmit={RegisterAdmin}>
              <Form.Group className="mb-3" >
                <Form.Label id="registerlabel1">Email<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  id="registerinput1"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required placeholder="Enter your email here"/>
                  <Form.Control.Feedback type="invalid">Please provide valid email.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" >
                <Form.Label id="registerlabel1">Username<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  id="registerinput1"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required placeholder="Enter your username here"/>
              </Form.Group>

              <Form.Group className="mb-3">
                        <Form.Label id="registerlabel1">Domain<DarkTooltip id = "setting-info" title=" NOTE: All your custom urls will be based on this name ">
                            <img
                                src={InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className="mx-1 cursor_pointer"/>
                        </DarkTooltip></Form.Label>
                        
                        <span className="d-flex">
                            <Form.Control
                            id="registerinput1"
                            type="text"
                            required
                            placeholder="Enter your domain here"
                            value={domain}
                            onChange={event => setDomain(event.target.value)} />
                        </span>
                    </Form.Group>

              <Form.Group className="mb-3" >
                <Form.Label id="registerlabel1">Password<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  id="registerinput1"
                  required
                  type={boxChecked === true ? "text" : "password"}
                  value={password}
                  isInvalid={passwordIsInvalid}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    const regex = new RegExp(PasswordReg)
                    if(regex.test(e.target.value)){
                      setPasswordIsInvalid(false);
                    }else{
                      setPasswordIsInvalid(true);
                    }
                    if(e.target.value === confirmpassword){
                      setConfirmPasswordIsInvalid(false);
                    }else{
                      setConfirmPasswordIsInvalid(true);
                    }
                  }}
                  placeholder="Enter your password here"/>
                  <Form.Control.Feedback type="invalid">
                  Please enter a valid password
                  <PasswordLightToolTip 
                  title="Invalid password! 
                  Min length:8 Special Char: 1 Lower Case: 1 Upper Case:1 
                  Number:1.Allowed characters are a-z, A-Z, 0-9, @$!%*?&#^().<>"
                  />
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label id="registerlabel1">Confirm Password<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  id="registerinput1"
                  required
                  type={boxChecked === true ? "text" : "password"}
                  value={confirmpassword} 
                  isInvalid={confirmPasswordIsInvalid}
                  onChange={(e) => {
                    setConfirmpassword(e.target.value)
                    if(password === e.target.value){
                      setConfirmPasswordIsInvalid(false);
                    }else{
                      setConfirmPasswordIsInvalid(true);
                    }
                  }}
                  placeholder="Confirm your password here"/>
                   <Form.Check
                type="checkbox"
                label="Show Password"
                className="checkbox_show_password ps-2 my-2 d-flex align-items-center"
                onChange={() => setBoxChecked(!boxChecked)}
              />
              <Form.Control.Feedback type="invalid">
                  Passwords does not match.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label id="registerlabel1">
                  By Creating an Account,{" "}
                  <i>
                    you agree to our <span id="figmaa1" onClick={()=>window.open("https://plugins.miniorange.com/end-user-license-agreement")}>Terms and Policies</span>{" "}
                  </i>
                </Form.Label>
              </Form.Group>

              <Button id="registerbutton1" type="submit">
                CREATE AN ACCOUNT
              </Button>
              <Button id="registerbutton2" type="submit">
              <Form.Label id="registerlabel1">
              Already an account?
                  <i>
                    <a id="figmaa2" onClick={()=>{navigate("/")}}> Login</a>
                  </i>
                 </Form.Label>
             
              </Button>

            </Form>
          </Col>
          <Col id="registercol3" sm={5}>
            <img
              id="registerimg3"
              src={require("./icons/register1.svg").default} />
            <img
              id="registerimg2"
              src={require("./icons/register2.svg").default} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Register;
