import React, { useEffect, useState } from 'react'
import { miniorangeChallenge, miniorangeMFAConfiguration, miniorangePolling } from '../../../../api/two_fa';
import { showError, showSuccess } from '../../../../utils/showMessage';
import PhoneInput from 'react-phone-input-2'
import './SMS.scss';
import images from '../../../../utils/images';
import { RxCross1 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";


const SMS = ({ email, phone_number, setPhoneNumber, countryCode, setCountryCode, methodName, methodHeading, methodNote, setOpenModal, setConfiguredMethod, setIsMethodConfigured }) => {
    let timer;
    const [otpSendSuccesful, setOtpSendSuccesful] = useState(false);
    const [otp, setOtp] = useState("");
    const [txid, setTxid] = useState("");
    const [sendButtonText, setSendButtonText] = useState(["sms_link", "email_link"].includes(methodName) ? "Send Link" : "Send OTP");
    const [loading, setLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const startPolling = async (txid) => {
        const { data, error } = await miniorangePolling(methodName, txid);
        if (data != null) {
            if (data.status === 'SUCCESS') {
                clearInterval(timer);
                const { data, error } = await miniorangeMFAConfiguration({
                    "txid": txid,
                    "method": methodName
                });
                if (data != null) {
                    setConfiguredMethod(methodName);
                    setIsMethodConfigured(Object.create(null));

                    showSuccess(data.message);
                }
                if (error != null) {
                    showError(error);
                }
                setOpenModal(o => !o);
            }
            if (data.status === 'ERROR') {
                clearInterval(timer);
                setOpenModal(o => !o);
            }
            if (data.status === 'DENIED') {
                clearInterval(timer);
                showSuccess(data.message);
                setOpenModal(o => !o);
            }
        }
        if (error != null) {
            clearInterval(timer);
            showError(error);
        }
    }
    const challenge = async (event) => {
        event.preventDefault();
        setLoading(true);
        const { data, error } = await miniorangeChallenge({
            "method": methodName,
            "phone_number": "+" + phone_number,
            "country_code": countryCode
        });
        if (data != null) {
            setTxid(data.txid);
            showSuccess(data.message);
            if (["otp_over_sms", "otp_over_email", "otp_over_sms_email", "otp_over_call"].includes(methodName)) {
                setOtpSendSuccesful(true);
            } else {
                if (timer) {
                    clearInterval(timer);
                }
                timer = setInterval(() => {
                    startPolling(data.txid);
                }, 1000);
            }
            setSendButtonText(["sms_link", "email_link"].includes(methodName) ? "Resend Link" : "Resend OTP")
        }
        if (error != null) {
            showError(error);
        }
        setLoading(false);
    }
    const handleOtpVerification = async (event) => {
        event.preventDefault();
        setVerifyLoading(true);
        const { data, error } = await miniorangeMFAConfiguration({
            "otp": otp,
            "txid": txid,
            "method": methodName
        })
        if (data != null) {
            setConfiguredMethod(methodName);
            setIsMethodConfigured(Object.create(null));
            showSuccess(data.message);
            setOpenModal(o => !o);
        }
        if (error != null) {
            showError(error);
        }
        setVerifyLoading(false);
    }
    useEffect(() => {
        return () => clearInterval(timer);
    }, []);
    return (
        <>
            <div class="sms_container">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mfa_method_heading border-0 customize-column-name mb-0 me-5">{methodHeading}</h3>
                    <CrossButton onClick={() => { setOpenModal(o => !o) }}></CrossButton>
                </div>
                <div>
                    <p id="sms_mfa_method_description pb-3" class="mfa_method_description">
                        {methodNote}
                    </p>
                </div>
                <div class="mfa_method_content py-4">
                    <form class="sms w-100" id="sms_form" onSubmit={challenge}>
                        <div class="form-group py-3 d-flex flex-row justify-content-around align-items-end">
                            <div class="d-flex flex-column align-items-start w-50"
                                id="sms_link_phone_input_container">
                                {["otp_over_sms", "sms_link", "otp_over_sms_email", "otp_over_call"].includes(methodName) &&
                                    <>
                                        <label for="sms_phone_input_field" class="input_label pb-2">Phone</label>
                                        <PhoneInput
                                            inputProps={{
                                                required: true,
                                                autoFocus: true
                                            }}
                                            className="mfa_phone_input p-0"
                                            country={"in"}
                                            value={phone_number}
                                            onChange={(phone, country) => {
                                                setPhoneNumber(phone);
                                                setCountryCode(country.dialCode);
                                            }}
                                        />
                                    </>
                                }
                                {["otp_over_email", "email_link", "otp_over_sms_email"].includes(methodName) &&
                                    <>
                                        <label for="email_sms_input_field" class="input_label py-2">Email</label>
                                        <input id="email_sms_input_field" readOnly value={email} className="form-control form_input_field mfa_input_field" name="email" type="email" />
                                    </>
                                }
                            </div>
                            <button class="btn btn-primary form_submit_button width-20" type="submit" disabled={loading}>
                                {loading ? 
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                sendButtonText}
                            </button>
                        </div>
                    </form>
                    {otpSendSuccesful && <form method="POST" class="sms w-100" id="sms_otp_form" onSubmit={handleOtpVerification}>
                        <div class="form-group py-3 d-flex flex-row justify-content-around align-items-end">
                            <div class="d-flex flex-column align-items-start w-50"
                                id="sms_link_phone_input_container">
                                <label for="sms_otp" class="input_label pb-2">OTP</label>
                                <input id="sms_otp" onChange={(event) => { setOtp(event.target.value) }} type="text" name="otp" className="form-control form_input_field mfa_input_field" placeholder="Enter your OTP" />
                            </div>
                            <button class="btn btn-primary form_submit_button width-20" type="submit" disabled={verifyLoading}>
                                {verifyLoading ? 
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                "Verify OTP"}
                            
                            </button>
                        </div>
                    </form>}
                </div>
                <div class="mt-4 d-flex justify-content-end">
                    <div class="d-flex justify-content-end">
                        <button type="button" id="sms_cancel_button"
                            class="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SMS
