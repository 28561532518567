import React, { useEffect, useState } from "react";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import icon from "./icons/icon.svg";
import DataTable from "react-data-table-component";
import arrow_first from "./icons/arrow_first.svg";
import arrow_last from "./icons/arrow_last.svg";
import arrow_right from "./icons/arrow_right.svg";
import arrow_left from "./icons/arrow_left.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { useSelector } from "react-redux";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import { delayTime, fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import { formatted_timedate } from "../../../utils/updateDateFormat";

import AdvanceFilter from "./AdvanceFilter";
import { fetchData, ActionComponent} from "./getReportsData";
import CustomModal from "../../common/CustomModal/CustomModal";


const AppsReport = () => {
  const path = 'app-audits'
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const UserColumns = [
    { label: "User Id", value: "user_id" },
    { label: "User", value: "email" },
    { label: "Web App Name", value: "web_app_name" },
    { label: "Web App Type", value: "web_app_type" },
    { label: "IP/Host", value: "ip" },
    { label: "Action", value: "action" },
  ];
  const title_heading = useSelector((state) => state.header.title);
  const pages_url = 'audit-dashboard/report_web_apps/';
  const customizeColumnDict = { "IP/Host": false, "Web App Type": true, "User Id": false, "Datetime": true }
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [allFields ,setAllFields] = useState([])



  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        name: "User Id",
        selector: (row) => row.user_id,
        style: {},
        omit: !checkedStateDict["User Id"],
        sortable: true,
      },
      {
        name: "User",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Web App Name",
        selector: (row) => row.web_app_name,
        sortable: true,
      },
      {
        name: "Web App Type",
        selector: (row) => row.web_app_type,
        style: {},
        omit: !checkedStateDict["Web App Type"],
        sortable: true,
      },
      {
        name: "IP/Host",
        selector: (row) => row.ip,
        sortable: true,
        omit: !checkedStateDict["IP/Host"],
      },
      {
        name: "Action",
        selector: (row) => row.action,
        sortable: true,
      },

      {
        name: "Datetime",
        selector: (row) => formatted_timedate(row.datetime),
        style: {},
        omit: !checkedStateDict["Datetime"],
        sortable: true,
        width:'220px'
      },
    ],
    [checkedStateDict]
  );

  /* customize column logic ends. */
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_WebAppReport") !== null
      ? localStorage.getItem("rowsPerPage_WebAppReport")
      : 5
  );
  const [advanceSearchToggle, setAdvanceSearchToggle] = useState(false);
  const [app, setApp] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [totalReportData, setTotalReportData] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const pageType= "web_apps_audit";
  const advancedFilters =  useSelector((state) => state.advanceFilter)
  const advancedQuery =  advancedFilters.advanceQuery[pageType]
  const selectedValuesList= advancedFilters.selectedValuesList
  const keysToExclude = ['city', 'id', 'user_name', 'datetime'];

  const getData = async (page, perPage, search, report_type, response_type, filterBy, advanceFilters) =>{
    fetchData(page, perPage, search, report_type, response_type, filterBy, setReportData, setTotalReportData, setFilteredData, setLoading, setPending, setAllFields,allFields, keysToExclude, advanceFilters, dispatch, selectedValuesList )
  }

  useEffect(() => {
    GetColmn(setCheckedStateDict,  pages_url,  checkedStateDict, setColCount);
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
  }, [perPage]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const filter_set_data = (filteredData, count) => {
    setFilteredData(filteredData);
    setTotalReportData(count);
    setReportData(filteredData);
  };

 const handlePageChange = async (page) => {
     getData(page, perPage, search, pageType, "table", filterBy, advancedQuery);
 };


 const handlePerRowsChange = async (newPerPage, page) => {
  setLoading(true);
  getData(page, newPerPage, search, pageType, "table", filterBy, advancedQuery);
  localStorage.setItem("rowsPerPage_WebAppReport", newPerPage);
  setPerPage(newPerPage);
};


 //for searching in frontend.
 useEffect(() => {
  const result = reportData.filter((object) => {
    let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    if (filterBy === "all") {
      if (search === "") {
        getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
      } else {
        return (
          (object.user_id).toString().match(searchs)||
          object.ip.toLowerCase().match(searchs.toLowerCase()) ||
          object.web_app_name.toLowerCase().match(searchs.toLowerCase()) ||
          object.datetime.toLowerCase().match(searchs.toLowerCase()) ||
          object.web_app_type.toLowerCase().match(searchs.toLowerCase()) ||
          object.action.toLowerCase().match(searchs.toLowerCase())
        );
      }
    } else {
      if (search === "") {
        getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
      }
      else if (["user_id"].includes(filterBy)){
        return (object[filterBy]).toString().match(searchs);
      } 
      else {
        return object[filterBy.toLowerCase()].match(searchs.toLowerCase());
      }
    }
  });

  setFilteredData(result);
}, [search]);


  const handleSearch = async (event) => {
    event.preventDefault();
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
  };



  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal padding="0px !important" open={openFilterPopup}>
        <AdvanceFilter setOpenFilterPopup={setOpenFilterPopup} />
      </CustomModal>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">

                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Web Apps Report"
                        dataTableHeadingIcon={icon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      (
                        <CustomDelaySkeleton
                          rowsPerPage={perPage}
                          colCount={colCount}
                          totalColumns={7}
                        />
                      )
                    }
                    fixedHeader
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalReportData}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    actions={
                      !advanceSearchToggle && <ActionComponent getData={getData} pageType= {pageType} search= {search} filter_by= {filterBy} download_report={false} disable_buttons={filteredData.length > 0 ? true : false} />
                    }
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="web app reports"
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        filterOptions={UserColumns}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                        advanceSearchToggle={advanceSearchToggle}
                        showAdvance={true}
                        setAdvanceSearchToggle={setAdvanceSearchToggle}
                        filter_set_data={filter_set_data}
                        page={1}
                        perPage={perPage}
                        getData={getData}
                        path={path}
                        for_userlogin={true}
                        all_fields={allFields}
                        report_type={pageType}
                        download_report={false}
                        disable_buttons={filteredData.length > 0 ? true : false}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={arrow_first} alt="first page" />}
                    paginationIconLastPage={<img src={arrow_last} alt="last page" />}
                    paginationIconNext={<img src={arrow_right} alt="next" />}
                    paginationIconPrevious={<img src={arrow_left} alt="previous" />}
                  />
                </div>
              </div>
            </div>
          </Fade>


        </div>
      </Slide>
    </>
  );
}
export default AppsReport;
