import React, { useEffect, useState } from "react";
import {
  getAllowedMethods,
  getEndUserMFAConfigurations,
  setEndUserMFAConfigurations,
} from "../../../../api/two_fa";
import images from "../../../../utils/images";
import {
  showError,
  showErrorMsg,
  showSuccess,
} from "../../../../utils/showMessage";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import "./EndUserConfigurations.scss";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../../state/slices/header";
import { MiniorangeConfiguration } from "../../SSO/MiniorangeConfiguration";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../../utils/transitionEffectParams";
import { Slide, Fade } from "@mui/material";
import SaveButton from "../../../common/SaveButton/SaveButton";
import GeneralButton from "../../../common/SaveButton/GeneralButton";

const EndUserConfigurations = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 190 + "px";
  const [methods, setMethods] = useState({});
  const [enableMiniorangeMfaForPAM, setEnableMiniorangeMFAForPAM] =
    useState(false);
  const [enableInlineFlow, setEnableInlineFlow] = useState(false);
  const [enablePAMMfa, setEnablePAMMfa] = useState(false);
  const [isAtleastOneMethodSelected, setIsAtleastOneMethodSelected] =
    useState(null);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  const getAllowedMethodsList = async () => {
    const { data, error } = await getEndUserMFAConfigurations();
    if (data != null) {
      setEnablePAMMfa(data.pam_mfa_enabled);
      setEnableInlineFlow(data.end_user_inline_flow);
      setEnableMiniorangeMFAForPAM(data.miniorange_mfa_enabled);
      setMethods(data.end_user_enabled_methods);
      checkIsAtleastOneMethodSelected(data.end_user_enabled_methods);
    }
    if (error != null) {
      showError(error);
    }
  };
  useEffect(() => {
    getAllowedMethodsList();
    dispatch(changeTitle("TWOFA"));
  }, []);

  function checkIsAtleastOneMethodSelected(methods) {
    for (const i in Object.keys(methods)) {
      let key = Object.keys(methods)[i];
      for (let index in methods[key]) {
        let method = methods[key][index];

        if (method.isAllowed === true) {
          setIsAtleastOneMethodSelected(true);
          return;
        }
      }
    }
    setIsAtleastOneMethodSelected(false);
  }

  function updateMethodName(methodName) {
    const newMethodObj = {
      "Authenticator App": [],
      SMS: [],
      Email: [],
      "Miniorange Authenticator": [],
      "Call Verification": [],
      "Hardware Token": [],
      "Security Question": [],
    };
    let isAtleastOneSelected = false;

    for (const i in Object.keys(methods)) {
      let key = Object.keys(methods)[i];
      const newMethods = [];
      for (let index in methods[key]) {
        let method = methods[key][index];
        if (method.method_name === methodName) {
          const newMethod = {
            ...method,
            isAllowed: !method.isAllowed,
          };
          newMethods.push(newMethod);
        } else {
          newMethods.push(method);
        }
        if (
          (method.method_name !== methodName && method.isAllowed === true) ||
          (method.method_name === methodName && method.isAllowed === false)
        ) {
          isAtleastOneSelected = true;
        }
      }
      newMethodObj[key] = newMethods;
    }
    setIsAtleastOneMethodSelected(isAtleastOneSelected);
    return newMethodObj;
  }

  const handleAllowedMethodCheckbox = (method) => {
    setMethods(updateMethodName(method));
  };

  const handleFormSubmit = async (event) => {
    let tempIsAtleastOneMethodSelected = isAtleastOneMethodSelected;
    if (
      !enableMiniorangeMfaForPAM &&
      !methods["Authenticator App"][0].isAllowed
    ) {
      tempIsAtleastOneMethodSelected = false;
    }
    if (!tempIsAtleastOneMethodSelected && (enableInlineFlow || enablePAMMfa)) {
      showErrorMsg("Please allow atleast one method");
      return;
    }
    const { data, error } = await setEndUserMFAConfigurations({
      allowed_methods: methods,
      end_user_inline_flow: enableInlineFlow,
      miniorange_mfa_enabled: enableMiniorangeMfaForPAM,
      pam_mfa_enabled: enablePAMMfa,
    });
    if (data != null) {
      showSuccess(data.message);
      getAllowedMethodsList();
    }
    if (error != null) {
      showError(error);
    }
  };
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "TWOFA":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={images.Key} alt="users round icon" /> &nbsp;
                        Two Factor Authentication
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  <div className="configure_mfa_container_main">
                    <div className="configure_mfa_heading d-flex flex-row justify-content-start align-items-center">
                      <img
                        src={images.Key}
                        alt="EGC"
                        className="mfa_heading_image"
                      />
                      <h2 className="main-heading">Two-Factor configurations</h2>
                    </div>
                    <div className="w-100 border-bottom pe-5 pb-2 miniOrangeConfiguration-div">
                      <MiniorangeConfiguration />
                    </div>
                    <div className="configure_mfa_body my-5">
                      <div className="enable_mfa_for_pam_container mb-5 d-flex justify-content-between align-items-start">
                        <div>
                          <div>
                            <h3 className="sub_container_heading mb-3">
                              miniOrange Two-Factor Authentication Methods
                            </h3>
                          </div>
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            <FormControlLabel
                              control={
                                <Checkbox checked={enableMiniorangeMfaForPAM} />
                              }
                              onChange={() => {
                                setEnableMiniorangeMFAForPAM((o) => !o);
                              }}
                            />
                            <p className="my-0">
                              Enable 2FA methods of Miniorange
                            </p>
                          </div>
                        </div>
                        <div>
                          <GeneralButton
                            className="me-1"
                            value="Save"
                            variant="outlined"
                            size="large"
                            onClickEvent={handleFormSubmit}
                          />
                        </div>
                      </div>
                      <div>
                        <div>
                          <h3 className="sub_container_heading mb-3">
                            Allowed Methods
                          </h3>
                        </div>
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                          {Object.keys(methods).map((key) => (
                            <>
                              {methods[key].map((method) => (
                                <>
                                  {(enableMiniorangeMfaForPAM ||
                                    method.method_name ==
                                      "google_authenticator") && (
                                    <div
                                      key={method.method_name}
                                      className="allowed_method_card d-flex flex-row justify-content-start align-items-center px-3 m-2"
                                    >
                                      <div>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={method.isAllowed}
                                            />
                                          }
                                          onChange={() => {
                                            handleAllowedMethodCheckbox(
                                              method.method_name
                                            );
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <p className="my-0">{method.name}</p>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </>
                          ))}
                        </div>
                      </div>
                      <div className="inline_registration_configuration_container mt-5">
                        <div>
                          <h3 className="sub_container_heading mb-3">
                            Two-Factor Authentication for PAM
                          </h3>
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <FormControlLabel
                            control={<Checkbox checked={enablePAMMfa} />}
                            onChange={() => {
                              setEnablePAMMfa((o) => !o);
                            }}
                          />
                          <p className="my-0">
                            Enable Two-Factor Authentication for PAM
                          </p>
                        </div>
                      </div>

                      <div className="inline_registration_configuration_container mt-5">
                        <div>
                          <h3 className="sub_container_heading">
                            Inline Registration Configuration
                          </h3>
                          <p className="mb-3 mapping_paragraph_label">
                            Inline registration will only be activated for users
                            registered after enabling this option. Users
                            desiring 2FA can set it up through their dashboard
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <FormControlLabel
                            control={<Checkbox checked={enableInlineFlow} />}
                            onChange={() => {
                              setEnableInlineFlow((o) => !o);
                            }}
                          />
                          <p className="my-0">
                            Enforce users to set up their 2FA Method on First
                            Login
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default EndUserConfigurations;
