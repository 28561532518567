import { Fade } from "@mui/material";
import MuiButton from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import { bulkUploadUser, deleteUser, getUserDetails, getUsersCsv, listUsers, updateUserDetails } from "../../../api/users";
import UserHeadingIcon from "../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import csv_icon from "../../../images/csv_icon.svg";
import exportIcon from "../../../images/export.svg";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import plus from "../../../images/plus.svg";
import ThreeDotIcon from "../../../images/three_dot.svg";
import upload from "../../../images/upload.svg";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import {
  showError,
  showErrorMsg,
  showSuccess,
  showWarning,
} from "../../../utils/showMessage";
import { delayTime, fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import CustomModal from "../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import SampleFile from "../../common/Sample_files/Sample_data.csv";
import ShowAlert from "../../common/ShowAlert/ShowAlert";
import AddUsers from "./AddUsers/AddUsers";
import EditUser from "./EditUser/EditUser";
import UpdatePassword from "./EditUser/UpdatePassword";
import "./UsersList.scss";
import Change_password from "./icons/change_password.svg";
import Delete from "./icons/delete.svg";
import Edit from "./icons/edit.svg";
import Lock_icon from "./icons/lock_icon.svg";
import Locked from "./icons/locked.svg";
import Unlock_icon from "./icons/unlock_icon.svg";
import Unlocked from "./icons/unlocked.svg";
import { useNavigate } from "react-router";
import GetCapabilities from "../../../utils/getCapabilities";
import GeneralButton from "../../common/SaveButton/GeneralButton";

const maxFileSize = 2e+6;
const Csvregex = new RegExp('[^.]+$');

const ActionComponent = ({ setOpenAddUser, setUsers }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [invalidData, setInvalidData] = useState(null);
  const [openbulkUpload, setOpenBulkUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonAble, setButtonAble] = useState(true);
  const [showInvalid, setShowInvalid] = useState(true);
  const [fileSizeExceed, setFileSizeExceed] = useState(false);
  const [isCsv, setIsCsv] = useState(false);
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  let fileSizeExceedMsg= "File size exceeds 2Mb";
  let notCSV= "Selected file is not a csv";
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const handleFileSelect = (event) => {
    let extension = event.target.files[0].name.toLowerCase().match(Csvregex);
    if (getExtension(extension[0]) === "csv") {
      if (event.target.files[0].size > maxFileSize) {
        setIsCsv(false);
        showErrorMsg("File size exceeds maximum");
        setButtonAble(true);
        setFileSizeExceed(true);
      } else {
        setSelectedFile(event.target.files[0]);
        setButtonAble(false);
        setFileSizeExceed(false);
        setIsCsv(false);
      }
    } else {
      showErrorMsg("Selected file is not a csv");
      setFileSizeExceed(false);
      setButtonAble(true);
      setIsCsv(true);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const file = new FormData();
    file.append("file", selectedFile);
    setLoading(true);
    const { data, error } = await bulkUploadUser(file);
    if (data !== null) {
      if (data.partial && data.partail !== null) {
        setUsers(new Object());
        setInvalidData(data.invalid_data);
        showWarning(data.partial);
        setShowInvalid(false);
        setLoading(false);
        setButtonAble(true);
      }
      if (data.success && data.success !== null) {
        showSuccess(data.success);
        setUsers(new Object());
        setLoading(false);
        setOpenBulkUpload((o) => !o);
        setButtonAble(true);
      }
      if (data.failed && data.failed !== null) {
        showErrorMsg(data.failed[0]);
        setInvalidData(data.invalid_data);
        setShowInvalid(false);
        setLoading(false);
        setButtonAble(true);
      }
    }
    if (error != null) {
      showError(error);
      setOpenBulkUpload((o) => !o);
      setLoading(false);
    }
    setSelectedFile(null);
    file.delete("file");
  };

  const getInvalidCSV = async () => {
    if (invalidData !== null) {
      const url = window.URL.createObjectURL(new Blob([invalidData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invalid_data.csv");
      document.body.appendChild(link);
      link.click();
    }
    setShowInvalid(true);
    setOpenBulkUpload((o) => !o);
  };

  const handleClose = () => {
    setFileSizeExceed(false);
    setIsCsv(false);
    setShowInvalid(true);
    setOpenBulkUpload((o) => !o);
    setButtonAble(true);
  };
  const getCSVFile = async () => {
    const { data, error } = await getUsersCsv();
    if (data !== null) {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (error !== null) {
      showError(error);
    }
  };

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  const downloadSampleFile = async () => {
    fetch(SampleFile).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SampleData.csv";
        alink.click();
      });
    });
  };
  return (
    <Stack direction="row" spacing={2} className="py-3">
      {isCapable && isCapable.users.user.add &&(
      <GeneralButton
        onClickEvent={() => downloadSampleFile()}
        className=""
        value="Sample Data"
        color="#000000"
        variant="text"
        size="large"
        startIcon={
          <img
            src={csv_icon}
            height="20px"
            width="20px"
            alt="csv icon"
            id="csv_icon"
          />}
      />
      )}
      <GeneralButton
        onClickEvent={() => getCSVFile()}
        className=""
        value="Export"
        color="#000000"
        variant="text"
        size="large"
        startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
      />
      <CustomModal open={openbulkUpload} width="40% !important" handleClose={()=>{setOpenBulkUpload(o=>!o);}}>
        <Form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <h2 className="main_content_heading">Bulk Upload Users Data</h2>
            <GeneralButton
              onClickEvent={handleClose}
              className=""
              value="Hide"
              color="#505050"
              variant="outlined"
              size="medium"
            />
          </div>
          {loading && <LinearProgress />}
          <div className="py-2">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select CSV file </Form.Label>
              <Form.Control
                onChange={handleFileSelect}
                type="file"
                accept=".csv"
              />
              <div class="ff-poppins fs-12px d-flex bd-highlight">
                <div class="me-auto p-1 bd-highlight text-danger">
                  {fileSizeExceed === true ? fileSizeExceedMsg : ""}
                  {isCsv === true ? notCSV : ""}
                </div>
                <div class="p-1 bd-highlight">Max size : 2Mb</div>
              </div>
            </Form.Group>
          </div>
          <Row className="mb-3 justify-content-end">
            <Col sm="auto" className="me-sm-2 p-0">
              <GeneralButton
                  value="Download Invalid Data"
                  variant="outlined"
                  size="large"
                  color="#f16f4f"
                  hidden={showInvalid}
                  onClickEvent={() => getInvalidCSV()}
                />
            </Col>
            <Col sm="auto">
              <GeneralButton
                onClickEvent={handleClose}
                className="me-1"
                value="Cancel"
                color="#505050"
                variant="outlined"
                size="large"
              />
            </Col>

            <Col sm="auto" className="me-sm-2 p-0">
              <GeneralButton
                className="me-1"
                value="Upload"
                variant="contained"
                size="large"
                disabled={loading || buttonAble}
              />
            </Col>
          </Row>
          <hr />
          <div className="ff-poppins fs-12px">
            <div className="fs-14px">
              Please refer to CSV schema and sample file available for download
              below :
            </div>
            <UploadButton
              onClick={() => downloadSampleFile()}
              startIcon={
                <img
                  src={csv_icon}
                  height="23px"
                  width="23px"
                  alt="csv icon"
                  id="csv_icon"
                />
              }
            >
              Sample File
            </UploadButton>
            (schema and guide included in the sample file)
          </div>
        </Form>
      </CustomModal>
      {isCapable && isCapable.users.user.add && (<>
      <GeneralButton
        onClickEvent={() => setOpenBulkUpload((o) => !o)}
        className=""
        value="Bulk Upload"
        color="#000000"
        variant="text"
        size="large"
        startIcon={<img src={upload} alt="icon" id="center-blueShadow" />}
      />
      <GeneralButton
          startIcon={<img src={plus} alt="icon" id="center-blueShadow" />}
          onClickEvent={() => {
            setOpenAddUser((o) => !o);
          }}
          className="me-1"
          value="Add User"
          variant="outlined"
          size="large"
      /></>)}
    </Stack>
  );
};

const UsersList = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_UserList") !== null
      ? localStorage.getItem("rowsPerPage_UserList")
      : 5
  );
  const [usersList, setUsersList] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [usersData, setUsersData] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/usersList";

  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const customizeColumnDict = {
    "User Id": true,
    "Locking": true,
    "Email": true,
    "Name": true,
    "Phone No.": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [pending, setPending] = useState(true);
  const [colCount, setColcount] = useState(null);
  const title_heading = useSelector((state) => state.header.title);
  const UserColumns = [
    { label: "User Id", value: "id" },
    { label: "Username", value: "username" },
    { label: "Email", value: "email" },
    { label: "Role", value: "role__display_name" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColcount);
    getUsersList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, users]);

  const getUsersList = async ({ page, rowsPerPage, search, filterBy }) => {
    setPending(true);
    const { data, error } = await listUsers({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
    });
    if (data !== null) {
      setUsersList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    }
    else {
      showError(error);
    }
    setPending(false);

  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getUsersList({ page: 1, rowsPerPage, search, filterBy });
  };
  useEffect(() => {
    dispatch(changeTitle("User"));
  }, []);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = usersList.filter((object) => {
      if (!filterBy) {
        return (
          (object.id).toString().match(s) ||
          object.username.toLowerCase().match(s.toLowerCase()) ||
          object.email.toLowerCase().match(s.toLowerCase()) ||
          object.role.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        if (filterBy === "id")
          return (object.id).toString().match(s);
        else if (filterBy === "role__display_name")
          return object.role.display_name
            .toLowerCase()
            .match(search.toLowerCase());
        else return object[filterBy].toLowerCase().match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_UserList", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let rowsToDelete = [];
    if (row) {
      rowsToDelete = row;
    } else {
      rowsToDelete = selectedRows;
    }
    setToBeDeleted(rowsToDelete);
    let temp = [];
    temp = rowsToDelete.map((item) => item.username);
    setColName(temp);
    setOpenDeleteUser((o) => !o);
  };

  const handleDeleteAction = async () => {
    let ids = toBeDeleted.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await deleteUser({ ids: ids });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setUsers(Object.create(null));
      setOpenDeleteUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleUserEdit = async (id) => {
    const { data, error } = await getUserDetails(id);
    if (data !== null) {
      setUsersData(data);
      setOpenEditUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handlelocking = async (id) => {
    const { data, error } = await getUserDetails(id);
    let user_data = data;
    if (data !== null) {
      const { data, error } = await updateUserDetails({
        id: id,
        locked: !user_data.locked,
      });
      if (data !== null) {
        setUsers(new Object());
        setLoading(false);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }

    if (error !== null) {
      showError(error);
    }
  };
  const rowDisabledCriteria = (row) => row.role.role_name === "Customeradmin";

  const handleUpdatePasswordAction = async (id) => {
    const { data, error } = await getUserDetails(id);
    if (data !== null) {
      setOpenUpdatePassword((o) => !o);
      setUsersData(data);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const columns = [
    {
      name: "",
      selector: (row) =>
        row.locked === false ? (
          <img src={Unlocked} alt="Unlocked" />
        ) : (
          <img src={Locked} alt="Locked" />
        ),
      minWidth: "100px",
      grow: 1,
      omit: !checkedStateDict["Locking"],
    },
    {
      name: "User Id",
      selector: (row) => row.id,
      minWidth: "150px",
      omit: !checkedStateDict["User Id"],
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => <div id ="Onhover">{row.username}</div>,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      minWidth: "150px",
      omit: !checkedStateDict["Email"],
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role.display_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        row.first_name !== "" || row.last_name !== ""
          ? row.first_name + " " + row.last_name
          : "-"
      ),
      minWidth: "150px",
      omit: !checkedStateDict["Name"],
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) =>
        row.country_code !== "" && row.phone_number !== ""
          ? "+" + row.country_code + " " + row.phone_number
          : "-",
      minWidth: "150px",
      omit: !checkedStateDict["Phone No."],
      sortable: true,
    },
    isCapable &&( isCapable.users.user.edit || isCapable.apps.view || isCapable.groups.view || isCapable.users.user.change_password|| isCapable.users.user.delete || isCapable.users.user.user_locking || isCapable.users.user.allocate_deallocate_resources)&&{
      name: "Action",
      cell: (row) => (
        <>
          <Nav className="user-dropdown">
            <NavDropdown
              id="nav-dropdown-user"
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.users.user.edit && (<NavDropdown.Item onClick={() => handleUserEdit(row.id)}>
                <img src={Edit} alt="edit" className="mx-2" width="13" height="13" /> Edit
              </NavDropdown.Item>)}
              {row.role.role_name === "Customeradmin" ? null : (
                isCapable && isCapable.users.user.delete && <NavDropdown.Item
                  onClick={async () => {
                    handleDelete([row]);
                  }}
                >
                  <img
                    src={Delete}
                    alt="delete"
                    className="mx-2"
                    width="13"
                    height="13"
                  />{" "}
                  Delete
                </NavDropdown.Item>
              )}
              {isCapable && isCapable.users.user.change_password &&(<NavDropdown.Item
                onClick={() => handleUpdatePasswordAction(row.id)}
              >
                <img
                  src={Change_password}
                  height="13px"
                  width="13px"
                  alt="change password"
                  className="mx-2"
                />{" "}
                Change Password
              </NavDropdown.Item>)}
              {row.role.role_name === "Customeradmin" ? null : (
                isCapable && isCapable.users.user.user_locking && <NavDropdown.Item onClick={() => handlelocking(row.id)}>
                  {row.locked === true ? (
                    <>
                      <img
                        src={Unlock_icon}
                        alt="edit"
                        className="mx-2"
                        height="13px"
                        width="13px"
                      />{" "}
                      Unlock
                    </>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={Lock_icon}
                        alt="edit"
                        className="mx-2"
                        height="13px"
                        width="13px"
                      />{" "}
                      Lock
                    </>
                  )}
                </NavDropdown.Item>
              )}
              {isCapable && isCapable.users.user.allocate_deallocate_resources && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/users/usersList/resource-allocate/${row.id}`);
                }}
              >
               <AiOutlineUserAdd className="mx-2"></AiOutlineUserAdd>{" "} Allocate
              </NavDropdown.Item>)}
              {isCapable && (isCapable.apps.view || isCapable.assets.view)&&(<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/users/usersList/resource-deallocate/${row.id}`);
                }}
              >
               <AiOutlineUserAdd className="mx-2"></AiOutlineUserAdd>{" "} View Allocated
              </NavDropdown.Item>)}
              {isCapable && isCapable.groups.view && (<NavDropdown.Item
                onClick={() => {
                  navigate(`/dashboard/users/usersList/view-groups/${row.id}`);
                }}
              >
               <AiOutlineUsergroupAdd className="mx-2"></AiOutlineUsergroupAdd>{" "} View Groups
              </NavDropdown.Item>)}
            </NavDropdown>
          </Nav>
        </>
      ),
      center: true,
      minWidth: "50px",
    },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "User":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Users
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} className="" direction={slideDirection} in={slideChecked}><div>
        <Fade timeout={fadedelayTime} in={slideChecked}>
          <div>
            <div className="main_content_container p-3 mx-auto w-100">
              <CustomModal open={openAddUser} handleClose={()=>{setOpenAddUser(o=>!o);}}>
                <AddUsers setOpenAddUser={setOpenAddUser} setUsers={setUsers} />
              </CustomModal>

              {usersData && (
                <CustomModal open={openEditUser} handleClose={()=>{setOpenEditUser(o=>!o);}}>
                  {" "}
                  <EditUser
                    setOpenEditUser={setOpenEditUser}
                    setUsers={setUsers}
                    userData={usersData}
                  />
                </CustomModal>
              )}

              {usersData && (
                <CustomModal open={openUpdatePassword} handleClose={()=>{setOpenUpdatePassword(o=>!o);}}>
                  <UpdatePassword
                    setOpenUpdatePassword={setOpenUpdatePassword}
                    setUsers={setUsers}
                    data={usersData}
                  />
                </CustomModal>
              )}

              {colName && (
                <CustomModal open={openDeleteUser} handleClose={()=>{setOpenDeleteUser(o=>!o);}}>
                  <ShowAlert
                    setOpenAlert={setOpenDeleteUser}
                    handleAlertAction={handleDeleteAction}
                    colName={colName}
                    alertMsg="Below users will be deleted : "
                    headingMsg="Delete User"
                  />
                </CustomModal>
              )}

              <div
                className="position-relative bg-white"
                style={{ height: `${datatable_height}` }}
              >
                <DataTable
                  title={
                    <ListHeading
                      dataTableHeadingText="User List"
                      dataTableHeadingIcon={UserHeadingIcon}
                    />
                  }
                  columns={columns}
                  data={filteredData}
                  className="rdt_container"
                  progressPending={pending}
                  progressComponent={
                    (
                      <CustomDelaySkeleton
                        rowsPerPage={rowsPerPage}
                        colCount={colCount}
                        totalColumns={6}
                      />
                    )
                  }
                  fixedHeader
                  paginationDefaultPage={page}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                  pagination
                  paginationServer
                  paginationTotalRows={totalUsers}
                  selectableRows
                  selectableRowsVisibleOnly
                  selectableRowsHighlight
                  highlightOnHover
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  selectableRowDisabled={rowDisabledCriteria}
                  actions={
                    <ActionComponent
                      setUsers={setUsers}
                      setOpenAddUser={setOpenAddUser}
                    />
                  }
                  subHeader
                  subHeaderComponent={
                    <SubHeaderComponent
                      placeholder="users"
                      filterBy={filterBy}
                      action={action}
                      setAction={setAction}
                      setFilterBy={setFilterBy}
                      handleAction={handleAction}
                      filterOptions={UserColumns}
                      actionOptions={(isCapable && isCapable.users.user.delete)?actionOptions:null}
                      search={search}
                      setSearch={setSearch}
                      handleSearch={handleSearch}
                      selectedRows={selectedRows}
                      pages_url={pages_url}
                      checkedStateDict={checkedStateDict}
                      setCheckedStateDict={setCheckedStateDict}
                    />
                  }
                  subHeaderAlign="center"
                  paginationIconFirstPage={
                    <img src={FirstPageIcon} alt="first page" />
                  }
                  paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
                  paginationIconNext={<img src={NextPageIcon} alt="next" />}
                  paginationIconPrevious={
                    <img src={PreviousPageIcon} alt="previous" />
                  }
                />
              </div>
            </div>
          </div>
        </Fade>

      </div>
      </Slide>
    </>
  );
};

export default UsersList;
