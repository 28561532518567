import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions';

export const postNetworkAnalyzerConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/network-analyzer-config/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const putNetworkAnalyzerConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/network-analyzer-config/`;
        const response = await axios.put(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const deleteConfigurations = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/ldap/network-analyzer-config/`;
        const response = await axios.delete(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const getNetworkAnalyzerConfs = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/network-analyzer-config/?page=${data.page}&page_size=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const getNetworkAnalyzerConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/network-analyzer-config/?id=${data.id}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}


export const triggerScanner = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/adanalyzer/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}






export const getScannedresults = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/adanalyzer-history/?get_systemusers=${data.get_systemusers}&config_id=${data.config_id}&page=${data.page}&page_size=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}


export const onboardAssets = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/adanalyzer-history/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}