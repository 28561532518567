import axios from './axios_interceptor';
import { get_jwt_token, loginRedirect } from './helper_funtions'

export const allWebAppsList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/web_apps/web_app/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const allAssetsList = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/web_apps/assets/`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const deleteWebApp = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/web_apps/web_app/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const getWebAppDetail = async ({ web_app_id }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/web_apps/web_app/?web_app_id=${web_app_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const updateWebApp = async ({ web_app_id, updatedWebApp }) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/web_apps/web_app/?web_app_id=${web_app_id}`;
        const response = await axios.put(url, updatedWebApp, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const addWebApp = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/web_apps/web_app/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const userWebAppAllocationList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/user-allocation/?web_app_id=${data.web_app_id}&page=${data.page}&limit=${data.page_size}&search=${data.search}&filter_by=${data.filter_by}&action=${data.action}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const webAppAllocateToUsers = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const url = `/web_apps/user-allocation/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const extendWebAppAllocationUsers = async (web_app_id, updatedWebAppData) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/user-allocation/?web_app_id=${web_app_id}`;
        const response = await axios.put(url, updatedWebAppData, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const webAppDeAllocateToUsers = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/web_apps/user-allocation/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const getWebAppAllocationInfo = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/web-app-allocation-window/?web_app_id=${data.web_app_id}&entity_id=${data.entity_id}&entity_type=${data.entity_type}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const groupWebAppAllocationList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/group-allocation/?web_app_id=${data.web_app_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filter_by}&action=${data.action}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const webAppAllocateToGroups = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const url = `/web_apps/group-allocation/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const extendWebAppAllocationGroup = async (web_app_id, updatedWebAppData) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/group-allocation/?web_app_id=${web_app_id}`;
        const response = await axios.put(url, updatedWebAppData, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}
export const webAppDeAllocateToGroups = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/web_apps/group-allocation/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const myWebAppsListUser = async (data) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/web_apps/user_web_app/?resourcetype=web_apps&userid=${data.userid}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}


export const myWebAppsAllocatedSystemUsers = async (webapp_id) => {

    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/web_apps/user_web_app/?resourcetype=systemuser&webapp_id=${webapp_id}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const roleWebAppAllocationList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/role-allocation/?web_app_id=${data.web_app_id}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filter_by}&action=${data.action}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};

export const webAppAllocateToRoles = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const url = `/web_apps/role-allocation/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};
export const webAppDeAllocateToRoles = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: data
        };
        const url = `/web_apps/role-allocation/`;
        const response = await axios.delete(url, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const extendWebAppAllocationRole = async (web_app_id, updatedWebAppData) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const url = `/web_apps/role-allocation/?web_app_id=${web_app_id}`;
        const response = await axios.put(url, updatedWebAppData, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}

export const updateWebAppAsset = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        };
        const url = `/web_apps/assets/`;
        const response = await axios.post(url, data, config);
        return { data: response.data, error: null };
    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
};



export const allWebAppsAllocationList = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        let url = `/web_apps/web-app-allocation/?entity_id=${data.entity_id}&action=${data.action}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
        const response = await axios.get(url, config);
        return { data: response.data, error: null };

    } catch (e) {
        return { data: null, error: e.response.data.errors };
    }
}