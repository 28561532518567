import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities";
import useWindowDimensions from "../../../utils/getHeightWidth";
import images from "../../../utils/images";
import "../../common/css/common.scss";
import App from "./Apps";
import Asset from "./Asset";
import { ContactUsForm } from "./Contactusform";
import Customizations from "./Customization";
import Groups from "./Groups";
import Policy from "./Policy";
import Settings from "./Settings";
import "./Sidebar.scss";
import SSO from "./Sso";
import TwoFa from "./TwoFa";
import User from "./User";

import WebApp from "./WebApp";
const activeLink = "d-flex py-2 ps-3 active-accordion text-decoration-none";
const normalLink = "d-flex py-2 ps-3 inactive-text-color text-decoration-none";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return <div onClick={decoratedOnClick}>{children}</div>;
}

function Sidebar(props) {
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const isCapableEnduser = my_capabilities[1];
  const title = useSelector((state) => state.header.title);
  const { isEndUser } = props;
  const { width } = useWindowDimensions();

  const activeButton =
    " active-linkElement padding_sidebar sidebar-font text-decoration-none d-flex";

  const normalButton =
    "inactive-text-color padding_sidebar text-decoration-none text-start sidebar-font d-flex";

  return (
    <div className="bs-white">
      <Accordion defaultActiveKey="" flush className="my-2 mr-1 p-30">
        {(isCapable || isCapableEnduser) && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("dashboard"));
                  }}
                  end
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/dashboard.svg").default}
                    alt="dashboard-icon"
                    height="22px"
                    width="22px"
                  />
                  Dashboard
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapable && isCapable.system_user.view && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/mysystemusers"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Sys_user"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/sys_user.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  System Users
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_assets && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myassets"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Assets"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/assets.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Assets
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_apps && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myApps"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("Apps"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/apps.svg").default}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Apps
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.view_allocated_web_apps && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/myWebApps"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("WebApps"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={images.WebAppSidebar}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  My Web Apps
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapableEnduser && isCapableEnduser.enable_disable_mfa && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/dashboard/mfa/list"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("TWOFA"));
                  }}
                >
                  <img
                    className="mx-3 filter-active"
                    src={images.TwoFactorSidebarIcon}
                    alt="sysUser-icon"
                    height="22px"
                    width="22px"
                  />
                  Setup 2FA
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}

        {isCapable &&
          (isCapable.assets.view || isCapable.assets.view_allocated_assets) && (
            <Asset title={title} />
          )}
        {isCapable &&
          (isCapable.apps.view || isCapable.apps.view_allocated_apps) && (
            <App title={title} />
          )}
        {isCapable &&
          (isCapable.web_apps?.view ||
            isCapable.web_apps?.view_allocated_web_apps) && (
            <WebApp title={title} />
          )}
        {isCapable &&
          (isCapable.policies.manage_database_policy.view ||
            isCapable.policies.manage_ssh_policy.view ||
            isCapable.policies.manage_rdp_policy.view ||
            isCapable.policies.manage_password_policy.view) && (
            <Policy title={title} />
          )}
        {isCapable &&
          (isCapable.SSO.enable_disable_sso || isCapable.LDAP.view) && (
            <SSO title={title} />
          )}
        {isCapable && isCapable.MFA.configure_mfa && <TwoFa title={title} />}
        {isCapable &&
          (isCapable.branding.edit_branding_setting ||
            isCapable.branding.manage_email_provider) && (
            <Customizations title={title} />
          )}
        {isCapable &&
          (isCapable.users.user.view || isCapable.users.roles.view) && (
            <User title={title} />
          )}
        {isCapable && isCapable.groups.view && <Groups title={title} />}
        <Card className="border-0 bg-transparent">
          <Card.Header className="p-0 border-0 bg-transparent">
            <CustomToggle eventKey="0">
              <NavLink
                to="/dashboard/tickets"
                className={({ isActive }) =>
                  isActive ? activeButton : normalButton
                }
              >
                <img
                  className="mx-3 filter-active"
                  src={images.TicketIcon}
                  alt="ticket-icon"
                  height="22px"
                  width="22px"
                />
                {(isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                (isCapable && isCapable.miscellaneous.ticket.view_ticket) ? (
                  <>Tickets</>
                ) : (
                  <>My Tickets</>
                )}
              </NavLink>
            </CustomToggle>
          </Card.Header>
        </Card>
        {isCapable && isCapable.miscellaneous.setting.show_settings && (
          <Settings title={title} />
        )}
      </Accordion>
      {<ContactUsForm />}
    </div>
  );
}

export default Sidebar;

export { activeLink, normalLink };
