import { React, useState, useEffect }  from "react";
import { showErrors, showSuccess } from "../../../../utils/showMessage";
import { putNetworkAnalyzerConf, getNetworkAnalyzerConf } from "../../../../api/networkanalyzer";
import LdapConfiguration from "../common/LdapConfiguration";
import { useLocation } from 'react-router-dom';
import { Fade, Slide } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../../state/slices/header";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { delayTime, fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import GetCapabilities from "../../../../utils/getCapabilities";
import { Row, Col } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Heading from "../common/Heading";
import ImportUsers from "../common/ImportUsers";
import ImportGroups from "../common/ImportGroups";
import images from "../../../../utils/images";


const EditConfiguration = () => {

  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });

    const { state } = useLocation();
    const [configurationSubNavbarName, setConfigurationSubNavbarName] = useState("Configuration");

    // ldap configuration variables
    const [confDetail, setConfDetail] = useState(state.confDetail);
    const [ldapName, setLdapName] = useState(confDetail.name);
    const [ldapServerProtocol, setLdapServerProtocol] = useState(confDetail.protocol ? confDetail.protocol : "");
    const [ldapServerDomain, setLdapServerDomain] = useState(confDetail.domain ? confDetail.domain : "");
    const [username, setUsername] = useState(confDetail.username ? confDetail.username : "");
    const [password, setPassword] = useState(confDetail.password ? confDetail.password : "");
    const [searchBase, setSearchBase] = useState(confDetail.search_base ? confDetail.search_base : "");
    const [searchFilterValue, setSearchFilterValue] = useState(confDetail.search_filter ? confDetail.search_filter : "");
    const [ldapValidated, setLdapValidated] = useState(false);
    const [domainName, setDomainName] = useState(confDetail.domain_name ? confDetail.domain_name : "");
    const [firstNameAttr, setFirstNameAttr] = useState(confDetail.attribute_mapping.first_name ? confDetail.attribute_mapping.first_name : ""); 
    const [lastNameAttr, setLastNameAttr] = useState(confDetail.attribute_mapping.last_name ? confDetail.attribute_mapping.last_name : "");
    const [emailAttr, setEmailAttr] = useState(confDetail.attribute_mapping.email ? confDetail.attribute_mapping.email : "");
    const [usernameAttr, setUsernameAttr] = useState(confDetail.attribute_mapping.username ? confDetail.attribute_mapping.username : "");
    const [phoneNumberAttr, setPhoneNumberAttr] = useState(confDetail.attribute_mapping.phone_number ? confDetail.attribute_mapping.phone_number : "");
    
    // import users variables
    const [importusers, setImportusers] = useState(confDetail.import_users);
    const [createuser, setCreateuser] = useState(confDetail.create_user);
    const [edituser, setEdituser] = useState(confDetail.edit_user);
    const [deleteuser, setDeleteuser] = useState(confDetail.delete_user);

    // import groups variables
    const [groupSearchBase, setGroupSearchBase] = useState(confDetail.group_search_base);
    const [importgroups, setImportgroups] = useState(confDetail.import_groups);
    const [creategroup, setCreategroup] = useState(confDetail.create_group);
    const [editgroup, setEditgroup] = useState(confDetail.edit_group);
    const [deletegroup, setDeletegroup] = useState(confDetail.delete_group);

    // import machines variables
    const [machineSearchBase, setMachineSearchBase] = useState(confDetail.machine_search_base);
    const [importmachines, setImportmachines] = useState(confDetail.import_machines);
  
    // import password policy variables
    const [passwordSearchBase, setPasswordSearchBase] = useState(confDetail.password_search_base);
    const [importpasswordpolicy, setImportpasswordpolicy] = useState(confDetail.import_password_policy);


    
    const { width, height } = useWindowDimensions();
    let datatable_height = height - 190 + "px";
    const dispatch = useDispatch();
    const my_capabilities=GetCapabilities();
    const isCapable = my_capabilities[0]
    const [slideChecked, setSlideChecked] = useState(false);
    const title_heading = useSelector((state) => state.header.title);


    const getConfiguration = async () => {
      const { data, error } = await getNetworkAnalyzerConf({ id: state.confDetail.id });
      if (data !== null) {
        setConfDetail(data);
      }
      if (error !== null) {
        showErrors(error);
      }
    };

    useEffect(() => {
      setLdapName(confDetail.name);
      setLdapServerProtocol(confDetail.protocol);
      setLdapServerDomain(confDetail.domain)
      setUsername(confDetail.username)
      setPassword(confDetail.password)
      setSearchBase(confDetail.search_base)
      setSearchFilterValue(confDetail.search_filter)
      setDomainName(confDetail.domain_name)
      setFirstNameAttr(confDetail.attribute_mapping.first_name)
      setLastNameAttr(confDetail.attribute_mapping.last_name)
      setEmailAttr(confDetail.attribute_mapping.email)
      setUsernameAttr(confDetail.attribute_mapping.username)
      setPhoneNumberAttr(confDetail.attribute_mapping.phone_number)
      setImportusers(confDetail.import_users)
      setCreateuser(confDetail.create_user)
      setEdituser(confDetail.edit_user)
      setDeleteuser(confDetail.delete_user)
      setGroupSearchBase(confDetail.group_search_base)
      setImportgroups(confDetail.import_groups)
      setCreategroup(confDetail.create_group)
      setEditgroup(confDetail.edit_group)
      setDeletegroup(confDetail.delete_group)
      setMachineSearchBase(confDetail.machine_search_base)
      setImportmachines(confDetail.import_machines)
      setPasswordSearchBase(confDetail.password_search_base)
      setImportpasswordpolicy(confDetail.import_password_policy)
    }, [confDetail]);

    useEffect(() => {
      dispatch(changeTitle("SSO"));
      setSlideChecked(true);
      getConfiguration();
    }, []);

    const EditLdapFormHandler = async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setLdapValidated(true);
      } else {
        const { data, error } = await putNetworkAnalyzerConf({
          "id": state.confDetail.id,  
          "name": ldapName,
          "protocol": ldapServerProtocol,
          "domain": ldapServerDomain,
          "username": username,
          "password": password,
          "search_base": searchBase,
          "search_filter": searchFilterValue,
          "type": "ldap",
          "import_users": importusers,
          "create_user": createuser,
          "edit_user": edituser,
          "delete_user": deleteuser,
          'group_search_base': groupSearchBase,
          'import_groups': importgroups,
          'create_group': creategroup,
          'edit_group': editgroup,
          'delete_group': deletegroup,
          'machine_search_base': machineSearchBase,
          'import_machines': importmachines,
          'password_search_base': passwordSearchBase,
          'import_password_policy': importpasswordpolicy,
          "domain_name": domainName,
          'attribute_mapping': {
            "first_name": firstNameAttr,
            "last_name": lastNameAttr,
            "email": emailAttr,
            "username": usernameAttr,
            "phone_number": phoneNumberAttr,
          }
        })
        if (data !== null) {
          showSuccess(data.message);
        }
        if (error !== null) {
          showErrors(error);
        }
      }
    };


    return (
        <>            
        {(() => {
        switch (title_heading) {
          case "SSO":
            return (
              <div className="heading_datable bg-white position-sticky">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            images.Authentication
                            }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Authentication
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
          

            <div>
              <div className="main_content_container p-3 mx-auto w-100" style = {{overflowY: 'hidden'}}>
                  <div
                    className="position-relative bg-white w-100"
                    style={{ height: `${datatable_height}` }}
                  >
             <div className="bg-white position-sticky top-0 w-100">
              <div className="configure_mfa_heading p-3 d-flex flex-row justify-content-between align-items-center">
              <div>
                <img
                  src={images.Authentication}
                  alt="LDAP - Import"
                  className="smtp_heading_image"
                />
                <h2 className="main-heading d-inline ms-2">
               &nbsp; LDAP - Edit
                </h2>
              </div>

              <SaveButton  className="me-5" form="ldapconfigform" type="submit" variant="outlined">
                Save 
              </SaveButton>
            </div>
              </div>
                <div className="w-100" style={{ height: "100%" }}>
                <div className="w-100 h-100 ps-5">
                  <Heading configurationSubNavbarName={configurationSubNavbarName} setConfigurationSubNavbarName={setConfigurationSubNavbarName} />
                  <Form
                        id="ldapconfigform"
                        noValidate
                        validated={ldapValidated}
                        onSubmit={EditLdapFormHandler}
                        className="add_user_form w-100 h-100"
                        autoComplete="off"
                  >
                  <div className="d-flex justify-content-start align-items-start overflow-auto h-75">
                  {configurationSubNavbarName === "Configuration" && <LdapConfiguration 
                    ldapName = {ldapName}
                    setLdapName = {setLdapName}
                    ldapServerProtocol = {ldapServerProtocol}
                    setLdapServerProtocol = {setLdapServerProtocol}
                    ldapServerDomain = {ldapServerDomain}
                    setLdapServerDomain = {setLdapServerDomain}
                    username = {username}
                    setUsername = {setUsername}
                    password = {password}
                    setPassword = {setPassword}
                    searchBase = {searchBase}
                    setSearchBase = {setSearchBase}
                    searchFilterValue = {searchFilterValue}
                    setSearchFilterValue = {setSearchFilterValue}
                    domainName={domainName}
                    firstNameAttr={firstNameAttr}
                    lastNameAttr={lastNameAttr}
                    emailAttr={emailAttr}
                    usernameAttr={usernameAttr}
                    phoneNumberAttr={phoneNumberAttr}
                    setDomainName={setDomainName}
                    setFirstNameAttr={setFirstNameAttr}
                    setLastNameAttr={setLastNameAttr}
                    setEmailAttr={setEmailAttr}
                    setUsernameAttr={setUsernameAttr}
                    setPhoneNumberAttr={setPhoneNumberAttr}
                  />}
                  {configurationSubNavbarName === "Import Users" && <ImportUsers 
                    importusers={importusers}
                    createuser={createuser}
                    edituser={edituser}
                    deleteuser={deleteuser}
                    setImportusers={setImportusers}
                    setCreateuser={setCreateuser}
                    setEdituser={setEdituser}
                    setDeleteuser={setDeleteuser}
                  />}
                  {configurationSubNavbarName === "Import Groups" && <ImportGroups 
                    groupSearchBase={groupSearchBase} 
                    importgroups={importgroups}
                    creategroup={creategroup}
                    editgroup={editgroup}
                    deleteuser={deletegroup}
                    setGroupSearchBase={setGroupSearchBase}
                    setImportgroups={setImportgroups}
                    setCreategroup={setCreategroup}
                    setEditgroup={setEditgroup}
                    setDeletegroup={setDeletegroup}
                  />}
                  {configurationSubNavbarName === "Import Machines" && 
                    <Container fluid className="mx-auto add_user_container main_content_container">
                      <Form.Group as={Row} className="mb-3 justify-content-between">
                        <Col md={2}>
                          <Form.Label className="input_lable">
                              Search Base
                          </Form.Label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            type="text"
                            className="form_input_field"
                            value={machineSearchBase}
                            onChange={(event) => setMachineSearchBase(event.target.value)}
                            placeholder="Search Base of Directory"
                            autoComplete="off"
                          />
                        </Col>
                        <Col md={6}></Col>
                      </Form.Group>
                      <div className='d-flex ps-1 pb-3 align-items-center'>
                          <Form.Check
                              type="switch"
                              checked={importmachines}
                              onChange={(e) => {setImportmachines(e.target.checked)}}
                          />
                          <div className='ps-3 input_lable'>
                              <div>Import Machines</div>
                              <div className='fw-400'>Enabling this option will import the rdp machines in the PAM database</div>
                          </div>
                      </div>
                    </Container>}
                    {configurationSubNavbarName === "Import Password Policy" && 
                      <Container fluid className="mx-auto add_user_container main_content_container">
                        <Form.Group as={Row} className="mb-3 justify-content-between">
                          <Col md={2}>
                            <Form.Label className="input_lable">
                                Search Base
                            </Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Control
                              type="text"
                              className="form_input_field"
                              value={passwordSearchBase}
                              onChange={(event) => setPasswordSearchBase(event.target.value)}
                              placeholder="Search Base of Directory"
                              autoComplete="off"
                            />
                          </Col>
                          <Col md={6}></Col>
                        </Form.Group>
                        <div className='d-flex ps-1 pb-3 align-items-center'>
                            <Form.Check
                                type="switch"
                                checked={importpasswordpolicy}
                                onChange={(e) => {setImportpasswordpolicy(e.target.checked)}}
                            />
                            <div className='ps-3 input_lable'>
                                <div>Import Password Policy</div>
                                <div className='fw-400'>Enabling this option will import the Password Policies in the PAM database</div>
                            </div>
                        </div>
                      </Container>}
                    </div>
                  </Form>
                </div>
          </div>
          </div>
          </div>
          </div>
        
            </Fade>
          </div>
        </Slide>
        </>
    )


}

export default EditConfiguration;