import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { showSuccess, showError } from "../../../utils/showMessage";
import { postMiniOCloudData,getMiniOCloudData } from "../../../api/sso";
import {Image} from "react-bootstrap";
import Show from "../../../images/show.png"
import Hide from "../../../images/hide.png"
import SaveButton from "../../common/SaveButton/SaveButton";
import { DarkTooltip } from "../../common/CustomTooltip/CustomTooltip";
import images from "../../../utils/images";
import GeneralButton from "../../common/SaveButton/GeneralButton";

export const MiniorangeConfiguration = () => {
        const [usernameEmail,setUsernameEmail]=useState("")
        const [password,setPassword]=useState("")
        const [domainUrl,setDomainUrl] = useState("")
        const [validated,setValidated] = useState(false)
        const [minioconfigured,setMinioconfigured] = useState(false)
        const [showPassword,setShowPassword] = useState(false)
        const [domainIsInvalid,setDomainIsInvalid] = useState(false)
        const [emailIsInvalid,setEmailIsInvalid] = useState(false)
        const GetMiniOrangeCloudInfo = async()=>{
            const {data,error} = await getMiniOCloudData()
            if(data!== null && data["minioconfig"] === "true"){
                    setMinioconfigured(true)
                    setDomainUrl(data["domainurl"])
                    setDomainIsInvalid(false);
                    setEmailIsInvalid(false);
                }
        }
        useEffect(()=>{
            GetMiniOrangeCloudInfo()
        },[])
        const saveData = async ()=>{
            const { data, error } = await postMiniOCloudData( usernameEmail, password, domainUrl)
        if (data !== null) {
            showSuccess(data.message);
            GetMiniOrangeCloudInfo();
        } else {
            showError(error);
        }
        }
        const handleSubmit=(event)=>{
            const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            setValidated(false);
            saveData()
        }
        }
    return (
        <>
            <div className="mt-3"><span className="sso-form-heading-twofa mt-3 ms-5">miniOrange credentials</span></div>
            <Form id="minio-api-form " noValidate validated={validated} className="ms-5 sso_validations" onSubmit={handleSubmit}>
                <Row className="my-3 d-flex flex-row justify-content-around align-items-end">
                    <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                            <Form.Label className="sso-form-label pe-2">Email</Form.Label>
                            <Form.Control
                                className="user_input_field sso-form-control-input"
                                type="text"
                                required placeholder="Enter your Email"
                                value={minioconfigured ? "***************" : usernameEmail}
                                disabled={minioconfigured ? minioconfigured : false}
                                onChange={(e) => {
                                    setUsernameEmail(e.target.value)
                                    const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                                    if (regex.test(e.target.value)) {
                                      setEmailIsInvalid(false);
                                    } else {
                                      setEmailIsInvalid(true);
                                    } 
                                   }}
                               isInvalid={emailIsInvalid}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter Email.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                            <Form.Label
                                className="sso-form-label pe-2"
                            >Password
                            </Form.Label>
                            <InputGroup
                            controlId="miniorangeCloudPassword"
                            >
                            <Form.Control
                                className="user_input_field sso-form-control-input border-end-0"
                                type={minioconfigured ? "text" : showPassword === false ? "password" : "text"}
                                required
                                value={minioconfigured ? "***************" : password}
                                disabled={minioconfigured ? minioconfigured : false}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password."
                            />
                            <InputGroup.Text
                            id="miniorangePassword"
                            className={minioconfigured ? "bg-inherit my-input-group input-group-text": "bg-white my-input-group input-group-text"}
                            style={{borderBottomRightRadius:"4px",borderTopRightRadius:"4px"}}
                            onClick={()=>setShowPassword(!showPassword)}
                            >
                                {showPassword === false ? (
                        <Image
                          src={Show}
                          height="20px"
                          className="px-2"
                        ></Image>
                      ) : (
                        <Image
                          src={Hide}
                          height="20px"
                          className="px-2"
                        ></Image>
                      )}
                            </InputGroup.Text>
                            
                            <Form.Control.Feedback type="invalid">
                                Please enter Password.
                            </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3 d-flex flex-row justify-content-around align-items-end">
                    <Col lg={6} md={6} sm={12}>
                        <Form.Group>
                            <Form.Label className="sso-form-label pe-2">Domain URL</Form.Label>
                            <DarkTooltip id="setting-info" className="lh-lg" title="Please enter valid branding url.In case of on-premise solution enter domain url, example: http://localhost:8080. In case of cloud solution, enter branding url, example: https://branding.xecurify.com/moas">
                            <img
                                src={images.InfoIcon}
                                alt="info"
                                width="15"
                                height="15"
                                className="mx-2 cursor_pointer" />
                            </DarkTooltip>
                            <Form.Control
                                className="user_input_field sso-form-control-input"
                                type="text"
                                required 
                                placeholder="https://branding.xecurify.com"
                                value={domainUrl}
                                disabled={minioconfigured ? minioconfigured : false}
                                onChange={(e) => {
                                     setDomainUrl(e.target.value);
                                     const regex = new RegExp(/^https?:\/\/(?:localhost(?::([1-9]\d{0,3}))?|(?:[^\/]+\.[^\/]+(?:\/[^\/]*)?))(?:(?!\/$|;\d{1,5}\/?$)[^\/])*$/)
                                     if (regex.test(e.target.value)) {
                                       setDomainIsInvalid(false);
                                     } else {
                                       setDomainIsInvalid(true);
                                     } 
                                    }}
                                isInvalid={domainIsInvalid}
                            />
                            <Form.Control.Feedback type="invalid">
                            Please enter valid branding url.In case of on-premise solution enter domain url, example: http://localhost:8080. In case of cloud solution, enter branding url, example: https://branding.xecurify.com/moas
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="text-end">
                        <GeneralButton
                            className="me-4"
                            value="Save"
                            variant="outlined"
                            size="large"
                            disabled={minioconfigured?true:false}
                        />
                    </Col>
                </Row>
            </Form>
        </>)
}