import React, { useEffect, useState } from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEmailDefaultPolicy, updateUserPassword } from "../../../api/vault";
import Hide from "../../../images/hide.png";
import Show from "../../../images/show.png";
import { showError, showSuccess } from "../../../utils/showMessage";
import LightTooltip from "../../common/CustomTooltip/CustomTooltip";
import {
  passwordPolicyRegex,
  showPasswordInvalidTooltip,
} from "../../common/PasswordPolicy/PolicyRegex";
import InfoIcon from "../../dashboard/Roles/icons/icon-info.svg";
import leftarrow from "./leftarrow.svg";
import logo from "./logo.svg";
import people from "./people.svg";
import "./update_password.scss";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const { update_token } = useParams();
  const [user, setUser] = useState({});
  const [policy, setPolicy] = useState(null);
  const [validated, setValidated] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [logoPath, setLogoPath] = useState(null);

  const getEmailPolicy = async () => {
    const { data, error } = await getEmailDefaultPolicy(update_token);
    if (data !== null) {
      setUser(data.user);
      setPolicy(data.policy);
      setLogoPath(data.logo);
    } else {
      showError(error);
    }
  };
  useEffect(() => {
    getEmailPolicy();
  }, []);

  const callUserPasswordUpdate = async () => {
    const args = {
      email: user?.email,
      password: currentPassword,
      newpassword: newPassword,
      confirmpassword: confirmPassword,
      id: user?.id,
      customer: user?.customer_id,
    };
    const { data, error } = await updateUserPassword(args);
    if (data !== null) {
      showSuccess(data.message);
      setTimeout(() => {
        navigate("/dashboard");
      }, 5000);
    } else {
      showError(error);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };
  const handlePasswordUpdate = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      callUserPasswordUpdate();
    }
  };
  return (
    <div className="page d-flex justify-content-center align-items-center">
      <div className="position-absolute main_container">
        <div className="row m-0 p-5 main-content h-100 align-items-start">
          <div className="col h-100">
            {/* form */}
            <div className="h-100">
              <div>
                <h2 className="heading pb-4">Update Password</h2>
                <p className="subheading pb-2">
                  {user?.email || "pam@xecurify.com"}
                </p>
              </div>
              <Form
                id="updatePasswordForm"
                noValidate
                validated={validated}
                onSubmit={handlePasswordUpdate}
              >
                <div className="pb-3">
                  <Form.Group>
                    <Form.Label className="inputLabel pb-1">
                      Current Password
                    </Form.Label>
                    <InputGroup id="current_password_input">
                      <Form.Control
                        className="user_input_field input_update_password border-end-0"
                        type={showCurrentPass === true ? "text" : "password"}
                        value={currentPassword}
                        onChange={(event) =>
                          setCurrentPassword(event.target.value)
                        }
                        required
                        placeholder="Enter your password here"
                      />
                      <InputGroup.Text
                        id=""
                        className="bg-white show-password-block"
                        onClick={() => setShowCurrentPass(!showCurrentPass)}
                      >
                        {showCurrentPass === false ? (
                          <Image
                            src={Show}
                            height="20px"
                            className="px-2"
                          ></Image>
                        ) : (
                          <Image
                            src={Hide}
                            height="20px"
                            className="px-2"
                          ></Image>
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid password.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="pb-3">
                  <Form.Group>
                    <Form.Label className="inputLabel pb-1">
                      New Password
                    </Form.Label>
                    <InputGroup id="new_password_input">
                      <Form.Control
                        className="user_input_field input_update_password border-end-0"
                        value={newPassword}
                        isInvalid={passwordIsInvalid}
                        type={showNewPass === true ? "text" : "password"}
                        onChange={(event) => {
                          setNewPassword(event.target.value);
                          const regex = new RegExp(
                            passwordPolicyRegex(
                              policy,
                              user?.email,
                              user?.username,
                              user?.first_name,
                              user?.last_name
                            )
                          );
                          if (regex.test(event.target.value)) {
                            setPasswordIsInvalid(false);
                          } else {
                            setPasswordIsInvalid(true);
                          }
                        }}
                        required
                        placeholder="Enter your new password here"
                      />
                      <InputGroup.Text
                        id="clientSecret"
                        className="bg-white show-password-block"
                        onClick={() => setShowNewPass(!showNewPass)}
                      >
                        {showNewPass === false ? (
                          <Image
                            src={Show}
                            height="20px"
                            className="px-2"
                          ></Image>
                        ) : (
                          <Image
                            src={Hide}
                            height="20px"
                            className="px-2"
                          ></Image>
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid password.
                        <LightTooltip
                          title={showPasswordInvalidTooltip(policy)}
                        >
                          <img
                            src={InfoIcon}
                            alt="info"
                            width="20"
                            height="20"
                            className="mx-2 cursor_pointer"
                          />
                        </LightTooltip>
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="pb-3">
                  <Form.Group>
                    <Form.Label className="inputLabel pb-1">
                      Confirm Password
                    </Form.Label>
                    <InputGroup id="confirm_password_input">
                      <Form.Control
                        className="user_input_field input_update_password border-end-0"
                        value={confirmPassword}
                        type={showConfirmPass === true ? "text" : "password"}
                        pattern={newPassword}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                        required
                        placeholder="Enter your new password here"
                      />
                      <InputGroup.Text
                        id="clientSecret"
                        className="bg-white show-password-block"
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                      >
                        {showConfirmPass === false ? (
                          <Image
                            src={Show}
                            height="20px"
                            className="px-2"
                          ></Image>
                        ) : (
                          <Image
                            src={Hide}
                            height="20px"
                            className="px-2"
                          ></Image>
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        Please provide password matching new password.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="pt-3">
                  <button
                    type="submit"
                    id="password_expiry_form_submit_btn"
                    class="btn btn-primary form_submit_button"
                  >
                    Update password
                  </button>
                </div>
                <div
                  className="pt-3 d-flex back-to-login-div justify-content-center"
                  role="button"
                  type="button"
                >
                  <img src={leftarrow} className="leftarrrow pe-3" />
                  <span className="return-to-login">
                    <Link
                      className="link-return-to-login"
                      to={window.location.origin}
                    >
                      Return to Login Page
                    </Link>
                  </span>
                </div>
              </Form>
            </div>
          </div>
          <div className="col ps-5 h-100">
            {/* images */}
            <div className="d-flex h-100 flex-column justify-content-between align-items-center">
              <div className="pt-5 ps-5 d-flex flex-column align-items-center">
                <img
                  className="logo"
                  width="100%"
                  src={logoPath !== null ? logoPath : logo}
                  alt="logo"
                />
              </div>
              <div className="position-absolute">
                <img className="people" src={people} alt="people" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdatePassword;
