import React from "react";
import {DarkTooltip} from "../../common/CustomTooltip/CustomTooltip";
import GeneralButton from "../../common/SaveButton/GeneralButton";

const ListCapabilities = ({
  capability_name,
  handleSelectAll,
  handleResetToDefault,
  handleAllSelected,
  handleActiveStateChange,
  capabilitiesTitle,
  capabilities,
  selectionArray,
  title,
  myCapabilitiesObject,
  isSearching,
  checkSelected,
  dependencyArray,
}) => {
  function get_title(capability_name, mylist, mycapability) {
    let array_list = dependencyArray.flat(1);
    for (let index in array_list) {
      if (Array.isArray(array_list[index][0])){
        for (let i in array_list[index])
        {
          if (mycapability !== null) {
            if (
              capability_name === array_list[index][i][0] &&
              mylist === array_list[index][i][1] &&
              mycapability === array_list[index][i][2]
            ) {
              return array_list[index][i][4];
            }
          } else {
            if (
              capability_name === array_list[index][i][0] &&
              mylist === array_list[index][i][1]
            ) {
              return array_list[index][i][3];
            }
          }

        }
      }
      else{
        if (mycapability !== null) {
          if (
            capability_name === array_list[index][0] &&
            mylist === array_list[index][1] &&
            mycapability === array_list[index][2]
          ) {
            return array_list[index][4];
          }
        } else {
          if (
            capability_name === array_list[index][0] &&
            mylist === array_list[index][1]
          ) {
            return array_list[index][3];
          }
        }
      }
      
    }
  }
  return (
    <div className="col-md-9 h-100 px-4 pb-4">
      <div>
        <div className="d-flex justify-content-between">
          <div className="ff-poppins fw-600 fs-18px lh-35px">{title}</div>
          {!isSearching && (
            <div className=" pe-4">
              <GeneralButton
                className="me-2 fs-10px"
                value="select all"
                variant="outlined"
                size="small"
                onClickEvent={() =>
                  handleSelectAll(capability_name, selectionArray, true)
                }
              />
              <GeneralButton
                className="me-2 fs-10px"
                value="clear all"
                variant="outlined"
                size="small"
                onClickEvent={() =>
                  handleSelectAll(capability_name, selectionArray, false)
                }
              />
              <GeneralButton
                className="me-1 fs-10px"
                value="reset to default"
                variant="outlined"
                size="small"
                onClickEvent={() =>
                  handleResetToDefault(capability_name, selectionArray)
                }
              />
            </div>
          )}
        </div>
        <p className="ff-poppins fs-12px capability-sub-title">
          Please select the capabilities you require for this role
        </p>
      </div>

      <ul className="ui-tree h-90 overflow-auto">
        {selectionArray !== null &&
          Object.keys(myCapabilitiesObject[capability_name]).map((mylist) => (
            <li>
              <div className="d-flex align-content-center ff-poppins fs-14px">
                <input
                  className="form-check-input mt-2 ms-2"
                  type="checkbox"
                  value=""
                  checked={capabilitiesTitle[mylist]}
                  onChange={() => handleAllSelected(capability_name, mylist)}
                />

                <p className="m-0 ps-2">
                  {myCapabilitiesObject[capability_name][mylist]["Title"]}
                </p>
              </div>
              <ul>
                {Object.keys(myCapabilitiesObject[capability_name][mylist]).map(
                  (mycapability) =>
                    mycapability !== "Title" && (
                      <li>
                        
                        <div className="d-flex align-content-center ff-poppins fs-12px">
                          <DarkTooltip title={get_title(capability_name, mylist, mycapability)}>
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              checked={
                                capabilities[capability_name][mylist][
                                  mycapability
                                ]
                              }
                              onChange={() =>
                                handleActiveStateChange(
                                  capabilities,
                                  capability_name,
                                  mylist,
                                  mycapability
                                )
                              }
                            />
                          </DarkTooltip>
                          <p className="m-0 ps-2">
                            {
                              myCapabilitiesObject[capability_name][mylist][
                                mycapability
                              ]
                            }
                          </p>
                        </div>
                      </li>
                    )
                )}
              </ul>
            </li>
          ))}

        {selectionArray === null && (
          <li>
            <div className="d-flex align-content-center ff-poppins fs-14px fs-14px">
              <input
                className="form-check-input mt-2 ms-2"
                type="checkbox"
                value=""
                checked={capabilitiesTitle[capability_name]}
                onChange={() => handleAllSelected(capability_name)}
              />

              <p className="m-0 ps-2">
                {myCapabilitiesObject[capability_name]["Title"]}
              </p>
            </div>
            <ul>
              {Object.keys(myCapabilitiesObject[capability_name]).map(
                (mycapability) =>
                  mycapability !== "Title" && (
                    <li>
                      <div className="d-flex align-content-center ff-poppins fs-12px">
                      <DarkTooltip title={get_title(capability_name, mycapability, null)}>
                        <input
                          className="form-check-input mt-2"
                          type="checkbox"
                          checked={capabilities[capability_name][mycapability]}
                          onChange={() =>
                            handleActiveStateChange(
                              capabilities,
                              capability_name,
                              mycapability
                            )
                          }
                        />
                        </DarkTooltip>
                        <p className="m-0 ps-2">
                          {myCapabilitiesObject[capability_name][mycapability]}
                        </p>
                      </div>
                    </li>
                  )
              )}
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ListCapabilities;
