import React from "react";
import "../ProductSettings.scss";
import Select from "react-select";
import { AiFillPlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import { DarkTooltip } from "../../../common/CustomTooltip/CustomTooltip";
import { useState } from "react";
import { useEffect } from "react";
import images from "../../../../utils/images";
import { getTicketApprovalSettings } from "../../../../api/ticketing";
import { Form } from "react-bootstrap";
import { showError } from "../../../../utils/showMessage";
import { useDispatch, useSelector } from "react-redux";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
const ApprovalSettings = ({ selectedRoles, setSelectedRoles }) => {
  const [roles, setRoles] = useState([]);
  const [allroles, setAllRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const role_id = useSelector((state) => state.user?.user?.role.role_id || "");
  const selectStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#f2f2f2" : "white",
      paddingTop: "6px",
      paddingBottom: "6px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  };
  useEffect(() => {
    getApprovalTicketSettings();
  }, []);

  const getApprovalTicketSettings = async (resource_type) => {
    const { data, error } = await getTicketApprovalSettings();
    if (data !== null) {
      setAllRoles(
        data.roles_list.map((role) => {
          role.label = role.display_name;
          role.value = role.role_id;
          return role;
        })
      );
      setSelectedRoles(
        data.approval_settings.map((role) => {
          role.label = role.display_name;
          role.value = role.role_id;
          return role;
        })
      );
      setUsers(
        data.view_ticket_capability_users.map((user) => {
          user.label = user.email;
          user.value = user.id;
          return user;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleRoleSelect = (value, idx) => {
    const newSelectedRoles = [...selectedRoles];
    newSelectedRoles[idx] = { ...newSelectedRoles[idx], ...value };
    setSelectedRoles(newSelectedRoles);
  };
  useEffect(() => {
    setRoles(
      allroles
        .filter(
          (role) =>
            !selectedRoles.some((dict) => {
              return dict.role_id == role.role_id;
            })
        )
        .filter((role) => role.role_id !== role_id)
    );
  }, [selectedRoles]);
  return (
    <>
      <div>
        <div className="mt-4 main_content_container">
          <div className="my-3 d-flex justify-content-start alert_box px-3 mx-0 pt-3 border rounded">
            <p className="fw-bold fst-italic">Note : &nbsp;</p>
            <p>
              Assign users in the right input box to role in the left input box
              for the multiple approval of tickets raised.
            </p>
          </div>
          <DarkTooltip title="Add Role">
            <button
              className="my-1 border-0 bg-white attribute-mapping-btn mapping-add-btn"
              type="button"
              onClick={() => {
                setSelectedRoles((rows) => [
                  ...rows,
                  {
                    role_id: "",
                    display_name: "",
                    ticket_approvers: [],
                    id: "",
                    label: "",
                  },
                ]);
              }}
            >
              <img
                src={images.SSOAddNewAttribute}
                className="attribute-mapping-btn mapping-add-btn"
                type="button"
                alt="Add new attribute button"
              />
            </button>
          </DarkTooltip>

          <div className="mt-2">
            <Row className="mt-3">
              <Col md={5}>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="input_label m-2">Select a Role</h3>
                </div>
              </Col>
              <Col md={5}>
                <h3 className="input_label m-2">Add Users</h3>
              </Col>
              <Col md={1}>
                <h3 className="input_label m-2">Minimum</h3>
              </Col>
            </Row>
            {selectedRoles &&
              selectedRoles.map((attribute, idx) => (
                <Row key={idx}>
                  <Col md={5}>
                    <DarkTooltip
                      title={
                        role_id === attribute.role_id &&
                        "You cannot change your own role settings"
                      }
                    >
                      <div>
                        <Select
                          isDisabled={role_id == attribute.role_id}
                          required={true}
                          className="m-2"
                          options={
                            allroles[
                              allroles.findIndex(
                                (item) => item.value === attribute.role_id
                              )
                            ]
                              ? [
                                  ...roles,
                                  allroles[
                                    allroles.findIndex(
                                      (item) => item.value === attribute.role_id
                                    )
                                  ],
                                ]
                              : roles
                          }
                          value={
                            allroles[
                              allroles.findIndex(
                                (item) => item.value === attribute.role_id
                              )
                            ]
                              ? allroles[
                                  allroles.findIndex(
                                    (item) => item.value === attribute.role_id
                                  )
                                ]
                              : ""
                          }
                          onChange={(value) => handleRoleSelect(value, idx)}
                          styles={selectStyles}
                        />
                      </div>
                    </DarkTooltip>
                  </Col>
                  <Col md={5}>
                    <DarkTooltip
                      title={
                        role_id === attribute.role_id &&
                        "You cannot change your own role settings"
                      }
                    >
                      <div>
                        <Select
                          isDisabled={role_id == attribute.role_id}
                          isMulti
                          styles={selectStyles}
                          onChange={(newValue) => {
                            selectedRoles.find(
                              (dict) => dict.role_id === attribute.role_id
                            ).ticket_approvers = newValue;
                            selectedRoles.find(
                              (dict) => dict.role_id === attribute.role_id
                            ).minimum_approvers = newValue.length;
                            setSelectedRoles([...selectedRoles]);
                          }}
                          required={true}
                          className="m-2"
                          options={attribute.role_id != "" ? users : []}
                          value={attribute.ticket_approvers.map(
                            (option) =>
                              users[
                                users.findIndex(
                                  (user) => user.value === option.id
                                )
                              ]
                          )}
                          noOptionsMessage={() =>
                            allroles[
                              allroles.findIndex(
                                (item) => item.value === attribute.role_id
                              )
                            ]
                              ? "No Options"
                              : "Select the role first in the left"
                          }
                        />
                      </div>
                    </DarkTooltip>
                  </Col>
                  <Col md={1}>
                    <DarkTooltip
                      title={
                        role_id === attribute.role_id &&
                        "You cannot change your own role settings"
                      }
                    >
                      {selectedRoles && (
                        <Form.Control
                          disabled={role_id == attribute.role_id}
                          type="number"
                          min={1}
                          max={
                            selectedRoles.find(
                              (dict) => dict.role_id === attribute.role_id
                            ).ticket_approvers.length
                          }
                          className="m-2 minimum_approval_count"
                          onChange={(event) => {
                            selectedRoles.find(
                              (dict) => dict.role_id === attribute.role_id
                            ).minimum_approvers = event.target.value;
                            setSelectedRoles([...selectedRoles]);
                          }}
                          value={
                            selectedRoles.find(
                              (dict) => dict.role_id === attribute.role_id
                            ).minimum_approvers
                          }
                        ></Form.Control>
                      )}
                    </DarkTooltip>
                  </Col>
                  <Col>
                    <DarkTooltip
                      title={
                        role_id === attribute.role_id &&
                        "You cannot change your own role settings"
                      }
                    >
                      <button
                        disabled={role_id == attribute.role_id}
                        type="button"
                        onClick={(e) => {
                          const tempRows = [...selectedRoles];
                          tempRows.splice(idx, 1);
                          setSelectedRoles(tempRows);
                        }}
                        className="mapping-remove-btn mt-2"
                      >
                        <img src={images.TrashIcon} alt="Remove" />
                      </button>
                    </DarkTooltip>
                  </Col>
                </Row>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default ApprovalSettings;
