import axios from "./axios_interceptor";
import { store } from "../state/store";
import { get_jwt_token, loginRedirect } from "./helper_funtions";

export const GetAdvanceData = async (
  page,
  perPage,
  status,
  from_date,
  to_date,
  path
) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const search = "";
    const filterBy = "advance";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    path = "active-usage";
    let url = `/audits/${path}/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}&status=${status}&from_date=${from_date}&to_date=${to_date}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const GetAdvanceDataUser = async (
  email,
  from_date,
  to_date,
  page,
  perPage,
  path
) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const search = "";
    const filterBy = "advance";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const statuss = "";
    let url = `/audits/${path}/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}&email=${email}&status=${statuss}&from_date=${from_date}&to_date=${to_date}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAppsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/apps-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAssetsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/assets-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const liveuserslist = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/users/live/`;
    const response = await axios.post(url, data, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getMostActiveUserDetails = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/active-user-detail/?username=${data.username}&page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getAuditsCsv = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/audits/export-audit-csv/?report_type=${data.report_type}`;
    const response = await axios.get(url, config);
    return { data: response, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getReportsData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const url = `/audits/generate-reports/?page=${data.page}&page_size=${data.page_size}&search=${data.search}&report_type=${data.report_type}&response_type=${data.response_type}&filter_by=${data.filter_by}&${data.advance_filters}`;
    const response = await axios.get(url, config);
    if (data.response_type === "table") {
      return { data: response.data, error: null };
    } else return { data: response, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getFieldsData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/distinct-elements-search/?field_name=${
      data.field_name
    }&page_size=${data.page_size}&page=${
      data.page ? data.page : 1
    }&search_query=${data.search_query}&report_type=${data.report_type}${
      data.start_time ? "&start_time=" + data.start_time : ""
    }${data.end_time ? "&end_time=" + data.end_time : ""}${
      data.added_query ? "&" + data.added_query : ""
    }`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getWebAppsAccessReportData = async (data) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    let url = `/audits/web-apps-access/?page=${data.page}&limit=${data.limit}&search=${data.search}&filter_by=${data.filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};
