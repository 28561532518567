import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  deletePasswordPolicy,
  getPasswordPoliciesDatatable,
} from "../../../../api/vault";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import image from "../../../../utils/images";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import { HoverTooltip } from "../../../common/Tooltip/Tooltip";
import "./passwordpolicy.scss";

const actionOptions = [{ label: "Delete", value: "delete" }];

const PasswordPolicy = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 300 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const dispatch = useDispatch();
  const [policyId, setPolicyId] = useState(null);

  useEffect(() => {
    dispatch(changeTitle("Policy"));
    setSlideChecked(true);
  }, []);

  const customizeColumnDict = {
    description: false,
    "minimum length": false,
    "maximum length": false,
    "require lowercase letter": false,
    "require uppercase letter": false,
    "require number": false,
    "require special symbols": false,
    "special symbols": false,
    "can contain email": false,
    "can contain username": false,
    "can contain first name": false,
    "can contain last name": false,
    "expire in days": false,
    "last n password": false,
    "user password expiry option": false,
  };
  const pages_url = "dashboard/password_policy/";
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);

  const associatedGroupsOptions = [
    { value: "pam_user", label: "PAM user", selected: false },
  ];

  let selectedAssociationValueArray = [];
  const columns = React.useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => {
          return (
            <HoverTooltip title={row.name} placement="bottom">
              <div>{row.name}</div>
            </HoverTooltip>
          );
        },
        style: {},
        sortable: true,
      },
      {
        name: "Description",
        selector: (row) => {
          return (
            <HoverTooltip title={row.description} placement="bottom">
              <div>{row.description}</div>
            </HoverTooltip>
          );
        },
        sortable: true,
        omit: !checkedStateDict["description"],
      },
      {
        name: "Minimum length",
        selector: (row) => row.min_password_length,
        style: {},
        sortable: true,
        omit: !checkedStateDict["minimum length"],
      },
      {
        name: "Maximum length",
        selector: (row) => row.max_password_length,
        sortable: true,
        omit: !checkedStateDict["maximum length"],
      },
      {
        name: "Require lowercase letter",
        selector: (row) => row.require_lowercase_letter.toString(),
        style: {},
        sortable: true,
        omit: !checkedStateDict["require lowercase letter"],
      },
      {
        name: "Require uppercase letter",
        selector: (row) => row.require_uppercase_letter.toString(),
        style: {},
        sortable: true,
        omit: !checkedStateDict["require uppercase letter"],
      },
      {
        name: "Require number",
        selector: (row) => row.require_number.toString(),
        style: {},
        sortable: true,
        omit: !checkedStateDict["require number"],
      },
      {
        name: "Require special symbols",
        selector: (row) => row.require_special_symbols.toString(),
        sortable: true,
        omit: !checkedStateDict["require special symbols"],
      },
      {
        name: "Special symbols",
        selector: (row) => {
          return (
            <HoverTooltip
              title={row.password_special_symbols}
              placement="bottom"
            >
              <div>{row.password_special_symbols}</div>
            </HoverTooltip>
          );
        },
        sortable: true,
        omit: !checkedStateDict["special symbols"],
      },
      {
        name: "Can contain email",
        selector: (row) => row.can_contain_email_or_part.toString(),
        style: {},
        omit: !checkedStateDict["can contain email"],
        sortable: true,
      },
      {
        name: "Can contain username",
        selector: (row) => row.can_contain_username_or_part.toString(),
        style: {},
        omit: !checkedStateDict["can contain username"],
        sortable: true,
      },
      {
        name: "Can contain first name",
        selector: (row) => row.can_contain_firstname_or_part.toString(),
        style: {},
        omit: !checkedStateDict["can contain first name"],
        sortable: true,
      },
      {
        name: "Can contain last name",
        selector: (row) => row.can_contain_lastname_or_part.toString(),
        style: {},
        omit: !checkedStateDict["can contain last name"],
        sortable: true,
      },
      {
        name: "Expiry days",
        selector: (row) => row.expire_in_days,
        style: {},
        omit: !checkedStateDict["expire in days"],
        sortable: true,
      },
      {
        name: "Last n password",
        selector: (row) => row.last_n_password,
        style: {},
        omit: !checkedStateDict["last n password"],
        sortable: true,
      },
      {
        name: "User password expiry option",
        selector: (row) => {
          return (
            <HoverTooltip
              title={row.user_password_expire_flow}
              placement="bottom"
            >
              <div>{row.user_password_expire_flow}</div>
            </HoverTooltip>
          );
        },
        style: {},
        omit: !checkedStateDict["user password expiry option"],
        sortable: true,
      },
      {
        name: "Association Groups",
        selector: (row) => {
          if (row.association_group.length > 0) {
            selectedAssociationValueArray = row.association_group.map((obj) => {
              const entry = associatedGroupsOptions.find(
                (grp) => grp.value === obj.association_group
              );
              return entry.label;
            });
            return (
              <HoverTooltip
                title={selectedAssociationValueArray.map((item, index) => (
                  <span className="ff-poppins" key={index}>
                    {item},
                  </span>
                ))}
                placement="bottom"
              >
                <div>
                  {selectedAssociationValueArray.map((item, index) => (
                    <span className="ff-poppins" key={index}>
                      {item}
                    </span>
                  ))}
                </div>
              </HoverTooltip>
            );
          } else {
            return (
              <span className="ff-poppins">No association with any group.</span>
            );
          }
        },
        style: {
          whiteSpace: "nowrap",
        },
      },
      isCapable && (isCapable.policies.manage_password_policy.delete || isCapable.policies.manage_password_policy.edit) && {
        name: "Action",
        cell: (row) => (
          <>
            <Nav className="password-policy-nav-dropdown">
              <NavDropdown
                title={<img src={image.ThreeDotIcon} alt="action" />}
                menuVariant="primary"
              >
                {isCapable && isCapable.policies.manage_password_policy.edit &&
                <NavDropdown.Item
                  onClick={() => {
                    handlePasswordPolicyEdit(row.id);
                  }}
                >
                  <img
                    src={image.editPasswordPolicyAction}
                    alt="edit"
                    className="mx-2"
                    width="13"
                    height="13"
                  />{" "}
                  Edit
                </NavDropdown.Item>
                }
                {isCapable && isCapable.policies.manage_password_policy.delete &&
                (row.name === "default" || row.name === "Default" ? null : 
                (
                  <NavDropdown.Item
                    onClick={async () => {
                      handleDelete([row]);
                    }}
                  >
                    <img
                      src={image.deletePasswordPolicyAction}
                      alt="delete"
                      className="mx-2"
                      width="13"
                      height="13"
                    />{" "}
                    Delete
                  </NavDropdown.Item>
                ))
                }
              </NavDropdown>
            </Nav>
          </>
        ),
        center: true,
        minWidth: "50px",
      },
    ],
    [checkedStateDict]
  );
  /* customize column logic ends. */
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_AssetReport") !== null
      ? localStorage.getItem("rowsPerPage_AssetReport")
      : 5
  );
  const [passwordPolicies, setPasswordPolicies] = useState([]);
  const [totalPasswordPolicies, setTotalPasswordPolicies] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState("");
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [colName, setColName] = useState(null);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [page, setPage] = useState(1);

  /* customize column modal functionality starts. */
  const UserColumns = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Minimum length", value: "min_password_length" },
    { label: "Maximum length", value: "max_password_length" },
    { label: "Special symbols", value: "password_special_symbols" },
    { label: "Expire in days", value: "expire_in_days" },
  ];

  const fetchData = async (page, perPage, search, filterBy) => {
    setLoading(true);
    page = page - 1;
    const { data, error } = await getPasswordPoliciesDatatable(
      page,
      perPage,
      search,
      filterBy
    );
    if (data !== null) {
      setPasswordPolicies(data.data || []);
      setTotalPasswordPolicies(data.count);
      setFilteredData(data.data);
      setPending(false);
      setLoading(false);
    } else {
      setPasswordPolicies([]);
      setTotalPasswordPolicies(0);
      setFilteredData([]);
      setPending(false);
      setLoading(false);
      showError(error.data.errors);
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    fetchData(1, perPage, search, filterBy);
  }, [perPage]);

  const handlePageChange = (page) => {
    fetchData(page, perPage, search, filterBy);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    page = page - 1;
    const { data, error } = await getPasswordPoliciesDatatable(
      page,
      newPerPage,
      search,
      filterBy
    );
    if (data !== null) {
      setPasswordPolicies(data.data || []);
      localStorage.setItem("rowsPerPage_AssetsReport", newPerPage);
      setPerPage(newPerPage);
      setTotalPasswordPolicies(data.count);
      setFilteredData(data.data);
      setLoading(false);
    } else {
      setPasswordPolicies([]);
      setTotalPasswordPolicies(0);
      setFilteredData([]);
      setPending(false);
      setLoading(false);
      showError(error.data.errors);
    }
  };

  //for searching in frontend.
  useEffect(() => {
    const result = passwordPolicies.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "") {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return (
            (object.name &&
              object.name.toLowerCase().includes(searchs.toLowerCase())) ||
            (object.description &&
              object.description
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.password_special_symbols &&
              object.password_special_symbols
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.user_password_expire_flow &&
              object.user_password_expire_flow
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.min_password_length &&
              object.min_password_length
                .toString()
                .includes(searchs.toLowerCase())) ||
            (object.max_password_length &&
              object.max_password_length
                .toString()
                .includes(searchs.toLowerCase())) ||
            (object.last_n_password &&
              object.last_n_password
                .toString()
                .includes(searchs.toLowerCase())) ||
            (object.expire_in_days &&
              object.expire_in_days
                .toString()
                .includes(searchs.toLowerCase())) ||
            (object.require_lowercase_letter &&
              object.require_lowercase_letter
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.require_uppercase_letter &&
              object.require_uppercase_letter
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.require_number &&
              object.require_number
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.can_contain_email_or_part &&
              object.can_contain_email_or_part
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.can_contain_username_or_part &&
              object.can_contain_username_or_part
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.can_contain_firstname_or_part &&
              object.can_contain_firstname_or_part
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase())) ||
            (object.can_contain_lastname_or_part &&
              object.can_contain_lastname_or_part
                .toString()
                .toLowerCase()
                .includes(searchs.toLowerCase()))
          );
        }
      } else {
        if (search == "") {
          fetchData(1, perPage, search, filterBy);
        } else {
          return object[filterBy].toString().match(searchs.toLowerCase());
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  const handleSearch = async (event) => {
    event.preventDefault();
    fetchData(1, perPage, search, filterBy);
  };
  const rowDisabledCriteria = (row) => row.name === "default";

  const handlePasswordPolicyEdit = (id) => {
    setPolicyId(id);
    //call EditAddPolicy component using url react-router
    navigate(`/dashboard/policies/password/${id}`);
  };

  const CreatePasswordPolicyButtonComponent = () => {
    return (
      <GeneralButton
        onClickEvent={() => {
          addNewPasswordPolicy();
        }}
        startIcon={
          <img
            src={image.passwordPolicyCreateSign}
            alt="password policy create sign"
            className="pe-2"
          />
        }
        className="me-1"
        value="Add Policy"
        variant="outlined"
        size="large"
      ></GeneralButton>
    );
  };
  const addNewPasswordPolicy = () => {
    // call EditAddPolicy using react-router.
    navigate(`/dashboard/policies/password/Add`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let rowsToDelete = [];
    if (row) {
      rowsToDelete = row;
    } else {
      rowsToDelete = selectedRows;
    }
    setToBeDeleted(rowsToDelete);
    let temp = [];
    temp = rowsToDelete.map((item) => item.name);
    setColName(temp);
    setOpenDeleteUser((o) => !o);
  };

  const handleDeleteAction = async () => {
    let ids = toBeDeleted.map((item) => item.id);
    let count = ids.length;
    const { data, error } = await deletePasswordPolicy({ ids: ids });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalPasswordPolicies % perPage === count || perPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setOpenDeleteUser((o) => !o);
      fetchData(1, perPage, search, filterBy);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Policy":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={image.PasswordPolicyIcon}
                          alt="users round icon"
                          className="me-0"
                        />{" "}
                        &nbsp; Policy
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  {/* delete password policies: */}
                  {colName && (
                    <CustomModal open={openDeleteUser}>
                      <ShowAlert
                        setOpenAlert={setOpenDeleteUser}
                        handleAlertAction={handleDeleteAction}
                        colName={colName}
                        alertMsg="Below password policies will be deleted : "
                        headingMsg="Delete Password policies"
                      />
                    </CustomModal>
                  )}
                  {/* password policies datatable */}
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Password Policy"
                        dataTableHeadingIcon={image.PasswordPolicyIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container password_data_table"
                    progressPending={pending}
                    fixedHeader
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPasswordPolicies}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    subHeader
                    onSelectedRowsChange={handleRowSelected}
                    actions={
                      isCapable && isCapable.policies.manage_password_policy.add &&
                      (<CreatePasswordPolicyButtonComponent />)
                    }
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="password policy"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    selectableRowDisabled={rowDisabledCriteria}
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img
                        src={image.FirstPageDataTableIcon}
                        alt="first page"
                      />
                    }
                    paginationIconLastPage={
                      <img src={image.LastPageDataTableIcon} alt="last page" />
                    }
                    paginationIconNext={
                      <img src={image.NextPageIcon} alt="next" />
                    }
                    paginationIconPrevious={
                      <img src={image.PreviousPageIcon} alt="previous" />
                    }
                  />
                  {/* password policy data table ends */}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default PasswordPolicy;
