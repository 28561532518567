import axios from './axios_interceptor';
import {get_jwt_token, loginRedirect} from './helper_funtions';

export const postLdapConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/ldapconfig/`;
        const response = await axios.post(url, data, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}


export const testLdapConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/ldapconfig/?url=${data.url}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}

export const getLdapConf = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/ldapconfig/`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}


export const triggerImportResources = async (data) => {
    try {
        const token = get_jwt_token();
        loginRedirect(token);
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        const url = `/ldap/ldapconfig/?import_devices=${data.import_devices}`;
        const response = await axios.get(url, config);
        return {data: response.data, error: null};

    } catch (e) {
        return {data: null, error: e.response.data.errors};
    }
}