import { getReportsData } from "../../../api/report";
import { showError } from "../../../utils/showMessage";
import Stack from "@mui/material/Stack";
import exportIcon from "../../../images/export.svg";
import { changeSelectedValuesList } from "../../../state/slices/advance_filter";
import GeneralButton from "../../common/SaveButton/GeneralButton";

export const ActionComponent = ({
  getData,
  search,
  pageType,
  filter_by,
  download_report,
  disable_buttons,
}) => {
  return (
    <Stack direction="row" spacing={2} className="py-3">
      {disable_buttons && (
        <GeneralButton
          onClickEvent={() =>
            getData(null, null, search, pageType, "csv", filter_by)
          }
          color="#000000"
          variant="text"
          size="large"
          value="Export csv"
          startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
        />
      )}

      {download_report && (
        <GeneralButton
          onClickEvent={() =>
            getData(null, null, search, pageType, "pdf", filter_by)
          }
          color="#000000"
          variant="text"
          size="large"
          value="Export report"
          startIcon={
            <img src={exportIcon} alt="icon" id="center-blueShadow-1" />
          }
        />
      )}
    </Stack>
  );
};

export const fetchData = async (
  page,
  perPage,
  search,
  report_type,
  response_type,
  filterBy,
  setReportData,
  setTotalReportData,
  setFilteredData,
  setLoading,
  setPending,
  setAllFields,
  allFields,
  keysToExclude,
  advanceFilters,
  dispatch,
  selectedValuesList
) => {
  setLoading(true);
  const { data, error } = await getReportsData({
    page: page,
    page_size: perPage,
    search: search,
    report_type: report_type,
    response_type: response_type,
    filter_by: filterBy,
    advance_filters: advanceFilters,
  });
  if (data !== null && response_type === "table") {
    setReportData(data.results || []);
    let tempAllFields =
      data.count > 0
        ? Object.keys(data.results[0]).filter(
            (key) => !keysToExclude.includes(key)
          )
        : [];
    tempAllFields = [...tempAllFields, ...allFields];
    setAllFields([...new Set(tempAllFields)]);
    if (
      selectedValuesList[report_type] &&
      selectedValuesList[report_type].length === 0
    ) {
      let tempSelectedValuesList = { ...selectedValuesList };
      tempSelectedValuesList = {
        ...tempSelectedValuesList,
        [report_type]: [...new Set(tempAllFields)],
      };
      dispatch(changeSelectedValuesList(tempSelectedValuesList));
    }

    setTotalReportData(data.count);
    setFilteredData(data.results);
    setLoading(false);
    setPending(false);
  } else if (data !== null && response_type === "csv") {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", report_type + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (data !== null && response_type === "pdf") {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", report_type + ".pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    showError(error);
  }
};
