import {React, useState, useEffect }  from "react";
import Select from 'react-select';
import { showError, showSuccess } from "../../../../utils/showMessage";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { triggerScanner } from "../../../../api/networkanalyzer";
import { checkIpPattern } from "../../../../utils/checkIpPattern";
import GetCapabilities from "../../../../utils/getCapabilities";
import { toast } from "react-toastify";
import InputGroup from "react-bootstrap/InputGroup";
import Image from "react-bootstrap/Image";
import Show from "../../../../images/show.png";
import Hide from "../../../../images/hide.png";


const LdapConfiguration = ({ldapName, setLdapName, ldapServerProtocol, setLdapServerProtocol, ldapServerDomain, setLdapServerDomain, username, setUsername, 
    password, setPassword, searchBase, setSearchBase, searchFilterValue, setSearchFilterValue, domainName,
    firstNameAttr,
    lastNameAttr,
    emailAttr,
    usernameAttr,
    phoneNumberAttr,
    setDomainName,
    setFirstNameAttr,
    setLastNameAttr,
    setEmailAttr,
    setUsernameAttr,
    setPhoneNumberAttr,
  }) => {

    const my_capabilities = GetCapabilities();
    const isCapable = my_capabilities[0]
    const [key, setKey] = useState();
    const [filterSelect, setFilterSelect] = useState({});
    const [protocolSelect, setProtocolSelect] = useState({});
    const [showHidden, setShowHidden] = useState(false);

    const ldapProtocolOptions = [
      {"label": "ldap://", "value": "ldap://"},
      {"label": "ldaps://", "value": "ldaps://"}
    ]
    const searchFilterType = [
      {"label": "User Filter", "value": "(&(objectCategory=person)(objectClass=user))"},
      {"label": "Basic User Filter", "value": "(&(|(samaccountname=?)(userprincipalname=?)(mail=?))(objectClass=user))"},
      {"label": "Enabled User Filter", "value": "(&(|(samaccountname=?)(userprincipalname=?)(mail=?))(objectClass=user)(!(UserAccountControl:1.2.840.113556.1.4.803:=2)))"},
      {"label": "User in Single Group Custom Filter", "value": "(&(memberof=<group-dn>)(|(samaccountname=?)(userprincipalname=?)(mail=?))(objectClass=user)(!(UserAccountControl:1.2.840.113556.1.4.803:=2)))"},
      {"label": "User in Multiple Groups Custom Filter", "value": "(&(|(memberof=<group1-dn>)(memberof=<group2-dn>))(|(samaccountname=?)(userprincipalname=?)(mail=?))(objectClass=user)(!(UserAccountControl:1.2.840.113556.1.4.803:=2)))"},
      {"label": "Write Your Custom Filter", "value": ""},
    ]

    useEffect(() => {
      if (ldapServerProtocol === "") {setLdapServerProtocol(ldapProtocolOptions[0].value); setKey(0);}
      else {
        let temp_array = ldapProtocolOptions.filter((value, index) => {if(value.value === ldapServerProtocol) {setKey(index); return true} else return false})}
    }, []);

    useEffect(() => {}, [key]);

    const testBindCredentials = async (credentials) => {
      if (credentials.username === "" || credentials.password === "") showError({"message": ["Username/Password cannot be empty"]});
      else testConfiguration(credentials); 
    }

    const testConfiguration = async (credentials) => {
      const pendingToast = toast.loading('Testing LDAP Connection', { autoClose: false });

      const { data, error } = await triggerScanner(credentials)
      if (data !== null) {
        toast.update(pendingToast, {
          render: `${data.message}`,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          isLoading: false,
        });

      }
      if (error !== null) {
        toast.update(pendingToast, {
          render: `${error.message[0]}`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          isLoading: false,
        });
      }
    }

    const getProtocolValue = () => {
      let filterValue = ldapProtocolOptions.filter(protocolType => protocolType.value === ldapServerProtocol);
      setProtocolSelect(filterValue[0])
    }
    useEffect(() => {getProtocolValue()}, [ldapServerProtocol]);

    const getSearchFilterValue = () => {
      let filterValue = searchFilterType.filter(filterType => filterType.value === searchFilterValue);
      if (filterValue.length === 0) {
        setFilterSelect(searchFilterType[5])
      } else { setFilterSelect(filterValue[0]) }
    }
    useEffect(() => {getSearchFilterValue()}, [searchFilterValue]);

    return (
      <Container fluid className="mx-auto add_user_container main_content_container">
          <Stack>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formRoleName"
            >
            <Form.Label column md={3} className="input_lable">
              Name<span className="text-danger">*</span>
            </Form.Label>
              <Col md={6}>
                <Form.Control
                  required
                  className="form_input_field"
                  type="text"
                  value={ldapName}
                  onChange={(event) => setLdapName(event.target.value)}
                  placeholder="Name of Ldap configuration"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formDisplayName"
            >
              <Form.Label column md={3} className="input_lable">
                LDAP Server URL<span className="text-danger">*</span>
              </Form.Label>

              <Col md={3}>
                {typeof key === 'number' && <Select
                  className="basic-single asset_type_select"
                  classNamePrefix="select"
                  value={protocolSelect}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name="ldap protocol"
                  options={ldapProtocolOptions}
                  onChange={(e) => setLdapServerProtocol(e.value)}
                />}
              </Col>

              <Col md={3}>
                <Form.Control
                  required
                  type="text"
                  className="form_input_field h-100"
                  value={ldapServerDomain}
                  onChange={(event) => {setLdapServerDomain(event.target.value)}}
                  placeholder="Ldap Server Domain"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}>
              {isCapable && isCapable.LDAP.test_connection && <Button
                  variant="light"
                  as="input"
                  type="button"
                  className="user_cancel_button border-secondary input_lable"
                  value="Test Connection"
                  onClick={() => testConfiguration({"testdomain": ldapServerProtocol + ldapServerDomain})}
                />}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Bind Account Username<span className="text-danger">*</span>
              </Form.Label>

              <Col md={6}>
              <Form.Control
                  required
                  type="text"
                  className="form_input_field"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  placeholder="Ldap Account Username"
                  autoComplete="off"
                />

              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
              Bind Account Password<span className="text-danger">*</span>
              </Form.Label>

              <Col md={6}>
                <InputGroup>
                  <Form.Control
                    className="user_input_field border-end-0" 
                    required
                    type={showHidden === false ? "password" : "text"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Ldap Account Password"
                    autoComplete="off"
                  />
                  <InputGroup.Text
                    className="bg-white my-input-group cursor_pointer"
                    onClick={() => setShowHidden(!showHidden)}
                  >
                    {showHidden === false ? (
                      <Image
                        src={Show}
                        height="20px"
                        className="px-2"
                      ></Image>
                    ) : (
                      <Image
                        src={Hide}
                        height="20px"
                        className="px-2"
                      ></Image>
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={3}>
              {isCapable && isCapable.LDAP.test_connection && <Button
                  variant="light"
                  as="input"
                  type="button"
                  className="user_cancel_button border-secondary input_lable"
                  value="Test Connection"
                  onClick={() => testBindCredentials(
                    {
                      "username": username,
                      "password": password,
                      "testdomain": ldapServerProtocol + ldapServerDomain,
                    }
                  )}
                />}
              </Col>
            </Form.Group>


            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Search Base<span className="text-danger">*</span>
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  required
                  type="text"
                  className="form_input_field"
                  value={searchBase}
                  onChange={(event) => setSearchBase(event.target.value)}
                  placeholder="Search Base of Directory"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Search Filter Type
              </Form.Label>

              <Col md={6}>
                <Select
                  className="basic-single asset_type_select"
                  classNamePrefix="select"
                  value={filterSelect}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  name="filter type"
                  options={searchFilterType}
                  onChange={(e) => setSearchFilterValue(e.value)}
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Search Filter Value
              </Form.Label>

              <Col md={6}>
              <Form.Control
                  required
                  type="text"
                  className="form_input_field"
                  value={searchFilterValue}
                  onChange={(event) => setSearchFilterValue(event.target.value)}
                  placeholder="Search Base of Directory"
                  autoComplete="off"
                />

              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-4 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Domain Name
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={domainName}
                  onChange={(event) => setDomainName(event.target.value)}
                  placeholder="Eg. organisation.com"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <div className="ff-poppins fw-500 mb-4" style={{"fontSize": "18px", "textDecoration": "underline"}}>Attribute Mapping</div>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                First Name
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={firstNameAttr}
                  onChange={(event) => setFirstNameAttr(event.target.value)}
                  placeholder="First Name Attribute Eg. givenName"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Last Name
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={lastNameAttr}
                  onChange={(event) => setLastNameAttr(event.target.value)}
                  placeholder="Last Name Attribute Eg. sn"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Email
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={emailAttr}
                  onChange={(event) => setEmailAttr(event.target.value)}
                  placeholder="Email Attribute Eg. mail"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Username
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={usernameAttr}
                  onChange={(event) => setUsernameAttr(event.target.value)}
                  placeholder="Username Attribute Eg. sAMAccountName"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 justify-content-between">
              <Form.Label column md={3} className="input_lable">
                Phone Number
              </Form.Label>

              <Col md={6}>
                <Form.Control
                  type="text"
                  className="form_input_field"
                  value={phoneNumberAttr}      
                  onChange={(event) => setPhoneNumberAttr(event.target.value)}
                  placeholder="Phone Number Attribute Eg. telephoneNumber"
                  autoComplete="off"
                />
              </Col>
              <Col md={3}></Col>
            </Form.Group>
          </Stack>
      </Container>
    )
}


export default LdapConfiguration;