import { Col, Form, Row, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Select from "react-select";
import { getFieldsData } from "../../../api/report";
import { showError, showErrorMsg } from "../../../utils/showMessage";
import exportIcon from "../../../images/export.svg";
import "./AdvanceFilter.scss";
import DateTimeRangePicker from "./DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import GeneralButton from "../../common/SaveButton/GeneralButton";
import images from "../../../utils/images";
import {
  changeAdvanceQuery,
  changeFrom,
  changeItemElements,
  changeTo,
  changeSelectedValuesList,
  changeQuickDateButton,
} from "../../../state/slices/advance_filter";

const AdvanceSearchFilter = ({
  page,
  perPage,
  all_fields,
  report_type,
  getData,
  filterBy,
  addedQuery,
  download_report,
  disable_buttons,
}) => {
  const dispatch = useDispatch();
  const [changeState, setChangeState] = useState({});
  const loadingState = {};
  const advancedFilter = useSelector((state) => state.advanceFilter);
  const advancedQuery = advancedFilter.advanceQuery;
  const from = advancedFilter.from;
  const to = advancedFilter.to;
  const itemElements = advancedFilter.itemElements;
  const selectedValuesList = advancedFilter.selectedValuesList;
  const activeButton = advancedFilter.quickDateButton;

  const [loading, setLoading] = useState(
    all_fields.map((keys) => {
      return (loadingState[keys] = false);
    })
  );

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "3px",
      paddingBottom: "3px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"Poppins"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 5 }),
  };

  const renameObjKey = (oldObj, oldKey, newKey) => {
    const keys = Object.keys(oldObj);
    const newObj = keys.reduce((acc, val) => {
      if (val === oldKey) {
        acc[newKey] = oldObj[oldKey];
      } else {
        acc[val] = oldObj[val];
      }
      return acc;
    }, {});

    return newObj;
  };

  const selectionAction = async (value, old_value) => {
    if (old_value !== value) {
      let temp = { ...itemElements };
      let swappedItems = renameObjKey(temp[report_type], old_value, value);
      temp = { ...temp, [report_type]: swappedItems };

      let new_results = await setFieldsList(
        value,
        "",
        from[report_type],
        to[report_type],
        1
      );
      if (new_results !== null) {
        let results = serializerArrayToObject(new_results.results);
        let tempObj2 = {
          allOptions: results,
          selectedOptionsList: [],
          search_query: "",
          pageNumber: 1,
          nextPage: !new_results.next ? false : true,
        };
        temp = {
          ...temp,
          [report_type]: { ...temp[report_type], [value]: tempObj2 },
        };
        dispatch(changeItemElements(temp));

        let tempSelectedValues = [...selectedValuesList[report_type]];
        tempSelectedValues[tempSelectedValues.indexOf(value)] = old_value;
        let temp_selected = { ...selectedValuesList };
        temp_selected = { ...temp_selected, [report_type]: tempSelectedValues };
        dispatch(changeSelectedValuesList(temp_selected));
        setChangeState(Object.create(null));
      }
    }
  };

  const sectionValues = (options, key) => {
    let tempItemElements = { ...itemElements };
    tempItemElements = {
      ...tempItemElements,
      [report_type]: {
        ...tempItemElements[report_type],
        [key]: {
          ...tempItemElements[report_type][key],
          selectedOptionsList: options,
        },
      },
    };
    dispatch(changeItemElements(tempItemElements));
    setChangeState(Object.create(null));
  };

  const changeToISO = (date) => {
    if (date !== undefined && date !== null) {
      return date.toISOString();
    } else {
      return undefined;
    }
  };

  const setFieldsList = async (
    value,
    search_query,
    from_date,
    to_date,
    page_no
  ) => {
    from_date = changeToISO(from_date);
    to_date = changeToISO(to_date);

    const { data, error } = await getFieldsData({
      field_name: value,
      report_type: report_type,
      search_query: search_query,
      page: page_no,
      page_size: 10,
      added_query: addedQuery,
      start_time: from_date,
      end_time: to_date,
    });
    if (data !== null) {
      return data;
    }
    if (error !== null) {
      showError(error);
      return null;
    }
  };

  const getUpdatedFieldsList = () => {
    let tempUpdatedList =
      selectedValuesList[report_type] &&
      selectedValuesList[report_type].map((item, index) => ({
        value: item,
        label: formatKey(item),
      }));
    return tempUpdatedList;
  };

  const loadMoreOptions = async (key) => {
    if (itemElements[report_type][key].nextPage) {
      let new_results = await setFieldsList(
        key,
        itemElements[report_type][key].search_query,
        from[report_type],
        to[report_type],
        itemElements[report_type][key].pageNumber + 1
      );
      if (new_results !== null) {
        let added_results = serializerArrayToObject(new_results.results);
        let tempObj = {
          allOptions: [
            ...itemElements[report_type][key].allOptions,
            ...added_results,
          ],
          selectedOptionsList:
            itemElements[report_type][key].selectedOptionsList,
          search_query: itemElements[report_type][key].search_query,
          pageNumber: itemElements[report_type][key].pageNumber + 1,
          nextPage: !new_results.next ? false : true,
        };
        let tempItemElements = { ...itemElements };
        tempItemElements = {
          ...tempItemElements,
          [report_type]: { ...tempItemElements[report_type], [key]: tempObj },
        };
        dispatch(changeItemElements(tempItemElements));
        setChangeState(Object.create(null));
      }
    }
    setItemLoading(key, false);
  };

  const handleSearch = (search_query, key) => {
    if (search_query.length > 0) {
      setFieldsList(key, search_query, from[report_type], to[report_type], 1)
        .then((new_results) => {
          if (new_results.results?.length > 0) {
            let added_results = serializerArrayToObject(new_results.results);
            let tempObj = {
              allOptions: [
                ...itemElements[report_type][key].selectedOptionsList,
                ...added_results,
              ],
              selectedOptionsList:
                itemElements[report_type][key].selectedOptionsList,
              search_query: search_query,
              pageNumber: 1,
              nextPage: !new_results.next ? false : true,
            };
            let tempItemElements = { ...itemElements };
            tempItemElements = {
              ...tempItemElements,
              [report_type]: {
                ...tempItemElements[report_type],
                [key]: tempObj,
              },
            };
            dispatch(changeItemElements(tempItemElements));
            setChangeState(Object.create(null));
          }
        })
        .catch(() => {});
    }
  };

  const serializerArrayToObject = (list) => {
    return list?.map((item, index) => ({
      value: item,
      label: item,
    }));
  };

  const setItemLoading = (key, value) => {
    let tempObj = loading;
    tempObj[key] = value;
    setLoading(tempObj);
    setChangeState(Object.create(null));
  };

  const addItem = async () => {
    if (selectedValuesList[report_type].length > 0) {
      let new_results = await setFieldsList(
        selectedValuesList[report_type][0],
        "",
        from[report_type],
        to[report_type],
        1
      );
      let results = serializerArrayToObject(new_results.results);
      let tempObj2 = {
        allOptions: results,
        selectedOptionsList: [],
        search_query: "",
        pageNumber: 1,
        nextPage: !new_results.next ? false : true,
      };
      let tempItemElements = new Object();
      tempItemElements = { ...itemElements };
      tempItemElements = {
        ...tempItemElements,
        [report_type]: {
          ...tempItemElements[report_type],
          [selectedValuesList[report_type][0]]: tempObj2,
        },
      };

      let slicedFields = selectedValuesList[report_type].slice(1);
      let tempSelectedValuesList = { ...selectedValuesList };
      tempSelectedValuesList = {
        ...tempSelectedValuesList,
        [report_type]: slicedFields,
      };
      dispatch(changeSelectedValuesList(tempSelectedValuesList));
      setChangeState(Object.create(null));
      dispatch(changeItemElements(tempItemElements));
    }
  };

  useEffect(() => {
    if (
      selectedValuesList[report_type] &&
      all_fields.length === selectedValuesList[report_type].length
    ) {
      addItem();
    }
  }, []);

  const removeFilter = (key) => {
    let temp = { ...itemElements };
    if (Object.keys(temp[report_type]).length > 0) {
      let temp_selected = { ...selectedValuesList };
      temp_selected = {
        ...temp_selected,
        [report_type]: [...temp_selected[report_type], key],
      };
      dispatch(changeSelectedValuesList(temp_selected));
      const {
        [report_type]: { [key]: _, ...restOfInnerKeys },
        ...restOfObject
      } = temp;
      const updatedTempObject = {
        [report_type]: { ...restOfInnerKeys },
        ...restOfObject,
      };
      setChangeState(Object.create(null));
      dispatch(changeItemElements(updatedTempObject));
    }
  };

  const handleClear = async () => {
    let tempFrom = { ...from };
    let tempTo = { ...to };
    tempFrom = { ...tempFrom, [report_type]: null };
    tempTo = { ...tempTo, [report_type]: null };
    dispatch(changeFrom(tempFrom));
    dispatch(changeTo(tempTo));

    let tempActiveButton = { ...activeButton };
    tempActiveButton = { ...tempActiveButton, [report_type]: null };
    dispatch(changeQuickDateButton(tempActiveButton));

    let tempSelectedValues = { ...selectedValuesList };
    tempSelectedValues = { ...tempSelectedValues, [report_type]: all_fields };
    dispatch(changeSelectedValuesList(tempSelectedValues));

    let new_results = await setFieldsList(all_fields[0], "", null, null, 1);
    let results = serializerArrayToObject(new_results.results);

    let tempObj2 = {
      [all_fields[0]]: {
        allOptions: results,
        selectedOptionsList: [],
        search_query: "",
        pageNumber: 1,
        nextPage: !new_results.next ? false : true,
      },
    };
    let tempItemElements = { ...itemElements };
    tempItemElements = { ...tempItemElements, [report_type]: tempObj2 };
    let sliced_all_fields = all_fields.slice(1);
    let tempSelectedValuesList = { ...selectedValuesList };
    tempSelectedValuesList = {
      ...tempSelectedValuesList,
      [report_type]: sliced_all_fields,
    };
    dispatch(changeSelectedValuesList(tempSelectedValuesList));
    dispatch(changeItemElements(tempItemElements));

    let tempAdvanceQuery = { ...advancedQuery };
    tempAdvanceQuery = { ...tempAdvanceQuery, [report_type]: "" };

    dispatch(changeAdvanceQuery(tempAdvanceQuery));
    getData(page, perPage, "", report_type, "table", filterBy, "");
    setChangeState(Object.create(null));
  };

  const getDateTimeQuery = () => {
    let from_date = changeToISO(from[report_type]);
    let to_date = changeToISO(to[report_type]);

    let advance_query = "";
    if (["app_access_audit", "asset_access_audit"].includes(report_type)) {
      from_date && (advance_query = "accessed_time__gte=" + from_date);
      from_date && to_date && (advance_query += "&");
      to_date && (advance_query += "accessed_time__lte=" + to_date);
    } else if (
      ["app_executed_commands_audit", "asset_executed_commands_audit"].includes(
        report_type
      )
    ) {
      advance_query =
        from_date && (advance_query = "executed_time__gte=" + from_date);
      from_date && to_date && (advance_query += "&");
      to_date && (advance_query += "executed_time__lte=" + to_date);
    } else {
      advance_query =
        from_date && (advance_query = "datetime__gte=" + from_date);
      from_date && to_date && (advance_query += "&");
      to_date && (advance_query += "datetime__lte=" + to_date);
    }

    return advance_query;
  };

  const handleSubmit = async (event) => {
    if (from[report_type] !== "" && to[report_type] !== "") {
      event && event.preventDefault();
      let advance_query = getDateTimeQuery();

      itemElements[report_type] &&
        Object.keys(itemElements[report_type]).length > 0 &&
        Object.keys(itemElements[report_type]).map((keys) =>
          keys === "command"
            ? (advance_query +=
                "&" +
                keys +
                "__in=" +
                itemElements[report_type][keys]["selectedOptionsList"].map(
                  (value, index) =>
                    index ===
                    itemElements[report_type][keys]["selectedOptionsList"]
                      .length -
                      1
                      ? value.value
                      : value.value + ",|xnewx|"
                ))
            : (advance_query +=
                "&" +
                keys +
                "__in=" +
                itemElements[report_type][keys]["selectedOptionsList"].map(
                  (value) => value.value
                ))
        );
      let tempAdvanceQuery = { ...advancedQuery };
      tempAdvanceQuery[report_type] = advance_query;
      dispatch(changeAdvanceQuery(tempAdvanceQuery));
      getData(page, perPage, "", report_type, "table", filterBy, advance_query);
    } else {
      event && event.preventDefault();
      showErrorMsg("Empty Date Values");
    }
  };

  const updateAllOptions = async (from_date, to_date) => {
    let tempSelectedValues = { ...selectedValuesList };
    tempSelectedValues = { ...tempSelectedValues, [report_type]: all_fields };
    dispatch(changeSelectedValuesList(tempSelectedValues));

    let new_results = await setFieldsList(
      all_fields[0],
      "",
      from_date,
      to_date,
      1
    );
    if (new_results != null) {
      let results = serializerArrayToObject(new_results.results);
      let tempObj2 = {
        [all_fields[0]]: {
          allOptions: results,
          selectedOptionsList: [],
          search_query: "",
          pageNumber: 1,
          nextPage: !new_results.next ? false : true,
        },
      };
      let tempItemElements = { ...itemElements };
      tempItemElements = { ...tempItemElements, [report_type]: tempObj2 };

      let sliced_all_fields = all_fields.slice(1);
      let tempSelectedValuesList = { ...selectedValuesList };
      tempSelectedValuesList = {
        ...tempSelectedValuesList,
        [report_type]: sliced_all_fields,
      };
      dispatch(changeSelectedValuesList(tempSelectedValuesList));
      dispatch(changeItemElements(tempItemElements));

      let tempAdvanceQuery = { ...advancedQuery };
      tempAdvanceQuery = { ...tempAdvanceQuery, [report_type]: "" };
      dispatch(changeAdvanceQuery(tempAdvanceQuery));
      getData(page, perPage, "", report_type, "table", filterBy, "");
      setChangeState(Object.create(null));
    }
  };

  const downloadFile = (response_type) => {
    if (from[report_type] !== "" && to[report_type] !== "") {
      let advance_query = getDateTimeQuery();
      itemElements[report_type] &&
        Object.keys(itemElements[report_type]).length > 0 &&
        Object.keys(itemElements[report_type]).map((keys) =>
          keys === "command"
            ? (advance_query +=
                "&" +
                keys +
                "__in=" +
                itemElements[report_type][keys]["selectedOptionsList"].map(
                  (value, index) =>
                    index ===
                    itemElements[report_type][keys]["selectedOptionsList"]
                      .length -
                      1
                      ? value.value
                      : value.value + ",|xnewx|"
                ))
            : (advance_query +=
                "&" +
                keys +
                "__in=" +
                itemElements[report_type][keys]["selectedOptionsList"].map(
                  (value) => value.value
                ))
        );
      getData(
        page,
        perPage,
        "",
        report_type,
        response_type,
        filterBy,
        advance_query
      );
      getData(page, perPage, "", report_type, "table", filterBy, advance_query);
    } else {
      showErrorMsg("Empty Date Values");
    }
  };

  const formatKey = (key) => {
    const formattedKey = key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const handleButtonDisable = () => {
    return (
      (selectedValuesList[report_type].length === 0 &&
        Object.keys(itemElements[report_type]).length === 0) ||
      (selectedValuesList[report_type].length === 0 &&
        Object.keys(itemElements[report_type]).length === 1 &&
        itemElements[report_type].hasOwnProperty("command"))
    );
  };

  return (
    <>
      <Form
        className="mt-3 advance-search my-3 ms-0 ps-0"
        onSubmit={handleSubmit}
      >
        <div className="w-100 ps-4">
          <Row className="mb-1 my-1 pb-2 w-100">
            <Form.Label className="d-flex flex-row justify-content-start py-2 ms-1 fw-bolder advance-search-label ">
              Advance Filter
            </Form.Label>
            <DateTimeRangePicker
              report_type={report_type}
              updateAllOptions={updateAllOptions}
              disable_buttons={disable_buttons}
              handleButtonDisable={handleButtonDisable}
            />
          </Row>
          <Row className="mb-1  w-100 ff-poppins">
            {itemElements[report_type] &&
              Object.keys(itemElements[report_type]).map((key, index) => (
                <div
                  className="d-flex flex-row pb-3 advance_filter"
                  key={index}
                  id={"filter_" + index}
                >
                  <div className="w-30 pe-3">
                    <Select
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      inputId={index + "selection"}
                      value={{ label: formatKey(key), value: key }}
                      onChange={(option) => selectionAction(option.value, key)}
                      options={getUpdatedFieldsList()}
                    />
                  </div>
                  <div className="w-60">
                    <Select
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      inputId={index + "options"}
                      isMulti
                      onChange={(options) => {
                        sectionValues(options, key);
                      }}
                      value={itemElements[report_type][key].selectedOptionsList}
                      options={itemElements[report_type][key].allOptions}
                      isLoading={loading[key]}
                      onInputChange={(e) => handleSearch(e, key)}
                      onMenuScrollToBottom={() => {
                        setItemLoading(key, true);
                        loadMoreOptions(key);
                      }}
                    />
                  </div>
                  {Object.keys(itemElements[report_type]).length > 1 && (
                    <div className="w-10 d-flex justify-content-center align-items-center">
                      {index !== -1 && (
                        <button
                          type="button"
                          className="mapping-remove-btn d-flex align-items-center"
                          onClick={() => removeFilter(key)}
                        >
                          <img src={images.TrashIcon} alt="Remove" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </Row>
          <Row className="mb-3 mt-3  w-100 text-justify d-flex">
            <span className="d-flex justify-content-between pt-1 ps-3 pe-3">
              <div className="py-2">

                <GeneralButton
                  disabled={handleButtonDisable()}
                  onClickEvent={() => handleClear()}
                  className="w-150px me-3"
                  value="Clear all"
                  color="#1565c0"
                  variant="outlined"
                  size="large"
                />

                <GeneralButton
                    disabled={
                      (selectedValuesList[report_type] &&
                        !selectedValuesList[report_type].length > 0) ||
                      (selectedValuesList[report_type].length === 1 &&
                        selectedValuesList[report_type][0] === "command")
                    }
                    onClickEvent={() =>
                      selectedValuesList[report_type] &&
                      selectedValuesList[report_type].length > 0 &&
                      addItem()
                    }
                    value="+ Add new filter row"
                    variant="contained"
                    size="large"
                  />
              </div>
              <div className="py-2">
                {disable_buttons && (
                  <GeneralButton
                  onClickEvent={() => downloadFile("csv")}
                  color="#000000"
                  variant="text"
                  size="large"
                  value="Export csv"
                  startIcon={<img src={exportIcon} alt="icon" id="center-blueShadow" />}
                />
                )}

                {download_report && (
                   <GeneralButton
                   onClickEvent={() =>downloadFile("pdf")}
                   color="#000000"
                   variant="text"
                   size="large"
                   value="Export report"
                   startIcon={
                     <img src={exportIcon} alt="icon" id="center-blueShadow-1" />
                   }
                 />
                )}
                 <GeneralButton
                    disabled={!disable_buttons}
                    className="me-1 ms-3 float-end w-200px"
                    type="submit"
                    value="Search"
                    variant="contained"
                    size="large"
                  />
              </div>
            </span>
          </Row>
        </div>
      </Form>
    </>
  );
};
export default AdvanceSearchFilter;
