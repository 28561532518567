import React, { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ButtonGroup, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AiOutlineArrowRight } from "react-icons/ai";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFrom,
  changeTo,
  changeQuickDateButton,
} from "../../../state/slices/advance_filter";
import { Col, Row } from "react-bootstrap";

const DateTimeRangePicker = ({ report_type, updateAllOptions, handleButtonDisable }) => {
  const dispatch = useDispatch();
  const activeClass = "ff-poppins fs-12px fw-600 text-white bg-primary";
  const inactiveClass = "ff-poppins fs-12px fw-600";
  const maxDateTime = dayjs(new Date());
  const advancedFilter = useSelector((state) => state.advanceFilter);
  const from = advancedFilter.from;
  const to = advancedFilter.to;
  const activeButton = advancedFilter.quickDateButton;

  const handleQuickDateRange = (type) => {
    const now = new Date();
    let fromDate, toDate;

    switch (type) {
      case "today":
        fromDate = dayjs(new Date(now)).startOf("day");
        toDate = dayjs(new Date(now));
        break;
      case "yesterday":
        fromDate = dayjs(new Date(now).setDate(now.getDate() - 1));
        toDate = dayjs(new Date(now));
        break;
      case "week":
        fromDate = dayjs(new Date(now).setDate(now.getDate() - 7));
        toDate = dayjs(new Date(now));
        break;
      case "month":
        fromDate = dayjs(new Date(now).setMonth(now.getMonth() - 1));
        toDate = dayjs(new Date(now));

        break;
      case "year":
        fromDate = dayjs(new Date(now).setFullYear(now.getFullYear() - 1));
        toDate = dayjs(new Date(now));
        break;
      default:
        break;
    }
    updateAllOptions(fromDate, toDate);
    handleFromChange(fromDate);
    handleToChange(toDate);
  };

  const handleFromChange = (date) => {
    let tempFrom = { ...from };
    tempFrom = { ...tempFrom, [report_type]: date };
    dispatch(changeFrom(tempFrom));
  };

  const handleToChange = (date) => {
    let tempTo = { ...to };
    tempTo = { ...tempTo, [report_type]: date };
    dispatch(changeTo(tempTo));
  };

  const handleActiveButtonChange = (value) => {
    let tempActiveButton = { ...activeButton };
    tempActiveButton = { ...tempActiveButton, [report_type]: value };
    dispatch(changeQuickDateButton(tempActiveButton));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Row>
        <Col lg={6} className="py-2">
          <Row>
            <div className="d-flex">
            <DateTimePicker
                disabled={handleButtonDisable()}
                label="From Date and Time"
                value={from[report_type]}
                maxDateTime={to[report_type] ? to[report_type] : maxDateTime}
                onChange={(date) => {
                  handleFromChange(date);
                  handleActiveButtonChange(null);
                  updateAllOptions(date, to[report_type]);
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              <div className="d-flex align-items-center px-2">
                <AiOutlineArrowRight />
              </div>
              <DateTimePicker
                disabled={handleButtonDisable()}
                label="To Date and Time"
                minDateTime={from[report_type]}
                maxDateTime={maxDateTime}
                value={to[report_type]}
                onChange={(date) => {
                  handleToChange(date);
                  handleActiveButtonChange(null);
                  updateAllOptions(from[report_type], date);
                }}
                slotProps={{ textField: { size: "small" } }}
                className="pe-3"
              />
            </div>
             
          </Row>
        </Col>
        <Col lg={6} className="py-2">
          <ButtonGroup className="h-100" color="primary" variant="outlined">
            <Button
              disabled={handleButtonDisable()}
              className={
                activeButton[report_type] === "today"
                  ? activeClass
                  : inactiveClass
              }
              onClick={() => {
                handleQuickDateRange("today");
                handleActiveButtonChange("today");
              }}
            >
              Today
            </Button>
            <Button
              disabled={handleButtonDisable()}
              className={
                activeButton[report_type] === "yesterday"
                  ? activeClass
                  : inactiveClass
              }
              onClick={() => {
                handleQuickDateRange("yesterday");
                handleActiveButtonChange("yesterday");
              }}
            >
              Yesterday
            </Button>
            <Button
              disabled={handleButtonDisable()}
              className={
                activeButton[report_type] === "week"
                  ? activeClass
                  : inactiveClass
              }
              onClick={() => {
                handleQuickDateRange("week");
                handleActiveButtonChange("week");
              }}
            >
              Last Week
            </Button>
            <Button
              disabled={handleButtonDisable()}
              className={
                activeButton[report_type] === "month"
                  ? activeClass
                  : inactiveClass
              }
              onClick={() => {
                handleQuickDateRange("month");
                handleActiveButtonChange("month");
              }}
            >
              Last Month
            </Button>
            <Button
              disabled={handleButtonDisable()}
              className={
                activeButton[report_type] === "year"
                  ? activeClass
                  : inactiveClass
              }
              onClick={() => {
                handleQuickDateRange("year");
                handleActiveButtonChange("year");
              }}
            >
              Last Year
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </LocalizationProvider>
  );
};

export default DateTimeRangePicker;
