import { getMostUsedResourceList } from "../../../../api/plot";
import { useState, useEffect } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "../icons/icon-info.svg";
import { DarkTooltip } from "../../../common/CustomTooltip/CustomTooltip";
import useWindowDimensions from "../../../../utils/getHeightWidth";

export function MostUsedResources() {
  const {height, width} = useWindowDimensions();
  const [mostUsedResources, setMostUsedResources] = useState(null);
  const [hasData, setHasData] = useState(false);

  const getMostUsedResourceData = async () => {
    const response = await getMostUsedResourceList();
    if (response.data !== null) {
      if (response.data.top_three_resources.length > 0)
        {
          setHasData(true)
        }
      setMostUsedResources(response.data.top_three_resources);
    }
  };

  const tableContainerStyle = {
    maxHeight: "80%", 
    overflowY: "auto", 
    boxShadow: "none",
  };

  useEffect(() => {
    getMostUsedResourceData();
  }, []);

  return (
    <div className="h-100 w-100 bg-white ">
      <div className="h-90 w-100">
        <div className={"ff-poppins ps-5 pt-4 fw-600 "+ (height <= 786 ? " fs-14px":" fs-18px")}>
          Top 3 most accessed resources
          <DarkTooltip
                id="setting-info"
                title="Top 3 most accessed resources"
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  width="15"
                  height="15"
                  className="mx-2 cursor_pointer"
                />
              </DarkTooltip>
        </div>
<div className="h-100 w-100 px-3 pt-3">
  {!hasData && <div className="ff-popins border-top fs-14 h-100 d-flex align-items-center justify-content-center"><div>There are no records to display</div></div>}
{hasData && <TableContainer component={Paper} style={tableContainerStyle}>
          <Table  size={height <=786 ?"small": "medium"} stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="border-bottom">
              <TableRow>
                <TableCell align="left" className="fw-600 ">Resource type</TableCell>
                <TableCell align="center" className="fw-600 ff-poppins" >
                  Id
                </TableCell>
                <TableCell align="center" className="fw-600 ff-poppins" >
                  Name
                </TableCell>
                <TableCell align="center" className="fw-600 ff-poppins" >
                  Type
                </TableCell>
                <TableCell align="center" className="fw-600 ff-poppins" >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mostUsedResources &&
                mostUsedResources.map((row) => (
                  <TableRow
                    key={
                      "app_id" in row
                        ? "App_" + row.app_id
                        : "web_app_id" in row
                        ? "WebApp_" + row.web_app_id
                        : "Asset_" + row.asset_id
                    }
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                    align="left"
                      className="ff-poppins"
                      component="th"
                      scope="row"
                    >
                      {"app_id" in row ? "App" : "web_app_id" in row ? "Web App" : "Asset"}
                    </TableCell>
                    <TableCell align="center" className="ff-poppins" >
                      {row.app_id || row.asset_id || row.web_app_id}
                    </TableCell>
                    <TableCell align="center" className="ff-poppins">
                      {row.app_name || row.asset_name || row.web_app_name}
                    </TableCell>
                    <TableCell align="center" className="ff-poppins" >
                      {row.app_type || row.asset_type || row.web_app_type}
                    </TableCell>
                    <TableCell align="center" className="ff-poppins" >
                      {row.total}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>}
</div>
     
      </div>
    </div>
  );
}
