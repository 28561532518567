import { Fade, Slide } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { TbFileImport } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import VaultDropdownIndicator from "./icons/vault-select-dropdown-indicator.svg";

import { Container } from "react-bootstrap";
import {
  getPasswordVaultOptions,
  updatePasswordVaultOptions,
} from "../../../api/password_vault";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import images from "../../../utils/images";
import { generateRandomString } from "../../../utils/oAuthHelperFuntion";
import { showError, showSuccess } from "../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import { SettingDarkToolTip } from "../../common/CustomTooltip/CustomTooltip";
import FileUpload from "../../common/FileUpload/FileUpload";
import "./ProductSettings.scss";
import "./StorageConfig.scss";

const CaretDownIcon = () => {
  return <img src={VaultDropdownIndicator} />;
};

const encryptionOptions = [
  {
    label: "AES 256",
    value: 1,
  },
  {
    label: "AES 128",
    value: 2,
  },
];
const hashingOptions = [
  {
    label: "PBKDF2",
    value: 1,
  },
  {
    label: "Scrypt",
    value: 2,
  },
];
const findLabelByValue = (value, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].value === value) {
      return array[i].label;
    }
  }
  return null; // Return null if the value is not found in the array
};

const PasswordVaultConfig = () => {
  const dispatch = useDispatch();

  const active_switch =
    "form-control p-1 d-flex flex-row justify-content-between align-items-center active_card";
  const inactive_switch =
    "form-control p-1 d-flex flex-row justify-content-between align-items-center";
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 180 + "px";
  const [selectedOption, setSelectedOption] = useState(null);
  const [passwordVaultType, setPasswordVaultType] = useState(null);
  const [showHidden1, setShowHidden1] = useState(false);
  const [showHidden2, setShowHidden2] = useState(false);
  const [showHidden3, setShowHidden3] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  //gcp
  const [openFileImport, setOpenFileImport] = useState(false);
  const default_google_dictionary = {
    type: "",
    project_id: "",
    private_key_id: "",
    private_key: "",
    client_email: "",
    client_id: "",
    auth_uri: "",
    token_uri: "",
    auth_provider_x509_cert_url: "",
    client_x509_cert_url: "",
    universe_domain: "",
  };
  const [googleAccountType, setGoogleAccountType] = useState("");
  const [googleProjectId, setGoogleProjectId] = useState("");
  const [googlePrivateKeyId, setGooglePrivateKeyId] = useState("");
  const [googlePrivateKey, setGooglePrivateKey] = useState("");
  const [googleClientEmail, setGoogleClientEmail] = useState("");
  const [googleAuthUri, setGoogleAuthUri] = useState("");
  const [googleTokenUri, setGoogleTokenUri] = useState("");
  const [googleAuthProviderCertUrl, setGoogleAuthProviderCertUrl] =
    useState("");
  const [googleClientCertUrl, setGoogleClientCertUrl] = useState("");
  const [googleUniverseDomain, setGoogleUniverseDomain] = useState("");
  const [validated, setValidated] = useState(false);

  //aws
  const [awsAccessKey, setAwsAccessKey] = useState("");
  const [awsRegionName, setAwsRegionName] = useState("");

  //azure
  const [azureTenantId, setAzureTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [azureClientSecret, setAzureClientSecret] = useState("");
  const [azureSubscriptionId, setAzureSubscriptionId] = useState("");
  const [azureVaultUrl, setAzureVaultUrl] = useState("");

  //hashicorp
  const [hashicorpVaultAddr, setHashicorpVaultAddr] = useState("");
  const [hashicorpRootToken, setHashicorpRootToken] = useState("");

  //keepass

  const [keepassFilePath, setKeePassFilePath] = useState("");
  const [keePassMasterPassword, setKeePassMasterPassword] = useState("");

  //local
  const [encryptionAlgo, setEncryptionAlgo] = useState(encryptionOptions[0]);
  const [hashingAlgo, setHashingAlgo] = useState(hashingOptions[0]);
  const [keyLength, setKeyLength] = useState(256);
  const [byteLength, setByteLength] = useState(32);
  const [secretKey, setSecretKey] = useState(
    "update this locationFor 3rdParty"
  );
  const [keysize, setKeysize] = useState();

  const [loading, setLoading] = useState(false);

  const generateSecretKey = () => {
    setSecretKey(generateRandomString(byteLength / 2));
    showSuccess("Secret Key Rotated");
  };
  const isKeyMatchesBytes = (secretKey, byteLength) => {
    return secretKey?.length === byteLength;
  };
  const keykeySizeCheck = () => {
    if (isKeyMatchesBytes(secretKey, byteLength)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    keykeySizeCheck();
  }, [keyLength, keysize, secretKey]);

  const available_platforms = {
    local_vault: "local_password_vault",
    aws_secrets_manager: "aws_secrets_manager",
    gcp_secret_manager: "gcp_secret_manager",
    azure_key_vault: "azure_key_vault",
    hashicorp_vault: "hashicorp_vault",
    keepass_vault: "keepass_vault",
  };
  const encryptionValue = () => {
    let a = {};
    encryptionOptions.map((value, index) => {
      if (value.value === String(encryptionAlgo)) {
        a = encryptionOptions[index];
      }
    });
    return a;
  };
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "4px",
      paddingBottom: "4px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
    }),
  };
  const hashValue = () => {
    let a = {};
    hashingOptions.map((value, index) => {
      if (value.value === String(hashingAlgo)) {
        a = hashingOptions[index];
      }
    });
    return a;
  };
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const title_heading = useSelector((state) => state.header.title);
  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 16,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    color: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });

  const fileInvalidMessage =
    "Selected file does not contain a valid credentials data";
  const maxFileSize = {
    label: "2MB",
    value: 2e6,
  };

  const handleFileSubmit = (fileData, handleClose) => {
    setGoogleAccountType(fileData.type);
    setGoogleProjectId(fileData.project_id);
    setGooglePrivateKeyId(fileData.private_key_id);
    setGooglePrivateKey(fileData.private_key);
    setClientId(fileData.client_id);
    setGoogleClientEmail(fileData.client_email);
    setGoogleAuthUri(fileData.auth_uri);
    setGoogleTokenUri(fileData.token_uri);
    setGoogleAuthProviderCertUrl(fileData.auth_provider_x509_cert_url);
    setGoogleClientCertUrl(fileData.client_x509_cert_url);
    setGoogleUniverseDomain(fileData.universe_domain);
    showSuccess("Credentials imported successfully");
    handleClose();
  };

  const updateOption = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const { data, error } = await updatePasswordVaultOptions({
        password_vault_type: selectedOption,
        ...(encryptionAlgo !== "" && {
          encryption_algorithm: encryptionAlgo.value,
        }),
        ...(secretKey !== "" && { secret_key: secretKey }),
        ...(hashingAlgo !== "" && { hashing_algorithm: hashingAlgo.value }),

        ...(hashicorpVaultAddr !== "" && { vault_address: hashicorpVaultAddr }),
        ...(hashicorpRootToken !== "" && { root_token: hashicorpRootToken }),

        ...(keepassFilePath !== "" && {
          keepass_database_file_path: keepassFilePath,
        }),
        ...(keePassMasterPassword !== "" && {
          keepass_master_password: keePassMasterPassword,
        }),

        ...(azureTenantId !== "" && { tenant_id: azureTenantId }),
        ...(clientId !== "" && { client_id: clientId }),
        ...(azureClientSecret !== "" && { client_secret: azureClientSecret }),
        ...(azureSubscriptionId !== "" && {
          subscription_id: azureSubscriptionId,
        }),
        ...(azureVaultUrl !== "" && { vault_url: azureVaultUrl }),

        ...(awsAccessKey !== "" && { access_key: awsAccessKey }),
        ...(awsRegionName !== "" && { region_name: awsRegionName }),

        ...(googleAccountType !== "" && { account_type: googleAccountType }),
        ...(googleProjectId !== "" && { project_id: googleProjectId }),
        ...(googlePrivateKeyId !== "" && {
          private_key_id: googlePrivateKeyId,
        }),
        ...(googlePrivateKey !== "" && { private_key: googlePrivateKey }),
        ...(googleClientEmail !== "" && { client_email: googleClientEmail }),
        ...(googleAuthUri !== "" && { auth_uri: googleAuthUri }),
        ...(googleTokenUri !== "" && { token_uri: googleTokenUri }),
        ...(googleAuthProviderCertUrl !== "" && {
          auth_provider_cert_url: googleAuthProviderCertUrl,
        }),
        ...(googleClientCertUrl !== "" && {
          client_cert_url: googleClientCertUrl,
        }),
        ...(googleUniverseDomain !== "" && {
          universe_domain: googleUniverseDomain,
        }),
      });
      if (data !== null) {
        showSuccess(data.message);
      }
      if (error !== null) {
        showError(error);
      }
    }
  };

  const handleSwitch = (platform) => {
    if (platform !== selectedOption) {
      getPasswordVaultOption(platform);
    } else {
      getPasswordVaultOption(available_platforms.local_vault);
    }
    setValidated(false);
  };
  const getPasswordVaultOption = async (platform) => {
    setSelectedOption(platform);
    setLoading(true);
    const { data, error } = await getPasswordVaultOptions(platform);
    if (data !== null) {
      let password_vault_configurations = data.password_vault_configurations;
      setSelectedOption(password_vault_configurations?.password_vault_type);
      setGoogleAccountType(
        password_vault_configurations?.account_type
          ? password_vault_configurations.account_type
          : ""
      );
      setGoogleProjectId(
        password_vault_configurations?.project_id
          ? password_vault_configurations.project_id
          : ""
      );
      setGooglePrivateKeyId(
        password_vault_configurations?.private_key_id
          ? password_vault_configurations.private_key_id
          : ""
      );
      setGooglePrivateKey(
        password_vault_configurations?.private_key
          ? password_vault_configurations.private_key
          : ""
      );
      setGoogleClientEmail(
        password_vault_configurations?.client_email
          ? password_vault_configurations.client_email
          : ""
      );
      setGoogleAuthUri(
        password_vault_configurations?.auth_uri
          ? password_vault_configurations.auth_uri
          : ""
      );
      setGoogleTokenUri(
        password_vault_configurations?.token_uri
          ? password_vault_configurations.token_uri
          : ""
      );
      setGoogleAuthProviderCertUrl(
        password_vault_configurations?.auth_provider_cert_url
          ? password_vault_configurations.auth_provider_cert_url
          : ""
      );
      setGoogleClientCertUrl(
        password_vault_configurations?.client_cert_url
          ? password_vault_configurations.client_cert_url
          : ""
      );
      setGoogleUniverseDomain(
        password_vault_configurations?.universe_domain
          ? password_vault_configurations.universe_domain
          : ""
      );

      setAwsAccessKey(
        password_vault_configurations?.access_key
          ? password_vault_configurations.access_key
          : ""
      );
      setAwsRegionName(
        password_vault_configurations?.region_name
          ? password_vault_configurations.region_name
          : ""
      );

      setAzureTenantId(
        password_vault_configurations?.tenant_id
          ? password_vault_configurations.tenant_id
          : ""
      );
      setClientId(
        password_vault_configurations?.client_id
          ? password_vault_configurations.client_id
          : ""
      );
      setAzureClientSecret(
        password_vault_configurations?.client_secret
          ? password_vault_configurations.client_secret
          : ""
      );
      setAzureSubscriptionId(
        password_vault_configurations?.subscription_id
          ? password_vault_configurations.subscription_id
          : ""
      );
      setAzureVaultUrl(
        password_vault_configurations?.vault_url
          ? password_vault_configurations.vault_url
          : ""
      );

      setHashicorpRootToken(
        password_vault_configurations?.root_token
          ? password_vault_configurations.root_token
          : ""
      );
      setHashicorpVaultAddr(
        password_vault_configurations?.vault_address
          ? password_vault_configurations.vault_address
          : ""
      );

      setKeePassFilePath(
        password_vault_configurations?.keepass_database_file_path
          ? password_vault_configurations.keepass_database_file_path
          : ""
      );
      setKeePassMasterPassword(
        password_vault_configurations?.keepass_master_password
          ? password_vault_configurations.keepass_master_password
          : ""
      );

      setSecretKey(
        password_vault_configurations?.secret_key
          ? password_vault_configurations?.secret_key
          : ""
      );
      setHashingAlgo({
        label: findLabelByValue(
          password_vault_configurations?.hashing_algorithm,
          hashingOptions
        ),
        value: password_vault_configurations?.hashing_algorithm,
      });
      setEncryptionAlgo({
        label: findLabelByValue(
          password_vault_configurations?.encryption_algorithm,
          encryptionOptions
        ),
        value: password_vault_configurations?.encryption_algorithm,
      });
      setByteLength(32 / password_vault_configurations?.encryption_algorithm);
    } else showError(error);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(changeTitle("Settings"));
    getPasswordVaultOption(null);
  }, []);

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Settings":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../images/settings-icon.svg").default
                          }
                          alt="settings icon"
                        />{" "}
                        &nbsp; Settings
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  <Form
                    noValidate
                    validated={validated}
                    autocomplete="off"
                    onSubmit={updateOption}
                  >
                    <div className="heading_title storage_header p-0">
                      <div className="d-flex flex-row justify-content-between align-items-center py-3">
                        <div className="abc d-flex align-items-center">
                          <img
                            src={
                              require("../../../images/settings-icon.svg")
                                .default
                            }
                            alt="settings icon"
                          />
                          <h2 className="main-heading d-inline ms-2">
                            Password Vault Configuration
                          </h2>
                        </div>
                        <SaveButton
                          type="submit"
                          variant="outlined"
                          disabled={
                            selectedOption === null || loading === true
                              ? true
                              : false
                          }
                        >
                          Save
                        </SaveButton>
                      </div>
                    </div>
                    <div className="select-storage storage_header border-top-0 p-0">
                      <div className="d-flex flex-row justify-content-between align-items-center py-3">
                        <h2 className="main-heading sub_heading d-inline ff-poppins">
                          Select Password Vault
                        </h2>
                      </div>
                      <Row className="pb-2 flex-nowrap overflow-auto">
                        <Col lg={3} md={6} sm={12} className="pb-4">
                          <div
                            className={
                              selectedOption ===
                              available_platforms.gcp_secret_manager
                                ? active_switch
                                : inactive_switch
                            }
                          >
                            <div>
                              <Image
                                src={images.GoogleCloud}
                                height="35px"
                                width="50px"
                                className="px-2"
                              ></Image>
                              <span className="ff-poppins fs-12px">
                                GCP Secret Manager
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                checked={
                                  selectedOption ===
                                  available_platforms.gcp_secret_manager
                                    ? true
                                    : false
                                }
                                id="GoogleCloud"
                                onChange={() =>
                                  handleSwitch(
                                    available_platforms.gcp_secret_manager
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="pb-4">
                          <div
                            className={
                              selectedOption ===
                              available_platforms.aws_secrets_manager
                                ? active_switch
                                : inactive_switch
                            }
                          >
                            <div>
                              <Image
                                src={images.AWS_Icon}
                                height="35px"
                                width="50px"
                                className="px-2"
                              ></Image>
                              <span className="ff-poppins fs-12px">
                                AWS Secrets Manager
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                checked={
                                  selectedOption ===
                                  available_platforms.aws_secrets_manager
                                    ? true
                                    : false
                                }
                                id="aws-secrets-manager"
                                onChange={() =>
                                  handleSwitch(
                                    available_platforms.aws_secrets_manager
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="pb-4">
                          <div
                            className={
                              selectedOption ===
                              available_platforms.azure_key_vault
                                ? active_switch
                                : inactive_switch
                            }
                          >
                            <div>
                              <Image
                                src={images.Azure_Icon}
                                height="35px"
                                width="50px"
                                className="px-2"
                              ></Image>
                              <span className="ff-poppins fs-12px">
                                Azure Key Vault
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                checked={
                                  selectedOption ===
                                  available_platforms.azure_key_vault
                                    ? true
                                    : false
                                }
                                id="azure_key_vault"
                                onChange={() =>
                                  handleSwitch(
                                    available_platforms.azure_key_vault
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="pb-4">
                          <div
                            className={
                              selectedOption ===
                              available_platforms.hashicorp_vault
                                ? active_switch
                                : inactive_switch
                            }
                          >
                            <div>
                              <Image
                                src={images.HashiCorp_Icon}
                                height="35px"
                                width="50px"
                                className="px-2"
                              ></Image>
                              <span className="ff-poppins fs-12px">
                                HashiCorp Vault
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                checked={
                                  selectedOption ===
                                  available_platforms.hashicorp_vault
                                    ? true
                                    : false
                                }
                                id="hashiCorp_vault"
                                onChange={() =>
                                  handleSwitch(
                                    available_platforms.hashicorp_vault
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} md={6} sm={12} className="pb-4">
                          <div
                            className={
                              selectedOption ===
                              available_platforms.keepass_vault
                                ? active_switch
                                : inactive_switch
                            }
                          >
                            <div>
                              <Image
                                src={images.KeePass_Icon}
                                height="35px"
                                width="50px"
                                className="px-2"
                              ></Image>
                              <span className="ff-poppins fs-12px">
                                KeePass Vault
                              </span>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                checked={
                                  selectedOption ===
                                  available_platforms.keepass_vault
                                    ? true
                                    : false
                                }
                                id="keepass_vault"
                                onChange={() =>
                                  handleSwitch(
                                    available_platforms.keepass_vault
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {selectedOption ===
                      available_platforms.gcp_secret_manager &&
                      loading === false && (
                        <div className="px-5 pt-4 w-100">
                          <h2 className="main-heading sub_heading ff-poppins d-flex justify-content-between w-100">
                            Configuration for Google Cloud Secret Manager
                            <Button
                              variant="primary"
                              onClick={() => setOpenFileImport(true)}
                            >
                              <TbFileImport size={22} /> Import From File
                            </Button>
                            <FileUpload
                              openModal={openFileImport}
                              setOpenModal={setOpenFileImport}
                              maxFileSize={maxFileSize}
                              formLabel={"Select JSON file"}
                              heading={"Import GCP Secret Manager Credentials"}
                              fileInvalidMessage={fileInvalidMessage}
                              invalidFileDataMessage={
                                "Selected file does not contain valid credentials data"
                              }
                              dirToCompare={default_google_dictionary}
                              fileType={["json"]}
                              handleFileSubmit={handleFileSubmit}
                            />
                          </h2>
                          <Row className="py-4">
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="type"
                              >
                                <Form.Label>Account Type</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleAccountType}
                                  onChange={(event) =>
                                    setGoogleAccountType(event.target.value)
                                  }
                                  placeholder="Enter account type. eg. service_account"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="projectId"
                              >
                                <Form.Label>Project Id</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleProjectId}
                                  onChange={(event) =>
                                    setGoogleProjectId(event.target.value)
                                  }
                                  placeholder="Enter project id"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="privateKeyId"
                              >
                                <Form.Label>Private Key Id</Form.Label>
                                <InputGroup
                                  className="pb-2 form_label"
                                  controlId="privateKeyId1"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden1 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={googlePrivateKeyId}
                                    onChange={(event) =>
                                      setGooglePrivateKeyId(event.target.value)
                                    }
                                    placeholder="Enter private key id"
                                  />
                                  <InputGroup.Text
                                    id="privateKeyId"
                                    className="bg-white"
                                    onClick={() => setShowHidden1(!showHidden1)}
                                  >
                                    {showHidden1 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="privateKey"
                              >
                                <Form.Label>Private Key </Form.Label>
                                <InputGroup
                                  className="pb-2 form_label"
                                  controlId="privateKey"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden2 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={googlePrivateKey}
                                    onChange={(event) =>
                                      setGooglePrivateKey(event.target.value)
                                    }
                                    placeholder="Enter private key"
                                  />
                                  <InputGroup.Text
                                    id="privateKey"
                                    className="bg-white"
                                    onClick={() => setShowHidden2(!showHidden2)}
                                  >
                                    {showHidden2 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="clientEmail"
                              >
                                <Form.Label>Client Email</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="email"
                                  value={googleClientEmail}
                                  onChange={(event) =>
                                    setGoogleClientEmail(event.target.value)
                                  }
                                  placeholder="Enter client email"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="clientId"
                              >
                                <Form.Label>Client Id</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={clientId}
                                  onChange={(event) =>
                                    setClientId(event.target.value)
                                  }
                                  placeholder="Enter client id"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="auth_uri"
                              >
                                <Form.Label>Auth uri</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleAuthUri}
                                  onChange={(event) =>
                                    setGoogleAuthUri(event.target.value)
                                  }
                                  placeholder="Enter auth uri"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="tokenUri"
                              >
                                <Form.Label>Token Uri</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleTokenUri}
                                  onChange={(event) =>
                                    setGoogleTokenUri(event.target.value)
                                  }
                                  placeholder="Enter token uri"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="client_x509_cert_url"
                              >
                                <Form.Label>Client Cert Url</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleClientCertUrl}
                                  onChange={(event) =>
                                    setGoogleClientCertUrl(event.target.value)
                                  }
                                  placeholder="Enter client cert url"
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="auth_provider_x509_cert_url"
                              >
                                <Form.Label>Auth Provider Cert Url</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleAuthProviderCertUrl}
                                  onChange={(event) =>
                                    setGoogleAuthProviderCertUrl(
                                      event.target.value
                                    )
                                  }
                                  placeholder="Enter Auth Provider Cert Url"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="universeDomain"
                              >
                                <Form.Label>Universe Domain</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={googleUniverseDomain}
                                  onChange={(event) =>
                                    setGoogleUniverseDomain(event.target.value)
                                  }
                                  placeholder="Enter Universe Domain"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    {selectedOption === available_platforms.hashicorp_vault &&
                      loading === false && (
                        <div className="px-5 pt-4">
                          <h2 className="main-heading sub_heading d-inline ff-poppins">
                            Configuration for HashiCorp Vault
                          </h2>
                          <Row className="py-4">
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="rootToken"
                              >
                                <Form.Label>Root Token</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="rootToken"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden2 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={hashicorpRootToken}
                                    onChange={(event) =>
                                      setHashicorpRootToken(event.target.value)
                                    }
                                    placeholder="Enter hashicorp root token"
                                  />
                                  <InputGroup.Text
                                    id="apiSecret"
                                    className="bg-white"
                                    onClick={() => setShowHidden2(!showHidden2)}
                                  >
                                    {showHidden2 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="vaultAddr"
                              >
                                <Form.Label>Vault ADDR</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={hashicorpVaultAddr}
                                  onChange={(event) =>
                                    setHashicorpVaultAddr(event.target.value)
                                  }
                                  placeholder="Enter hashicorp vault address"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    {selectedOption === available_platforms.keepass_vault &&
                      loading === false && (
                        <div className="px-5 pt-4">
                          <h2 className="main-heading sub_heading d-inline ff-poppins">
                            Configuration for KeePass Vault
                          </h2>
                          <Row className="py-4">
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="rootToken"
                              >
                                <Form.Label>Database File Path</Form.Label>
                                <Form.Control
                                  required
                                  className="user_input_field"
                                  type="text"
                                  value={keepassFilePath}
                                  onChange={(event) =>
                                    setKeePassFilePath(event.target.value)
                                  }
                                  placeholder="Enter keepass database file"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="vaultAddr"
                              >
                                <Form.Label>Master Password</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="rootToken"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden2 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={keePassMasterPassword}
                                    onChange={(event) =>
                                      setKeePassMasterPassword(
                                        event.target.value
                                      )
                                    }
                                    placeholder="Enter keepass master password"
                                  />
                                  <InputGroup.Text
                                    id="database_file_path"
                                    className="bg-white"
                                    onClick={() => setShowHidden2(!showHidden2)}
                                  >
                                    {showHidden2 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    {selectedOption ===
                      available_platforms.aws_secrets_manager &&
                      loading === false && (
                        <div className="px-5 pt-4">
                          <h2 className="main-heading sub_heading d-inline ff-poppins">
                            Configuration for AWS Secrets Manager
                          </h2>
                          <Row className="py-4">
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="awsAccessKey"
                              >
                                <Form.Label>Access Key</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="accessKey"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden2 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={awsAccessKey}
                                    onChange={(event) =>
                                      setAwsAccessKey(event.target.value)
                                    }
                                    placeholder="Enter aws access key"
                                  />
                                  <InputGroup.Text
                                    id="apiKey"
                                    className="bg-white"
                                    onClick={() => setShowHidden2(!showHidden2)}
                                  >
                                    {showHidden2 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="regionName"
                              >
                                <Form.Label>Region Name</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={awsRegionName}
                                  onChange={(event) =>
                                    setAwsRegionName(event.target.value)
                                  }
                                  placeholder="Enter aws region name"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="awsSecretKey"
                              >
                                <Form.Label>Secret key</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="apiSecret"
                                >
                                  <Form.Control
                                    required
                                    className="user_input_field border-end-0"
                                    type={
                                      showHidden1 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={secretKey}
                                    onChange={(event) =>
                                      setSecretKey(event.target.value)
                                    }
                                    placeholder="Enter aws secret key"
                                  />
                                  <InputGroup.Text
                                    id="apiSecret"
                                    className="bg-white"
                                    onClick={() => setShowHidden1(!showHidden1)}
                                  >
                                    {showHidden1 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    {selectedOption === available_platforms.azure_key_vault &&
                      loading === false && (
                        <div className="px-5 pt-4">
                          <h2 className="main-heading sub_heading d-inline ff-poppins">
                            Configuration for Azure Key Vault
                          </h2>
                          <Row className="py-4">
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="tenantId"
                              >
                                <Form.Label>Tenant Id</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="tenantId"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden1 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={azureTenantId}
                                    onChange={(event) =>
                                      setAzureTenantId(event.target.value)
                                    }
                                    placeholder="Enter azure tenant id"
                                  />
                                  <InputGroup.Text
                                    id="tenantId"
                                    className="bg-white"
                                    onClick={() => setShowHidden1(!showHidden1)}
                                  >
                                    {showHidden1 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="clientId"
                              >
                                <Form.Label>Client Id</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="clientId"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden2 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={clientId}
                                    onChange={(event) =>
                                      setClientId(event.target.value)
                                    }
                                    placeholder="Enter azure client id"
                                  />
                                  <InputGroup.Text
                                    id="clientId"
                                    className="bg-white"
                                    onClick={() => setShowHidden2(!showHidden2)}
                                  >
                                    {showHidden2 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="clientSecret"
                              >
                                <Form.Label>Client Secret</Form.Label>
                                <InputGroup
                                  className="pb-4 form_label"
                                  controlId="clientSecret"
                                >
                                  <Form.Control
                                    required
                                    className=" user_input_field border-end-0"
                                    type={
                                      showHidden3 === false
                                        ? "password"
                                        : "text"
                                    }
                                    value={azureClientSecret}
                                    onChange={(event) =>
                                      setAzureClientSecret(event.target.value)
                                    }
                                    placeholder="Enter azure client secret"
                                  />
                                  <InputGroup.Text
                                    id="clientSecret"
                                    className="bg-white"
                                    onClick={() => setShowHidden3(!showHidden3)}
                                  >
                                    {showHidden3 === false ? (
                                      <Image
                                        src={images.Show_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    ) : (
                                      <Image
                                        src={images.Hide_Text_Eye_Icon}
                                        height="20px"
                                        className="px-2"
                                      ></Image>
                                    )}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="subscriptionId"
                              >
                                <Form.Label>Subscription Id</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={azureSubscriptionId}
                                  onChange={(event) =>
                                    setAzureSubscriptionId(event.target.value)
                                  }
                                  placeholder="Enter azure subscription id"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="pe-4">
                              <Form.Group
                                className="pb-4 form_label"
                                controlId="vaultURL"
                              >
                                <Form.Label>Vault URL</Form.Label>
                                <Form.Control
                                  required
                                  className=" user_input_field"
                                  type="text"
                                  value={azureVaultUrl}
                                  onChange={(event) =>
                                    setAzureVaultUrl(event.target.value)
                                  }
                                  placeholder="Enter azure vault URL"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    {selectedOption === available_platforms.local_vault &&
                      loading === false && (
                        <>
                          <div className="px-5 pt-4">
                            <h2 className="main-heading sub_heading d-inline ff-poppins">
                              Configuration for Local Password Vault
                            </h2>
                            <Container fluid>
                              <Row className="py-4">
                                <div className="vault-divs">
                                  <div className="w-75">
                                    <h4 className="main-heading sub_heading d-inline ff-poppins">
                                      System user secret settings
                                    </h4>
                                    <Form.Group className="mb-3">
                                      <Form.Label className="input_label">
                                        Encryption algorithm
                                        <SettingDarkToolTip
                                          className="lh-lg"
                                          title="AES block cipher encryption algorithm with GCM (Galois Counter Mode) will be used to encrypt system user secrets."
                                        />
                                      </Form.Label>
                                      <Col md={9}>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="vault-select"
                                          placeholder={
                                            "Choose an encryption algorithm"
                                          }
                                          isClearable={false}
                                          required
                                          isSearchable={true}
                                          name="color"
                                          options={encryptionOptions}
                                          value={encryptionAlgo}
                                          onChange={(selectedOption) => {
                                            setEncryptionAlgo(selectedOption);
                                            if (selectedOption.value === 1) {
                                              setKeyLength(256);
                                              setByteLength(32);
                                            } else {
                                              setKeyLength(128);
                                              setByteLength(16);
                                            }
                                          }}
                                          styles={selectStyles}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="mb-3 justify-align-content-between align-items-start"
                                      controlId="formUserSecretKey"
                                    >
                                      <Form.Label className="input_label">
                                        Secret Key
                                        <SettingDarkToolTip
                                          className="lh-lg"
                                          title="Based on selected encryption algorithm, 128 bit or 256 bit encryption key/secret key will be configured."
                                        />
                                      </Form.Label>
                                      <Col md={9}>
                                        <Form.Control
                                          required
                                          value={secretKey}
                                          onChange={(event) =>
                                            setSecretKey(event.target.value)
                                          }
                                          placeholder="Secret key"
                                          className="form_input_field "
                                          isInvalid={!keykeySizeCheck()}
                                          isValid={keykeySizeCheck()}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please configure secret key with
                                          selected algorithm and of specified
                                          byte length.
                                        </Form.Control.Feedback>
                                      </Col>
                                      <Col md={"auto"}>
                                        <button
                                          type="button"
                                          className="generate-secret-setting"
                                          onClick={() => {
                                            generateSecretKey();
                                            setKeysize(keyLength);
                                          }}
                                        >
                                          Generate key
                                        </button>
                                      </Col>
                                    </Form.Group>
                                  </div>
                                  <hr id="setting-line-break"></hr>
                                  <div className="w-75">
                                    <h4 className="main-heading sub_heading d-inline ff-poppins">
                                      User secret settings
                                    </h4>
                                    <Form.Group className="mb-3">
                                      <Form.Label className="input_label">
                                        Hashing algorithm
                                        <SettingDarkToolTip
                                          className="lh-lg"
                                          styles={{ fontSize: "3em" }}
                                          title="Selected hashing algorithm will be used for hashing user passwords."
                                        />
                                      </Form.Label>
                                      <Col md={9}>
                                        <Select
                                          required
                                          className="basic-single"
                                          classNamePrefix="vault-select"
                                          placeholder={
                                            "Choose an hashing algorithm"
                                          }
                                          defaultValue={hashValue()}
                                          isClearable={false}
                                          isSearchable={true}
                                          name="hashing-algorithm"
                                          options={hashingOptions}
                                          onChange={(selectedOption) => {
                                            setHashingAlgo(selectedOption);
                                          }}
                                          styles={selectStyles}
                                          value={hashingAlgo}
                                        />
                                      </Col>
                                    </Form.Group>
                                  </div>
                                </div>
                              </Row>
                            </Container>
                          </div>
                        </>
                      )}
                    {loading === true && (
                      <div className="px-5 pt-4">
                        <div className="d-flex justify-content-center align-items-center">
                          {<img src={images.Loader} width="7%" />}
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default PasswordVaultConfig;
