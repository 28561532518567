import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  roleWebAppAllocationList,
  webAppAllocateToRoles,
} from "../../../../api/web_app";
import webAppHeadingIcon from "../../../../images/all_asset_icon.svg";
import images from "../../../../utils/images";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import '../../Assets/AllocateAssets/Allocateusers.scss';
import { SystemUserResourceList } from "../../../../api/systemuser";

const AllocateWebAppToRole = ({ webAppName, ActionComponent }) => {
  const { web_app_id } = useParams();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [changeState, setChangeState] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateWebAppsToRole") !== null
      ? localStorage.getItem("rowsPerPage_AllocateWebAppsToRole")
      : 5
  );
  const [rolesList, setRolesList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateRole, setOpenAllocateRole] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/allocateWebAppsToRole";
  const customizeColumnDict = {
    "Display Name": true,
    ID: true,
    "Total Users": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  useEffect(() => {
    setSlideChecked(true);
    dispatch(changeTitle("WebApps"));
  }, []);
  const title_heading = useSelector((state) => state.header.title);

  const UserColumns = [
    { label: "Role Name", value: "role_name" },
    { label: "Display Name", value: "display_name" },
  ];
  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getRoles();
  }, [page, rowsPerPage, dispatch, changeState]);

  const getRoles = async () => {
    const { data, error } = await roleWebAppAllocationList({
      web_app_id: web_app_id,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filter_by: filterBy,
      action: "get_not_allocated",
    });
    if (data !== null) {
      setRolesList(data.roles);
      setTotalUsers(data.totalRoles);
      setFilteredData(data.roles);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    getRoles();
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = rolesList.filter((object) => {
      if (!filterBy) {
        return (
          object.role_name.toLowerCase().match(s.toLowerCase()) ||
          object.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateWebAppsToRole", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => ({
      id: item.role_id,
      name: item.role_name,
    }));
    setColName(temp);
    setOpenAllocateRole((o) => !o);
  };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy, systemuser_id, allocation_type) => {
    let role_ids = toBeAllocate.map((item) => item.role_id);
    const { data, error } = await webAppAllocateToRoles({
      web_app_ids: [web_app_id],
      role_ids: role_ids,
      systemuser_id: systemuser_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      allocation_type: allocation_type,
    });
    if (data !== null) {
      showSuccess(data.message);
      setChangeState(Object.create(null));
      setOpenAllocateRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "150px",
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      minWidth: "150px",
      omit: !checkedStateDict["Display Name"],
    },
    {
      name: "ID",
      selector: (row) => row.role_id,
      minWidth: "150px",
      omit: !checkedStateDict["ID"],
    },
    {
      name: "Total Users",
      selector: (row) => row.total_users,
      minWidth: "150px",
      omit: !checkedStateDict["Total Users"],
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.web_apps.allocate_web_apps && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd> Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "WebApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={images.WebAppHeading}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Web Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateRole}
                    handleClose={() => {
                      setOpenAllocateRole((o) => !o);
                    }}
                  >
                    <TimeComp
                      resource_id={web_app_id}
                      resource_type={"webapps"}
                      setOpenAlert={setOpenAllocateRole}
                      handleAlertAction={handleAllocateAction}
                      SystemUserResourceList={SystemUserResourceList}
                      colName={colName}
                      alertMsg="Below roles will be allocated for web app"
                      target_entity_name={webAppName}
                      resource_name={webAppName}
                      headingMsg="Allocate Roles"
                      target_type="Roles"
                      show_policy={false}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`WebApp : ${webAppName}`}
                        dataTableHeadingIcon={images.WebAppHeading}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={3}
                        />
                      )
                    }
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="role"
                        actions={ActionComponent}
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={actionOptions}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateWebAppToRole;
