import "@fontsource/dm-sans";
import "@fontsource/inter";
import "@fontsource/poppins";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { record } from "rrweb";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import axios from "./api/axios_interceptor";
import { get_jwt_token, loginRedirect } from "./api/helper_funtions";
import { sendEventStream } from "./api/session_recording";
import Logo from "./components/Branding/logo";
import ChangePassword from "./components/dashboard/AdminPassword/ChangePassword";
import AllocateAppToUser from "./components/dashboard/Apps/AllocateApps/AllocateAppToUser";
import MainAllocateApps from "./components/dashboard/Apps/AllocateApps/MainAllocateApps";
import MainDeAllocateApps from "./components/dashboard/Apps/AllocateApps/MainDeAllocateApps";
import MyApps from "./components/dashboard/Apps/MyApps/MyApps";
import UserApps from "./components/dashboard/Apps/UserApps/UserApps";
import { default as AllocateAssetToUsers } from "./components/dashboard/Assets/AllocateAssets/Allocateusers";
import MainAllocateAsset from "./components/dashboard/Assets/AllocateAssets/MainAllocateAsset";
import MainDeAllocateAsset from "./components/dashboard/Assets/AllocateAssets/MainDeAllocateAsset";
import DeAllocateAssetFromUsers from "./components/dashboard/Assets/AllocateAssets/viewusers";
import MyAssets from "./components/dashboard/Assets/MyAssets/MyAssets";
import UserAssets from "./components/dashboard/Assets/UsersAssets/UsersAssets";
import ConfigureSMTP from "./components/dashboard/ConfigureSMTP/ConfigureSMTP";
import Dashboard from "./components/dashboard/Dashboard/Dashboard";
import Home from "./components/dashboard/Dashboard/Home";
import AssignUsers from "./components/dashboard/Groups/AssignUsers/AssignUsers";
import AssignedUsers from "./components/dashboard/Groups/AssignedUser/AssignedUser";
import AutoAssign from "./components/dashboard/Groups/AutoAssign/AutoAssign";
import GroupsList from "./components/dashboard/Groups/GroupsList/GroupsList";
import Activeusers from "./components/dashboard/LiveSession/ActiveSessions";
import LiveReplayer from "./components/dashboard/LiveSession/LiveReplayer";
import AddConfiguration from "./components/dashboard/NetworkAnalyzer/AddConfiguration/AddConfiguration";
import Configurations from "./components/dashboard/NetworkAnalyzer/Configurations";
import EditConfiguration from "./components/dashboard/NetworkAnalyzer/EditConfiguration/EditConfiguration";
import ScannedHistory from "./components/dashboard/NetworkAnalyzer/ScannedHistory";
import ScannedSystemUsers from "./components/dashboard/NetworkAnalyzer/ScannedSystemUsers";
import DatabasePolicy from "./components/dashboard/Policy/DatabasePolicy/DatabasePolicyList/DatabasePolicy";
import PasswordPolicy from "./components/dashboard/Policy/PasswordPolicy/PasswordPolicy";
import PasswordPolicyAdd from "./components/dashboard/Policy/PasswordPolicy/PasswordPolicyAdd";
import Recordings from "./components/dashboard/Recordings/Recordings";
import AppAccessDetails from "./components/dashboard/Reports/AppAccessDetails/AppAccessDetails";
import AppAccessReport from "./components/dashboard/Reports/AppAccessReport";
import AppsReport from "./components/dashboard/Reports/AppsReport";
import Assets from "./components/dashboard/Reports/Asset";
import AssetAccessDetails from "./components/dashboard/Reports/AssetAccessDetails/AssetAccessDetails";
import AssetAccessReport from "./components/dashboard/Reports/AssetAccessReport";
import MostActiveUser from "./components/dashboard/Reports/MostActiveUserDetail";
import RecentApps from "./components/dashboard/Reports/RecentApps";
import RecentAssets from "./components/dashboard/Reports/RecentAssets";
import RecentWebApps from "./components/dashboard/Reports/RecentWebApps";
import TicketsReport from "./components/dashboard/Reports/TicketReports";
import Users from "./components/dashboard/Reports/User";
import WebAppAccessReport from "./components/dashboard/Reports/WebAppAccessReport";
import WebApps from "./components/dashboard/Reports/WebApps";
import MainAllocateRole from "./components/dashboard/Roles/AllocateRole/MainAllocateRole";
import MainDeAllocateRole from "./components/dashboard/Roles/AllocateRole/MainDeAllocateRole";
import RolesList from "./components/dashboard/Roles/RolesList";
import Sso from "./components/dashboard/SSO/Sso";
import License from "./components/dashboard/Settings/License";
import PasswordVaultConfig from "./components/dashboard/Settings/PasswordVaultConfig";
import ProductSettings from "./components/dashboard/Settings/ProductSettings";
import StorageConfig from "./components/dashboard/Settings/StorageConfiguration";
import TicketSettings from "./components/dashboard/Settings/TicketSettings/TicketSettings";
import MySystemUsers from "./components/dashboard/SystemUsers/MySystemUsers/MySystemUsers";
import MyTickets from "./components/dashboard/Ticketing/MyTickets";
import TicketDetails from "./components/dashboard/Ticketing/TicketDetails";
import EndUserConfigurations from "./components/dashboard/TwoFa/EndUserOptions/EndUserConfigurations";
import GoogleAuthenticator from "./components/dashboard/TwoFa/GoogleAuthenticator/GoogleAuthenticator";
import MFAList from "./components/dashboard/TwoFa/MFAList/MFAList";
import AllocateUserToGroup from "./components/dashboard/Users/AllocateUser/AllocateUserToGroups";
import DeAllocateUserFromGroups from "./components/dashboard/Users/AllocateUser/DeAllocateUserFromGroups";
import MainAllocateUser from "./components/dashboard/Users/AllocateUser/MainAllocateUser";
import MainDeAllocateUser from "./components/dashboard/Users/AllocateUser/MainDeAllocateUser";
import UsersList from "./components/dashboard/Users/UsersList";
import UsersProfile from "./components/dashboard/UsersProfile/usersprofile";
import AllWebApps from "./components/dashboard/WebApps/AllWebApps/AllWebApps";
import MainAllocateWebApp from "./components/dashboard/WebApps/AllocateWebApps/MainAllocateWebApps";
import MainDeAllocateWebApp from "./components/dashboard/WebApps/AllocateWebApps/MainDeAllocateWebApps";
import UserWebApps from "./components/dashboard/WebApps/UserWebApps/UserWebApps";
import Header from "./components/layouts/Header/Header";
import ForgotPassword from "./components/user/ForgotPassword/ForgotPassword";
import ConsumeToken from "./components/user/Login/ConsumeToken";
import Check from "./components/user/Login/check";
import Login from "./components/user/Login/login";
import Register from "./components/user/Login/register";
import ResetPassword from "./components/user/ResetPassword/ResetPassword";
import UpdatePassword from "./components/user/UpdatePassword/UpdatePassword";
import { getLiveStreamInfo, getSessionInfo } from "./state/slices/customer";
import { logoutUser } from "./state/slices/user";
import useWindowDimensions from "./utils/getHeightWidth";
import Ldap from "./components/dashboard/SSO/Ldap";
import TicketsColumnConfig from "./components/dashboard/Settings/TicketSettings/TicketsColumnConfig";
import GeneralButton from "./components/common/SaveButton/GeneralButton";
import AppAllocationReport from "./components/dashboard/Reports/AppAllocationReport";
import AssetAllocationReport from "./components/dashboard/Reports/AssetAllocationReport";
import WebAppAllocationReport from "./components/dashboard/Reports/WebAppsAllocationReport";

function App() {
  const { width, height } = useWindowDimensions();
  const startSessionRecording = useRef(null);
  const startLiveRecording = useRef(null);
  const [CustomUIs, setCustomUIs] = useState([]);
  const [recordToggle, setRecordToggle] = useState([true]);
  const { isAuthenticated } = useSelector((state) => state.user);
  const customer_id = useSelector((state) => state.user?.user?.customer);
  const session_key = useSelector((state) => state.user?.session_key || "");
  const capabilities = useSelector(
    (state) => state.user?.user?.role?.capabilities || {}
  );
  const isCapable = "is_admin_user" in capabilities ? capabilities : false;
  const isCapableEnduser = "is_end_user" in capabilities ? capabilities : false;
  const { recordSession } = useSelector((state) => state.customer);
  const { liveStream } = useSelector((state) => state.customer);
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const ws_endpoint = `${serverURL.replace(
    /http:|https:/gi,
    ws_protocol + ":"
  )}/ws/livesession/${customer_id}/?session_key=${session_key}`;
  const user_id = useSelector((state) => state.user?.user?.id || "");
  let recorder = [];
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  const { oauthUrl } = useSelector((state) => state.customer);
  if (user_id != "") {
    if (window.name === "") {
      const name = uuidv4();
      window.name = name;
    }
  }

  function SessionRecordStop() {
    if (startSessionRecording.current) {
      startSessionRecording.current();
      startSessionRecording.current = null;
      return true;
    }
  }

  function LiveRecordStop() {
    if (startLiveRecording.current) {
      startLiveRecording.current();
      startLiveRecording.current = null;
      return true;
    }
  }
  if (window.location.pathname.split("/")[1] != "livestream") {
    sessionStorage.removeItem("livestreaming");
  }
  useEffect(() => {
    if (
      isAuthenticated == true &&
      liveStream == "true" &&
      recordSession != "" &&
      user_id != "" &&
      session_key != ""
    ) {
      const recording_ws_connection = new WebSocket(ws_endpoint);
      recording_ws_connection.onopen = function (e) {
        recording_ws_connection.send(
          JSON.stringify({
            type: "active_tabs_list",
            message: "get_tab_id",
            session_key: session_key,
          })
        );
      };

      recording_ws_connection.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (
          data.type === "active_tabs_list" &&
          data.message === "get_tab_id" &&
          window.location.pathname.split("/")[1] != "livestream"
        ) {
          const data_message = {
            tab_id: window.name,
            user_id: user_id,
            session_type: "Dashboard",
            session_type_id: "-",
          };
          recording_ws_connection.send(
            JSON.stringify({
              type: "active_users",
              message: data_message,
              session_key: session_key,
            })
          );
        }
        if (
          data.type === "start_recording" &&
          window.name == data.message.tab_id
        ) {
          if (SessionRecordStop()) {
            sessionStorage.setItem("live_recording", "on");
            startLiveRecording.current = record({
              emit: (event) => {
                const data_message = { event: event, tab: window.name };
                recorder.push(event);
                if (recorder.length > 10) {
                  const recording_event_response = sendEventStream({
                    event_stream: recorder,
                    video_id: window.name,
                    session_type: "Dashboard",
                    resource_id: "-",
                    resource_access_id: "-",
                  });
                  recorder = [];
                }
                recording_ws_connection.send(
                  JSON.stringify({
                    type: "recorded_events",
                    message: data_message,
                    session_key: session_key,
                  })
                );
              },
              recordCanvas: true,
              sampling: {
                canvas: 15,
              },
              // optional image format settings
              dataURLOptions: {
                type: "image/webp",
                quality: 0.6,
              },
            });
          } else {
            if (
              sessionStorage.getItem("live_recording") != "on" ||
              LiveRecordStop()
            ) {
              sessionStorage.setItem("live_recording", "on");
              startLiveRecording.current = record({
                emit: (event) => {
                  const data_message = { event: event, tab: window.name };
                  recording_ws_connection.send(
                    JSON.stringify({
                      type: "recorded_events",
                      message: data_message,
                      session_key: session_key,
                    })
                  );
                },
                recordCanvas: true,
                sampling: {
                  canvas: 15,
                },
                // optional image format settings
                dataURLOptions: {
                  type: "image/webp",
                  quality: 0.6,
                },
              });
            } else {
              const data_message = { onlive: data.message.window_name };
              recording_ws_connection.send(
                JSON.stringify({
                  type: "recorded_events",
                  message: data_message,
                  session_key: session_key,
                })
              );
            }
          }
        }
        if (data.type === "stop_recording") {
          sessionStorage.removeItem("live_recording");
          if (LiveRecordStop()) {
            if (
              recordSession === "true" &&
              sessionStorage.getItem("live_recording") != "on" &&
              SessionRecordStop()
            ) {
              startSessionRecording.current = record({
                emit(e) {
                  recorder.push(e);
                  if (recorder.length > 10) {
                    const recording_event_response = sendEventStream({
                      event_stream: recorder,
                      video_id: window.name,
                      session_type: "Dashboard",
                      resource_id: "-",
                      resource_access_id: "-",
                    });
                    recorder = [];
                  }
                },
                recordCanvas: true,
                sampling: {
                  canvas: 15,
                },
                // optional image format settings
                dataURLOptions: {
                  type: "image/webp",
                  quality: 0.6,
                },
              });
            }
          }
        }
        if (data.type === "kill_session" && data.message === window.name) {
          recording_ws_connection.send(
            JSON.stringify({
              type: "remove_row",
              message: window.name,
              session_key: session_key,
            })
          );
          const token = get_jwt_token();
          loginRedirect(token);
          dispatch(logoutUser());
        }
      };
    }
  }, [isAuthenticated, recordSession, user_id, liveStream, session_key]);

  useEffect(() => {
    if (isAuthenticated) {
      getCustomUIs();
    }
  }, [dispatch, count, isAuthenticated]);

  useEffect(() => {
    dispatch(getSessionInfo());
    dispatch(getLiveStreamInfo());
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      recordSession === "true" &&
      sessionStorage.getItem("live_recording") != "on"
    ) {
      startSessionRecording.current = record({
        emit(e) {
          recorder.push(e);
          if (recorder.length > 10) {
            const recording_event_response = sendEventStream({
              event_stream: recorder,
              video_id: window.name,
              session_type: "Dashboard",
              resource_id: "-",
              resource_access_id: "-",
            });
            recorder = [];
          }
        },
        recordCanvas: true,
        sampling: {
          canvas: 15,
        },
        // optional image format settings
        dataURLOptions: {
          type: "image/webp",
          quality: 0.6,
        },
      });
    }
  }, [recordSession, recordToggle]);

  useEffect(() => {
    if (recordSession != "" && liveStream == "true" && session_key != "") {
      if (performance.navigation.type === 1) {
        const recording_ws_connection = new WebSocket(ws_endpoint);

        if (sessionStorage.getItem("live_recording") == "on") {
          recording_ws_connection.onopen = function (e) {
            sessionStorage.setItem("live_recording", "on");
            startLiveRecording.current = record({
              emit: (event) => {
                const data_message = { event: event, tab: window.name };
                if (recordSession === "true") {
                  recorder.push(event);
                  if (recorder.length > 10) {
                    const recording_event_response = sendEventStream({
                      event_stream: recorder,
                      video_id: window.name,
                      session_type: "Dashboard",
                      resource_id: "-",
                      resource_access_id: "-",
                    });
                    recorder = [];
                  }
                }

                recording_ws_connection.send(
                  JSON.stringify({
                    type: "recorded_events",
                    message: data_message,
                    session_key: session_key,
                  })
                );
              },
              recordCanvas: true,
              sampling: {
                canvas: 15,
              },
              // optional image format settings
              dataURLOptions: {
                type: "image/webp",
                quality: 0.6,
              },
            });
          };
        }
      }
    }
  }, [recordSession, session_key]);
  let c = 1;
  const getCustomUIs = async () => {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response11 = await axios.get("/branding/?name=favicon", config);
    setCustomUIs(response11.data);
    ++c;
    setCount(c);
    document.getElementById("favicon").href =
      serverURL.replace("/apps", "") + CustomUIs.logo;
  };

  useEffect(() => {
    const recording_ws_connection = new WebSocket(ws_endpoint);
    recording_ws_connection.onopen = function (e) {
      if (isCapable && isCapable.report.live_session) {
        window.addEventListener("beforeunload", () => {
          recording_ws_connection.send(
            JSON.stringify({
              type: "stop_recording",
              message: "",
              session_key: session_key,
            })
          );
          recording_ws_connection.send(
            JSON.stringify({
              type: "remove_row",
              message: window.name,
              session_key: session_key,
            })
          );
        });
      } else {
        window.addEventListener("beforeunload", () => {
          recording_ws_connection.send(
            JSON.stringify({
              type: "remove_row",
              message: window.name,
              session_key: session_key,
            })
          );
        });
      }
    };
  }, [isCapable, session_key]);
  window.addEventListener("storage", function (event) {
    if (event.key === "jwt") {
      window.location.reload(false);
    }
  });

  return (
    <>
      {isAuthenticated && !sessionStorage.getItem("livestreaming") && (
        <Header />
      )}
      <ToastContainer
        className="toast-container-custom"
        position="top-right"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        {isAuthenticated !== true && (
          <Route path="/token/:access_token" element={<ConsumeToken />} />
        )}
        {isAuthenticated && (
          <Route
            path="/livestream/:tab_id/:username"
            element={<LiveReplayer />}
          />
        )}
        {isAuthenticated === false && (
          <Route path="*" element={<Check />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>
        )}

        {isAuthenticated !== true && (
          <Route path="/forgot-password" element={<ForgotPassword />} />
        )}
        {isAuthenticated !== true && (
          <Route
            path="/reset-password/:reset_token"
            element={<ResetPassword />}
          />
        )}
        {isAuthenticated === false && (
          <Route
            path="/update-password/:update_token"
            element={<UpdatePassword />}
          />
        )}
        {isAuthenticated && (
          <Route path="*" element={<Navigate to="/dashboard" />} />
        )}
        {isAuthenticated && (
          <Route
            path="dashboard"
            element={<Dashboard isAuditDashboard={false} />}
          >
            <Route path="" element={<Home isAuditDashboard={false} />} />
            <Route path="two-fa" element={<GoogleAuthenticator />} />
            {((isCapableEnduser && isCapableEnduser.view_allocated_assets) ||
              (isCapable && isCapable.assets.view_allocated_assets)) && (
              <Route path="myassets" element={<UserAssets />} />
            )}
            {((isCapableEnduser && isCapableEnduser.view_allocated_web_apps) ||
              (isCapable && isCapable.web_apps.view_allocated_web_apps)) && (
              <Route path="myWebApps" element={<UserWebApps />} />
            )}
            {((isCapableEnduser && isCapableEnduser.view_allocated_apps) ||
              (isCapable && isCapable.apps.view_allocated_apps)) && (
              <Route path="myapps" element={<UserApps />} />
            )}
            {((isCapableEnduser && isCapableEnduser.allow_password_change) ||
              (isCapable && isCapable.users.user.change_password)) && (
              <Route path="change-password" element={<ChangePassword />} />
            )}
            <Route path="users-profile" element={<UsersProfile />} />
            {isCapable &&
              isCapable.miscellaneous.setting.network_analyzer_scan && (
                <Route path="scannedhistory" element={<ScannedHistory />} />
              )}
            {isCapable &&
              isCapable.miscellaneous.setting.network_analyzer_scan && (
                <Route
                  path="scannedsystemusers"
                  element={<ScannedSystemUsers />}
                />
              )}
            {isCapable && isCapable.LDAP.view && (
              <Route path="configurations" element={<Configurations />} />
            )}
            {isCapable && isCapable.LDAP.add && (
              <Route
                path="addnetworkconfiguration"
                element={<AddConfiguration />}
              />
            )}
            {isCapable && isCapable.LDAP.edit && (
              <Route
                path="editnetworkconfiguration"
                element={<EditConfiguration />}
              />
            )}
            <Route path="tickets" element={<MyTickets />} />
            <Route path="tickets/:ticket_id" element={<TicketDetails />} />
            {isCapableEnduser && isCapableEnduser.view_allocated_apps && (
              <Route path="recent-apps" element={<RecentApps />} />
            )}
            {isCapableEnduser && isCapableEnduser.view_allocated_assets && (
              <Route path="recent-assets" element={<RecentAssets />} />
            )}
            {isCapableEnduser && isCapableEnduser.view_allocated_web_apps && (
              <Route path="recent-webapps" element={<RecentWebApps />} />
            )}
            {((isCapable && isCapable.MFA.enable_disable_mfa) ||
              (isCapableEnduser && isCapableEnduser.enable_disable_mfa)) && (
              <Route path="mfa/list" element={<MFAList />} />
            )}
            {isCapable && isCapable.MFA.configure_mfa && (
              <Route
                path="mfa/configurations"
                element={<EndUserConfigurations />}
              />
            )}
            {isCapable && isCapable.SSO.enable_disable_sso && (
              <Route path="sso" element={<Sso />} />
            )}
            {isCapable && isCapable.SSO.enable_disable_sso && (
              <Route path="autoAssign" element={<AutoAssign />} />
            )}
            {isCapable && isCapable.SSO.enable_disable_sso && (
              <Route path="ldap" element={<Ldap />} />
            )}
            {isCapable && isCapable.miscellaneous.license.view_license && (
              <Route path="license" element={<License />} />
            )}
            {isCapable && isCapable.apps.view && (
              <Route path="apps" element={<MyApps />} />
            )}
            {isCapable && isCapable.assets.view && (
              <Route path="assets" element={<MyAssets />} />
            )}
            {isCapable && isCapable.web_apps.view && (
              <Route path="web-apps" element={<AllWebApps />} />
            )}
            {isCapable && isCapable.users.user.view === true && (
              <Route
                path="viewusers/:asset_id"
                element={<DeAllocateAssetFromUsers />}
              />
            )}
            {isCapable && isCapable.assets.allocate_assets && (
              <Route
                path="allocateusers/:asset_id"
                element={<AllocateAssetToUsers />}
              />
            )}
            {isCapable && isCapable.assets.deallocate_assets && (
              <Route
                path="viewusers/:app_id"
                element={<DeAllocateAssetFromUsers />}
              />
            )}
            {isCapable && isCapable.apps.allocate_apps && (
              <Route
                path="allocateusers/:app_id"
                element={<AllocateAppToUser />}
              />
            )}
            {isCapable && isCapable.users.roles.view === true && (
              <Route path="roles" element={<RolesList />} />
            )}
            {isCapable && isCapable.users.user.view && (
              <Route path="users/usersList" element={<UsersList />} />
            )}
            {isCapable && isCapable.system_user.view && (
              <Route path="mysystemusers" element={<MySystemUsers />} />
            )}
            {isCapable && isCapable.groups.view && (
              <Route path="groups" element={<GroupsList />} />
            )}
            {isCapable && isCapable.groups.view && (
              <Route
                path="groups/assign-users/:group_id"
                element={<AssignUsers />}
              />
            )}
            {isCapable && isCapable.groups.view && (
              <Route
                path="groups/assigned-users/:group_id"
                element={<AssignedUsers />}
              />
            )}
            {isCapable && isCapable.assets.allocate_assets && (
              <Route
                path="assets/main-allocate/:asset_id"
                element={<MainAllocateAsset />}
              />
            )}
            {isCapable && isCapable.assets.deallocate_assets && (
              <Route
                path="assets/main-deallocate/:asset_id"
                element={<MainDeAllocateAsset />}
              />
            )}
            {isCapable && isCapable.web_apps.allocate_web_apps && (
              <Route
                path="web-apps/main-allocate/:web_app_id"
                element={<MainAllocateWebApp />}
              />
            )}
            {isCapable && isCapable.web_apps.deallocate_web_apps && (
              <Route
                path="web-apps/main-deallocate/:web_app_id"
                element={<MainDeAllocateWebApp />}
              />
            )}
            {isCapable && isCapable.apps.allocate_apps && (
              <Route
                path="apps/main-allocate/:app_id"
                element={<MainAllocateApps />}
              />
            )}
            {isCapable && isCapable.apps.deallocate_apps && (
              <Route
                path="apps/main-deallocate/:app_id"
                element={<MainDeAllocateApps />}
              />
            )}
            {isCapable &&
              isCapable.miscellaneous.setting.show_product_setting && (
                <Route path="productSettings" element={<ProductSettings />} />
              )}
            {isCapable &&
              isCapable.miscellaneous.setting.show_ticket_setting && (
                <Route path="ticket-settings" element={<TicketSettings />} />
              )}
            {isCapable && isCapable.branding.edit_branding_setting && (
              <Route path="Branding" element={<Logo />} />
            )}
            {isCapable && isCapable.branding.manage_email_provider && (
              <Route path="configure-smtp" element={<ConfigureSMTP />} />
            )}
            {isCapable && isCapable.report.active_usage_report && (
              <Route
                path="most-active-user-details/:username"
                element={<MostActiveUser />}
              />
            )}
            {isCapable &&
              isCapable.miscellaneous.setting.configure_external_storage && (
                <Route path="storageconfig" element={<StorageConfig />} />
              )}
            {isCapable &&
              isCapable.miscellaneous.setting
                .configure_vault_configurations && (
                <Route path="vaultconfig" element={<PasswordVaultConfig />} />
              )}
            {isCapable && (isCapable.apps.view || isCapable.assets.view) && (
              <Route
                path="users/usersList/resource-allocate/:user_id"
                element={<MainAllocateUser />}
              />
            )}
            {isCapable && (isCapable.apps.view || isCapable.assets.view) && (
              <Route
                path="users/usersList/resource-deallocate/:user_id"
                element={<MainDeAllocateUser />}
              />
            )}
            {isCapable && isCapable.groups.view && (
              <Route
                path="users/usersList/view-groups/:user_id"
                element={<DeAllocateUserFromGroups />}
              />
            )}
            {isCapable && isCapable.groups.view && (
              <Route
                path="users/usersList/assign-groups/:user_id"
                element={<AllocateUserToGroup />}
              />
            )}
            {isCapable && isCapable.policies.manage_database_policy.view && (
              <Route path="policies/database" element={<DatabasePolicy />} />
            )}
            {isCapable && isCapable.policies.manage_password_policy.view && (
              <Route path="policies/password" element={<PasswordPolicy />} />
            )}
            {isCapable && isCapable.policies.manage_password_policy.view && (
              <Route
                path="policies/password/:password_policy_id"
                element={<PasswordPolicyAdd />}
              />
            )}
            {isCapable &&
              isCapable.users.roles.allocate_deallocate_resources && (
                <Route
                  path="roles/resource-allocate/:role_id"
                  element={<MainAllocateRole />}
                />
              )}
            {isCapable &&
              isCapable.users.roles.allocate_deallocate_resources && (
                <Route
                  path="roles/resource-deallocate/:role_id"
                  element={<MainDeAllocateRole />}
                />
              )}
          </Route>
        )}
        {isAuthenticated && (
          <Route
            path="audit-dashboard"
            element={<Dashboard isAuditDashboard={true} />}
          >
            <Route path="" element={<Home isAuditDashboard={true} />} />
            {isCapable && isCapable.report.assets_report && (
              <Route path="report_assets" element={<Assets />} />
            )}
            {isCapable && isCapable.report.web_apps_report && (
              <Route path="report_web_apps" element={<WebApps />} />
            )}
            {isCapable && isCapable.report.apps_access_report && (
              <Route path="app_access_report" element={<AppAccessReport />} />
            )}
            {isCapable && isCapable.report.assets_access_report && (
              <Route
                path="asset_access_report"
                element={<AssetAccessReport />}
              />
            )}
            {isCapable && isCapable.report.web_apps_access_report && (
              <Route
                path="web_app_access_report"
                element={<WebAppAccessReport />}
              />
            )}
            {isCapable && isCapable.report.apps_report && (
              <Route path="report_apps" element={<AppsReport />} />
            )}
            {/*{isCapable && isCapable.report.active_usage_report && <Route path="active_usage" element={<Sessions />} />}*/}
            {isCapable && isCapable.report.user_login_report && (
              <Route path="report_users" element={<Users />} />
            )}
            {isCapable && isCapable.report.apps_allocation_report && (
              <Route
                path="app_allocation_report"
                element={<AppAllocationReport />}
              />
            )}
            {isCapable && isCapable.report.assets_allocation_report && (
              <Route
                path="asset_allocation_report"
                element={<AssetAllocationReport />}
              />
            )}
            {isCapable && isCapable.report.webapps_allocation_report && (
              <Route
                path="web_app_allocation_report"
                element={<WebAppAllocationReport />}
              />
            )}
            {isCapable && isCapable.report.ticket_report && (
              <Route path="report_tickets" element={<TicketsReport />} />
            )}
            {isCapable && isCapable.report.apps_access_report && (
              <Route
                path="app_access_report/app_access_details/:app_access_id/:app_name/:user_name/:is_recorded"
                element={<AppAccessDetails />}
              />
            )}
            {isCapable && isCapable.report.assets_access_report && (
              <Route
                path="asset_access_report/asset_access_details/:asset_access_id/:asset_name/:user_name/:is_recorded"
                element={<AssetAccessDetails />}
              />
            )}
            {isCapable && isCapable.report.live_session == true && (
              <Route path="live" element={<Activeusers />} />
            )}
            {isCapable && isCapable.report.recordings && (
              <Route path="recordings" element={<Recordings />} />
            )}
            <Route path="users-profile" element={<UsersProfile />} />
            {((isCapableEnduser && isCapableEnduser.allow_password_change) ||
              (isCapable && isCapable.users.user.change_password)) && (
              <Route path="change-password" element={<ChangePassword />} />
            )}
          </Route>
        )}
      </Routes>
    </>
  );
}
export default App;
