import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch, useSelector } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";
import GetCapabilities from "../../../utils/getCapabilities.js";
const App = (props) => {
  const { title } = props;
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("Apps"));
  };
  return (
    <Accordion.Item
      eventKey="Apps"
      className={title === "Apps" ? "sidebar-button-border " : ""}
    >
      <Accordion.Header>
        <img
          className="mx-3 filter-active"
          src={require("./icons/apps.svg").default}
        />
        Apps
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup variant="flush" className="sidebar-list">
          {isCapable.apps.view && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/apps"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">All Apps</div>
              </NavLink>
            </ListGroup.Item>
          )}
          {isCapable.apps.view_allocated_apps && (
            <ListGroup.Item className="py-0 pe-0 ps-2">
              <NavLink
                to="/dashboard/myApps"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={handleTitle}
              >
                <img src={images.DotIcon} alt="dot-icon" width="4px" />
                <div className="ps-4">My Apps</div>
              </NavLink>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default App;
