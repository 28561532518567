import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch } from "react-redux";
import { addRole, getRole, updateRole } from "../../../api/role";
import CapabilitiesIcon from "../../../images/capabilities.svg";
import SearchIcon from "../../../images/search.svg";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../utils/showMessage";
import GeneralButton from "../../common/SaveButton/GeneralButton.js";
import ListCapabilities from "./ListCapabilities.js";
import "./UserCapabilities.scss";
const UsersCapabilities = ({
  isRoleAdded,
  setIsRoleAdded,
  role_Name,
  roleId,
  display_Name,
  capability,
  setOpenCapabilitiesDialog,
  setOpenEditCapabilities,
}) => {
  const SaveButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    background: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });

  const BackButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    background: "white",
    border: "1px solid",
    fontFamily: ['"DM Sans"'].join(","),
  });

  const AddRoleButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 28px",
    lineHeight: "21px",
    background: "#5177FF",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const mainBodyRef = useRef(null);
  const headingRef = useRef(null);
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 190 + "px";
  const [headerHeight, setHeaderHeight] = useState(0);
  const [headingHeight, setHeadingHeight] = useState(0);
  const active_section = "active-section";
  const inactive_section = "inactive-section";

  const getheaderSize = () => {
    if (
      mainBodyRef &&
      mainBodyRef.current &&
      headingRef &&
      headingRef.current
    ) {
      const newHeight = mainBodyRef.current.clientHeight;
      const newHeadingHeight = headingRef.current.clientHeight;
      setHeaderHeight(newHeight);
      setHeadingHeight(newHeadingHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getheaderSize);
  }, []);

  let initial_title = {
    user: null,
    roles: null,
    groups: null,
    system_user: null,
    apps: null,
    policies: null,
    assets: null,
    SSO: null,
    LDAP: null,
    MFA: null,
    report: null,
    branding: null,
    setting: null,
    license: null,
    ticket: null,
    web_apps: null,
    user_setting: null,
    vault_setting: null,
  };

  const titleObject = {
    users: ["Users & Roles", ["user", "roles"]],
    groups: ["Groups", null],
    system_user: ["System users", null],
    apps: ["Apps", null],
    assets: ["Assets", null],
    web_apps: ["Web Apps", null],
    policies: [
      "Policies",
      [
        "manage_database_policy",
        "manage_ssh_policy",
        "manage_rdp_policy",
        "manage_password_policy",
      ],
    ],
    SSO: ["SSO", null],
    LDAP: ["LDAP", null],
    MFA: ["MFA", null],
    report: ["Report", null],
    branding: ["Branding", null],
    miscellaneous: [
      "Miscellaneous",
      ["setting", "license", "ticket", "user_setting", "vault_setting"],
    ],
  };

  const enduserCapabilitiesObject = {
    view_dashboard: "View dashboard",
    view_allocated_apps: "View allocated apps",
    access_apps: "Access apps",
    access_web_apps: "Access web apps",
    view_allocated_assets: "View allocated assets",
    access_assets: "Access assets",
    enable_disable_mfa: "Enable/Disable MFA",
    configure_mfa: "Allow users to configure mfa",
    allow_username_change: "Allow users to change the Username",
    allow_password_change: "Allow users to change the Password",
    allow_email_change: "Allow users to change the Email",
    allow_ticket_view: "Allow users to view tickets",
    view_allocated_web_apps: "View allocated web apps",
  };

  const myCapabilitiesObject = {
    users: {
      user: {
        Title: "Users",
        add: "Add User",
        edit: "Edit User",
        view: "View User",
        delete: "Delete User",
        user_locking: "Lock/unlock users",
        change_password: "Change Password",
        allocate_deallocate_resources: "Allocate/dellocate resource",
      },
      roles: {
        Title: "Roles",
        add: "Add Roles",
        edit: "Edit Roles",
        view: "View Roles",
        delete: "Delete Roles",
        allocate_deallocate_resources: "Allocate/dellocate resources",
      },
    },
    groups: {
      Title: "Groups",
      add: "Add groups",
      edit: "Edit groups",
      view: "View groups",
      delete: "Delete groups",
      assign_users_to_group: "Assign users to groups",
      deassign_users_from_group: "Deassign users from groups",
    },
    system_user: {
      Title: "System User",
      view: "View system user",
      add: "Add system user",
      edit: "Edit system user",
      delete: "Delete system user",
    },
    apps: {
      Title: "Apps",
      view: "View apps",
      add: "Add apps",
      edit: "Edit apps",
      delete: "Delete apps",
      access_app: "Access apps",
      view_allocated_apps: "View allocated apps",
      allocate_apps: "Allocate apps",
      deallocate_apps: "Deallocate apps",
    },
    assets: {
      Title: "Assets",
      view: "View assets",
      add: "Add assets",
      edit: "Edit assets",
      delete: "Delete assets",
      access_assets: "Access assets",
      view_allocated_assets: "View allocated assets",
      allocate_assets: "Allocate assets",
      deallocate_assets: "Deallocate assets",
    },
    web_apps: {
      Title: "WebApps",
      view: "View web apps",
      add: "Add  web apps",
      edit: "Edit web apps",
      delete: "Delete web apps",
      access_web_apps: "Access web apps",
      view_allocated_web_apps: "View allocated web apps",
      allocate_web_apps: "Allocate web apps",
      deallocate_web_apps: "Deallocate web apps",
    },
    SSO: {
      Title: "SSO",
      enable_disable_sso: "Enable/Disable SSO",
      configure_sso: "Configure SSO",
    },
    LDAP: {
      Title: "LDAP",
      view: "View LDAP Configurations",
      add: "Add LDAP Configurations",
      edit: "Edit LDAP Configurations",
      delete: "Delete LDAP Configurations",
      test_connection: "Test LDAP Connection",
    },
    MFA: {
      Title: "MFA",
      enable_disable_mfa: "Enable/Disable MFA",
      configure_mfa: "Configure MFA",
    },
    policies: {
      manage_database_policy: {
        Title: "Manage database policies",
        view: "View database policies",
        add: "Add database policies",
        edit: "Edit database policies",
        delete: "Delete database policies",
      },
      manage_ssh_policy: {
        Title: "Manage SSH policies",
        view: "View SSH policies",
        add: "Add SSH policies",
        edit: "Edit SSH policies",
        delete: "Delete SSH policies",
      },
      manage_rdp_policy: {
        Title: "Manage RDP policies",
        view: "View RDP policies",
        add: "Add RDP policies",
        edit: "Edit RDP policies",
        delete: "Delete RDP policies",
      },
      manage_password_policy: {
        Title: "Manage Password policy",
        view: "View Password policy",
        add: "Add Password policies",
        edit: "Edit Password policy",
        delete: "Delete Password policies",
      },
    },

    report: {
      Title: "Reports",
      show_reports: "Show Reports",
      active_usage_report: "Active Usage Report",
      user_login_report: "User Login Report",
      apps_report: "Apps Report",
      role_report: "Role Report",
      apps_access_report: "Apps Access Report",
      assets_access_report: "Assets Access Report",
      apps_allocation_report: "Apps Allocation Report",
      assets_allocation_report: "Assets Allocation Report",
      webapps_allocation_report: "Web Apps Allocation Report",
      assets_report: "Assets Report",
      live_session: "Live Session",
      recordings: "Recordings",
      ticket_report: "Ticket Report",
    },
    branding: {
      Title: "Branding",
      manage_email_provider: "Manage Email Provider",
      edit_branding_setting: "Edit Branding Settings",
    },
    miscellaneous: {
      setting: {
        Title: "Settings",
        show_searchbar: "Show Searchbar",
        show_settings: "Show Settings",
        show_product_setting: "Show Product Settings",
        show_ticket_setting: "Show Ticket Settings",
        edit_user_locking: "Edit User Locking Settings",
        edit_app_expiry: "Edit App Expiry Settings",
        edit_asset_expiry: "Edit Asset Expiry Settings",
        admin_dashboard: "Admin Dashboard",
        audit_dashboard: "Audit Dashboard",
        kill_session: "Kill Session",
        enable_disable_recording: "Enable/Disable Recording",
        enable_disable_live_stream: "Enable/Disable Live Streaming",
        configure_external_storage: "Configure External Storage",
        app_timeout: "Configure App Timeout",
        asset_timeout: "Configure Asset Timeout",
        enable_mfa: "Configure MFA Enable",
        network_analyzer_scan: "Network Scanning and importing devices",
      },
      user_setting: {
        Title: "User setting",
        edit_user_locking: "Edit User Locking Settings",
      },
      vault_setting: {
        Title: "Vault setting",
        view_vault_setting: "View/Update Vault Settings",
      },
      license: {
        Title: "License",
        view_license: "View License",
        update_license: "Update License",
      },
      ticket: {
        Title: "Ticket",
        view_ticket: "View Tickets",
        add_ticket_column: "Add Ticket Column",
      },
    },
  };

  const dependencyArray = [
    [
      [["users", "user", "add", true, "Dependent on - View roles"]],
      ["users", "roles", "view", true, "Dependent on - Add users"],
    ],
    [
      [
        ["LDAP", "edit", true, "Dependent on - view LDAP Configurations"],
        ["LDAP", "delete", true, "Dependent on - view LDAP Configurations"],
      ],
      ["LDAP", "view", true, "Dependent on - edit, delete LDAP Configurations"],
    ],
    [
      [
        ["web_apps", "add", true, "Dependent on - view system users"],
        ["assets", "add", true, "Dependent on - view system users"],
        ["apps", "add", true, "Dependent on - view system users"],
        ["assets", "edit", true, "Dependent on - view system users"],
        ["apps", "edit", true, "Dependent on - view system users"],
        ["assets", "view", true, "Dependent on - view system users"],
        ["apps", "view", true, "Dependent on - view system users"],
      ],
      [
        "system_user",
        "view",
        true,
        "Dependent on - add, edit, view  apps and assets",
      ],
    ],
    [
      [
        ["users", "user", "edit", true, "Dependent on - view users"],
        ["users", "user", "add", true, "Dependent on - view users"],
        ["users", "user", "delete", true, "Dependent on - view users"],
        ["groups", "add", true, "Dependent on - view users"],
      ],
      [
        "users",
        "user",
        "view",
        true,
        "Dependent on - edit, delete & add users, add groups",
      ],
    ],
    [
      [
        [
          "miscellaneous",
          "license",
          "view_license",
          true,
          "Dependent on - show settings",
        ],
      ],
      [
        "miscellaneous",
        "setting",
        "show_settings",
        true,
        "Dependent on - view license ",
      ],
    ],
    [
      [
        ["groups", "add", true, "Dependent on - view users, view groups"],
        ["groups", "edit", true, "Dependent on - view groups"],
        ["groups", "delete", true, "Dependent on - view groups"],
      ],
      ["groups", "view", true, "Dependent on - edit, delete & add groups"],
    ],
  ];

  const dispatch = useDispatch();
  const [roleName, setRoleName] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [capabilities, setCapabilities] = useState(null);
  const [selectedEndUser, setSelectedEndUser] = useState(null);
  const [defaultCapabilities, setDefaultCapabilities] = useState(null);
  const [active, setActive] = useState("User");
  const [capabilitiesTitle, setCapabilitiesTitle] = useState(initial_title);
  const [keySearching, setKeySearching] = useState("");
  const [filteredCapabilities, setFilteredCapabilities] =
    useState(myCapabilitiesObject);
  const [filteredEnduserCapabilities, setFilteredEnduserCapabilities] =
    useState(enduserCapabilitiesObject);
  const [filteredTitleObject, setFilteredTitleObject] = useState(null);
  const getCapabilityData = async () => {
    if (capability !== undefined && "is_admin_user" in capability) {
      setCapabilities(capability);
      setDefaultCapabilities(capability);
      checkSelected(capability, "users", "user");
      checkSelected(capability, "users", "roles");
    } else if (capability !== undefined && "is_end_user" in capability) {
      setCapabilities(capability);
      setDefaultCapabilities(capability);
      checkSelected(capability);
    }
  };

  const getRoleData = async (role_id) => {
    const { data, error } = await getRole({ role_id: role_id });
    if (data !== null && "is_admin_user" in data.capabilities) {
      setRoleName(data.role_name);
      setDisplayName(data.display_name);
      setCapabilities(data.capabilities);
      setDefaultCapabilities(data.capabilities);
      checkSelected(data.capabilities, "users", "user");
      checkSelected(data.capabilities, "users", "roles");
    } else if (data !== null && "is_end_user" in data.capabilities) {
      setRoleName(data.role_name);
      setDisplayName(data.display_name);
      setCapabilities(data.capabilities);
      setDefaultCapabilities(data.capabilities);
      checkSelected(data.capabilities);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const addRoleFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { data, error } = await addRole({
        role_name: role_Name,
        display_name: display_Name,
        capabilities: capabilities,
      });
      if (data !== null) {
        setIsRoleAdded(!isRoleAdded);
        showSuccess(data.message);
        setOpenCapabilitiesDialog(false);
      }
      if (error !== null) {
        showError(error);
      }
    }
  };

  const editRole = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { data, error } = await updateRole({
        role_id: roleId,
        role_name: roleName,
        display_name: displayName,
        capabilities: capabilities,
      });
      if (data !== null) {
        showSuccess(data.message);
      }
      if (error !== null) {
        showError(error);
      }
    }
  };

  const handleActive = async (active_element) => {
    setActive(active_element);
  };

  const handleResetToDefault = (level1, level2) => {
    let temp_capabilities = capabilities;
    if (level1 === null || level1 === undefined) {
      for (let key in temp_capabilities) {
        temp_capabilities = {
          ...temp_capabilities,
          [key]: defaultCapabilities[key],
        };
        setCapabilities(temp_capabilities);
      }
      checkSelected(temp_capabilities);
    } else if (level2 === null || level2 === undefined) {
      for (let key in temp_capabilities[level1]) {
        temp_capabilities = {
          ...temp_capabilities,
          [level1]: {
            ...temp_capabilities[level1],
            [key]: defaultCapabilities[level1][key],
          },
        };
        setCapabilities(temp_capabilities);
      }
      checkSelected(temp_capabilities, level1);
    } else {
      for (let capability in level2) {
        for (let key in temp_capabilities[level1][level2[capability]]) {
          temp_capabilities = {
            ...temp_capabilities,
            [level1]: {
              ...temp_capabilities[level1],
              [level2[capability]]: {
                ...temp_capabilities[level1][level2[capability]],
                [key]: defaultCapabilities[level1][level2[capability]][key],
              },
            },
          };
          setCapabilities(temp_capabilities);
        }
        checkSelected(temp_capabilities, level1, level2[capability]);
      }
    }
    if ("is_admin_user" in temp_capabilities) {
      handleDepend(temp_capabilities);
    }
  };

  const handleAllSelected = (level1, level2) => {
    let temp_capabilities = capabilities;
    if (level2 === null || level2 === undefined) {
      for (let key in temp_capabilities[level1]) {
        if (key in filteredCapabilities[level1]) {
          temp_capabilities = {
            ...temp_capabilities,
            [level1]: {
              ...temp_capabilities[level1],
              [key]: !capabilitiesTitle[level1],
            },
          };
        }
      }
      if (keySearching !== "") {
        checkSelectedFiltered(
          temp_capabilities,
          level1,
          null,
          filteredCapabilities
        );
      } else {
        checkSelected(temp_capabilities, level1);
      }
    } else {
      for (let key in temp_capabilities[level1][level2]) {
        if (key in filteredCapabilities[level1][level2]) {
          temp_capabilities = {
            ...temp_capabilities,
            [level1]: {
              ...temp_capabilities[level1],
              [level2]: {
                ...temp_capabilities[level1][level2],
                [key]: !capabilitiesTitle[level2],
              },
            },
          };
        }
      }
      if (keySearching !== "") {
        checkSelectedFiltered(
          temp_capabilities,
          level1,
          level2,
          filteredCapabilities
        );
      } else {
        checkSelected(temp_capabilities, level1, level2);
      }
    }
    setCapabilities(temp_capabilities);
    if ("is_admin_user" in temp_capabilities) {
      handleDepend(temp_capabilities);
    }
  };

  const handleSelectAll = (level1, level2, state) => {
    let temp_capabilities = capabilities;
    if (level1 === null || level1 === undefined) {
      for (let key in temp_capabilities) {
        temp_capabilities = {
          ...temp_capabilities,
          [key]: state,
        };
        setCapabilities(temp_capabilities);
      }
      checkSelected(temp_capabilities);
    } else if (level2 === null || level2 === undefined) {
      for (let key in temp_capabilities[level1]) {
        temp_capabilities = {
          ...temp_capabilities,
          [level1]: { ...temp_capabilities[level1], [key]: state },
        };
        setCapabilities(temp_capabilities);
      }
      checkSelected(temp_capabilities, level1);
    } else {
      for (let capability in level2) {
        for (let key in temp_capabilities[level1][level2[capability]]) {
          temp_capabilities = {
            ...temp_capabilities,
            [level1]: {
              ...temp_capabilities[level1],
              [level2[capability]]: {
                ...temp_capabilities[level1][level2[capability]],
                [key]: state,
              },
            },
          };
          setCapabilities(temp_capabilities);
        }
        checkSelected(temp_capabilities, level1, level2[capability]);
      }
    }
    if ("is_admin_user" in temp_capabilities) {
      handleDepend(temp_capabilities);
    }
  };

  const checkSelected = (capabilities, level1, level2) => {
    let result = true;
    if (level2 === undefined || level2 === null) {
      for (let key in capabilities[level1]) {
        if (capabilities[level1][key] === false) {
          result = false;
        }
      }
      setCapabilitiesTitle((capabilitiesTitle) => ({
        ...capabilitiesTitle,
        [level1]: result,
      }));
    } else if (level1 === undefined || level1 === null) {
      for (let key in capabilities) {
        if (capabilities[key] === false) {
          result = false;
        }
      }
      setSelectedEndUser(result);
    } else {
      for (let key in capabilities[level1][level2]) {
        if (capabilities[level1][level2][key] === false) {
          result = false;
        }
      }
      setCapabilitiesTitle((capabilitiesTitle) => ({
        ...capabilitiesTitle,
        [level2]: result,
      }));
    }
  };

  const checkSelectedFiltered = (
    capabilities,
    level1,
    level2,
    filteredCapabilities1
  ) => {
    let result = true;
    if (level2 === undefined || level2 === null) {
      for (let key in filteredCapabilities1[level1]) {
        if (capabilities[level1][key] === false) {
          result = false;
        }
      }
      setCapabilitiesTitle((capabilitiesTitle) => ({
        ...capabilitiesTitle,
        [level1]: result,
      }));
    } else if (level1 === undefined || level1 === null) {
      for (let key in filteredCapabilities1) {
        if (capabilities[key] === false) {
          result = false;
        }
      }
      setSelectedEndUser(result);
    } else {
      for (let key in filteredCapabilities1[level1][level2]) {
        if (capabilities[level1][level2][key] === false) {
          result = false;
        }
      }
      setCapabilitiesTitle((capabilitiesTitle) => ({
        ...capabilitiesTitle,
        [level2]: result,
      }));
    }
  };

  const handleActiveStateChange = (
    current_capabilities,
    capability_type,
    level1,
    level2
  ) => {
    let temp_capabilities = {};
    if (level1 === null || level1 === undefined) {
      temp_capabilities = {
        ...current_capabilities,
        [capability_type]: !current_capabilities[capability_type],
      };
      checkSelected(temp_capabilities);
    } else if (level2 === null || level2 === undefined) {
      temp_capabilities = {
        ...current_capabilities,
        [capability_type]: {
          ...current_capabilities[capability_type],
          [level1]: !current_capabilities[capability_type][level1],
        },
      };
      if (keySearching !== "") {
        checkSelectedFiltered(
          temp_capabilities,
          capability_type,
          null,
          filteredCapabilities
        );
      } else {
        checkSelected(temp_capabilities, capability_type);
      }
    } else {
      temp_capabilities = {
        ...current_capabilities,
        [capability_type]: {
          ...current_capabilities[capability_type],
          [level1]: {
            ...current_capabilities[capability_type][level1],
            [level2]: !current_capabilities[capability_type][level1][level2],
          },
        },
      };
      if (keySearching !== "") {
        checkSelectedFiltered(
          temp_capabilities,
          capability_type,
          level1,
          filteredCapabilities
        );
      } else {
        checkSelected(temp_capabilities, capability_type, level1);
      }
    }
    setCapabilities(temp_capabilities);
    if ("is_admin_user" in temp_capabilities) {
      handleDepend(temp_capabilities);
    }
  };

  const get_value = (arr, temp_capabilities) => {
    if (arr.length === 5) {
      return temp_capabilities[arr[0]][arr[1]][arr[2]] === arr[3];
    } else {
      return temp_capabilities[arr[0]][arr[1]] === arr[2];
    }
  };
  const handleDepend = (temp_capabilities) => {
    for (let index in dependencyArray) {
      for (let i in dependencyArray[index][0]) {
        if (
          get_value(dependencyArray[index][0][i], temp_capabilities) === true &&
          get_value(dependencyArray[index][1], temp_capabilities) === false
        ) {
          if (dependencyArray[index][1].length === 5)
            handleActiveStateChange(
              temp_capabilities,
              dependencyArray[index][1][0],
              dependencyArray[index][1][1],
              dependencyArray[index][1][2]
            );
          else {
            handleActiveStateChange(
              temp_capabilities,
              dependencyArray[index][1][0],
              dependencyArray[index][1][1],
              null
            );
          }
        }
      }
    }
  };

  const searchCapability = (event, obj) => {
    let tempCapabilities = searchCapabilityObject(event, obj);
    let temptitleObject = {};
    for (let prop in tempCapabilities) {
      if (titleObject[prop][1] !== null) {
        titleObject[prop][1].map((list, index) =>
          checkSelectedFiltered(
            capabilities,
            prop,
            [titleObject[prop][1][index]],
            tempCapabilities
          )
        );
      } else {
        checkSelectedFiltered(capabilities, prop, null, tempCapabilities);
      }
    }
    Object.keys(tempCapabilities).map(
      (list) =>
        list in titleObject && (temptitleObject[list] = titleObject[list])
    );
    setFilteredCapabilities(tempCapabilities);
    setFilteredTitleObject(temptitleObject);
    setKeySearching(event);
  };

  const searchCapabilityObject = (event, obj) => {
    const filteredObj = {};

    for (let prop in obj) {
      if (typeof obj[prop] === "object" && obj[prop] !== null) {
        const nestedObj = searchCapabilityObject(event, obj[prop]);
        if (Object.keys(nestedObj).length > 0) {
          filteredObj[prop] = nestedObj;
        }
      } else {
        const propValue = String(obj[prop]).toLowerCase();
        if (propValue.includes(event.toLowerCase())) {
          filteredObj[prop] = obj[prop];
          if (!("Title" in filteredObj)) {
            filteredObj["Title"] = obj["Title"];
          }
        }
      }
    }
    return filteredObj;
  };

  const searchEnduserCapability = (event, capabilityObjecct) => {
    setKeySearching(event);
    let tempCapabilities = {};
    for (let props in capabilityObjecct) {
      if (props.toLowerCase().includes(event.toLowerCase())) {
        tempCapabilities[props] = capabilityObjecct[props];
      }
    }
    setFilteredEnduserCapabilities(tempCapabilities);
  };

  useEffect(() => {
    getheaderSize();
    dispatch(changeTitle("User"));
    {
      roleId && getRoleData(roleId);
    }
    {
      role_Name && getCapabilityData();
    }
  }, []);
  return (
    <div>
      <div ref={mainBodyRef}>
        <div className="heading_title px-3 p-0">
          <div className="row d-flex justify-content-between align-items-center ">
            <div className="col-md-4 col-sm-12 d-flex align-items-center">
              <Image src={CapabilitiesIcon}></Image>
              <h2 className="main-heading d-inline ">Capabilities</h2>
            </div>
            <div className="col-md-8 col-sm-12 d-flex justify-content-end">
              <Row className="pt-3 mb-3 me-2 justify-content-end">
                <Col sm="auto">
                  <GeneralButton
                    onClickEvent={() => {
                      setIsRoleAdded(!isRoleAdded);
                      setOpenCapabilitiesDialog(false);
                      setOpenEditCapabilities(false);
                    }}
                    className="me-1"
                    value="Back"
                    variant="outlined"
                    size="large"
                  />
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {role_Name && (
                    <GeneralButton
                      onClickEvent={addRoleFormHandler}
                      className="me-1"
                      value="+ Add Role"
                      variant="contained"
                      size="large"
                    />
                  )}
                  {roleId && (
                    <GeneralButton
                      onClickEvent={editRole}
                      className="me-1"
                      value="Save"
                      variant="contained"
                      size="large"
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content_container p-3 mx-auto w-100">
        <div
          className="position-relative bg-white"
          style={{ height: `${datatable_height}` }}
        >
          <div
            ref={headingRef}
            className="heading_title bg-white px-5 border-top-0 p-0"
          >
            <div className="ff-poppins pt-3 pb-0">
              <p className="fs-20px fw-600 header-text mb-1">
                Edit Capabilities : {roleId && roleName}{" "}
                {role_Name && role_Name}
              </p>
              <p className="fs-14px header-text mb-2">
                Define the access level for the role.
              </p>
            </div>
            <div className="row mb-2">
              <div className="col-md-4 d-flex align-items-center my-2">
                <InputGroup className=" border border-primary rounded-1 search_capabilities ">
                  <InputGroup.Text
                    id="basic-addon1"
                    className="bg-searchBox border-0"
                  >
                    <img src={SearchIcon} alt="icon" id="center-blueShadow" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-0 bg-searchBox"
                    placeholder="search for a capability"
                    aria-label="search"
                    aria-describedby="basic-addon1"
                    onChange={(event) =>
                      capabilities && "is_admin_user" in capabilities
                        ? searchCapability(
                            event.target.value,
                            myCapabilitiesObject
                          )
                        : searchEnduserCapability(
                            event.target.value,
                            enduserCapabilitiesObject
                          )
                    }
                  />
                </InputGroup>
              </div>
            </div>
          </div>
          {((capability && "is_admin_user" in capability) ||
            (roleId && capabilities && "is_admin_user" in capabilities)) &&
            keySearching === "" && (
              <div
                className="row py-2"
                style={{
                  height:
                    String(height - headerHeight - headingHeight - 130) + "px",
                }}
              >
                <div className="col-md-3 h-100 overflow-auto border-end pe-0 ps-5 pb-4">
                  <ListGroup className="capabilities-list cursor_pointer">
                    <ListGroup.Item
                      className={
                        active === "User" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("User");
                        checkSelected(capabilities, "users", "user");
                        checkSelected(capabilities, "users", "roles");
                      }}
                    >
                      Users & roles
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Groups" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("Groups");
                        checkSelected(capabilities, "groups");
                      }}
                    >
                      Groups
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Sys_user"
                          ? active_section
                          : inactive_section
                      }
                      onClick={function () {
                        handleActive("Sys_user");
                        checkSelected(capabilities, "system_user");
                      }}
                    >
                      System User
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Policies"
                          ? active_section
                          : inactive_section
                      }
                      onClick={function () {
                        handleActive("Policies");
                        checkSelected(
                          capabilities,
                          "policies",
                          "manage_database_policy"
                        );
                        checkSelected(
                          capabilities,
                          "policies",
                          "manage_ssh_policy"
                        );
                        checkSelected(
                          capabilities,
                          "policies",
                          "manage_password_policy"
                        );
                        checkSelected(
                          capabilities,
                          "policies",
                          "manage_rdp_policy"
                        );
                        checkSelected(
                          capabilities,
                          "policies",
                          "manage_password_policy"
                        );
                      }}
                    >
                      Policies
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Apps" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("Apps");
                        checkSelected(capabilities, "apps");
                      }}
                    >
                      Apps
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Assets" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("Assets");
                        checkSelected(capabilities, "assets");
                      }}
                    >
                      Assets
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Web Apps"
                          ? active_section
                          : inactive_section
                      }
                      onClick={function () {
                        handleActive("Web Apps");
                        checkSelected(capabilities, "web_apps");
                      }}
                    >
                      Web Apps
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "SSO" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("SSO");
                        checkSelected(capabilities, "SSO");
                      }}
                    >
                      SSO
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "LDAP" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("LDAP");
                        checkSelected(capabilities, "LDAP");
                      }}
                    >
                      LDAP
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "MFA" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("MFA");
                        checkSelected(capabilities, "MFA");
                      }}
                    >
                      MFA
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Report" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("Report");
                        checkSelected(capabilities, "report");
                      }}
                    >
                      Report
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Branding"
                          ? active_section
                          : inactive_section
                      }
                      onClick={function () {
                        handleActive("Branding");
                        checkSelected(capabilities, "branding");
                      }}
                    >
                      Branding & Customization
                    </ListGroup.Item>
                    <ListGroup.Item
                      className={
                        active === "Misc" ? active_section : inactive_section
                      }
                      onClick={function () {
                        handleActive("Misc");
                        checkSelected(capabilities, "miscellaneous", "setting");
                        checkSelected(capabilities, "miscellaneous", "license");
                        checkSelected(capabilities, "miscellaneous", "ticket");
                        checkSelected(
                          capabilities,
                          "miscellaneous",
                          "user_setting"
                        );
                        checkSelected(
                          capabilities,
                          "miscellaneous",
                          "vault_setting"
                        );
                      }}
                    >
                      Miscellaneous
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                {capabilities && active === "User" && (
                  <ListCapabilities
                    capability_name={"users"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={titleObject["users"][1]}
                    title={titleObject["users"][0]}
                    dependencyArray={dependencyArray}
                  />
                )}
                {active === "Groups" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"groups"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Groups"}
                  />
                )}
                {active === "Sys_user" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"system_user"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"System User"}
                  />
                )}
                {active === "Policies" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"policies"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={titleObject["policies"][1]}
                    title={titleObject["policies"][0]}
                  />
                )}
                {active === "Apps" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"apps"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Apps"}
                  />
                )}
                {active === "Assets" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"assets"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Assets"}
                  />
                )}
                {active === "Web Apps" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"web_apps"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Web Apps"}
                  />
                )}
                {active === "SSO" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"SSO"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"SSO"}
                  />
                )}
                {active === "LDAP" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"LDAP"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"LDAP"}
                  />
                )}
                {active === "MFA" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"MFA"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"MFA"}
                  />
                )}
                {active === "Report" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"report"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Reports"}
                  />
                )}
                {active === "Branding" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"branding"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={null}
                    title={"Branding & Customization"}
                  />
                )}
                {active === "Misc" && (
                  <ListCapabilities
                    dependencyArray={dependencyArray}
                    capability_name={"miscellaneous"}
                    myCapabilitiesObject={myCapabilitiesObject}
                    handleSelectAll={handleSelectAll}
                    handleResetToDefault={handleResetToDefault}
                    handleAllSelected={handleAllSelected}
                    handleActiveStateChange={handleActiveStateChange}
                    capabilitiesTitle={capabilitiesTitle}
                    capabilities={capabilities}
                    selectionArray={[
                      "setting",
                      "license",
                      "ticket",
                      "user_setting",
                      "vault_setting",
                    ]}
                    title={"Miscellaneous"}
                  />
                )}
              </div>
            )}
          {((capability && capabilities && !("is_admin_user" in capability)) ||
            (roleId && capabilities && "is_end_user" in capabilities)) &&
            (Object.keys(filteredEnduserCapabilities).length !== 0 ? (
              <div>
                <div className="px-5">
                  <div className="d-flex justify-content-between">
                    <div className="ff-poppins fw-600 fs-18px lh-35px">
                      End user
                    </div>
                    {keySearching === "" && (
                      <div className=" pe-4">
                        <GeneralButton
                          className="me-2 fs-10px"
                          value="select all"
                          variant="outlined"
                          size="small"
                          onClickEvent={() => handleSelectAll(null, null, true)}
                        />
                        <GeneralButton
                          className="me-2 fs-10px"
                          value="clear all"
                          variant="outlined"
                          size="small"
                          onClickEvent={() =>
                            handleSelectAll(null, null, false)
                          }
                        />
                        <GeneralButton
                          className="me-1 fs-10px"
                          value="reset to default"
                          variant="outlined"
                          size="small"
                          onClickEvent={() => handleResetToDefault()}
                        />
                      </div>
                    )}
                  </div>
                  <div className="ff-poppins fs-12px capability-sub-title">
                    Please select the capabilities you require for this role
                  </div>
                </div>
                <div
                  className="row py-2 px-2"
                  style={{ height: String(height - headerHeight - 100) + "px" }}
                >
                  <div className="col-md-6 pe-0 ps-5 pb-4">
                    {Object.keys(filteredEnduserCapabilities).map(
                      (mylist, index) =>
                        index <
                          Object.keys(filteredEnduserCapabilities).length /
                            2 && (
                          <div className="d-flex align-content-center my-2">
                            <input
                              className="form-check-input ms-2"
                              type="checkbox"
                              value=""
                              checked={capabilities[mylist]}
                              onChange={() =>
                                handleActiveStateChange(capabilities, mylist)
                              }
                            />
                            <p className="m-0 ps-2">
                              {filteredEnduserCapabilities[mylist]}
                            </p>
                          </div>
                        )
                    )}
                  </div>
                  <div className="col-md-6 pe-0 ps-5 pb-4">
                    {Object.keys(filteredEnduserCapabilities).map(
                      (mylist, index) =>
                        index >=
                          Object.keys(filteredEnduserCapabilities).length /
                            2 && (
                          <div className="d-flex align-content-center my-2">
                            <input
                              className="form-check-input ms-2"
                              type="checkbox"
                              value=""
                              checked={capabilities[mylist]}
                              onChange={() =>
                                handleActiveStateChange(capabilities, mylist)
                              }
                            />
                            <p className="m-0 ps-2">
                              {filteredEnduserCapabilities[mylist]}
                            </p>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-5 text-center ff-poppins">
                Unfortunately, no matching results found.
              </div>
            ))}

          {keySearching !== "" &&
            capabilities &&
            "is_admin_user" in capabilities &&
            filteredCapabilities &&
            filteredTitleObject && (
              <div
                className="overflow-auto"
                style={{
                  height:
                    String(height - headerHeight - headingHeight - 130) + "px",
                }}
              >
                {Object.keys(filteredTitleObject).map((mylist, index) => (
                  <div className="px-5">
                    <ListCapabilities
                      dependencyArray={dependencyArray}
                      capability_name={mylist}
                      myCapabilitiesObject={filteredCapabilities}
                      isSearching={true}
                      handleSelectAll={handleSelectAll}
                      handleResetToDefault={handleResetToDefault}
                      handleAllSelected={handleAllSelected}
                      handleActiveStateChange={handleActiveStateChange}
                      capabilitiesTitle={capabilitiesTitle}
                      capabilities={capabilities}
                      checkSelected={checkSelected}
                      selectionArray={filteredTitleObject[mylist][1]}
                      title={filteredTitleObject[mylist][0]}
                    />
                    {index < Object.keys(filteredTitleObject).length - 1 && (
                      <hr className="search-border-bottom" />
                    )}
                  </div>
                ))}
              </div>
            )}
          {keySearching !== "" &&
            Object.keys(filteredCapabilities).length === 0 && (
              <div className="p-5 text-center ff-poppins">
                Unfortunately, no matching results found.
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default UsersCapabilities;
