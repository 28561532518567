import { Fade, Slide } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import { FcLock } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userPasswordChange } from "../../../api/users";
import { getPasswordPolicy } from "../../../api/vault";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import {
  showEmptyError,
  showError,
  showSuccess,
} from "../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import LightTooltip from "../../common/CustomTooltip/CustomTooltip";
import {
  passwordPolicyRegex,
  showPasswordInvalidTooltip,
} from "../../common/PasswordPolicy/PolicyRegex";
import GeneralButton from "../../common/SaveButton/GeneralButton";
import image from "../../../utils/images";
import "./ChangePassword.scss";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 190 + "px";
  const [currentpassword, setCurrentpassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [policy, setPolicy] = useState(null);
  const id = useSelector((state) => state.user.user.id);
  const email_passcode = useSelector((state) => state.user.user.email);
  const username_passcode = useSelector((state) => state.user.user.first_name);
  const firstname_passcode = useSelector((state) => state.user.user.first_name);
  const lastname_passcode = useSelector((state) => state.user.user.last_name);
  const [validated, setValidated] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const getPassPolicy = async () => {
    const { data, error } = await getPasswordPolicy("pam_user");
    if (data !== null) {
      setPolicy(data.policies[0]);
    }
    if (error !== null) {
      showError(error);
    }
  };

  useEffect(() => {
    getPassPolicy();
    dispatch(changeTitle("dashboard"));
    setSlideChecked(true);
  }, []);

  const changePassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      let formField = new FormData();
      formField.append("currentpassword", currentpassword);
      formField.append("id", id);
      formField.append("password", password);
      formField.append("confirmpassword", confirmpassword);
      const { data, error } = await userPasswordChange(formField);
      if (data !== null) {
        showSuccess(data.message);
        navigate("/dashboard");
      }
      if (error !== null && error.data !== null && error.data.message !== null) {
        showEmptyError(error.data.message);
      }
      if (error !== null && error.data !== null && error.data.errors !== null) {
        showError(error.data.errors);
      }
    }
  };
  const handleClick = () => {
    setConfirmpassword("");
    setCurrentpassword("");
    setpassword("");
  };
  return (
    <>
      <div className="heading_datable bg-white">
        <span
          style={{ color: "#50514F" }}
          className="ff-poppins fs-24px fw-600"
        >
          <div className="d-flex align-items-center">
            <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
              <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                <FcLock id="passwordicon2"></FcLock> &nbsp; Change Password
              </span>
              <br />
            </div>
          </div>
        </span>
      </div>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white p-4 overflow-auto"
                  style={{ height: `${datatable_height}` }}
                >
                  <div className="p-3 pb-2 w-100" id="passwordp4">
                    <i id="passwordnote">
                      <b>Note &nbsp;: &nbsp;</b> For Setting Password, follow
                      default password constraints:
                      {policy && (
                        <ul>
                          <li>
                            Password must be at least{" "}
                            {policy.min_password_length} characters and not more
                            than {policy.max_password_length} characters
                          </li>
                          {policy.require_uppercase_letter && (
                            <li>Must include at least one upper case letter</li>
                          )}
                          {policy.require_lowercase_letter && (
                            <li>Must include at least one lower case letter</li>
                          )}
                          {policy.require_number && (
                            <li>Must include at least one number</li>
                          )}
                          {policy.require_special_symbols && (
                            <li>
                              Must include at least one special character from
                              this list: {policy.password_special_symbols}
                            </li>
                          )}
                          {!policy.can_contain_email_or_part && (
                            <li>
                              Password can not contain email or parts of it.
                            </li>
                          )}
                          {!policy.can_contain_firstname_or_part && (
                            <li>Password can not contain first name.</li>
                          )}
                          {!policy.can_contain_lastname_or_part && (
                            <li>Password can not contain last name.</li>
                          )}
                          {!policy.can_contain_username_or_part && (
                            <li>Password can not contain username.</li>
                          )}
                        </ul>
                      )}
                    </i>
                  </div>
                  <Form
                    id="passwordform"
                    noValidate
                    validated={validated}
                    onSubmit={changePassword}
                  >
                    <Row id="passwordrow1">
                      <Col md="6">
                        <Form.Group className="p-1">
                          <Form.Label className="sso-form-label">
                            Current Password{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <InputGroup
                            className="password_input_group"
                            controlId="showPasswordNew"
                          >
                            <Form.Control
                              className="user_input_field password_fields border-end-0"
                              type={
                                showPassword === false ? "password" : "text"
                              }
                              required
                              placeholder="Current password"
                              value={currentpassword}
                              onChange={(e) =>
                                setCurrentpassword(e.target.value)
                              }
                            />
                            <InputGroup.Text
                              id="showPasswordNew"
                              className={"bg-white eye_icon_component"}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword === false ? (
                                <Image
                                  src={image.ShowEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              ) : (
                                <Image
                                  src={image.HideEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              )}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please enter Password.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row id="passwordrow2">
                      <Col md="6">
                        <Form.Group className="mb-3 new_password_control">
                          <Form.Label id="passwordlabel1">
                            New Password <span className="text-danger">*</span>
                          </Form.Label>
                          <InputGroup
                            className="password_input_group"
                            controlId="passwordNew"
                          >
                            <Form.Control
                              type={
                                showNewPassword === false ? "password" : "text"
                              }
                              className="user_input_field password_fields border-end-0"
                              required
                              isInvalid={passwordIsInvalid}
                              placeholder="New Password"
                              value={password}
                              onChange={(e) => {
                                setpassword(e.target.value);
                                const regex = new RegExp(
                                  passwordPolicyRegex(
                                    policy,
                                    email_passcode,
                                    username_passcode,
                                    firstname_passcode,
                                    lastname_passcode
                                  )
                                );
                                if (regex.test(e.target.value)) {
                                  setPasswordIsInvalid(false);
                                } else {
                                  setPasswordIsInvalid(true);
                                }
                              }}
                            />
                            <InputGroup.Text
                              id="passwordNew"
                              className={"bg-white eye_icon_component"}
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              {showNewPassword === false ? (
                                <Image
                                  src={image.ShowEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              ) : (
                                <Image
                                  src={image.HideEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              )}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid password
                              <LightTooltip
                                title={showPasswordInvalidTooltip(policy)}
                              >
                                <img
                                  src={image.passwordInfoIcon}
                                  alt="info"
                                  width="20"
                                  height="20"
                                  className="mx-2 cursor_pointer"
                                />
                              </LightTooltip>
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Label id="passwordlabel1">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <InputGroup
                            className="password_input_group"
                            controlId="passwordConfirm"
                          >
                            <Form.Control
                              type={
                                showConfirmPassword === false
                                  ? "password"
                                  : "text"
                              }
                              className="user_input_field password_fields border-end-0"
                              required
                              placeholder="Confirm Password"
                              value={confirmpassword}
                              isInvalid={confirmPasswordIsInvalid}
                              onChange={(e) => {
                                setConfirmpassword(e.target.value);
                                if (e.target.value === password) {
                                  setConfirmPasswordIsInvalid(false);
                                } else {
                                  setConfirmPasswordIsInvalid(true);
                                }
                              }}
                            />
                            <InputGroup.Text
                              id="passwordConfirm"
                              className={"bg-white eye_icon_component"}
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword === false ? (
                                <Image
                                  src={image.ShowEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              ) : (
                                <Image
                                  src={image.HideEyeIcon}
                                  height="20px"
                                  className="px-2"
                                ></Image>
                              )}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              Passwords do not match.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <GeneralButton
                      className="me-3"
                      value="Save"
                      variant="outlined"
                      size="large"
                    ></GeneralButton>
                    <GeneralButton
                      value="Reset"
                      variant="outlined"
                      color="#505050"
                      onClickEvent={handleClick}
                      size="large"
                    ></GeneralButton>
                  </Form>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
}
