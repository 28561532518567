import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../api/users";
import { showError, showSuccess } from "../../../utils/showMessage";
import { getEmailDefaultPolicy } from "../../../api/vault";
import { passwordPolicyRegex, showPasswordInvalidTooltip } from "../../common/PasswordPolicy/PolicyRegex";
import LightTooltip from "../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../../dashboard/Roles/icons/icon-info.svg";
import { InputGroup, Image } from "react-bootstrap";
import images from "../../../utils/images";

const ResetPassword = () => {
    const navigate = useNavigate();
    const { reset_token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [policy, setPolicy] = useState(null);
    const [user, setUser] = useState({});
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
    const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const forgotPasswordFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage("Password and Confirm Password must be the same");
            setPasswordIsInvalid(true);
            return;
        }
        setErrorMessage("")
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await resetPassword({ reset_token, password, confirmPassword });
            if (data !== null) {
                showSuccess(data.message);
                navigate('/')
            }
            if (error !== null) {
                showError(error)
            }
            setLoading(false);
        }
    };
    const getPassPolicy = async () => {
        const { data, error } = await getEmailDefaultPolicy(reset_token)
        if (data !== null) {
            setUser(data.user)
            setPolicy(data.policy)
        }
        if (error !== null) {
            showError(error)
        }
    }
    useEffect(() => {
        getPassPolicy();
    }, [])

    return (
        <>
            <div className="forgot_password_container">
                <div className="password_container d-flex justify-content-center align-items-center w-100 h-100">
                    <div className="d-flex flex-column justify-content-between align-items-center password_container_body">
                        <div className="forgot_password_heading_container">
                            {errorMessage === "" && <h2 id="forgot_password_heading">Reset Password</h2>}
                        </div>
                        {errorMessage !== "" && <div className="alert alert-danger">{errorMessage}</div>}
                        <Form noValidate validated={validated} onSubmit={forgotPasswordFormHandler} className="add_asset_form w-100">
                            <Stack gap={1}>
                                <Form.Group className="mb-3" controlId="formPassword">
                                    <InputGroup>
                                    <Form.Control
                                        required
                                        className="form_input_field border-end-0"
                                        type={showPass === true ? "text" : "password"}
                                        isInvalid={passwordIsInvalid}
                                        value={password}
                                        onChange={event => {
                                            setPassword(event.target.value);
                                            const regex = new RegExp(passwordPolicyRegex(policy, user["email"], user["username"], user["first_name"], user["last_name"]))
                                            if (regex.test(event.target.value)) {
                                                setPasswordIsInvalid(false);
                                            } else {
                                                setPasswordIsInvalid(true);
                                            }
                                            if (event.target.value === confirmPassword) {
                                                setConfirmPasswordIsInvalid(false);
                                            } else {
                                                setConfirmPasswordIsInvalid(true);
                                            }
                                        }
                                        }
                                        placeholder="Password"
                                    />
                                    <InputGroup.Text
                                                id="clientSecret"
                                                className="bg-white "
                                                onClick={() => setShowPass(!showPass)}
                                            >
                                                {showPass === false ? (
                                                    <Image
                                                        src={images.Show}
                                                        height="20px"
                                                        className="px-2"
                                                    ></Image>
                                                ) : (
                                                    <Image
                                                        src={images.Hide}
                                                        height="20px"
                                                        className="px-2"
                                                    ></Image>
                                                )}
                                            </InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">
                                        Please enter a valid password
                                        <LightTooltip
                                            title={showPasswordInvalidTooltip(policy)}
                                        >
                                            <img
                                                src={InfoIcon}
                                                alt="info"
                                                width="20"
                                                height="20"
                                                className="mx-2 cursor_pointer"
                                            />
                                        </LightTooltip>
                                    </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formConfirmPassword">
                                    <InputGroup>
                                    <Form.Control
                                        required
                                        className="form_input_field border-end-0"
                                        type={showConfirmPass === true ? "text" : "password"}
                                        value={confirmPassword}
                                        isInvalid={confirmPasswordIsInvalid}
                                        onChange={event => {
                                            setConfirmPassword(event.target.value)
                                            if (password === event.target.value) {
                                                setConfirmPasswordIsInvalid(false);
                                            } else {
                                                setConfirmPasswordIsInvalid(true);
                                            }
                                        }
                                        }
                                        placeholder="Confirm Password"
                                    />
                                    <InputGroup.Text
                                                id="clientSecret"
                                                className="bg-white"
                                                onClick={() => setShowConfirmPass(!showConfirmPass)}
                                            >
                                                {showConfirmPass === false ? (
                                                    <Image
                                                        src={images.Show}
                                                        height="20px"
                                                        className="px-2"
                                                    ></Image>
                                                ) : (
                                                    <Image
                                                        src={images.Hide}
                                                        height="20px"
                                                        className="px-2"
                                                    ></Image>
                                                )}
                                            </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid confirm password.
                                    </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Link to={window.location.origin} className="back_to_login_link">Back to Login</Link>
                                <Button as="input" type="submit" value="Reset Password" className="form_submit_button" disabled={loading} />
                            </Stack>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;