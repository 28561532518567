import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import Select from "react-select";
import { listRole } from "../../../../api/role";
import { updateUserDetails } from "../../../../api/users";
import { showError, showSuccess } from "../../../../utils/showMessage";
import CrossIcon from "../../../../images/cross.svg";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {useSelector} from "react-redux";
import {PasswordReg, UserNameReg, FirstLastNameReg} from "../../../common/regex/Regex";
import {Fade} from "@mui/material";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import GetCapabilities from "../../../../utils/getCapabilities";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";

const EditUser = ({ setOpenEditUser, setUsers, userData }) => {

  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [role_id, setRoleId] = useState("");
  const [validated, setValidated] = useState(false);
  const [userRole, setuserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [role,setRole] = useState(null);

const [slideChecked, setSlideChecked] = useState(false);
useEffect(() => {
  setSlideChecked(true);
}, []);
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#ced4da",
      boxShadow: "none",
      borderRadius: "0.375rem",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    setId(userData.id);
    setUserName(userData.username);
    setPhoneNumber(userData.country_code + userData.phone_number);
    setEmail(userData.email);
    setFirstName(userData.first_name);
    setLastName(userData.last_name);
    setRoleId(userData.role.role_id);
    setRole(userData.role)
    setCountryCode(userData.country_code);
    const getUserroles = async () => {
      let userRoles = [];
      const { data, error } = await listRole({
        page: 1,
        page_size: 100,
        search: "",
        filter_by: "",
      });
      if (data != null) {
        data.results.map((row) =>
          row.role_name !== "Customeradmin" ? userRoles.push(row) : null
        );
        setuserRole(userRoles);
      }
      if (error !== null) {
        showError(error);
      }
    };
    isCapable && isCapable.users.roles.view && getUserroles();
  }, []);

  const updateUserFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      let len = countryCode.length;
      let phoneNumber = phone_number.slice(len, phone_number.length);
      const { data, error } = await updateUserDetails({
        id: id,
        ...(username !== "" && { username: username }),
        ...(countryCode !== "" && phoneNumber !== ""
          ? { country_code: countryCode }
          : { country_code: "" }),
        ...(countryCode !== "" && phoneNumber !== ""
          ? { phone_number: phoneNumber }
          : { phone_number: "" }),
        ...(email !== "" && { email: email }),
        ...{ first_name: first_name },
        ...{ last_name: last_name },
        ...(role_id !== "" && { role_id: role.role_id }),
      });
      if (data !== null) {
        showSuccess(data.message);
        setUsers(new Object());
        setLoading(false);
        setOpenEditUser((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  };

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <Container fluid className="edit_user_container mx-auto main_content_container">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">Edit User</h2>
        <CrossButton onClick={() => setOpenEditUser((o) => !o)}></CrossButton>
      </div>
      <Form noValidate validated={validated} onSubmit={updateUserFormHandler}>
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlaintextName"
          >
            <Form.Label column md={2} className="input_lable">
              Username<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                type="text"
                pattern={UserNameReg}
                className="user_input_field"
                value={username}
                onChange={(event) => setUserName(event.target.value)}
                placeholder="Enter your username"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid username
                <LightTooltip title="Invalid username! Usernames must start with a letter. Allowed characters are a-z, A-Z, 0-9">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formNumber"
          >
            <Form.Label column md={3} className="input_lable">
              Phone Number
            </Form.Label>
            <Col md={9}>
              <div className="phone_number_field">
                <PhoneInput
                className="user_input_field form-control p-0"
                  value={phone_number}
                  onChange={(phone, country) => {
                    setPhoneNumber(phone);
                    setCountryCode(country.dialCode);
                  }}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter a valid phone number
                <LightTooltip title="Phone Number must be of 10 digits.">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {isCapable && isCapable.users.roles.view && (userData.role.role_name === "Customeradmin" ? null : (
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formUserRole"
            >
              <Form.Label column md={2} className="input_label">
                Select Role <span className="text-danger">*</span>
              </Form.Label>
              <Col md={9}>
                {userRole.length > 0 && <Select
                  className="role_select"
                  classNamePrefix="role"
                  getOptionLabel={(option) => option.display_name}
                  getOptionValue={(option) => option.role_id}
                  defaultValue={role}
                  isClearable={true}
                  isSearchable={true}
                  name="role_id"
                  required={true}
                  options={userRole}
                  onChange={(selectedOption) =>
                    setRole(selectedOption)
                  }
                  styles={selectStyles}
                />}
              </Col>
              <Form.Control.Feedback type="invalid">
                Please select a role
              </Form.Control.Feedback>
            </Form.Group>
          ))}
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formEmail"
          >
            <Form.Label column md={2} className="input_lable">
              Email<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                type="text"
                pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                className="user_input_field"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter your email id"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email id
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlaintextFirstName"
          >
            <Form.Label column md={2} className="input_lable">
              First Name
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="text"
                pattern={FirstLastNameReg}
                className="user_input_field"
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
                placeholder="Enter your first name"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid first name
                <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlaintextLastName"
          >
            <Form.Label column md={2} className="input_lable">
              Last Name
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="text"
                pattern={FirstLastNameReg}
                className="user_input_field"
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
                placeholder="Enter your username"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid last name
                <LightTooltip title="Invalid text! Name must start with a letter. Allowed characters are a-z, A-Z">
                  <img
                    src={InfoIcon}
                    alt="info"
                    width="20"
                    height="20"
                    className="mx-2 cursor_pointer"
                  />
                </LightTooltip>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          
          <Row className="pt-3 mb-3 justify-content-end">
            <Col sm="auto">
              <GeneralButton
                onClickEvent={() => setOpenEditUser((o) => !o)}
                className="me-1"
                value="Cancel"
                color="#505050"
                variant="outlined"
                size="large"
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              {loading ? (
                <GeneralButton
                  variant="contained"
                  disabled={true}
                  className="me-1"
                  value={<img src={images.Loader} width="26px" height="26px" />}
                  size="large"
                ></GeneralButton>
              ) : (
                <GeneralButton
                  className="me-1"
                  value="Update"
                  variant="contained"
                  size="large"
                />
              )}
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>
  </div>
</Fade>

  );
};

export default EditUser;
