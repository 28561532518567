import { Fade } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { default as React, useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { BsCheck2All } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  DeleteTicketDetails,
  TicketsPaginatedList,
  CheckTicketDetails,
} from "../../../api/ticketing";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import ThreeDotIcon from "../../../images/three_dot.svg";
import ticketingIcon from "../../../images/ticket.svg";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import CustomModal from "../../common/CustomModal/CustomModal";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../common/ShowAlert/ShowAlert";
import ApproveTicket from "./ChangeTicketStatus/ApproveTicket";
import RejectTicket from "./ChangeTicketStatus/RejectTicket";
import CreateTicket from "./CreateTicket/CreateTicket";
import UpdateTicket from "./UpdateTicket/UpdateTicket";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import "./ticketing.css";
import GeneralButton from "../../common/SaveButton/GeneralButton";

const CreateButton = styled(MuiButton)({
  boxShadow: "none",
  textTransform: "none",
  fontWeight: 600,
  fontSize: 14,
  fontStyle: "normal",
  padding: "10px 20px",
  lineHeight: "21px",
  fontFamily: ['"DM Sans"'].join(","),
});

function MyTickets(props) {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const [TicketAddModal, setTicketAddModal] = useState(false);
  const [TicketEditModal, setTicketEditModal] = useState(false);
  const [TicketDeleteModal, setTicketDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deleteTicketsDetail, setDeleteTicketsDetail] = useState([]);
  const [action, setAction] = React.useState("");
  const [toggleCleared, setToggleCleared] = useState(false);
  const [TicketData, setTicketData] = useState();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const user_id = useSelector((state) => state.user?.user?.id || "");
  const is_customer_admin = useSelector((state) => state.user?.user?.role.role_name) == 'Customeradmin'
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_MyTickets") !== null
      ? localStorage.getItem("rowsPerPage_MyTickets")
      : 5
  );
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const isCapableEnduser = my_capabilities[1];
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const [advancefilterBy, setAdvancefilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [approvalType, setApprovalType] = useState(null);
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const pages_url = "dashboard/myTickets";
  const customizeColumnDict = {
    Reason: true,
    "Access From": true,
    "Access Until": true,
    "Request Time": false,
    Status: true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const appColumns = [
    { label: "Resource Type", value: "resource_type" },
    { label: "Resource Name", value: "resource_name" },
    { label: "Ticket Status", value: "approval_type" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];
  const navigate = useNavigate();
  const [slideChecked, setSlideChecked] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const [pending, setPending] = useState(true)

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getMyTicketsList({ page, rowsPerPage, search, filterBy, advancefilterBy });
  }, [page, rowsPerPage, advancefilterBy]);
  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const getMyTicketsList = async ({
    page,
    rowsPerPage,
    search,
    filterBy,
    advancefilterBy,
  }) => {
    const { data, error } = await TicketsPaginatedList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
      advancefilterBy: advancefilterBy,
    });
    if (data !== null) {
      setTickets(data.tickets);
      setFilteredData(data.tickets);
      setTotalTickets(data.totalTickets);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false)
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = tickets.filter((object) => {
      let resource_name;
      if(object.resource_type=='APP'){
        resource_name =  object.resource.app_name.toLowerCase().match(s.toLowerCase())
      }
      else if(object.resource_type=='ASSET'){
         resource_name =  object.resource.asset_name.toLowerCase().match(s.toLowerCase())
      }
      return(
          object.resource_type.toLowerCase().match(s.toLowerCase()) ||
          object.approval_type.toLowerCase().match(s.toLowerCase()) ||
          object.resource_id.toString().toLowerCase().match(s.toLowerCase()) ||
          object.requested_by.username.toString().toLowerCase().match(s.toLowerCase()) ||
          object.approval_type.toLowerCase().match(s.toLowerCase()) || resource_name
      )
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Ticket"));
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    getMyTicketsList({ page, rowsPerPage, search, filterBy, advancefilterBy });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_MyTickets", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const formatDate = (date) => {
    const today = new Date();
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: "true",
    };
    const formattedDate = new Date(date).toDateString("en-US", options);
    const formattedTime = new Date(date).toLocaleTimeString("en-US", options);
    const [weekday, month, day, year] = formattedDate.split(" ");
    const [, , , hour, period] = formattedTime.split(" ");
    const capitalizedMonth = month.toUpperCase();
    return `${day} ${capitalizedMonth} ${year}, ${weekday} - ${hour} ${period}`;
  };
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };
  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map(
      (object) =>
        `Ticket Number ${object.id} - to extend an ${object.resource_type} by ${object.requested_by.username}`
    );
    setDeleteTicketsDetail(t);
    setTicketDeleteModal((o) => !o);
  };
  const handleTicketDeleteAction = async (row) => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await DeleteTicketDetails({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      getMyTicketsList({
        page,
        rowsPerPage,
        search,
        filterBy,
        advancefilterBy,
      });
      setTicketDeleteModal((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const CheckTicketDetail = async (ticket,type) => {
    const { data, error } = await CheckTicketDetails(ticket);
    if (data !== null) {
      if(type == 'View'){navigate(`/dashboard/tickets/${ticket.id}`)}
      else if(type == 'Approve'){setApprovalType("APPROVED");setApprovalModal((o) => !o);}
      else if(type == 'Reject'){setApprovalType("DENIED");setRejectionModal((o) => !o);}
    }
    if (error !== null) {
      showError(error);
      getMyTicketsList({ page, rowsPerPage, search, filterBy, advancefilterBy });
    }
  };

  const columns = [
    {
      name: "Ticket Id",
      selector: (row) => row.id,
      grow: 1,
      width: "100px",
      center: true,
    },
    {
      name: "Resource Type",
      selector: (row) => (
        <div className="d-flex justify-content-center text-center">
          {row.resource_type}
        </div>
      ),
      width: "150px",
      center: true,
    },
    {
      name: "Resource Name",
      selector: (row) =>
        row.resource_type == "APP" ? (
          <div className="hovertext" data-hover={row.resource.app_name}>
            {row.resource.app_name}
          </div>
        ) : row.resource_type == "ASSET" ? (
          <div className="hovertext" data-hover={row.resource.asset_name}>
            {row.resource.asset_name}
          </div>
        ) : (
          <div className="hovertext" data-hover={row.resource.app_name}>
            {row.resource.app_name}
          </div>
        ),
      width: "180px",
    },
    {
      name: "Reason",
      selector: (row) => (
        <div className="hovertext" data-hover={row.reason}>
          {row.reason}
        </div>
      ),
      omit: !checkedStateDict["Reason"],
      width: "200px",
    },

    {
      name: "Access From",
      selector: (row) => (
        <div
          className="hovertext"
          data-hover={formatDate(new Date(row.access_from * 1000))}
        >
          {formatDate(new Date(row.access_from * 1000))}
        </div>
      ),
      omit: !checkedStateDict["Access From"],
      width: "200px",
    },
    {
      name: "Access Until",
      selector: (row) => (
        <div
          className="hovertext"
          data-hover={formatDate(new Date(row.access_until * 1000))}
        >
          {formatDate(new Date(row.access_until * 1000))}
        </div>
      ),
      omit: !checkedStateDict["Access Until"],
      width: "210px",
    },
    (isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
    (isCapable && isCapable.miscellaneous.ticket.view_ticket)
      ? {
          name: "Requested by",
          selector: (row) => <>{row.requested_by.username}</>,
          width: "150px",
        }
      : {
          width: "0px",
        },
    {
      name: "Request Time",
      selector: (row) => (
        <div
          className="hovertext"
          data-hover={formatDate(new Date(row.request_time))}
        >
          {formatDate(new Date(row.request_time))}
        </div>
      ),
      omit: !checkedStateDict["Request Time"],
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) =>
          (<div
            className={
              row.approval_type == "APPROVED" ? "text-success" : row.approval_type == "DENIED" ? "text-danger" : "text-warning"
            }
          >
            {row.approval_type}
          </div>),
      omit: !checkedStateDict["Status"],
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              className="ff-pam-dm-sans"
              id="nav-dropdown-user"
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              <NavDropdown.Item
                className="fs-16px fw-500"
                onClick={() => {CheckTicketDetail(row,'View');}}
              >
                <AiOutlineEye className="mb-1 me-2"></AiOutlineEye> View
              </NavDropdown.Item>
              {((isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                (isCapable && isCapable.miscellaneous.ticket.view_ticket)) &&
                row.ticket_status == "OPEN" && (Object.keys(row.responded_by).includes(
                  String(user_id)
                ) && !Object.keys(row.response).includes(
                  String(user_id)
                ) || Object.keys(row.responded_by).length==0) && (
                  <NavDropdown.Item
                    className="fs-16px fw-500"
                    onClick={() => {
                      setTicketData(row);
                      CheckTicketDetail(row,'Approve');
                      
                    }}
                  >
                    <BsCheck2All className="mb-1 me-2"></BsCheck2All>{" "}
                    Approve
                  </NavDropdown.Item>
                )}
              {((isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                (isCapable && isCapable.miscellaneous.ticket.view_ticket)) &&
                row.ticket_status == "OPEN" && (Object.keys(row.responded_by).includes(
                  String(user_id)
                ) && !Object.keys(row.response).includes(
                  String(user_id)
                ) || Object.keys(row.responded_by).length==0) && (
                  <NavDropdown.Item
                    className="fs-16px fw-500"
                    onClick={() => {
                      setTicketData(row);
                      CheckTicketDetail(row,'Reject');
                      
                    }}
                  >
                    <RxCross1 className="mb-1 me-2"></RxCross1> Reject
                  </NavDropdown.Item>
                )}
              {!(
                (isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                (isCapable && isCapable.miscellaneous.ticket.view_ticket)
              ) &&
                row.ticket_status == "OPEN" && (
                  <NavDropdown.Item
                    className="fs-16px fw-500"
                    onClick={() => {
                      setTicketData(row);
                      setTicketEditModal((o) => !o);
                    }}
                  >
                    <CiEdit className="mb-1 me-2"></CiEdit> Update
                  </NavDropdown.Item>
                )}
              {!(
                (isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                (isCapable && isCapable.miscellaneous.ticket.view_ticket)
              ) &&
                row.ticket_status == "OPEN" && (
                  <NavDropdown.Item
                    className="fs-16px fw-500"
                    onClick={async () => {
                      handleDelete([row]);
                    }}
                  >
                    <AiOutlineDelete className="mb-1 me-2"></AiOutlineDelete>{" "}
                    Delete
                  </NavDropdown.Item>
                )}
            </NavDropdown>
          </Nav>
        </>
      ),
      center: true,
    },
  ];
  const rowDisabledCriteria = (row) => row.ticket_status === "CLOSED";
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Ticket":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img src={ticketingIcon} alt="users round icon" />{" "}
                        &nbsp; Tickets
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal open={approvalModal} handleClose={()=>{setApprovalModal(o=>!o);}}>
        {" "}
        {TicketData && <ApproveTicket setApprovalModal={setApprovalModal} approvalTicket={TicketData} resource={TicketData.resource} approvalType={approvalType} getTicketDetail={()=>{getMyTicketsList({ page, rowsPerPage, search, filterBy, advancefilterBy });}}></ApproveTicket>}
      </CustomModal>
      <CustomModal open={rejectionModal} handleClose={()=>{setRejectionModal(o=>!o);}}>
        {" "}
        {TicketData && <RejectTicket setRejectionModal={setRejectionModal} approvalTicket={TicketData} resource={TicketData.resource} approvalType={approvalType} getTicketDetail={()=>{getMyTicketsList({ page, rowsPerPage, search, filterBy, advancefilterBy });}}></RejectTicket>}      
      </CustomModal>
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  {!(
                    (isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                    (isCapable && isCapable.miscellaneous.ticket.view_ticket)
                  ) && (
                    <DataTable
                      title={
                        <ListHeading
                          dataTableHeadingText="My Tickets"
                          dataTableHeadingIcon={ticketingIcon}
                        />
                      }
                      columns={columns}
                      data={filteredData}
                      className="rdt_container"
                      progressPending={pending}
                      progressComponent={
                          <CustomDelaySkeleton
                              rowsPerPage={rowsPerPage}
                              colCount={0}
                              totalColumns={4}
                          />
                      }
                      fixedHeader
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                      pagination
                      paginationServer
                      paginationTotalRows={totalTickets}
                      selectableRows
                      selectableRowsVisibleOnly
                      selectableRowsHighlight
                      highlightOnHover
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleCleared}
                      selectableRowDisabled={rowDisabledCriteria}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      actions={(
                          <CreateButton
                          onClick={() => {
                            setTicketAddModal((o) => !o);
                          }}
                            variant="outlined"
                          >
                            Create Ticket
                          </CreateButton>
                        )
                      }
                      subHeader
                      subHeaderComponent={
                        <SubHeaderComponent
                          filterBy={filterBy}
                          action={action}
                          setAction={setAction}
                          setFilterBy={setFilterBy}
                          handleAction={handleAction}
                          filterOptions={appColumns}
                          actionOptions={actionOptions}
                          search={search}
                          setSearch={setSearch}
                          handleSearch={handleSearch}
                          placeholder="ticket"
                          selectedRows={selectedRows}
                          pages_url={pages_url}
                          checkedStateDict={checkedStateDict}
                          setCheckedStateDict={setCheckedStateDict}
                          showTicketAdvanceFilter={true}
                          setAdvancefilterBy={setAdvancefilterBy}
                          advancefilterBy={advancefilterBy}
                        />
                      }
                      subHeaderAlign="center"
                      paginationIconFirstPage={
                        <img src={FirstPageIcon} alt="first page" />
                      }
                      paginationIconLastPage={
                        <img src={LastPageIcon} alt="last page" />
                      }
                      paginationIconNext={<img src={NextPageIcon} alt="next" />}
                      paginationIconPrevious={
                        <img src={PreviousPageIcon} alt="previous" />
                      }
                    />
                  )}

                  {((isCapableEnduser && isCapableEnduser.allow_ticket_view) ||
                    (isCapable &&
                      isCapable.miscellaneous.ticket.view_ticket)) && (
                    <DataTable
                      title={
                        <ListHeading
                          dataTableHeadingText="All Tickets"
                          dataTableHeadingIcon={ticketingIcon}
                        />
                      }
                      columns={columns}
                      data={filteredData}
                      className="rdt_container"
                      progressPending={pending}
                      progressComponent={
                          <CustomDelaySkeleton
                              rowsPerPage={rowsPerPage}
                              colCount={0}
                              totalColumns={4}
                          />
                      }
                      fixedHeader
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                      pagination
                      paginationServer
                      paginationTotalRows={totalTickets}
                      highlightOnHover
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      actions={(
                        <div>
                          {!is_customer_admin && <GeneralButton
                            onClickEvent={() => {
                              setTicketAddModal((o) => !o);
                            }}
                            value="Create Ticket"
                            size="large"
                            variant="outlined"
                            className="me-3"
                          />}
                          {isCapable &&  isCapable.miscellaneous.setting.show_ticket_setting && <GeneralButton
                            onClickEvent={() => {
                              navigate("/dashboard/ticket-settings")
                            }}
                            value="Ticket Settings"
                            size="large"
                            variant="outlined"
                          />}
                        </div>
                      )
                    }
                      subHeader
                      subHeaderComponent={
                        <SubHeaderComponent
                          filterBy={filterBy}
                          action={action}
                          setAction={setAction}
                          setFilterBy={setFilterBy}
                          filterOptions={appColumns}
                          search={search}
                          setSearch={setSearch}
                          handleSearch={handleSearch}
                          placeholder="ticket"
                          pages_url={pages_url}
                          checkedStateDict={checkedStateDict}
                          setCheckedStateDict={setCheckedStateDict}
                          showTicketAdvanceFilter={true}
                          setAdvancefilterBy={setAdvancefilterBy}
                          advancefilterBy={advancefilterBy}
                        />
                      }
                      subHeaderAlign="center"
                      paginationIconFirstPage={
                        <img src={FirstPageIcon} alt="first page" />
                      }
                      paginationIconLastPage={
                        <img src={LastPageIcon} alt="last page" />
                      }
                      paginationIconNext={<img src={NextPageIcon} alt="next" />}
                      paginationIconPrevious={
                        <img src={PreviousPageIcon} alt="previous" />
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
      <CustomModal open={TicketAddModal} handleClose={()=>{setTicketAddModal(o=>!o);}}>
        <CreateTicket
          setTicketAddModal={setTicketAddModal}
          getMyTicketsList={getMyTicketsList}
          getTicketListData={{
            page,
            rowsPerPage,
            search,
            filterBy,
            advancefilterBy,
          }}
        ></CreateTicket>
      </CustomModal>
      <CustomModal open={TicketEditModal} handleClose={()=>{setTicketEditModal(o=>!o);}}>
        <UpdateTicket
          TicketData={TicketData}
          setTicketEditModal={setTicketEditModal}
          getMyTicketsList={getMyTicketsList}
          getTicketListData={{
            page,
            rowsPerPage,
            search,
            filterBy,
            advancefilterBy,
          }}
        ></UpdateTicket>
      </CustomModal>
      {deleteTicketsDetail.length > 0 && (
        <CustomModal open={TicketDeleteModal} handleClose={()=>{setTicketDeleteModal(o=>!o);}}>
          <ShowAlert
            setOpenAlert={setTicketDeleteModal}
            handleAlertAction={handleTicketDeleteAction}
            colName={deleteTicketsDetail}
            alertMsg="Below tickets will be removed :"
            headingMsg="Remove Ticket"
          />
        </CustomModal>
      )}
    </>
  );
}
export default MyTickets;
