import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerAdmin } from "../../../api/customer";
import { get_jwt_token, isTokenExpired } from '../../../api/helper_funtions';
import { loadUser } from '../../../state/slices/user';
import { challenge_from_verifier, generateRandomString, getSHA512Hash, text2Binary } from '../../../utils/oAuthHelperFuntion';
import Loader from '../../common/Loader/Loader';

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { serverUrl } = useSelector((state) => state.customer);

  const fetchData = async () => {

    const token = get_jwt_token();
    if (!token || isTokenExpired(token) || isAuthenticated === false) {
      initiateOauthFlowPKCE();
    } else {
      dispatch(loadUser());
    }


  };
  useEffect(() => {
    fetchData();
  }, [isAuthenticated, navigate, dispatch]);

  const initiateOauthFlowPKCE = async () => {
    const code_verifier = generateRandomString(7);
    const code_challenge = await challenge_from_verifier(code_verifier);
    const code_challenge_method = 'S256';
    const redirect_uri = window.location.origin + '/token'
    const client_id = "AvvDWAJywgGPQsn3qgSXlsEe9yYWkz61";
    let state = {
      "V": text2Binary(code_verifier),
      "H": await getSHA512Hash(code_verifier)
    }
    state = btoa(JSON.stringify(state));

    const server_url = `${serverUrl}/autheticate/authorize/`;
    const url = `${server_url}?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid%20profile%20email&state=${state}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}`
    window.location.href = url;
  }
  return (
    <>
      <Loader loading={true} bg_papper={false} />
    </>
  )
}
export default Login;