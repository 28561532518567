import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from 'react-select';
import '../../DatabasePolicyList/DatabasePolicy.scss';
import '../../AddDatabasePolicy/AddDatabasePolicy.scss';
import getWindowDimensions from '../../../../../../utils/getHeightWidth';
import Images from '../../../../../../utils/images';
import Stack from "react-bootstrap/Stack";
import MuiButton from "@mui/material/Button";
import { addPolicy, getDatabaseApps, getDatabaseResourceCommands, getDatabaseResourceListUsingAppId, updatePolicy } from '../../../../../../api/database_policy';


import styled from '@emotion/styled';
import { showError, showErrorMsg } from '../../../../../../utils/showMessage';
const AddButton = ({ handleAddConfigured }) => {
    const AddButton = styled(MuiButton)({
        boxShadow: "none",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 14,
        fontStyle: "normal",
        padding: "10px 20px",
        lineHeight: "21px",
        background: "transparent",
        color: "#25213B",
        border: "1px solid #39354F",
        fontFamily: ['"DM Sans"'].join(","),
    });

    return (
        <Stack direction="row" spacing={1}>
            <AddButton
                onClick={() => {
                    handleAddConfigured();
                }}
                variant="outlined"
                startIcon={<img src={Images.BlackPlus} alt="icon" id="center-blueShadow" />}
            >
                Add
            </AddButton>
        </Stack>
    );
};

const CommandsSection = ({ currentSelectedOption, setCurrentSelectedOption, hashmap, setPolicySubNavbarName, app, appSystemUser, 
    configuredDatabases, handleEditConfiguredDatabase, handleRemoveConfiguredDatabase, selectDatabase, setSelectDatabase, databases, setDatabases, database, setDatabase, databaseCommands, setDatabaseCommands, checkedDatabaseCommands, setCheckedDatabaseCommands, handleAddConfiguredDatabase,
    configuredTables, handleEditConfiguredTable, handleRemoveConfiguredTable, selectTable, setSelectTable, tables, setTables, table, setTable, tableCommands, setTableCommands, checkedTableCommands, setCheckedTableCommands, handleAddConfiguredTable,
    configuredColumns, handleEditConfiguredColumn, handleRemoveConfiguredColumn, selectColumn, setSelectColumn, columns, setColumns, column, setColumn, columnCommands, setColumnCommands, checkedColumnCommands, setCheckedColumnCommands, handleAddConfiguredColumn }) => {
    const { width } = getWindowDimensions();
    const sidebar_categories = ["Database", "Table", "Columns"].filter((item) => (app.app_type !== 'Oracledb') || (app.app_type === 'Oracledb' && item !== 'Database'))

    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    const getDatabases = async () => {
        const { data, error } = await getDatabaseResourceListUsingAppId(app.id, appSystemUser.id, "database");
        if (data !== null) {
            let tempDatabases = [];
            for (let d in data.data) {
                if (!(hashmap.has(data.data[d][0] + "_" + currentSelectedOption))) {
                    tempDatabases.push({ "database_name": data.data[d][0] });
                }
            }
            setDatabases(tempDatabases);
            return tempDatabases;
        }
        if (error !== null) {
            showError(error);
        }
    }
    const getTables = async (database_name) => {
        const { data, error } = await getDatabaseResourceListUsingAppId(app.id, appSystemUser.id, "table", database_name);
        if (data !== null) {
            let tempTables = [];
            for (let d in data.data) {
                if (!(hashmap.has(database_name + "_" + data.data[d][0] + "_" + currentSelectedOption))) {
                    tempTables.push({ "table_name": data.data[d][0] });
                }
            }
            setTables(tempTables);
        }
        if (error !== null) {
            showError(error);
        }
    }
    const getColumns = async (table_name) => {
        const { data, error } = await getDatabaseResourceListUsingAppId(app.id, appSystemUser.id, "column", database.database_name, table_name);
        if (data !== null) {
            let tempColumns = [];
            for (let d in data.data) {
                if (!(hashmap.has(database.database_name + "_" + table_name + "_" + data.data[d][0] + "_" + currentSelectedOption))) {
                    tempColumns.push({ "column_name": data.data[d][0] });
                }
            }
            setColumns(tempColumns);
        }
        if (error !== null) {
            showError(error);
        }
    }
    const handlePlusClick = async () => {
        if (app == null) {
            setPolicySubNavbarName("General");
            showErrorMsg("Please select an app first");
            return;
        }
        databases = await getDatabases("database");
        setSelectDatabase(true);
        if (app.app_type === 'Oracledb') {
            handleSelectDatabaseChange(databases[0]);
        }

    }
    const handleChangeSelectedOption = async (key) => {
        if (key === "Database" && databaseCommands.length === 0) {
            const { data, error } = await getDatabaseResourceCommands("Database");
            if (data !== null) {
                setDatabaseCommands(data.commands);
            }
        } else if (key === "Table" && tableCommands.length === 0) {
            const { data, error } = await getDatabaseResourceCommands("Table");
            if (data !== null) {
                setTableCommands(data.commands);
            }
        } else if (key === "Columns" && columnCommands.length === 0) {
            const { data, error } = await getDatabaseResourceCommands("Column");
            if (data !== null) {
                setColumnCommands(data.commands);
            }
        }
        setSelectDatabase(false);
        setSelectTable(false);
        setSelectColumn(false);
        setDatabase(null);
        setColumn(null);
        setTable(null);
        setCurrentSelectedOption(key);
    }

    const handleDatbaseCommandsCheck = async (command) => {
        let tempCheckedDatabaseCommands = { ...checkedDatabaseCommands };
        tempCheckedDatabaseCommands[command] = !tempCheckedDatabaseCommands[command];
        setCheckedDatabaseCommands(tempCheckedDatabaseCommands);
    }
    const handleTableCommandsCheck = async (command) => {
        let tempCheckedTableCommands = { ...checkedTableCommands };
        tempCheckedTableCommands[command] = !tempCheckedTableCommands[command];
        setCheckedTableCommands(tempCheckedTableCommands);
    }
    const handleColumnCommandsCheck = async (command) => {
        let tempCheckedColumnCommands = { ...checkedColumnCommands };
        tempCheckedColumnCommands[command] = !tempCheckedColumnCommands[command];
        setCheckedColumnCommands(tempCheckedColumnCommands);
    }
    const handleSelectDatabaseChange = async (selectedOption) => {
        if (currentSelectedOption === "Database") {
            let tempCheckedDatabaseCommands = {};
            for (let command in databaseCommands) {
                tempCheckedDatabaseCommands[databaseCommands[command].toLowerCase().replace(" ", "_") + "_privilege"] = false;
            }
            setCheckedDatabaseCommands(tempCheckedDatabaseCommands);
        }
        setDatabase(selectedOption);
        if (currentSelectedOption === "Table" || currentSelectedOption === "Columns") {
            setTable(null);
            setColumn(null);
            getTables(selectedOption.database_name);
            setSelectTable(true);
        }
    }
    const handleSelectTableChange = async (selectedOption) => {
        if (currentSelectedOption === "Table") {
            let tempCheckedTableCommands = {};
            for (let command in tableCommands) {
                tempCheckedTableCommands[tableCommands[command].toLowerCase().replace(" ", "_") + "_privilege"] = false;
            }
            setCheckedTableCommands(tempCheckedTableCommands);
        }
        setTable(selectedOption);
        if (currentSelectedOption === "Columns") {
            getColumns(selectedOption.table_name);
            setSelectColumn(true);
        }
    }

    const handleSelectColumnChange = async (selectedOption) => {
        let tempCheckedColumnCommands = {};
        for (let command in columnCommands) {
            tempCheckedColumnCommands[columnCommands[command].toLowerCase().replace(" ", "_") + "_privilege"] = false;
            setCheckedColumnCommands(tempCheckedColumnCommands);
        }
        setColumn(selectedOption);
    }
    useEffect(() => {
        handleChangeSelectedOption(sidebar_categories[0]);
    }, [])

    return (
        <div id="database_policy_commands">
            <div className="row justify-content-around h-75">
                <div id="sidebar_operations" className="col-12 col-lg-3 px-0 d-flex flex-column justify-content-start align-items-start">
                    <ul className={`${width >= 992 ? "border-none" : "border-bottom"} w-100 px-0  policy_sidebar_container d-lg-block d-flex justify-content-start align-items-center overflow-auto`}>
                        {sidebar_categories.map((key) => (
                            <>
                                <li key={key} className={`policy_sidebar cursor_pointer d-flex flex-row justify-content-start align-items-center py-2 py-lg-3 ${key === currentSelectedOption && `${width >= 992 ? "border-end" : "border-bottom mx-4"} border-2 border-primary mfa_sidebar_active`}`} onClick={() => { handleChangeSelectedOption(key) }}>
                                    <img src={Images[key]} alt="ICON" />
                                    <p className="mx-3 my-0">{key}</p>
                                </li>
                            </>
                        ))}
                    </ul>
                </div>

                <div id="main-content" className={` ${width >= 992 ? "border-start" : "border-none"} col-12 col-lg-9 d-flex flex-column justify-content-start align-items-center`}>
                    {currentSelectedOption === "Database" && <div id="" className="policy_container w-100 ps-0 ps-lg-4">
                        <div className='d-flex justify-content-lg-start align-items-center flex-wrap'>
                            {configuredDatabases.map((database) => (
                                <div className='configured_resource d-flex justify-content-between align-items-center me-3 mb-3' key={database.database_name}>
                                    <p className='m-0 px-3 py-2 cursor_pointer' onClick={() => { handleEditConfiguredDatabase(database.database_name) }}>{database.database_name}</p>
                                    <img src={Images.CrossIcon} alt="close" className="cursor_pointer px-2 py-2 border-start" onClick={() => handleRemoveConfiguredDatabase(database.database_name)} />
                                </div>
                            ))}
                            <div className='plus-box p-2 mb-3 cursor_pointer' onClick={() => { handlePlusClick() }}>
                                <img src={Images.PolicyPlus} alt="+" />
                            </div>
                        </div>
                        {selectDatabase && <div className='my-4 row'>
                            <Select
                                className="systemuser_select col-12 col-lg-6"
                                classNamePrefix="asset"
                                getOptionLabel={(option) => option.database_name}
                                getOptionValue={(option) => option.database_name}
                                isClearable={true}
                                isSearchable={true}
                                name="systemuser_select"
                                required={true}
                                options={databases}
                                onChange={(selectedOption) => { handleSelectDatabaseChange(selectedOption); }}
                                styles={selectStyles}
                                value={database}
                            />
                        </div>}
                        {database && <div className='commands_select_section p-4 my-4'>
                            <div className='d-flex justify-content-start align-items-center flex-wrap '>
                                {Object.keys(checkedDatabaseCommands).map((command) => (
                                    <Form.Group
                                        className="mb-3 d-flex justify-content-start align-items-center width_160"
                                        controlId="switch"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            className='database-commands me-3'
                                            checked={checkedDatabaseCommands[command]}
                                            onChange={() => { handleDatbaseCommandsCheck(command) }}
                                        />
                                        <Form.Label className="input_label mb-0 me-4">
                                            {command.toUpperCase().split("_").join(" ").replace("PRIVILEGE", "")}
                                        </Form.Label>
                                    </Form.Group>
                                ))}
                            </div>
                            <div className='pt-4 border-top'>
                                <Row className="justify-content-end">
                                    <Col sm="auto">
                                        <AddButton handleAddConfigured={handleAddConfiguredDatabase} />
                                    </Col>
                                </Row>
                            </div>
                        </div>}
                    </div>}
                    {currentSelectedOption === "Table" && <div id="" className="policy_container w-100 ps-0 ps-lg-4">
                        <div className='d-flex justify-content-lg-start align-items-center flex-wrap'>
                            {configuredTables.map((table) => (
                                <div className='configured_resource d-flex justify-content-between align-items-center me-3 mb-3' key={table.table_name}>

                                    <p className='m-0 px-3 py-2 cursor_pointer' onClick={() => { handleEditConfiguredTable(table.database_name, table.table_name) }} >{app.app_type === 'Oracledb' ? `${table.table_name}` : `${table.database_name}.${table.table_name}`}</p>
                                    <img src={Images.CrossIcon} alt="close" className="cursor_pointer px-2 py-2 border-start" onClick={() => handleRemoveConfiguredTable(table.database_name, table.table_name)} />
                                </div>
                            ))}
                            <div className='plus-box p-2 mb-3 cursor_pointer' onClick={() => { handlePlusClick() }}>
                                <img src={Images.PolicyPlus} alt="+" />
                            </div>
                        </div>
                        <div className='d-flex justify-content-start align-items-center row'>
                            {app.app_type !== 'Oracledb' && selectDatabase && <div className='my-4 col-12 col-lg-6'>
                                <Select
                                    className="systemuser_select"
                                    classNamePrefix="asset"
                                    getOptionLabel={(option) => option.database_name}
                                    getOptionValue={(option) => option.database_name}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="systemuser_select"
                                    required={true}
                                    options={databases}
                                    onChange={(selectedOption) => { handleSelectDatabaseChange(selectedOption); }}
                                    styles={selectStyles}
                                    value={database}
                                />
                            </div>}
                            {selectTable && <div className='my-4 col-12 col-lg-6'>
                                <Select
                                    className="systemuser_select"
                                    classNamePrefix="asset"
                                    getOptionLabel={(option) => option.table_name}
                                    getOptionValue={(option) => option.table_name}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="systemuser_select"
                                    required={true}
                                    options={tables}
                                    onChange={(selectedOption) => { handleSelectTableChange(selectedOption); }}
                                    styles={selectStyles}
                                    value={table}
                                />
                            </div>}
                        </div>
                        {table && <div className='commands_select_section p-4 my-4'>
                            <div className='d-flex justify-content-start align-items-center flex-wrap '>
                                {Object.keys(checkedTableCommands).map((command) => (
                                    <Form.Group
                                        className="mb-3 d-flex justify-content-start align-items-center width_160"
                                        controlId="switch"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            className='database-commands me-3'
                                            checked={checkedTableCommands[command]}
                                            onChange={() => { handleTableCommandsCheck(command) }}
                                        />
                                        <Form.Label className="input_label mb-0 me-4">
                                            {command.toUpperCase().split("_").join(" ").replace("PRIVILEGE", "")}
                                        </Form.Label>
                                    </Form.Group>
                                ))}
                            </div>
                            <div className='pt-4 border-top'>
                                <Row className="justify-content-end">
                                    <Col sm="auto">
                                        <AddButton handleAddConfigured={handleAddConfiguredTable} />
                                    </Col>
                                </Row>
                            </div>
                        </div>}
                    </div>}
                    {currentSelectedOption === "Columns" && <div id="" className="policy_container w-100 ps-0 ps-lg-4">
                        <div className='d-flex justify-content-lg-start align-items-center flex-wrap'>
                            {configuredColumns.map((column) => (
                                <div className='configured_resource  d-flex justify-content-between align-items-center me-3 mb-3' key={column.column_name}>
                                    <p className='m-0 px-3 py-2 cursor_pointer' onClick={() => { handleEditConfiguredColumn(column.database_name, column.table_name, column.column_name) }}>{app.app_type === 'Oracledb' ? `${column.table_name}.${column.column_name}` : `${column.database_name}.${column.table_name}.${column.column_name}`}</p>
                                    <img src={Images.CrossIcon} alt="close" className="cursor_pointer px-2 py-2 border-start" onClick={() => handleRemoveConfiguredColumn(column.database_name, column.table_name, column.column_name)} />
                                </div>
                            ))}
                            <div className='plus-box p-2 mb-3 cursor_pointer' onClick={() => { handlePlusClick() }}>
                                <img src={Images.PolicyPlus} alt="+" />
                            </div>
                        </div>
                        <div className='d-flex justify-content-start align-items-center row'>
                            {app.app_type !== 'Oracledb' && selectDatabase && <div className='my-4 col-12 col-lg-4'>
                                <Select
                                    className="systemuser_select"
                                    classNamePrefix="asset"
                                    getOptionLabel={(option) => option.database_name}
                                    getOptionValue={(option) => option.database_name}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="systemuser_select"
                                    required={true}
                                    options={databases}
                                    onChange={(selectedOption) => { handleSelectDatabaseChange(selectedOption); }}
                                    styles={selectStyles}
                                    value={database}
                                />
                            </div>}
                            {selectTable && <div className='my-4 col-12 col-lg-4'>
                                <Select
                                    className="systemuser_select"
                                    classNamePrefix="asset"
                                    getOptionLabel={(option) => option.table_name}
                                    getOptionValue={(option) => option.table_name}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="systemuser_select"
                                    required={true}
                                    options={tables}
                                    onChange={(selectedOption) => { handleSelectTableChange(selectedOption); }}
                                    styles={selectStyles}
                                    value={table}
                                />
                            </div>}
                            {selectColumn && <div className='my-4 col-12 col-lg-4'>
                                <Select
                                    className="systemuser_select"
                                    classNamePrefix="asset"
                                    getOptionLabel={(option) => option.column_name}
                                    getOptionValue={(option) => option.column_name}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="systemuser_select"
                                    required={true}
                                    options={columns}
                                    onChange={(selectedOption) => { handleSelectColumnChange(selectedOption); }}
                                    styles={selectStyles}
                                    value={column}
                                />
                            </div>}
                        </div>
                        {column && <div className='commands_select_section p-4 my-4'>
                            <div className='d-flex justify-content-start align-items-center flex-wrap '>
                                {Object.keys(checkedColumnCommands).map((command) => (
                                    <Form.Group
                                        className="mb-3 d-flex justify-content-start align-items-center width_160"
                                        controlId="switch"
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            className='database-commands me-3'
                                            checked={checkedColumnCommands[command]}
                                            onChange={() => { handleColumnCommandsCheck(command) }}
                                        />
                                        <Form.Label className="input_label mb-0 me-4">
                                            {command.toUpperCase().split("_").join(" ").replace("PRIVILEGE", "")}
                                        </Form.Label>
                                    </Form.Group>
                                ))}
                            </div>
                            <div className='pt-4 border-top'>
                                <Row className="justify-content-end">
                                    <Col sm="auto">
                                        <AddButton handleAddConfigured={handleAddConfiguredColumn} />
                                    </Col>
                                </Row>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default CommandsSection
