import { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { showErrorMsg } from "../../../../utils/showMessage";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const EditFieldModal = ({
  setEditFieldModal,
  fieldEditData,
  setFieldEditData,
  field,
  setField,
  fieldList,
  setFieldList,
}) => {
  const [labelName, setLabelName] = useState(Object.keys(fieldEditData)[1]);
  const [defaultvalue, setDefaultValue] = useState(
    fieldEditData[Object.keys(fieldEditData)[1]]["DefaultValue"]
  );
  const [required, setRequired] = useState(
    fieldEditData[Object.keys(fieldEditData)[1]]["required"]
  );
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState();

  useEffect(() => {
    fieldEditData[Object.keys(fieldEditData)[1]]["Options"].map((item) => {
      setValue((prev) => [...prev, createOption(item)]);
    });
  }, []);

  useEffect(() => {
    setOptions(value.map((item) => item.label));
    if (["Checkbox"].includes(field) && value.length > 0) {
      if (defaultvalue) {
        setDefaultOptions(
          defaultvalue.map(
            (option) => value[value.findIndex((item) => item.value === option)]
          )
        );
      } else {
        setDefaultOptions("");
      }
    }
    if (["Dropdown", "RadioButton"].includes(field) && value.length > 0) {
      if (defaultvalue) {
        setDefaultOptions(
          value[value.findIndex((item) => item.value === defaultvalue)]
        );
      } else {
        setDefaultOptions("");
      }
    }
  }, [value]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const EditTicketField = async (event) => {
    event.preventDefault();
    let fieldNameExist = false;
    if (fieldNameExist) {
      showErrorMsg("Field already exist");
    } else {
      fieldList = fieldList.map((item) => {
        if (item["id"] == fieldEditData[Object.keys(fieldEditData)[0]]) {
          if (labelName != Object.keys(fieldEditData)[1]) {
            item = {
              ...item,
              [labelName]: item[Object.keys(fieldEditData)[1]],
            };
            delete item[Object.keys(fieldEditData)[1]];
          }

          item[labelName]["Options"] = options;
          item[labelName]["DefaultValue"] = defaultvalue;
          item[labelName]["required"] = required;
          item[labelName]["col"] =
            ["SingleLineText", "ParagraphText", "Number", "Dropdown"].includes(
              field
            ) || options.length <= 3
              ? 6
              : 12;
        }
        return item;
      });
      setFieldList([...fieldList]);
      setField(null);
      setEditFieldModal((o) => !o);
    }
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "6px",
      paddingBottom: "6px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  };

  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form onSubmit={EditTicketField} className="add_systemuser_form">
          <Stack gap={1}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formPlaintextName"
            >
              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="ff-pam fw-500 profile_heading fs-24px">
                    {field.replace(/([A-Z])/g, " $1").trim()}
                  </span>
                  <CrossButton className="cursor_pointer float-end" onClick={() => {
                      setField(null);
                      setEditFieldModal((o) => !o);
                    }}></CrossButton>
                </div>
              </Col>
            </Form.Group>
            <Row>
              <Col className="mt-3" md={12}>
                <Form.Label column md={6} className="input_label">
                  Field Label <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={labelName}
                  className="form_input_field mt-2"
                  type="text"
                  placeholder="Enter your reason here"
                  onChange={(event) => setLabelName(event.target.value)}
                />
              </Col>
              {["Dropdown", "RadioButton"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Option(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <CreatableSelect
                      className="form_input_field"
                      required
                      components={components}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setValue(newValue)}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter the values and press enter"
                      value={value}
                      options={[]}
                      styles={selectStyles}
                    />
                  </Col>
                  <Col className="mt-2" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>
                    {(defaultOptions || defaultOptions == "") && (
                      <Select
                        defaultValue={defaultOptions}
                        onChange={(event) => setDefaultValue(event.value)}
                        options={value}
                        noOptionsMessage={() =>
                          "Give options to select default"
                        }
                      />
                    )}
                  </Col>
                </>
              )}
              {["SingleLineText", "ParagraphText"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>

                    <Form.Control
                      type="text"
                      value={defaultvalue}
                      className="form_input_field mt-2"
                      onChange={(event) => setDefaultValue(event.target.value)}
                      placeholder="Enter your first name"
                    />
                  </Col>
                </>
              )}
              {["Number"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>

                    <Form.Control
                      type="number"
                      value={defaultvalue}
                      className="form_input_field mt-2"
                      onChange={(event) => setDefaultValue(event.target.value)}
                      placeholder="Enter your first name"
                    />
                  </Col>
                </>
              )}
              {["Checkbox"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Option(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <CreatableSelect
                      className="form_input_field"
                      required
                      components={components}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setValue(newValue)}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter the values and press enter"
                      value={value}
                      styles={selectStyles}
                    />
                  </Col>
                  <Col className="mt-2" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>
                    {(defaultOptions || defaultOptions == "") && (
                      <Select
                        isMulti
                        className="basic-multi-select mt-1"
                        onChange={(event) => {
                          const selectedValues = event.map(
                            (option) => option.value
                          );
                          setDefaultValue(selectedValues);
                        }}
                        options={value}
                        noOptionsMessage={() =>
                          "Give options to select default"
                        }
                        defaultValue={defaultOptions}
                        styles={selectStyles}
                      />
                    )}
                  </Col>
                </>
              )}

              <Col className="mt-3" md={12}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  className="input_label ps-0 ms-2 pt-4"
                  label="Required"
                  checked={required}
                  onChange={() => setRequired((e) => !e)}
                />
              </Col>
            </Row>
            <Row className="pt-4 mb-3 justify-content-end">
              <Col sm="auto">
                <GeneralButton 
                  color='#505050' 
                  onClickEvent={() => {
                    setField(null);
                    setEditFieldModal((o) => !o);
                  }}
                  form="add-column-setting-form" 
                  value='Cancel' 
                  variant='contained' 
                  size='medium'
                  />

              </Col>
              <Col sm="auto">
                <GeneralButton 
                  className="me-1" 
                  value='Update' 
                  variant='contained' 
                  size='medium'
                  />
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
};

export default EditFieldModal;
