import { default as React, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getToken, myAppsListUser } from "../../../../api/apps";
import { myAssetsListUser } from "../../../../api/asset";
import { UpdateTicketDetails } from "../../../../api/ticketing";
import { changeTitle } from "../../../../state/slices/header";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { RxCross1 } from "react-icons/rx";
import "../ticketing.css";
import Spinner from "react-bootstrap/Spinner";
import { myWebAppsListUser } from "../../../../api/web_app";
import { GetTicketColumnSettingConfig } from "../../../../api/ticketing";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";

const createOption = (label) => ({
  label,
  value: label,
});
function UpdateTicket({
  setTicketEditModal,
  getMyTicketsList,
  getTicketListData,
  TicketData,
}) {
  const [loading, setLoading] = useState(false);
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const ws_endpoint = `${serverURL.replace(
    /http:|https:/gi,
    ws_protocol + ":"
  )}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const user_id = useSelector((state) => state.user?.user?.id || "");
  const [sessionkey, setSessionkey] = useState(null);
  const [resourcesList, setResourcesList] = useState(null);
  const options = [
    { value: "APP", label: "App" },
    { value: "ASSET", label: "Asset" },
    { value: "WEB_APP", label: "Web App" },
  ];
  const [resourceType, setResourceType] = useState(TicketData.resource_type);
  const [resourceId, setResourceId] = useState(null);
  const [reason, setReason] = useState(TicketData.reason);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [nowDate, setNowDate] = useState();
  const [nowTime, setNowTime] = useState();
  const [showFeedback, setShowFeedback] = useState(false);
  const dispatch = useDispatch();
  const [fieldList, setFieldList] = useState();
  const [additionalFields, setAdditionalFields] = useState(TicketData.additional_fields);

  useEffect(() => {
    if (resourceType == "APP") {
      TicketData.resource.label = `ID : ${TicketData.resource.id} , Name : ${TicketData.resource.app_name}`;
      TicketData.resource.value = TicketData.resource.id;
      setResourceId(TicketData.resource);
    } else if (resourceType == "ASSET") {
      TicketData.resource.label = `ID : ${TicketData.resource.id} , Name : ${TicketData.resource.asset_name}`;
      TicketData.resource.value = TicketData.resource.id;
      setResourceId(TicketData.resource);
    }
  }, []);

  useEffect(() => {
    dispatch(changeTitle("Ticket"));
    const now = new Date();
    setNowDate(
      now.getFullYear() +
        "-" +
        (now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) +
        "-" +
        (now.getDate() < 9 ? "0" + now.getDate() : now.getDate())
    );
    setNowTime(
      (now.getHours() <= 9 ? "0" + now.getHours() : now.getHours()) +
        ":" +
        (now.getMinutes() <= 9 ? "0" + now.getMinutes() : now.getMinutes())
    );

    handleTicketing();
    GetTicketColumnSetting();

    let TicketFromDate = new Date(TicketData.access_from * 1000);
    setFrom(
      TicketFromDate.getFullYear() +
        "-" +
        (TicketFromDate.getMonth() < 9
          ? "0" + (TicketFromDate.getMonth() + 1)
          : TicketFromDate.getMonth() + 1) +
        "-" +
        (TicketFromDate.getDate() <= 9
          ? "0" + TicketFromDate.getDate()
          : TicketFromDate.getDate())
    );
    setFromTime(
      (TicketFromDate.getHours() <= 9
        ? "0" + TicketFromDate.getHours()
        : TicketFromDate.getHours()) +
        ":" +
        (TicketFromDate.getMinutes() <= 9
          ? "0" + TicketFromDate.getMinutes()
          : TicketFromDate.getMinutes())
    );

    let TicketToDate = new Date(TicketData.access_until * 1000);

    setTo(
      TicketToDate.getFullYear() +
        "-" +
        (TicketToDate.getMonth() < 9
          ? "0" + (TicketToDate.getMonth() + 1)
          : TicketToDate.getMonth() + 1) +
        "-" +
        (TicketToDate.getDate() <= 9
          ? "0" + TicketToDate.getDate()
          : TicketToDate.getDate())
    );
    setToTime(
      (TicketToDate.getHours() <= 9
        ? "0" + TicketToDate.getHours()
        : TicketToDate.getHours()) +
        ":" +
        (TicketToDate.getMinutes() <= 9
          ? "0" + TicketToDate.getMinutes()
          : TicketToDate.getMinutes())
    );
  }, []);


  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };
  const GetTicketColumnSetting = async () => {
    const { data, error } = await GetTicketColumnSettingConfig();
    if (data !== null) {
      setFieldList(data.fields);
    }
  };

  const getAppsList = async () => {
    const { data, error } = await myAppsListUser({
      userid: TicketData.requested_by,
      page: 1 - 1,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.apps.map((app) => {
          app.label = `ID : ${app.id} , Name : ${app.app_name}`;
          app.value = app.id;
          return app;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  const getAssetsList = async () => {
    const { data, error } = await myAssetsListUser({
      userid: TicketData.requested_by,
      page: 1 - 1,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.assets.map((asset) => {
          asset.label = `ID : ${asset.id} , Name : ${asset.asset_name}`;
          asset.value = asset.id;
          return asset;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  const getWebAppsList = async () => {
    const { data, error } = await myWebAppsListUser({
      userid: TicketData.requested_by,
      page: 0,
      limit: 9999,
      search: "",
      filterBy: "",
    });
    if (data !== null) {
      setResourcesList(
        data.web_apps.map((web_app) => {
          web_app.label = `ID : ${web_app.id} , Name : ${web_app.app_name}`;
          web_app.value = web_app.id;
          return web_app;
        })
      );
    }
    if (error !== null) {
      showError(error);
    }
  };

  useEffect(() => {
    if (resourceType == "APP") {
      getAppsList();
    }
    if (resourceType == "ASSET") {
      getAssetsList();
    }
    if (resourceType == "WEB_APP") {
      getWebAppsList();
    }
  }, [resourceType]);

  const sendTicket = async (put_data) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_true/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: put_data,
          session_key: sessionkey,
        })
      );
    };
  };

  const UpdateTicketFormHandler = async (event) => {
    if (!loading) {
      setShowFeedback(true);
      const form = event.currentTarget;
      let finalFromDate, finalToDate;
      finalFromDate = new Date(from + " " + fromTime + ":00");
      finalToDate = new Date(to + " " + toTime + ":59");

      event.preventDefault();
      if (
        form.checkValidity() === false ||
        from === null ||
        to == null ||
        resourceType === "" ||
        resourceId === null ||
        reason === null ||
        fromTime === null ||
        toTime === null
      ) {
        event.stopPropagation();
      } else {
        setLoading(true);
        let put_data = {
          ...(TicketData && { id: TicketData.id }),
          ...(resourceType !== "" && { resource_type: resourceType }),
          ...(resourceId !== "" && { resource_id: resourceId.value }),
          ...(reason !== "" && { reason: reason }),
          ...(from !== "" && { access_from: Date.parse(finalFromDate) / 1000 }),
          ...(to !== "" && { access_until: Date.parse(finalToDate) / 1000 }),
          ...(additionalFields && { additional_fields: additionalFields }),
        };
        const { data, error } = await UpdateTicketDetails(put_data);
        if (data !== null) {
          sendTicket(put_data);
          showSuccess(data.message);
          getMyTicketsList(getTicketListData);
          setTicketEditModal((o) => !o);
          setLoading(false);
        }
        if (error !== null) {
          showError(error);
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      {resourcesList && (
        <Container fluid className="alert_container mx-auto">
          <Form onSubmit={UpdateTicketFormHandler}>
            <div className="d-flex justify-content-between mb-3">
              <span className="main_content_heading">Update Ticket</span>
              <CrossButton onClick={() => setTicketEditModal((o) => !o)}></CrossButton>
            </div>
            <Stack gap={1}>
              <Row className="mb-3">
              {fieldList &&
                fieldList.map((field, index) => {
                  const isDateColumn =
                    field[Object.keys(field)[1]]["ColumnType"] === "Date";
                  return isDateColumn ? (
                    <div className="mt-3 mx-0">
                      <Row
                        className="date_component_ticketing mx-0 mb-2 pt-2 pb-3 justify-content-between"
                      >
                        <Col md={6}>
                          <Form.Label column md={6} className="input_label">
                            Access From <span className="text-danger">*</span>
                          </Form.Label>
                          <Row>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="date"
                                value={from}
                                min={nowDate}
                                onChange={(event) =>
                                  setFrom(event.target.value)
                                }
                                placeholder="MyTickets"
                                isInvalid={from === null}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="time"
                                min={from > nowDate ? "00:00" : nowTime}
                                value={fromTime}
                                onChange={(event) =>
                                  setFromTime(event.target.value)
                                }
                                isInvalid={fromTime === null}
                              />
                            </Col>
                            {showFeedback &&
                              (fromTime === null || from === null) && (
                                <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                                  Please Enter a Valid Access From Date-Time
                                </span>
                              )}
                          </Row>
                        </Col>
                        <Col className="left_border_date_ticketing" md={6}>
                          <Form.Label column md={6} className="input_label">
                            Access Until <span className="text-danger">*</span>
                          </Form.Label>
                          <Row>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="date"
                                min={from}
                                value={to}
                                onChange={(event) => setTo(event.target.value)}
                                isInvalid={to === null}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                className="form_date_input_field py-2 pe-2"
                                type="time"
                                value={toTime}
                                min={to > from ? "00:00" : fromTime}
                                onChange={(event) =>
                                  setToTime(event.target.value)
                                }
                                placeholder="MyTickets"
                                isInvalid={toTime === null}
                              />
                            </Col>
                            {showFeedback &&
                              (toTime === null || to === null) && (
                                <span className="form_date_input_field text-danger fs-14px py-2 pe-2">
                                  Please Enter a Valid Access Until Date-Time
                                </span>
                              )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : Object.keys(field)[1] == "Resource Type" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Resource Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                    onChange={(event) => {
                      setResourceId(null);
                      setResourceType(event.value);
                    }}
                    options={options}
                    defaultValue={
                      options[
                        options.findIndex((item) => item.value === resourceType)
                      ]
                    }
                    required
                  />
                        {showFeedback && !resourceType && (
                          <span className="text-danger fs-14px p-0 m-0">
                            Please select a Resource Type.
                          </span>
                        )}
                      </Col>
                    </>
                  ) : Object.keys(field)[1] == "Resource Name" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Resource Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                    required
                    onChange={(event) => {
                      setResourceId(event);
                    }}
                    options={resourcesList}
                    value={resourceId}
                    defaultValue={
                      resourcesList[
                        resourcesList.findIndex(
                          (item) => item.value === resourceId
                        )
                      ]
                    }
                    isInvalid={resourceId === null}
                    placeholder={
                      resourceType == "APP" ||
                      resourceType == "ASSET" ||
                      resourceType == "WEB_APP"
                        ? `Select an ${resourceType.toLowerCase()}`
                        : `Select..`
                    }
                    noOptionsMessage={() =>
                      resourceType == "APP" ||
                      resourceType == "ASSET" ||
                      resourceType == "WEB_APP"
                        ? `No ${resourceType.toLowerCase()}s available`
                        : `No options available`
                    }
                  />
                      </Col>
                    </>
                  ) : Object.keys(field)[1] == "Reason" ? (
                    <>
                      <Col md={6} className="my-2">
                        <Form.Label column md={6} className="input_label">
                          Reason <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                  required
                    className="form_input_field"
                    as="textarea"
                    rows={1}
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    placeholder="Enter your reason here"
                    isInvalid={reason === null}
                  />
                      </Col>
                    </>
                  ) : (
                    <Col md={field[Object.keys(field)[1]][
                      "col"
                    ]} className="my-2">
                      <Form.Label column md={6} className="input_label label_word_wrap">
                        {Object.keys(field)[1]}{" "}
                        {field[Object.keys(field)[1]][
                                          "required"
                                        ] && (
                                          <span className="text-danger">*</span>
                                        )}
                      </Form.Label>
                      {field[Object.keys(field)[1]]["ColumnType"] ==
                      "Dropdown" ? (
                        <>
                          <Select
                            onChange={(event) => {
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.value,
                              }));
                            }}
                            defaultValue={createOption(
                              additionalFields[Object.keys(field)[1]]
                            )}
                            options={field[Object.keys(field)[1]][
                              "Options"
                            ].map((option, index) => {
                              return createOption(option);
                            })}
                            required = {field[Object.keys(field)[1]]["required"]}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "Number" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="bg-white py-2 form_input_field_slt_modal"
                            type="number"
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "ParagraphText" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="form_input_field"
                            as="textarea"
                            rows={1}
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "SingleLineText" ? (
                        <>
                          <Form.Control
                            required = {field[Object.keys(field)[1]]["required"]}
                            className="bg-white py-2 form_input_field_slt_modal"
                            type="text"
                            value={additionalFields[Object.keys(field)[1]]}
                            onChange={(event) =>
                              setAdditionalFields((prevData) => ({
                                ...prevData,
                                [Object.keys(field)[1]]: event.target.value,
                              }))
                            }
                            placeholder={`Enter your ${
                              Object.keys(field)[1]
                            } here`}
                          />
                        </>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "Checkbox" ? (
                        <div key={`inline-checkbox`} className="mt-3">
                          {field[Object.keys(field)[1]]["Options"].map(
                            (optionitem, index) => (
                              <>
                                <Form.Check
                                  defaultChecked={additionalFields[Object.keys(field)[1]].includes(optionitem)}
                                  inline
                                  label={optionitem}
                                  name={optionitem}
                                  type="checkbox"
                                  id={`inline-checkbox-1`}
                                  onChange={(event) => {
                                    const { name, id, checked } = event.target;
                                    if (checked) {
                                      additionalFields[
                                        Object.keys(field)[1]
                                      ].push(name);
                                      setAdditionalFields((prevData) => ({
                                        ...prevData,
                                        [Object.keys(field)[1]]:
                                          additionalFields[
                                            Object.keys(field)[1]
                                          ],
                                      }));
                                    } else {
                                      let selectedValues = additionalFields[
                                        Object.keys(field)[1]
                                      ].filter((item) => item !== name);
                                      setAdditionalFields((prevData) => ({
                                        ...prevData,
                                        [Object.keys(field)[1]]: selectedValues,
                                      }));
                                    }
                                  }}
                                />
                              </>
                            )
                          )}
                        </div>
                      ) : field[Object.keys(field)[1]]["ColumnType"] ==
                        "RadioButton" ? (
                        <div key={`inline-radio`} className="mt-3">
                          {field[Object.keys(field)[1]]["Options"].map(
                            (optionitem, index) => (
                              <>
                                <Form.Check
                                  inline
                                  defaultChecked={
                                    additionalFields[Object.keys(field)[1]] == optionitem
                                  }
                                  label={optionitem}
                                  name="group1"
                                  type="radio"
                                  id={`inline-radio-${index + 1}`}
                                  onChange={() =>
                                    setAdditionalFields((prevData) => ({
                                      ...prevData,
                                      [Object.keys(field)[1]]: optionitem,
                                    }))
                                  }
                                />
                              </>
                            )
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  );
                })}
              
              </Row>
              <Row className="pt-4 pb-2 heading_title_bottom justify-content-end">
                <Col sm="auto">
                  <GeneralButton
                    onClickEvent={() => setTicketEditModal((o) => !o)}
                    className="me-1"
                    value="Cancel"
                    color="#505050"
                    variant="outlined"
                    size="large"
                  ></GeneralButton>
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {loading ? (
                    <GeneralButton
                      variant="contained"
                      disabled={true}
                      className="me-1"
                      value={<img src={images.Loader} width="26px" height="26px" />}
                      size="large"
                    ></GeneralButton>
                  ) : (
                    <GeneralButton
                      className="me-1"
                      value="Update"
                      variant="contained"
                      size="large"
                    ></GeneralButton>
                  )}
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      )}
    </>
  );
}
export default UpdateTicket;
