import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GeneralButton from "../../common/SaveButton/GeneralButton";
import "react-phone-input-2/lib/style.css";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
const AdvanceFilter = ({ setOpenFilterPopup, setFrom, setTo,  setChangeState }) => {
  const date = new Date();
  let currentDay = String(date.getDate()).padStart(2, "0");
  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  let currentYear = date.getFullYear();
  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const [value, setValue] = React.useState(dayjs(currentDate));
  const [nextvalue, setNextvalue] = React.useState(dayjs(currentDate));

  const getFormatedDate = (prop) => {
    let getDay = String(prop.getDate()).padStart(2, "0");
    let getMonth = String(prop.getMonth() + 1).padStart(2, "0");
    let getYear = prop.getFullYear();
    let getDate = `${getYear}-${getMonth}-${getDay}`;
    return getDate;
  };

  const getDate = (prop) => {
    const date = new Date();
    const get_date = new Date(date.getTime());
    get_date.setDate(date.getDate() - prop);
    return getFormatedDate(get_date);
  };

  const shouldDisableDateFrom = (date) => {
    return date.isAfter(dayjs(currentDate), "day");
  };

  const shouldDisableDateTo = (date) => {
    return (
      date.isAfter(dayjs(currentDate), "day") ||
      date.isBefore(dayjs(value), "day")
    );
  };

  const manualTabColor = (days) =>{
    if(new Date(value).getDate() == new Date(dayjs(getDate(days))).getDate() && new Date(nextvalue).getDate() == new Date(currentDate).getDate()){
      return true
    }
    else{
      return false
    }
  }
  const manualTabColorRange = () =>{
    if((new Date(value).getDate() != new Date(dayjs(getDate(0))).getDate() &&
    new Date(value).getDate() != new Date(dayjs(getDate(1))).getDate() &&
    new Date(value).getDate() != new Date(dayjs(getDate(7))).getDate() &&
    new Date(value).getDate() != new Date(dayjs(getDate(30))).getDate() &&
    new Date(value).getDate() != new Date(dayjs(getDate(date.getDate() - 1))).getDate() ||
    new Date(nextvalue).getDate() != new Date(dayjs(currentDate)).getDate())){
      return true
    }
    else{
      return false
    }

}


  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Row className="border-bottom w-100">
          <Col lg={3} className="bg-home p-4 pt-4 pt-2">
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                {manualTabColor(0) ? <p
                className="bg-white p-2 Date_options_tab" role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(0)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Today
                </p> : <p
                className="p-2" role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(0)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Today
                </p>
                }
              </Col>
            </Row>
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                {(manualTabColor(1) && new Date(nextvalue).getDate() == new Date(currentDate).getDate()) ? <p
                  className='bg-white p-2 Date_options_tab' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(1)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 24 hours
                </p> : <p
                  className=' p-2' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(1)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 24 hours
                </p>}
              </Col>
            </Row>
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                { manualTabColor(7) ? <p
                  className='bg-white p-2 Date_options_tab' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(7)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 7 Days
                </p> : <p
                  className='p-2' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(7)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 7 Days
                </p>}
              </Col>
            </Row>
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                {manualTabColor(30) ? <p
                  className='bg-white p-2 Date_options_tab' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(30)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 30 Days
                </p> : <p
                  className='p-2' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(30)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  Last 30 Days
                </p>}
              </Col>
            </Row>
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                {manualTabColor(date.getDate() - 1) ? <p
                  className='bg-white p-2 Date_options_tab' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(date.getDate() - 1)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  This Month
                </p> : <p
                  className='p-2' role="button"
                  onClick={() => {
                    setValue(dayjs(getDate(date.getDate() - 1)));
                    setNextvalue(dayjs(currentDate));
                  }}
                >
                  This Month
                </p>}
              </Col>
            </Row>
            {/* <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                <p className='bg-white p-2 Date_options_tab' role="button">Last Month</p>
              </Col>
            </Row> */}
            <Row>
              <Col className="ps-4 pb-1 ff-poppins fs-14px fw-500">
                {manualTabColorRange() ? <p className='bg-white p-2 Date_options_tab' role="button">Custom Range</p> : <p className=' p-2' onClick={() => {
                    setValue(dayjs(getDate(2)));
                    setNextvalue(dayjs(currentDate));
                  }} role="button">Custom Range</p>}
              </Col>
            </Row>
          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={5} className="bg-white ps-0 pt-4">
                <p className="ff-poppins mb-0 fs-16px fw-500 d-flex align-items-center justify-content-center">
                  {new Date(value).toDateString("en")}
                </p>
              </Col>
              <Col lg={2} className="bg-white ps-0 pt-4">
                <p className="ff-poppins mb-0 fs-16px fw-500 d-flex align-items-center justify-content-center">
                  To
                </p>
              </Col>
              <Col lg={5} className="bg-white ps-0 pt-4">
                <p className="ff-poppins mb-0 fs-16px fw-500 d-flex align-items-center justify-content-center">
                  {new Date(nextvalue).toDateString("en")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="bg-white ps-0 pt-2">
                <DateCalendar
                  shouldDisableDate={shouldDisableDateFrom}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                  views={["year", "month", "day", "hour"]}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </Col>

              <Col lg={6} className="bg-white ps-0 pt-2">
                <DateCalendar
                  shouldDisableDate={shouldDisableDateTo}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                  views={["year", "month", "day"]}
                  value={nextvalue}
                  onChange={(newValue) => {
                    setNextvalue(newValue);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="p-3 pb-5">
          <GeneralButton
                onClickEvent={() => {
                  setFrom(getFormatedDate(new Date(value)));
                  setTo(getFormatedDate(new Date(nextvalue)));
                  setChangeState(Object.create(null));
                  setOpenFilterPopup((o) => !o);
                }}
                className="me-1 float-end"
                value="Apply"
                variant="contained"
                size="large"
              />

          <GeneralButton
                onClickEvent={() => setOpenFilterPopup((o) => !o)}
                className="float-end me-2"
                value="Cancel"
                color="#505050"
                variant="outlined"
                size="large"
              />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default AdvanceFilter;
