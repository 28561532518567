import React from "react";
import icon from "./icons/icon.svg";
import "./report.scss";
import arrow_first from "./icons/arrow_first.svg";
import arrow_last from "./icons/arrow_last.svg";
import arrow_right from "./icons/arrow_right.svg";
import arrow_left from "./icons/arrow_left.svg";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch } from "react-redux";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import CustomModal from "../../common/CustomModal/CustomModal";
import AdvanceFilter from "./AdvanceFilter";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { useSelector } from "react-redux";
import { formatted_timedate } from "../../../utils/updateDateFormat";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../utils/transitionEffectParams";
import { fetchData, ActionComponent } from "./getReportsData";

function Users() {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  /* customize column modal functionality starts. */
  const UserColumns = [
    { label: "Email", value: "email" },
    { label: "IP/Host", value: "ip" },
    { label: "Status", value: "status" },
    { label: "User Agent", value: "user_agent" },
  ];
  const pages_url = "audit-dashboard/report_users/";
  const customizeColumnDict = { "User Agent": false, Datetime: true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const [allFields, setAllFields] = useState([]);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "IP/Host",
        selector: (row) => row.ip,
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: "User Agent",
        selector: (row) => row.user_agent,
        omit: !checkedStateDict["User Agent"],
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) =>
          row.status === "Login Success" || row.status === "Logout Success" ? (
            <span className="text-success"> {row.status}</span>
          ) : (
            <span className="text-danger"> {row.status}</span>
          ),
        sortable: true,
        center: true,
      },
      {
        name: "Datetime",
        selector: (row) => formatted_timedate(row.datetime),
        omit: !checkedStateDict["Datetime"],
        sortable: true,
      },
    ],
    [checkedStateDict]
  );
  /* customize column logic ends. */

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_UserLoginReport") !== null
      ? localStorage.getItem("rowsPerPage_UserLoginReport")
      : 5
  );
  const [reportData, setReportData] = useState([]);
  const [advanceSearchToggle, setAdvanceSearchToggle] = useState(false);
  const [totalReportData, setTotalReportData] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const pageType = "users_audit";
  const advancedFilters = useSelector((state) => state.advanceFilter);
  const advancedQuery = advancedFilters.advanceQuery[pageType];
  const selectedValuesList = advancedFilters.selectedValuesList;
  const keysToExclude = ["city", "id", "user_name", "user_id", "datetime"];

  const getData = async (
    page,
    perPage,
    search,
    report_type,
    response_type,
    filterBy,
    advanceFilters
  ) => {
    fetchData(
      page,
      perPage,
      search,
      report_type,
      response_type,
      filterBy,
      setReportData,
      setTotalReportData,
      setFilteredData,
      setLoading,
      setPending,
      setAllFields,
      allFields,
      keysToExclude,
      advanceFilters,
      dispatch,
      selectedValuesList
    );
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
  }, [perPage]);

  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const filter_set_data = (filteredData, count) => {
    setFilteredData(filteredData);
    setTotalReportData(count);
    setReportData(filteredData);
  };

  const handlePageChange = async (page) => {
    getData(page, perPage, search, pageType, "table", filterBy, advancedQuery);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getData(
      page,
      newPerPage,
      search,
      pageType,
      "table",
      filterBy,
      advancedQuery
    );
    localStorage.setItem("rowsPerPage_UserLoginReport", newPerPage);
    setPerPage(newPerPage);
  };

  //for searching in frontend.
  useEffect(() => {
    const result = reportData.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "all") {
        if (search === "") {
          getData(
            1,
            perPage,
            search,
            pageType,
            "table",
            filterBy,
            advancedQuery
          );
        } else {
          return (
            object.email.toLowerCase().match(searchs.toLowerCase()) ||
            object.ip.toLowerCase().match(searchs.toLowerCase()) ||
            object.user_agent.toLowerCase().match(searchs.toLowerCase()) ||
            object.datetime.toLowerCase().match(searchs.toLowerCase()) ||
            object.status.toLowerCase().match(searchs.toLowerCase())
          );
        }
      } else {
        if (search === "") {
          getData(
            1,
            perPage,
            search,
            pageType,
            "table",
            filterBy,
            advancedQuery
          );
        } else {
          return object[filterBy.toLowerCase()].match(searchs.toLowerCase());
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  const handleSearch = async (event) => {
    event.preventDefault();
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal padding="0px !important" open={openFilterPopup}>
        <AdvanceFilter setOpenFilterPopup={setOpenFilterPopup} />
      </CustomModal>
      <Slide
        timeout={delayTime}
        className=""
        direction={slideDirection}
        in={slideChecked}
      >
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="User Login Report"
                        dataTableHeadingIcon={icon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      <CustomDelaySkeleton
                        rowsPerPage={perPage}
                        colCount={colCount}
                        totalColumns={5}
                      />
                    }
                    fixedHeader
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalReportData}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    subHeader
                    actions={
                      !advanceSearchToggle && (
                        <ActionComponent
                          getData={getData}
                          pageType={pageType}
                          search={search}
                          filter_by={filterBy}
                          download_report={
                            filteredData.length > 0 ? true : false
                          }
                          disable_buttons={
                            filteredData.length > 0 ? true : false
                          }
                        />
                      )
                    }
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="user login reports"
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        filterOptions={UserColumns}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                        advanceSearchToggle={advanceSearchToggle}
                        showAdvance={true}
                        setAdvanceSearchToggle={setAdvanceSearchToggle}
                        filter_set_data={filter_set_data}
                        page={1}
                        perPage={perPage}
                        getData={getData}
                        for_userlogin={true}
                        all_fields={allFields}
                        report_type={pageType}
                        download_report={filteredData.length > 0 ? true : false}
                        disable_buttons={filteredData.length > 0 ? true : false}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={arrow_first} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={arrow_last} alt="last page" />
                    }
                    paginationIconNext={<img src={arrow_right} alt="next" />}
                    paginationIconPrevious={
                      <img src={arrow_left} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
}
export default Users;
