import axios from './axios_interceptor';
import { get_jwt_token } from "./helper_funtions";
import { loginRedirect } from './helper_funtions';

export const getPasswordPolicy = async (association_group_value=null)=>{
    try{
      const token=get_jwt_token();
      loginRedirect(token)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
      const url=`/vaults/policy/?association_group=${association_group_value}`
      const response=await axios.get(url,config);
      return {data:response.data,error:null}
    }catch (e){
      return {data:null,error:e.response.data.errors}
    }
  }

  export const getPasswordPolicyAdd = async (id=null) => {
    try{
      const token=get_jwt_token();
      loginRedirect(token)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
      const url=`/vaults/policy-crud/?policy_id=${id}`
      const response=await axios.get(url,config);
      return {data:response.data,error:null}
    }catch(e){
      return {data:null,error: e.response.data.errors}
    }
  }

  export const getEmailConfigBoolean = async () => {
    try{
      const token=get_jwt_token();
      loginRedirect(token)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
      const url=`/vaults/password-policy-email-config/`
      const response=await axios.get(url,config);
      return {data:response.data,error:null}
    }catch(e){
      return {data:null,error:e.response.data}
    }
  }

export const updatePassPolicy = async(args,id)=>{
  try{
    const token=get_jwt_token();
      loginRedirect(token)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
      const url=`/vaults/policy-crud/`
      const response=await axios.put(url,args,config);
      return {data:response.data,error:null}
  }catch(e){
    return {data:null,error:e.response.data.errors}
  }
}
export const createPassPolicy = async(args,id)=>{
  try{
    const token=get_jwt_token();
      loginRedirect(token)
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
      const url=`/vaults/policy-crud/`
      const response=await axios.post(url,args,config);
      return {data:response.data,error:null}
  }catch(e){
    return {data:null,error:e.response.data.errors}
  }
}

export const deletePasswordPolicy = async ({ ids }) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids: ids,
      },
    };
    const url = `/vaults/policy-crud/`;
    const response = await axios.delete(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response.data.errors };
  }
};

export const getEmailDefaultPolicy = async (token)=>{
  try{
    const url=`/users/update-password/?token=${token}`
    const response=await axios.get(url);
    return {data:response.data,error:null}
  }catch (e){
    return {data:null,error:e.response.data.errors}
  }
}
export const updateUserPassword = async(args)=>{
  try{
    const url=`/users/update-password/`
    const response=await axios.post(url,args);
    return {data:response.data,error:null}
  }catch (e){
    return {data:null,error:e.response.data.errors}
  }
}
export const getPasswordPoliciesDatatable = async (page=0, perPage, search, filterBy) => {
  try {
    const token = get_jwt_token();
    loginRedirect(token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    let url = `/vaults/policies/?page=${page}&limit=${perPage}&search=${search}&filter_by=${filterBy}`;
    const response = await axios.get(url, config);
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: e.response };
  }
};