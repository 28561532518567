import { Fade, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  allWebAppsAllocationList,
  webAppAllocateToRoles,
} from "../../../../api/web_app";
import assetHeadingIcon from "../../../../images/User_heading_icon.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import TimeComp from "../../../common/TimeComponent/TimeComp";
import { SystemUserResourceList } from "../../../../api/systemuser";

const AllocateRoleToWebApp = ({ roleName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const { role_id } = useParams();
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_AllocateUserToWebApp") !== null
      ? localStorage.getItem("rowsPerPage_AllocateUserToWebApp")
      : 5
  );
  const [webAppsList, setWebAppsList] = useState([]);
  const [webApp, setWebApp] = useState(null);
  const [totalWebApps, setTotalWebApps] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAllocateRole, setOpenAllocateRole] = useState(false);
  const [toBeAllocate, setToBeAllocate] = useState([]);
  const [colName, setColName] = useState(null);
  const [checkedState, setCheckedState] = useState([]);
  const pages_url = "dashboard/roles/resource-allocate";
  const [slideChecked, setSlideChecked] = useState(false);
  const [pending, setPending] = useState(false);
  const [colCount, setColCount] = useState(null);

  useEffect(() => {
    setSlideChecked(true);
    dispatch(changeTitle("AllocateWebApps"));
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  const customizeColumnDict = { "Web App Id": true, "Login Page URL": false };

  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const webAppColumns = [
    { label: "Web App Id", value: "id" },
    { label: "Web App Name", value: "app_name" },
    { label: "Login Page URL", value: "login_page_url" },
    { label: "Web App Type", value: "app_type" },
  ];
  const actionOptions = [{ label: "Allocate", value: "allocate" }];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getAllWebAppList();
  }, [page, rowsPerPage, dispatch, webApp]);

  const getAllWebAppList = async () => {
    setPending(true);
    const { data, error } = await allWebAppsAllocationList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
      entity_id: role_id,
      action: "get_not_allocated_web_apps_to_role",
    });
    if (data !== null) {
      setWebAppsList(data.result);
      setFilteredData(data.result);
      setTotalWebApps(data.totalResults);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getAllWebAppList();
  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = webAppsList.filter((object) => {
      return (
        object.app_name.toLowerCase().match(s.toLowerCase()) ||
        object.app_type.toLowerCase().match(s.toLowerCase()) ||
        object.login_page_url.toLowerCase().match(s.toLowerCase()) ||
        object.id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_AllocateRoleToWebApp", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "allocate") {
      handleAllocate(null);
    }
  };

  const handleAllocate = async (row) => {
    let rowsToAllocate = [];
    if (row) {
      rowsToAllocate = row;
    } else {
      rowsToAllocate = selectedRows;
    }
    setToBeAllocate(rowsToAllocate);
    let temp = [];
    temp = rowsToAllocate.map((item) => ({ id: item.id, name: item.app_name }));
    setColName(temp);
    setOpenAllocateRole((o) => !o);
  };

  const handleAllocateAction = async (finalFromDate, finalToDate, policy, systemuser_id, allocation_type) => {
    let ids = toBeAllocate.map((item) => item.id);
    const { data, error } = await webAppAllocateToRoles({
      role_ids: [role_id],
      systemuser_id: systemuser_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      web_app_ids: ids,
      allocation_type: allocation_type,
    });
    if (data !== null) {
      showSuccess(data.message);
      setWebApp(Object.create(null));
      setOpenAllocateRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Web App Id",
      selector: (row) => row.id,
      grow: 1,
      omit: !checkedStateDict["Web App Id"],
    },
    {
      name: "Web App Name",
      selector: (row) => row.app_name,
      grow: 1,
    },
    {
      name: "Login Page URL",
      selector: (row) => row.login_page_url,
      omit: !checkedStateDict["Login Page URL"],
    },
    {
      name: "Web App Type",
      selector: (row) => row.app_type,
      grow: 1,
    },
    {
      name: "Action",
      cell: (row) =>
        isCapable &&
        isCapable.web_apps.allocate_web_apps && (
          <>
            <p
              className="myassetslink"
              onClick={async () => {
                handleAllocate([row]);
              }}
            >
              <AiOutlineUserAdd></AiOutlineUserAdd>Allocate
            </p>
          </>
        ),
      middle: true,
      minWidth: "50px",
    },
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "AllocateWebApps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/User_heading_icon.svg")
                              .default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Allocate Web Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName && (
                  <CustomModal
                    open={openAllocateRole}
                    handleClose={() => {
                      setOpenAllocateRole((o) => !o);
                    }}
                  >
                    <TimeComp
                      setOpenAlert={setOpenAllocateRole}
                      handleAlertAction={handleAllocateAction}
                      SystemUserResourceList={SystemUserResourceList}
                      colName={colName}
                      target_entity_name={roleName}
                      target_type="Web Apps"
                      resource_type={"webapps"}
                      alertMsg="Below web apps will be allocated for role"
                      headingMsg="Allocate Web Apps"
                      reverse_allocate={true}
                      show_policy={false}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`Role : ${roleName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                     (
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={3}
                        />
                      )
                    }
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalWebApps}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        actions={ActionComponent}
                        placeholder="web apps"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={webAppColumns}
                        actionOptions={
                          isCapable && isCapable.web_apps.allocate_web_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        setCheckedStateDict={setCheckedStateDict}
                        checkedStateDict={checkedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default AllocateRoleToWebApp;
