import { Fade, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { liveuserslist } from "../../../api/report";
import CrossIcon from "../../../images/cross.svg";
import useWindowDimensions from "../../../utils/getHeightWidth";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../utils/transitionEffectParams";
import CustomModal from "../../common/CustomModal/CustomModal";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import icon from "./icons/icon.svg";
import { getLiveStreamInfo } from "../../../state/slices/customer";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { changeTitle } from "../../../state/slices/header";
import GetCapabilities from "../../../utils/getCapabilities";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../common/ButtonSpinner/CrossButton";

const Activeusers = () => {
  let listusers = [];
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 300 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const dispatch = useDispatch();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const customer_id = useSelector((state) => state.user?.user?.customer);
  const ws_protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const session_key = useSelector((state) => state.user?.session_key);
  const ws_endpoint = `${serverURL.replace(
    /http:|https:/gi,
    ws_protocol + ":"
  )}/ws/livesession/${customer_id}/?session_key=${session_key}`;
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const customizeColumnDict = { "Session ID": false, "Asset/App Id": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const pages_url = "dashboard/live";
  const [slideChecked, setSlideChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openTerminateConfirm, setOpenTerminateConfirm] = useState(false);
  const [UserConfirm, setUserConfirm] = useState({});
  const { liveStream } = useSelector((state) => state.customer);
  let c = 0;
  useEffect(() => {
    setSlideChecked(true);
    dispatch(getLiveStreamInfo());
  }, []);

  const title_heading = useSelector((state) => state.header.title);
  const liveStreamColumn = [];
  useEffect(() => {
    setCount(0);
    dispatch(changeTitle("Live"));
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    if (liveStream == "true") {
      const recording_ws_connection = new WebSocket(ws_endpoint);
      recording_ws_connection.onopen = function (e) {
        recording_ws_connection.send(
          JSON.stringify({
            type: "active_tabs_list",
            message: "get_tab_id",
            session_key: session_key,
          })
        );
        recording_ws_connection.onmessage = function (e) {
          const data = JSON.parse(e.data);
          if (data.type === "active_users") {
            if (window.name != data.message.tab_id) {
              listusers.push(data.message);
            }
            const distinctlist = [...new Set(listusers)];

            listusers = distinctlist;
            getlist();
          }
          if (data.type === "remove_row") {
            listusers = listusers.filter(
              (item) => item["tab_id"] !== data.message
            );
            getlist();
          }
        };
      };
    } else {
      setFilteredData([]);
    }
  }, [liveStream]);
  const handleAction = (prop, user) => {
    window.open(`${window.location.origin}/livestream/${prop}/${user}`);
  };
  const getlist = async () => {
    const { data, error } = await liveuserslist({
      data: listusers,
    });
    setUsers(data);
    if (data) {
      setFilteredData(data);
    }
  };
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = users.filter((object) => {
      return (
        object.username.toLowerCase().match(s.toLowerCase()) ||
        object.tab_id.toLowerCase().match(s.toLowerCase()) ||
        object.session_type.toLowerCase().match(s.toLowerCase()) ||
        object.session_type_id.toString().toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);
  const columns = [
    {
      name: "User",
      selector: (row) => row.username,
      center: true,
      sortable: true,
    },

    {
      name: "Session ID",
      selector: (row) => row.tab_id,
      center: true,
      omit: !checkedStateDict["Session ID"],
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.session_type,
      center: true,
      sortable: true,
    },
    {
      name: "Resource Name",
      selector: (row) => row.resource_name,
      center: true,
      sortable: true,
    },
    {
      name: "Resource Id",
      selector: (row) => row.session_type_id,
      center: true,
      omit: !checkedStateDict["Asset/App Id"],
      sortable: true,
    },

    {
      name: "Live View",
      cell: (row) => (
        <p
          className="myassetslink"
          onClick={() => {
            handleAction(row.tab_id, row.username);
          }}
        >
          View
        </p>
      ),
      center: true,
    },
    isCapable &&
      isCapable.miscellaneous.setting.kill_session && {
        name: "Action",
        cell: (row) => (
          <>
            <p
              className="myassetslinkdanger"
              onClick={() => {
                setOpenTerminateConfirm((o) => !o);
                setUserConfirm({
                  username: row.username,
                  tab_id: row.tab_id,
                  session_type: row.session_type,
                });
              }}
            >
              Terminate
            </p>
          </>
        ),
        center: true,
      },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Live":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Sessions
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Real-Time Sessions"
                        dataTableHeadingIcon={icon}
                      />
                    }
                    className="rdt_container overflow-auto"
                    columns={columns}
                    data={filteredData}
                    selectableRowsHighlight
                    highlightOnHover
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 15, 25, 50]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Records per page:",
                      rangeSeparatorText: "out of",
                    }}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        filterOptions={liveStreamColumn}
                        search={search}
                        setSearch={setSearch}
                        placeholder="user"
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
      <CustomModal
        open={openTerminateConfirm}
        handleClose={() => {
          setOpenTerminateConfirm((o) => !o);
        }}
      >
        {" "}
        <Container fluid className="alert_container mx-auto">
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h2 className="main_content_heading">Confirm</h2>
            <CrossButton
              onClick={() => setOpenTerminateConfirm((o) => !o)}
            ></CrossButton>
          </div>
          <div>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <p className="sub_heading">
                User - <strong>{UserConfirm.username}</strong>
              </p>
              <p className="ms-4 sub_heading">
                Session Type - <strong>{UserConfirm.session_type}</strong>
              </p>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <p className="sub_heading">
                Are you sure to terminate this session ?
              </p>
            </div>
          </div>
          <Row className="pt-4 mb-3 justify-content-end">
            <Col sm="auto">
              <Button
                variant="light"
                as="input"
                className="form_cancel_button"
                type="button"
                value="Cancel"
                onClick={() => setOpenTerminateConfirm((o) => !o)}
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              <Button
                as="input"
                className="form_submit_button"
                type="submit"
                value="Confirm"
                onClick={() => {
                  const recording_ws_connection = new WebSocket(ws_endpoint);
                  recording_ws_connection.onopen = function (e) {
                    recording_ws_connection.send(
                      JSON.stringify({
                        type: "kill_session",
                        message: UserConfirm.tab_id,
                        session_key: session_key,
                      })
                    );
                    setOpenTerminateConfirm((o) => !o);
                    toast.success("Session Terminated Successfully");
                  };
                }}
              />
            </Col>
          </Row>
        </Container>
      </CustomModal>
    </>
  );
};

export default Activeusers;
