import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { updateUserPassword } from "../../../../api/users";
import { showError, showSuccess } from "../../../../utils/showMessage";
import CrossIcon from "../../../../images/cross.svg";
import LightTooltip from "../../../common/CustomTooltip/CustomTooltip";
import InfoIcon from "../icons/icon-info.svg";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";
import { getPasswordPolicy } from "../../../../api/vault";
import {
  passwordPolicyRegex,
  showPasswordInvalidTooltip,
} from "../../../common/PasswordPolicy/PolicyRegex";
import { Image, InputGroup } from "react-bootstrap";
import { showErrorMsg } from "../../../../utils/showMessage";

const UpdatePassword = ({ setOpenUpdatePassword, setUsers, data }) => {
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] =
    useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#ced4da",
      boxShadow: "none",
      borderRadius: "0.375rem",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    setId(data.id);
    setUserName(data.username);
    setPassword(data.password);
    const getPassPolicy = async () => {
      const { data, error } = await getPasswordPolicy("pam_user");
      if (data !== null && data !== "") {
        setPolicy(data.policies[0]);
      }
      if (error !== null) {
        showError(error);
      }
    };
    getPassPolicy();
  }, []);

  const updateUserPasswordFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if(password !== confirmPassword){
        event.stopPropagation();
        setValidated(true);
        setPasswordIsInvalid(true);
        showErrorMsg("Password and confirm password does not match!");
      }else{
        const { data, error } = await updateUserPassword({
          id: id,
          ...(password !== "" ? { password: password } : { password: null }),
        });
        if (data !== null) {
          showSuccess(data.message);
          setUsers(new Object());
          setLoading(false);
          setOpenUpdatePassword((o) => !o);
        }
        if (error !== null) {
          showError(error);
          setLoading(false);
        }
      }
    }
  };

  //policy check after every email, first_name, last_name, username
  let email = data.email;
  let user_name = data.username;
  let first_name = data.first_name;
  let last_name = data.last_name;
  useEffect(() => {
    passwordPolicyRegex(policy, email, user_name, first_name, last_name);
  }, []);

  useEffect(() => {
    showPasswordInvalidTooltip(policy);
  }, [policy]);

  return (
    <Container fluid className="update_password_container mx-auto">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">
          Change Password for : {username}
        </h2>
        <CrossButton onClick={() => setOpenUpdatePassword((o) => !o)}></CrossButton>
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={updateUserPasswordFormHandler}
      >
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formPlainPassword"
          >
            <Form.Label column md={2} className="input_lable">
              Password
            </Form.Label>
            <Col md={9}>
              <InputGroup className="" controlId="passwordChangeByAdmin">
                <Form.Control
                  type={showNewPassword === false ? "password" : "text"}
                  required
                  onChange={(event) => {
                    setPassword(event.target.value);
                    const regex = new RegExp(
                      passwordPolicyRegex(
                        policy,
                        email,
                        username,
                        first_name,
                        last_name
                      )
                    );
                    if (regex.test(event.target.value)) {
                      setPasswordIsInvalid(false);
                    } else {
                      setPasswordIsInvalid(true);
                    }
                    if (event.target.value === confirmPassword) {
                      setConfirmPasswordIsInvalid(false);
                    } else {
                      setConfirmPasswordIsInvalid(true);
                    }
                  }}
                  value={password}
                  isInvalid={passwordIsInvalid}
                  placeholder="Enter your Password"
                  className="user_input_field border-end-0"
                  autoComplete="new-password"
                />
                <InputGroup.Text
                  id="passwordChangeByAdmin"
                  className={"bg-white border-start-0"}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword === false ? (
                    <Image
                      src={images.ShowEyeIcon}
                      height="20px"
                      className="px-2"
                    ></Image>
                  ) : (
                    <Image
                      src={images.HideEyeIcon}
                      height="20px"
                      className="px-2"
                    ></Image>
                  )}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password
                  <LightTooltip title={showPasswordInvalidTooltip(policy)}>
                    <img
                      src={InfoIcon}
                      alt="info"
                      width="20"
                      height="20"
                      className="mx-2 cursor_pointer"
                    />
                  </LightTooltip>
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="formconfirmPassword"
          >
            <Form.Label column md={3} className="input_lable">
              Confirm Password
            </Form.Label>
            <Col md={9}>
            <InputGroup
              className=""
              controlId="confirmPasswordChangeByAdmin"
            >
              <Form.Control
                type={showConfirmPassword === false ? "password" : "text"}
                required
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  if (event.target.value === password) {
                    setConfirmPasswordIsInvalid(false);
                  } else {
                    setConfirmPasswordIsInvalid(true);
                  }
                }}
                value={confirmPassword}
                placeholder="Confirm Password"
                isInvalid={confirmPasswordIsInvalid}
                className="user_input_field border-end-0"
              />
              <InputGroup.Text
                id="confirmPasswordChangeByAdmin"
                className={"bg-white"}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword === false ? (
                  <Image
                    src={images.ShowEyeIcon}
                    height="20px"
                    className="px-2"
                  ></Image>
                ) : (
                  <Image
                    src={images.HideEyeIcon}
                    height="20px"
                    className="px-2"
                  ></Image>
                )}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                Password does not match.
              </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>

          <Row className="pt-3 mb-3 justify-content-end">
            <Col sm="auto">
              <GeneralButton
                onClickEvent={() => setOpenUpdatePassword((o) => !o)}
                className="me-1"
                value="Cancel"
                color="#505050"
                variant="outlined"
                size="large"
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              {loading ? (
                <GeneralButton
                  variant="contained"
                  disabled={true}
                  className="me-1"
                  value={<img src={images.Loader} width="26px" height="26px" />}
                  size="large"
                ></GeneralButton>
              ) : (
                <GeneralButton
                  className="me-1"
                  value="Update"
                  variant="contained"
                  size="large"
                />
              )}
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>
  );
};

export default UpdatePassword;
