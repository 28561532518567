import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import CrossIcon from '../../../../../images/cross.svg';
import '../DatabasePolicyList/DatabasePolicy.scss';
import '../AddDatabasePolicy/AddDatabasePolicy.scss';
import { addPolicy, getDatabaseApps, getDatabaseResourceCommands, getDatabaseResourceListUsingAppId, updatePolicy } from '../../../../../api/database_policy';
import Select from 'react-select';
import getWindowDimensions from '../../../../../utils/getHeightWidth';
import Images from '../../../../../utils/images';
import MuiButton from "@mui/material/Button";
import GeneralButton from "../../../../common/SaveButton/GeneralButton";
import styled from '@emotion/styled';
import { showError, showErrorMsg, showSuccess } from '../../../../../utils/showMessage';
import HashMap from 'hashmap';
import AdvanceSection from '../common/AdvanceSection/AdvanceSection';
import Sidebar from '../common/Sidebar/Sidebar';
import GeneralSection from '../common/GeneralSection/GeneralSection';
import CommandsSection from '../common/CommandsSection/CommandsSection';
import { SystemUserResourceList } from '../../../../../api/systemuser';
import images from '../../../../../utils/images';

const EditDatabasePolicy = ({ policy, setOpenEditPolicy, setPolicy }) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [policy_id, setPolicyId] = useState(policy.policy.id);
    const [name, setName] = useState(policy.policy.policy_name);
    const [auditEvents, setAuditEvents] = useState(policy.policy.audit_events);
    const [recordSession, setRecordSession] = useState(policy.policy.record_session);
    const [livestream, setLivestream] = useState(policy.policy.live_stream);
    const [enforceMFA, setEnforceMFA] = useState(policy.policy.enforce_mfa);
    const [sessionTimeout, setSessionTimeout] = useState(policy.policy.default_session_timeout);
    const [policySubNavbarName, setPolicySubNavbarName] = useState("General");
    const [app, setApp] = useState(policy.policy.targeted_app);
    const [currentSelectedOption, setCurrentSelectedOption] = useState("Database");
    const [appSystemUser, setAppSystemUser] = useState();

    const [selectDatabase, setSelectDatabase] = useState(false);
    const [database, setDatabase] = useState(null);
    const [databases, setDatabases] = useState([]);
    const [databaseCommands, setDatabaseCommands] = useState([]);
    const [checkedDatabaseCommands, setCheckedDatabaseCommands] = useState(null);
    const [configuredDatabases, setConfiguredDatabases] = useState(policy.databases);
    const [appDetails, setAppDetails] = useState([]);
    const [selectTable, setSelectTable] = useState(false);
    const [table, setTable] = useState(null);
    const [tables, setTables] = useState([]);
    const [tableCommands, setTableCommands] = useState([]);
    const [checkedTableCommands, setCheckedTableCommands] = useState(null);
    const [configuredTables, setConfiguredTables] = useState(policy.tables);
    const [selectColumn, setSelectColumn] = useState(false);
    const [column, setColumn] = useState(null);
    const [columns, setColumns] = useState([]);
    const [columnCommands, setColumnCommands] = useState([]);
    const [checkedColumnCommands, setCheckedColumnCommands] = useState(null);
    const [configuredColumns, setConfiguredColumns] = useState(policy.columns);
    const [hashmap, setHashmap] = useState(new HashMap());
    const [trackHashmap, setTrackHashmap] = useState(new HashMap());
    const [action, setAction] = useState(policy.policy.action);
    const [resourceAction, setResourceAction] = useState(policy.policy.default_resource_action);
    const [commandsChoice, setCommandsChoice] = useState(policy.policy.commands_action);
    const [commands, setCommands] = useState(policy.policy.commands);
    const [selectedTransactionCommands, setSelectedTransactionCommands] = useState(policy.policy.transactional_commands);
    const [selectedAutoCommitConditions, setSelectedAutoCommitConditions] = useState(policy.policy.auto_commit_conditions);
    
    useEffect(() => {
        for (let database in policy.databases) {
            trackHashmap.set(policy.databases[database].database_name, "IDLE");
        }
        for (let table in policy.tables) {
            trackHashmap.set(policy.tables[table].database_name + "." + policy.tables[table].table_name, "IDLE");
        }
        for (let column in policy.columns) {
            trackHashmap.set(policy.columns[column].database_name + "." + policy.columns[column].table_name + "." + policy.columns[column].column_name, "IDLE");
        }
    }, [])

    const fetchAppData = async () => {
            const { data } = await SystemUserResourceList({ isresource: 2, new: false, id: policy.policy.targeted_app.id });
                if (data!== null) 
                    {
                        setAppDetails(data.map(user => {user.label = user.system_display_username; user.value = user.id; return user;}));
                    }
    }

    const setCurrentSystemUser = () => {
        setAppSystemUser(appDetails.filter(user => user.value === policy.policy.systemuser_id)[0])
    }


    useEffect(() => {
        fetchAppData();
    }, [policy])

    useEffect(() => {
        setCurrentSystemUser();
    }, [appDetails])

    const handleAddConfiguredDatabase = async () => {
        let data = {
            "database_name": database.database_name,
            ...checkedDatabaseCommands
        }
        let tempConfiguredDatabase = configuredDatabases.filter((d) => {
            return d.database_name !== database.database_name;
        });
        if (trackHashmap.has(database.database_name)) {
            trackHashmap.set(database.database_name, "UPDATED");
        }
        tempConfiguredDatabase.push(data);
        setConfiguredDatabases(tempConfiguredDatabase);
        hashmap.set(database.database_name + "_" + currentSelectedOption, true);
        setSelectDatabase(false);
        setDatabase(null);
        setCheckedDatabaseCommands({});

    }
    const handleAddConfiguredTable = async () => {
        let data = {
            "database_name": database.database_name,
            "table_name": table.table_name,
            ...checkedTableCommands
        }
        let tempConfiguredTables = configuredTables.filter((d) => {
            return !(d.database_name === database.database_name && d.table_name === table.table_name);
        });
        if (trackHashmap.has(database.database_name + "." + table.table_name)) {
            trackHashmap.set(database.database_name + "." + table.table_name, "UPDATED");
        }
        tempConfiguredTables.push(data);
        setConfiguredTables(tempConfiguredTables);
        hashmap.set(database.database_name + "_" + table.table_name + "_" + currentSelectedOption, true);
        setSelectDatabase(false);
        setSelectTable(false);
        setDatabase(null);
        setTable(null);
        setCheckedTableCommands({});

    }
    const handleAddConfiguredColumn = async () => {
        let data = {
            "database_name": database.database_name,
            "table_name": table.table_name,
            "column_name": column.column_name,
            ...checkedColumnCommands
        }
        let tempConfiguredColumns = configuredColumns.filter((d) => {
            return !(d.database_name === database.database_name && d.table_name === table.table_name && d.column_name === column.column_name);
        });
        if (trackHashmap.has(database.database_name + "." + table.table_name + "." + column.column_name)) {
            trackHashmap.set(database.database_name + "." + table.table_name + "." + column.column_name, "UPDATED");
        }
        tempConfiguredColumns.push(data);
        setConfiguredColumns(tempConfiguredColumns);
        hashmap.set(database.database_name + "_" + table.table_name + "_" + column.column_name + "_" + currentSelectedOption);
        setSelectDatabase(false);
        setSelectTable(false);
        setSelectColumn(false);
        setDatabase(null);
        setTable(null);
        setColumn(null);
        setCheckedColumnCommands({});
    }
    const handleRemoveConfiguredDatabase = async (database_name) => {
        let tempConfiguredDatabase = configuredDatabases.filter((database) => {
            return database.database_name !== database_name;
        });
        hashmap.delete(database_name + "_" + currentSelectedOption)
        if (trackHashmap.has(database_name)) {
            trackHashmap.set(database_name, "DELETED");
        }
        setConfiguredDatabases(tempConfiguredDatabase);
    };
    const handleRemoveConfiguredTable = async (database_name, table_name) => {
        let tempConfiguredTables = configuredTables.filter((table) => {
            return !(table.table_name === table_name && table.database_name === database_name);
        });
        if (trackHashmap.has(database_name + "." + table_name)) {
            trackHashmap.set(database_name + "." + table_name, "DELETED");
        }
        hashmap.delete(database_name + "_" + table_name + "_" + currentSelectedOption);
        setConfiguredTables(tempConfiguredTables);
    };
    const handleRemoveConfiguredColumn = async (database_name, table_name, column_name) => {
        let tempConfiguredColumns = configuredColumns.filter((column) => {
            return !(column.database_name === database_name && column.table_name === table_name && column.column_name === column_name);
        });
        if (trackHashmap.has(database_name + "." + table_name + "." + column_name)) {
            trackHashmap.set(database_name + "." + table_name + "." + column_name, "DELETED");
        }
        hashmap.delete(database_name + "_" + table_name + "_" + column_name + "_" + currentSelectedOption);
        setConfiguredColumns(tempConfiguredColumns);
    };
    const handleEditConfiguredDatabase = async (database_name) => {
        let filteredDatabase = configuredDatabases.filter((database) => {
            return database.database_name === database_name;
        })
        if (filteredDatabase.length > 0) {
            setDatabase(filteredDatabase[0]);
            setSelectDatabase(true);
            const { database_name, id, policy, ...checkedCommands } = filteredDatabase[0];
            setCheckedDatabaseCommands(checkedCommands);
        }
    }
    const handleEditConfiguredTable = async (database_name, table_name) => {
        let filteredTable = configuredTables.filter((table) => {
            return table.database_name === database_name && table.table_name === table_name;
        })
        if (filteredTable.length > 0) {
            setTable(filteredTable[0]);
            setDatabase(filteredTable[0]);
            setSelectDatabase(true);
            setSelectTable(true);
            const { database_name, table_name, id, policy, ...checkedCommands } = filteredTable[0];
            setCheckedTableCommands(checkedCommands);
        }
    }
    const handleEditConfiguredColumn = async (database_name, table_name, column_name) => {
        let filteredColumn = configuredColumns.filter((column) => {
            return column.database_name === database_name && column.table_name === table_name && column.column_name === column_name;
        })
        if (filteredColumn.length > 0) {
            setDatabase(filteredColumn[0]);
            setTable(filteredColumn[0]);
            setColumn(filteredColumn[0]);
            setSelectDatabase(true);
            setSelectTable(true);
            setSelectColumn(true);
            const { database_name, table_name, column_name, id, policy, ...checkedCommands } = filteredColumn[0];
            setCheckedColumnCommands(checkedCommands);
        }
    }
    const editPolicyHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            let resource_status = {}
            for (let entry in trackHashmap.entries()) {
                let key = trackHashmap.entries()[entry][0];
                let value = trackHashmap.entries()[entry][1];
                resource_status[key] = value;
            }
            const { data, error } = await updatePolicy({
                "policy_id": policy_id, "updatedPolicy": {
                    "policy": {
                        ...(app !== null && { "targeted_app": app.id }),
                        ...(name !== "" && { "policy_name": name }),
                        ...(appSystemUser !== null && { "systemuser_id": appSystemUser.value }),
                        ...{ "action": "ALLOW" },
                        ...{ "audit_events": auditEvents },
                        ...{ "record_session": recordSession },
                        ...{ "live_stream": livestream },
                        ...{ "default_session_timeout": sessionTimeout },
                        ...{ "enforce_mfa": enforceMFA },
                        ...{ "action": action },
                        ...{ "default_resource_action": resourceAction },
                        ...{ "commands_action": commandsChoice },
                        ...{ "commands": commands },
                        ...{ "transactional_commands": selectedTransactionCommands },
                        ...{ "auto_commit_conditions": selectedAutoCommitConditions }
                    },
                    "databases": configuredDatabases,
                    "tables": configuredTables,
                    "columns": configuredColumns,
                    "resource_status": resource_status
                }
            });
            if (data !== null) {
                showSuccess(data.message);
                setPolicy(Object.create(null));
                setOpenEditPolicy(o => !o)
            }
            if (error !== null) {
                showError(error);
            }
            setLoading(false);
        }
    }
    const isPolicyNameEditable = (policyName) => {
        return !(policyName.toLowerCase().startsWith("default_"));
    }

    return (
        <>
            <Container fluid className="main_content_container mx-auto">
                <Sidebar
                    heading={"Edit Policy"}
                    policySubNavbarName={policySubNavbarName}
                    setPolicySubNavbarName={setPolicySubNavbarName}
                    setOpenPolicy={setOpenEditPolicy}
                    appSystemUser={appSystemUser}
                    app={app}
                />
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={editPolicyHandler}
                    className="add__database_policy_form mt-4"
                >
                    <Stack gap={1}>
                        {policySubNavbarName === "General" &&
                            <GeneralSection
                                ACTION_TYPE={"DATABASE_POLICY_EDIT"}
                                app={app}
                                name={name}
                                appSystemUser={appSystemUser}
                                appDetails={appDetails}
                                setAppSystemUser={setAppSystemUser}
                                setName={setName}
                                sessionTimeout={sessionTimeout}
                                setSessionTimeout={setSessionTimeout}
                                auditEvents={auditEvents}
                                setAuditEvents={setAuditEvents}
                                recordSession={recordSession}
                                setRecordSession={setRecordSession}
                                livestream={livestream}
                                setLivestream={setLivestream}
                                enforceMFA={enforceMFA}
                                setEnforceMFA={enforceMFA}
                            />
                        }
                        {policySubNavbarName === "Commands" &&
                            <CommandsSection
                                currentSelectedOption={currentSelectedOption}
                                setCurrentSelectedOption={setCurrentSelectedOption}
                                hashmap={hashmap}
                                setPolicySubNavbarName={setPolicySubNavbarName}
                                app={app}
                                appSystemUser={{"id": appSystemUser.value}}
                                configuredDatabases={configuredDatabases}
                                handleEditConfiguredDatabase={handleEditConfiguredDatabase}
                                handleRemoveConfiguredDatabase={handleRemoveConfiguredDatabase}
                                selectDatabase={selectDatabase}
                                setSelectDatabase={setSelectDatabase}
                                databases={databases}
                                setDatabases={setDatabases}
                                database={database}
                                setDatabase={setDatabase}
                                databaseCommands={databaseCommands}
                                setDatabaseCommands={setDatabaseCommands}
                                checkedDatabaseCommands={checkedDatabaseCommands}
                                setCheckedDatabaseCommands={setCheckedDatabaseCommands}
                                handleAddConfiguredDatabase={handleAddConfiguredDatabase}

                                configuredTables={configuredTables}
                                handleEditConfiguredTable={handleEditConfiguredTable}
                                handleRemoveConfiguredTable={handleRemoveConfiguredTable}
                                table={table}
                                setTable={setTable}
                                tables={tables}
                                setTables={setTables}
                                tableCommands={tableCommands}
                                setTableCommands={setTableCommands}
                                selectTable={selectTable}
                                setSelectTable={setSelectTable}
                                checkedTableCommands={checkedTableCommands}
                                setCheckedTableCommands={setCheckedTableCommands}
                                handleAddConfiguredTable={handleAddConfiguredTable}

                                configuredColumns={configuredColumns}
                                handleEditConfiguredColumn={handleEditConfiguredColumn}
                                handleRemoveConfiguredColumn={handleRemoveConfiguredColumn}
                                selectColumn={selectColumn}
                                columns={columns}
                                setColumns={setColumns}
                                column={column}
                                setColumn={setColumn}
                                setSelectColumn={setSelectColumn}
                                columnCommands={columnCommands}
                                setColumnCommands={setColumnCommands}
                                checkedColumnCommands={checkedColumnCommands}
                                setCheckedColumnCommands={setCheckedColumnCommands}
                                handleAddConfiguredColumn={handleAddConfiguredColumn}
                            />
                        }
                        {policySubNavbarName === "Advance" &&
                            <AdvanceSection
                                action={action}
                                setAction={setAction}
                                resourceAction={resourceAction}
                                setResourceAction={setResourceAction}
                                commandsChoice={commandsChoice}
                                setCommandsChoice={setCommandsChoice}
                                commands={commands}
                                setCommands={setCommands}
                                selectedAutoCommitConditions={selectedAutoCommitConditions}
                                setSelectedAutoCommitConditions={setSelectedAutoCommitConditions}
                                selectedTransactionCommands={selectedTransactionCommands}
                                setSelectedTransactionCommands={setSelectedTransactionCommands}
                            />
                        }
                        <Row className="pt-3 mb-3 justify-content-end">
                          <Col sm="auto">
                            <GeneralButton
                              onClickEvent={() => setOpenEditPolicy((o) => !o)}
                              className="me-1"
                              value="Cancel"
                              color="#505050"
                              variant="outlined"
                              size="large"
                            ></GeneralButton>
                          </Col>
                          <Col sm="auto" className="me-sm-2 p-0">
                            {loading ? (
                              <GeneralButton
                                variant="contained"
                                disabled={true}
                                className="me-1"
                                value={<img src={images.Loader} width="26px" height="26px" />}
                                size="large"
                              />
                            ) : (
                              <GeneralButton
                                className="me-1"
                                value="Update"
                                variant="contained"
                                size="large"
                              />
                            )}
                          </Col>
                        </Row>
                    </Stack>
                </Form>
            </Container>
        </>
    )
}

export default EditDatabasePolicy
