import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import {
  deleteMySystemUser,
  getMySystemUserDetail,
  mySystemUserList,
} from "../../../../api/systemuser";

import { Fade, Slide } from "@mui/material";
import { BsPersonCheck, BsPersonX } from "react-icons/bs";
import { changeTitle } from "../../../../state/slices/header";
import GetCapabilities from "../../../../utils/getCapabilities";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import Images from "../../../../utils/images";
import { showError, showSuccess } from "../../../../utils/showMessage";
import {
  delayTime,
  fadedelayTime,
  slideDirection,
} from "../../../../utils/transitionEffectParams";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import CustomModal from "../../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import "../../../common/css/RDT_common.scss";
import AddSystemUser from "../AddSystemUser/AddSystemUser";
import EditSystemUser from "../EditSystemUser/EditSystemUser";
import "./MySystemUsers.scss";
const ActionComponent = ({ setOpenAddSystemUser }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <div className="d-none">
        <UploadButton
          startIcon={
            <img src={Images.Upload} alt="icon" id="center-blueShadow" />
          }
        >
          CSV Upload
        </UploadButton>
      </div>
      {isCapable && isCapable.system_user.add && (
        <GeneralButton
          startIcon={
            <img src={Images.Plus} alt="icon" id="center-blueShadow" />
          }
          onClickEvent={() => {
            setOpenAddSystemUser((o) => !o);
          }}
          className="me-1"
          value="Add System User"
          variant="outlined"
          size="large"
        />
      )}
    </Stack>
  );
};

const MySystemUsers = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const [systemUser, setSystemUser] = useState(null);
  const [systemUsers, setSystemUsers] = useState([]);
  const [totalSystemUsers, setTotalSystemUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_MySystemUsers") !== null
      ? localStorage.getItem("rowsPerPage_MySystemUsers")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [openAddSystemUser, setOpenAddSystemUser] = useState(false);
  const [openEditSystemUser, setOpenEditSystemUser] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [systemUserData, setSystemUserData] = useState(null);
  const [deleteSystemUserNames, setDeleteSystemUserNames] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [openDeleteSystemUser, setOpenDeleteSystemUser] = useState(false);
  const pages_url = "dashboard/systemUsers";
  const customizeColumnDict = { Username: true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const title_heading = useSelector((state) => state.header.title);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const systemUserColumns = [
    { label: "System User Id", value: "id" },
    { label: "Display Name", value: "system_display_username" },
    { label: "Username", value: "system_username" },
  ];
  const actionOptions = [{ label: "Delete", value: "delete" }];
  const getSystemUsers = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await mySystemUserList({
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setSystemUsers(data.system_users);
      setTotalSystemUsers(data.totalSystemUser);
      setFilteredData(data.system_users);
    }
    setPending(false);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    getSystemUsers({ page, rowsPerPage, search, filterBy });
  };
  useEffect(() => {
    dispatch(changeTitle("Sys_user"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getSystemUsers({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, systemUser]);
  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = systemUsers.filter((object) => {
      return (
        object.system_username.toLowerCase().match(s.toLowerCase()) ||
        object.system_display_username.toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_MySystemUsers", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };
  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.system_display_username);
    setDeleteSystemUserNames(t);
    setOpenDeleteSystemUser((o) => !o);
  };
  const handleDeleteAction = async () => {
    setLoadingDelete(true);
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await deleteMySystemUser({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setSystemUser(Object.create(null));
      setOpenDeleteSystemUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
    setLoadingDelete(false);
  };
  const handleSystemUserEdit = async (system_user_id) => {
    setLoadingEdit(true);
    setRowToEdit(system_user_id);
    const { data, error } = await getMySystemUserDetail({
      system_user_id: system_user_id,
    });
    if (data !== null) {
      setSystemUserData(data.system_user);
      setOpenEditSystemUser((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setRowToEdit(null);
    setLoadingEdit(false);
  };

  const columns = [
    {
      name: "",
      selector: (row) =>
        row.iscomplete ? (
          <BsPersonCheck size={20} color="green" />
        ) : (
          <BsPersonX size={20} color="red" />
        ),
      maxWidth: "30px",
    },
    {
      name: "System User Id",
      selector: (row) => row.id,
      grow: 1,
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => row.system_display_username,
      grow: 1,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.system_username,
      sortable: true,
    },
    isCapable &&
      (isCapable.system_user.edit || isCapable.system_user.delete) && {
        name: "Action",
        cell: (row) => (
          <>
            <Nav>
              <NavDropdown
                id="nav-dropdown-systemuser"
                title={
                  loadingEdit && row.id == rowToEdit ? (
                    <img src={Images.Loader} width="26px" height="26px" />
                  ) : (
                    <img src={Images.ThreeDotIcon} alt="action" />
                  )
                }
                menuVariant="primary"
                disabled={loadingEdit}
              >
                {isCapable.system_user.edit && (
                  <NavDropdown.Item
                    onClick={() => handleSystemUserEdit(row.id)}
                  >
                    Edit
                  </NavDropdown.Item>
                )}
                {isCapable.system_user.delete && (
                  <NavDropdown.Item
                    onClick={async () => {
                      handleDelete([row]);
                    }}
                  >
                    Delete
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </>
        ),
        middle: true,
        minWidth: "50px",
      },
  ];
  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Sys_user":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={Images.SystemUserHeadingIcon}
                          alt="users round icon"
                        />{" "}
                        &nbsp; System Users
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container px-3 py-3 mx-auto w-100">
                <CustomModal
                  open={openAddSystemUser}
                  handleClose={() => {
                    setOpenAddSystemUser((o) => !o);
                  }}
                >
                  <AddSystemUser
                    setOpenAddSystemUser={setOpenAddSystemUser}
                    setSystemUser={setSystemUser}
                  />
                </CustomModal>
                {systemUserData && isCapable.system_user.edit && (
                  <CustomModal
                    open={openEditSystemUser}
                    handleClose={() => {
                      setOpenEditSystemUser((o) => !o);
                    }}
                  >
                    <EditSystemUser
                      systemUser={systemUserData}
                      setOpenEditSystemUser={setOpenEditSystemUser}
                      setSystemUser={setSystemUser}
                    />
                  </CustomModal>
                )}
                {deleteSystemUserNames.length > 0 && (
                  <CustomModal
                    open={openDeleteSystemUser}
                    handleClose={() => {
                      setOpenDeleteSystemUser((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenDeleteSystemUser}
                      handleAlertAction={handleDeleteAction}
                      colName={deleteSystemUserNames}
                      alertMsg="Below system users will be deleted :"
                      headingMsg="Delete System User"
                      loading={loadingDelete}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="System Users / Machine Users"
                        dataTableHeadingIcon={Images.SystemUserHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      <CustomDelaySkeleton
                        rowsPerPage={rowsPerPage}
                        colCount={colCount}
                        totalColumns={5}
                      />
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalSystemUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={
                      <ActionComponent
                        setOpenAddSystemUser={setOpenAddSystemUser}
                      />
                    }
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="system users"
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={systemUserColumns}
                        actionOptions={
                          isCapable && isCapable.system_user.delete
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={Images.FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={Images.LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={
                      <img src={Images.NextPageIcon} alt="next" />
                    }
                    paginationIconPrevious={
                      <img src={Images.PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default MySystemUsers;
