import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { registerAdmin } from "../../../api/customer";
import Loader from "../../common/Loader/Loader";

function Check() {
  const navigate = useNavigate();
  const [res, setRes] = useState(undefined)
  const fetchData = async () => {
    const {data,error} = await registerAdmin();
    if(data.is_on_premise){
      if(data.customers_count == 0 || data.customers_count == 1){
        setRes(true)
        navigate("register");
      }
      else{
        setRes(false)
        navigate("login");
      }
    }
    else{
      if(window.location.pathname === '/register'){
        navigate("register"); 
      }
      else{
        navigate("login");
      }
      setRes(false)
    }

  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => { }, [res])
  const style = {
    height: "auto",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
  }
  if (res === undefined) {
    return (
      <>
        <Loader loading={true} bg_papper={false} />
      </>
    );
  }
  else {
    return (
      <div>
        <Outlet />
      </div>
    );
  }


}
export default Check;
