import { Accordion, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { activeLink, normalLink } from "./Sidebar.js";
import { useDispatch } from "react-redux";
import { changeTitle } from "../../../state/slices/header";
import images from "../../../utils/images.js";

const TwoFa = (props) => {
  const { isEndUser, title } = props;
  const dispatch = useDispatch();

  const handleTitle = async () => {
    dispatch(changeTitle("TWOFA"));
  };
  return (
    <Accordion.Item eventKey="TwoFa" className={title === "TWOFA" ? "sidebar-button-border" : ""}>
      <Accordion.Header >
        <img
          className="mx-3 filter-active"
          src={images.TwoFactorSidebarIcon}
          height="22px"
          width="22px"
        />
        Two-Factor
      </Accordion.Header>
      <Accordion.Body className="pt-0 px-0">
        <ListGroup className="sidebar-list">
          <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/mfa/list"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Setup Two-Factor</div>
            </NavLink>
          </ListGroup.Item>
          <ListGroup.Item className="py-0 pe-0 ps-2">
            <NavLink
              to="/dashboard/mfa/configurations"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
              onClick={handleTitle}
            >
              <img src={images.DotIcon} alt="dot-icon" width="4px" />
              <div className="ps-4">Two-Factor Configurations</div>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default TwoFa;
