import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import Reports from "./Reports";
import LiveSession from "./LiveSession";
import "./Sidebar.scss";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/getHeightWidth";
import GetCapabilities from "../../../utils/getCapabilities";
import { ContactUsForm } from "./Contactusform";

const activeLink = "d-flex py-2 ps-2 active-accordion text-decoration-none";
const normalLink = "d-flex py-2 ps-2 inactive-text-color text-decoration-none";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return <div onClick={decoratedOnClick}>{children}</div>;
}

function AuditSidebar() {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const dispatch = useDispatch();
  const title = useSelector((state) => state.header.title);
  const { width } = useWindowDimensions();

  const activeButton =
    " active-linkElement padding_sidebar sidebar-font text-decoration-none d-flex";

  const normalButton =
    "inactive-text-color padding_sidebar text-decoration-none text-start sidebar-font d-flex";

  return (
    <div className="bs-white">
      <Accordion defaultActiveKey="" flush className="my-2 mr-1 p-30">
        {isCapable && (
          <Card className="border-0 bg-transparent">
            <Card.Header className="p-0 border-0 bg-transparent">
              <CustomToggle eventKey="0">
                <NavLink
                  to="/audit-dashboard"
                  className={({ isActive }) =>
                    isActive ? activeButton : normalButton
                  }
                  onClick={() => {
                    dispatch(changeTitle("dashboard"));
                  }}
                  end
                >
                  <img
                    className="mx-3 filter-active"
                    src={require("./icons/dashboard.svg").default}
                    alt="dashboard-icon"
                    height="22px"
                    width="22px"
                  />
                  Dashboard
                </NavLink>
              </CustomToggle>
            </Card.Header>
          </Card>
        )}
        {isCapable && isCapable.report.show_reports && (
          <Reports title={title} />
        )}
        {isCapable && isCapable.report.live_session && (
          <LiveSession title={title} />
        )}
      </Accordion>
      <ContactUsForm />
    </div>
  );
}

export default AuditSidebar;

export { activeLink, normalLink };
