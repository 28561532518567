import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "reactjs-popup/dist/index.css";
import ThreeDotIcon from "../../../images/three_dot.svg";
import Delete from "./icons/delete.svg";
import Edit from "./icons/edit.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import plus from "../../../images/plus.svg";
import UserHeadingIcon from "../../../images/User_heading_icon.svg";
import upload from "../../../images/upload.svg";
import download from "../../../images/export.svg";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import "./RolesList.scss";
import ShowAlert from "../../common/ShowAlert/ShowAlert";
import { deleteRole } from "../../../api/role";
import { getRole, listRole } from "../../../api/role";
import { showError, showSuccess } from "../../../utils/showMessage";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import CustomModal from "../../common/CustomModal/CustomModal";
import AddRole from "./AddRole/AddRole";
import EditRole from "./EditRole/EditRole";
import { changeTitle } from "../../../state/slices/header";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { TiEdit } from "react-icons/ti";
import UsersCapabilities from "../UsersCapabilities/UsersCapabilities";
import { MdFormatListBulletedAdd, MdFormatListBulleted } from "react-icons/md";
import GetCapabilities from "../../../utils/getCapabilities";
import Images from "../../../utils/images";
import GeneralButton from "../../common/SaveButton/GeneralButton";

const ActionComponent = ({ setOpenAddRole, setDisplayName, setRoleName }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const UploadButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "8px 12px",
    color: "#717171",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      <div className="d-none">
        <UploadButton
          startIcon={<img src={download} alt="icon" id="center-blueShadow" />}
        >
          Export
        </UploadButton>

        <UploadButton
          startIcon={<img src={upload} alt="icon" id="center-blueShadow" />}
        >
          Bulk Upload
        </UploadButton>
      </div>
      {isCapable && isCapable.users.roles.add && (
        <GeneralButton
        startIcon={<img src={plus} alt="icon" id="center-blueShadow" />}
          onClickEvent={() => {
            setDisplayName("");
            setRoleName("");
            setOpenAddRole((o) => !o);
          }}
          className="me-1"
          value="Add Role"
          variant="outlined"
          size="large"
        />
      )}
    </Stack>
  );
};

const RolesList = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_RolesList") !== null
      ? localStorage.getItem("rowsPerPage_RolesList")
      : 5
  );
  const [rolesList, setRolesList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [totalRoles, setTotalRoles] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAddRole, setOpenAddRole] = useState(false);
  const [openEditRole, setOpenEditRole] = useState(false);
  const [openDeleteRole, setOpenDeleteRole] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [rolesData, setRolesData] = useState();
  const [colName, setColName] = useState(null);
  const pages_url = "dashboard/rolesList";
  const customizeColumnDict = {
    "Display Name": true,
    "Role Id": true,
    "Total Users": true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const title_heading = useSelector((state) => state.header.title);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);
  const [openCapabilitiesDialog, setOpenCapabilitiesDialog] = useState(false);
  const [openEditCapabilities, setOpenEditCapabilities] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [capability, setCapability] = useState({});
  const [roleId, setRoleId] = useState(null);
  const [isRoleAdded, setIsRoleAdded] = useState(false);

  const UserColumns = [
    { label: "Role Id", value: "role_id" },
    { label: "Role Name", value: "role_name" },
    { label: "Display Name", value: "display_name" },
  ];

  const actionOptions = [{ label: "Delete", value: "delete" }];
  useEffect(() => {
    dispatch(changeTitle("User"));
  }, []);
  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getRolesList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, roles, isRoleAdded]);

  const getRolesList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await listRole({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
    });
    if (data !== null) {
      setRolesList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalRoles(data.count);
      setFilteredData(data.results);
    } else {
      showError(error);
    }
    setPending(false);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getRolesList({ page: 1, rowsPerPage, search, filterBy });
  };

    useEffect(() => {
        const result = rolesList.filter((object) => {
            let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
            let regexpression = new RegExp(searchs, "i");
                        if (!filterBy) {
                return (
                    (object.role_id).toString().match(regexpression) ||
                    object.role_name.toLowerCase().match(regexpression) ||
                    object.display_name.toLowerCase().match(regexpression)
                );
            } else {
                if (filterBy === "role_id")
                    return (object.role_id).toString().match(regexpression);
                else
                    return object[filterBy].toLowerCase().match(regexpression);
            }
        });
        setFilteredData(result);
    }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_RolesList", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let rowsToDelete = [];
    if (row) {
      rowsToDelete = row;
    } else {
      rowsToDelete = selectedRows;
    }
    setToBeDeleted(rowsToDelete);
    let temp = [];
    temp = rowsToDelete.map((item) => item.display_name);
    setColName(temp);
    setOpenDeleteRole((o) => !o);
  };

  const rowDisabledCriteria = (row) =>
    row.role_name === "Customeradmin" || row.role_name === "Enduser";

  const handleDeleteAction = async () => {
    let ids = toBeDeleted.map((item) => item.role_id);
    let count = ids.length;
    const { data, error } = await deleteRole({ ids: ids });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalRoles % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setRoles(Object.create(null));
      setOpenDeleteRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleRoleEdit = async (role_id) => {
    const { data, error } = await getRole({ role_id: role_id });
    if (data !== null) {
      setRolesData(data);
      setOpenEditRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const columns = [
    {
      name: "Role Id",
      selector: (row) => row.role_id,
      minWidth: "150px",
      omit: !checkedStateDict["Role Id"],
      sortable: true,
    },
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      minWidth: "150px",
      omit: !checkedStateDict["Display Name"],
      sortable: true,
    },
    {
      name: "Total Users",
      selector: (row) => row.total_users,
      minWidth: "150px",
      omit: !checkedStateDict["Total Users"],
      sortable: true,
    },
    isCapable &&
      (isCapable.users.roles.edit ||
        isCapable.users.roles.delete ||
        isCapable.users.roles.allocate_deallocate_resources) && {
        name: "Action",
        cell: (row) => (
          <>
            <Nav className="user-dropdown">
              <NavDropdown
                id="nav-dropdown-user"
                title={<img src={ThreeDotIcon} alt="action" />}
                menuVariant="primary"
              >
                {isCapable && isCapable.users.roles.edit && (
                  <NavDropdown.Item onClick={() => handleRoleEdit(row.role_id)}>
                    <img
                      src={Edit}
                      alt="edit"
                      className="mx-2"
                      width="13"
                      height="13"
                    />{" "}
                    Edit
                  </NavDropdown.Item>
                )}
                {row.role_name === "Customeradmin" ||
                row.role_name === "Enduser" ? null : (
                  <>
                    {isCapable && isCapable.users.roles.delete && (
                      <NavDropdown.Item
                        onClick={async () => {
                          handleDelete([row]);
                        }}
                      >
                        <img
                          src={Delete}
                          alt="delete"
                          className="mx-2"
                          width="13"
                          height="13"
                        />{" "}
                        Delete
                      </NavDropdown.Item>
                    )}
                    {isCapable && isCapable.users.roles.edit && (
                      <NavDropdown.Item
                        onClick={() => {
                          setRoleId(row.role_id);
                          setOpenEditCapabilities(true);
                          setOpenCapabilitiesDialog(true);
                        }}
                      >
                        <TiEdit className="mx-2" width="13" height="13" /> Edit
                        capabilities
                      </NavDropdown.Item>
                    )}
                  </>
                )}

                {isCapable &&
                  isCapable.users.roles.allocate_deallocate_resources && (
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          `/dashboard/roles/resource-allocate/${row.role_id}`
                        )
                      }
                    >
                      <MdFormatListBulletedAdd className="mx-2" /> Allocate
                    </NavDropdown.Item>
                  )}

                {isCapable &&
                  isCapable.users.roles.allocate_deallocate_resources && (
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          `/dashboard/roles/resource-deallocate/${row.role_id}`
                        )
                      }
                    >
                      <MdFormatListBulleted className="mx-2" /> View Allocated
                    </NavDropdown.Item>
                  )}
              </NavDropdown>
            </Nav>
          </>
        ),
        center: true,
        minWidth: "50px",
      },
  ];
  return (
    <>
      {openCapabilitiesDialog ? (
        openEditCapabilities ? (
          <UsersCapabilities
            isRoleAdded={isRoleAdded}
            setIsRoleAdded={setIsRoleAdded}
            roleId={roleId}
            setOpenEditCapabilities={setOpenEditCapabilities}
            setOpenCapabilitiesDialog={setOpenCapabilitiesDialog}
          />
        ) : (
          <UsersCapabilities
            isRoleAdded={isRoleAdded}
            setOpenEditCapabilities={setOpenEditCapabilities}
            role_Name={roleName}
            display_Name={displayName}
            setIsRoleAdded={setIsRoleAdded}
            capability={capability}
            setOpenCapabilitiesDialog={setOpenCapabilitiesDialog}
          />
        )
      ) : (
        <div className="main_content_container mx-auto">
          {(() => {
            switch (title_heading) {
              case "User":
                return (
                  <div className="heading_datable bg-white">
                    <span
                      style={{ color: "#50514F" }}
                      className="ff-poppins fs-24px fw-600"
                    >
                      <div className="d-flex align-items-center">
                        <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                          <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                            <img
                              src={
                                require("../../../images/User_heading_icon.svg")
                                  .default
                              }
                              alt="users round icon"
                            />{" "}
                            &nbsp; Users
                          </span>
                          <br />
                        </div>
                      </div>
                    </span>
                  </div>
                );
            }
          })()}
          <Slide
            timeout={delayTime}
            direction={slideDirection}
            in={slideChecked}
          >
            <div>
              <Fade timeout={fadedelayTime} in={slideChecked}>
                <div>
                  <div className="main_content_container p-3 mx-auto w-100">
                    <CustomModal
                      open={openAddRole}
                      handleClose={() => {
                        setOpenAddRole((o) => !o);
                      }}
                    >
                      <AddRole
                        setOpenAddRole={setOpenAddRole}
                        setRoles={setRoles}
                        setOpenCapabilitiesDialog={setOpenCapabilitiesDialog}
                        roleName={roleName}
                        setRoleName={setRoleName}
                        displayName={displayName}
                        setDisplayName={setDisplayName}
                        capability={capability}
                        setCapability={setCapability}
                      />
                    </CustomModal>

                    {rolesData && (
                      <CustomModal
                        open={openEditRole}
                        handleClose={() => {
                          setOpenEditRole((o) => !o);
                        }}
                      >
                        {" "}
                        <EditRole
                          setOpenEditRole={setOpenEditRole}
                          setRoles={setRoles}
                          data={rolesData}
                        />
                      </CustomModal>
                    )}

                    {colName && (
                      <CustomModal
                        open={openDeleteRole}
                        handleClose={() => {
                          setOpenDeleteRole((o) => !o);
                        }}
                      >
                        <ShowAlert
                          setOpenAlert={setOpenDeleteRole}
                          handleAlertAction={handleDeleteAction}
                          colName={colName}
                          alertMsg="Below roles will be deleted : "
                          headingMsg="Delete Role"
                        />
                      </CustomModal>
                    )}
                    <div
                      className="position-relative bg-white"
                      style={{ height: `${datatable_height}` }}
                    >
                      <DataTable
                        title={
                          <ListHeading
                            dataTableHeadingText="User Roles"
                            dataTableHeadingIcon={UserHeadingIcon}
                          />
                        }
                        columns={columns}
                        data={filteredData}
                        className="rdt_container"
                        progressPending={pending}
                        progressComponent={
                          (
                            <CustomDelaySkeleton
                              rowsPerPage={rowsPerPage}
                              colCount={colCount}
                              totalColumns={4}
                            />
                          )
                        }
                        fixedHeader
                        paginationDefaultPage={page}
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRoles}
                        selectableRows
                        selectableRowsVisibleOnly
                        selectableRowsHighlight
                        highlightOnHover
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRowDisabled={rowDisabledCriteria}
                        clearSelectedRows={toggleCleared}
                        actions={
                          <ActionComponent setOpenAddRole={setOpenAddRole} setDisplayName={setDisplayName} setRoleName={setRoleName} />
                        }
                        subHeader
                        subHeaderComponent={
                          <SubHeaderComponent
                            placeholder="user roles"
                            filterBy={filterBy}
                            action={action}
                            setAction={setAction}
                            setFilterBy={setFilterBy}
                            handleAction={handleAction}
                            filterOptions={UserColumns}
                            actionOptions={
                              isCapable && isCapable.users.roles.delete
                                ? actionOptions
                                : null
                            }
                            search={search}
                            setSearch={setSearch}
                            handleSearch={handleSearch}
                            selectedRows={selectedRows}
                            pages_url={pages_url}
                            checkedStateDict={checkedStateDict}
                            setCheckedStateDict={setCheckedStateDict}
                          />
                        }
                        subHeaderAlign="center"
                        paginationIconFirstPage={
                          <img src={FirstPageIcon} alt="first page" />
                        }
                        paginationIconLastPage={
                          <img src={LastPageIcon} alt="last page" />
                        }
                        paginationIconNext={
                          <img src={NextPageIcon} alt="next" />
                        }
                        paginationIconPrevious={
                          <img src={PreviousPageIcon} alt="previous" />
                        }
                      />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </Slide>
        </div>
      )}
    </>
  );
};

export default RolesList;
