import { Fade, Slide } from "@mui/material";
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteConfigurations, getNetworkAnalyzerConf, getNetworkAnalyzerConfs, triggerScanner } from "../../../api/networkanalyzer";
import { changeTitle } from "../../../state/slices/header";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { showError, showSuccess } from "../../../utils/showMessage";
import { delayTime, fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import CustomModal from "../../common/CustomModal/CustomModal";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ShowAlert from "../../common/ShowAlert/ShowAlert";
import GetCapabilities from "../../../utils/getCapabilities";
import images from "../../../utils/images";
import { GetColmn } from "../../common/CustomColumn/customize_column";


const ActionComponent = ({ navigate }) => {
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0]
  const AddButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    fontStyle: "normal",
    padding: "10px 20px",
    lineHeight: "21px",
    fontFamily: ['"DM Sans"'].join(","),
    variant: "secondary",
  });

  return (
    <Stack direction="row" spacing={2} className="py-3">
      {isCapable && isCapable.LDAP.add && (<AddButton
        onClick={() => navigate(`/dashboard/addnetworkconfiguration/`)}
        variant="outlined"
        startIcon={
          <img src={images.Plus} alt="icon" id="center-blueShadow" />
        }
      >
        Add Configuration
      </AddButton>)}
    </Stack>
  );
};

function Configurations() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable = my_capabilities[0]
  const navigate = useNavigate();
  const [configurations, setConfigurations] = useState([]);
  const [configuration, setConfiguration] = useState(null);
  const [totalConfigurations, setTotalConfigurations] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_MyConfigurations") !== null
      ? localStorage.getItem("rowsPerPage_MyConfigurations")
      : 5
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [confDetail, setConfDetail] = useState(null);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deleteConfNames, setDeleteConfNames] = useState([]);
  const [openDeleteConf, setOpenDeleteConf] = useState(false);
  const [slideChecked, setSlideChecked] = useState(false);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const title_heading = useSelector((state) => state.header.title); 
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const customizeColumnDict = { "Id": true, "Username": true, "Protocol": true, "Domain": true, "Search Base": true };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict)
  const pages_url = "dashboard/configurations";

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const confColumns = [
    { label: "Id", value: "id" },
    { label: "Name", value: "name" },
    { label: "Username", value: "username" },
    { label: "Protocol", value: "protocol" },
    { label: "Domain", value: "domain" },
    { label: "Search Base", value: "search_base" },
  ];

  const actionOptions = [{ label: "Delete", value: "delete" }];

  useEffect(() => {
    dispatch(changeTitle("SSO"));
  }, []);

  const getConfigurations = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data } = await getNetworkAnalyzerConfs({
      page: page,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });

    if (data !== null) {
      setConfigurations(data.results);
      setFilteredData(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalConfigurations(data.count);
      setPending(false);
    }
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getConfigurations({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, configuration]);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = configurations.filter((object) => {
      return (
        object.name.toLowerCase().match(s.toLowerCase()) ||
        object.username.toLowerCase().match(s.toLowerCase()) ||
        object.protocol.toLowerCase().match(s.toLowerCase()) ||
        object.domain.toLowerCase().match(s.toLowerCase()) ||
        object.search_base.toLowerCase().match(s.toLowerCase())
  )});
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_MyConfigurations", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleAction = async () => {
    if (action === "delete") {
      handleDelete(null);
    }
  };

  const handleDelete = async (row) => {
    let temp = [];
    if (row) {
      temp = row;
    } else {
      temp = selectedRows;
    }
    setRowsToDelete(temp);
    let t = temp.map((object) => object.name);
    setDeleteConfNames(t);
    setOpenDeleteConf((o) => !o);
  };

  const handleDeleteAction = async (row) => {
    let ids = rowsToDelete.map((item) => item.id);
    const { data, error } = await deleteConfigurations({ ids: ids });
    if (data !== null) {
      showSuccess(data.message);
      setConfiguration(Object.create(null));
      setOpenDeleteConf((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleAppEdit = async (id) => {
    const { data, error } = await getNetworkAnalyzerConf({ id: id });
    if (data !== null) {
      setConfDetail(data);
    }
    if (error !== null) {
      showError(error);
    }
  };

  useEffect(() => {
    confDetail && navigate(`/dashboard/editnetworkconfiguration/`, {state: { confDetail: confDetail, }})
  }, [confDetail]);


  const startAdScanner = async (event) => {
    const { data, error } = await triggerScanner(event);
    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getConfigurations({ page, rowsPerPage, search, filterBy });
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "100px",
      omit: !checkedStateDict["Id"],
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      grow: 1,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      grow: 1,
      omit: !checkedStateDict["Username"],
      sortable: true,
    },
    {
      name: "Protocol",
      selector: (row) => row.protocol.substring(0, row.protocol.length - 3),
      grow: 1,
      omit: !checkedStateDict["Protocol"],
      sortable: true,
    },
    {
      name: "Domain",
      selector: (row) => row.domain,
      grow: 1,
      omit: !checkedStateDict["Domain"],
      sortable: true,
    },
    {
      name: "Search Base",
      selector: (row) => row.search_base,
      grow: 1,
      omit: !checkedStateDict["Search Base"],
      sortable: true,
    },
    isCapable && (isCapable.LDAP.edit || isCapable.LDAP.delete || isCapable.miscellaneous.setting.network_analyzer_scan) &&
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id="nav-dropdown-systemuser"
              title={<img src={images.ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              {isCapable && isCapable.LDAP.edit && (<NavDropdown.Item onClick={() => handleAppEdit(row.id)}>
                Edit
              </NavDropdown.Item>)}
             {isCapable && isCapable.LDAP.delete && ( <NavDropdown.Item
                onClick={async () => {
                  handleDelete([row]);
                }}
              >
                Delete
              </NavDropdown.Item>)}
              {/* TODO:// dont remove the commented code here */}
              {/* {isCapable && (<NavDropdown.Item 
                onClick={() => console.log({"id": row.id})}
              >
                Import Users
              </NavDropdown.Item>)}
              {isCapable && (<NavDropdown.Item
                onClick={() => console.log({"id": row.id})}
              >
                Import Groups
              </NavDropdown.Item>)}
              {isCapable && (<NavDropdown.Item
                onClick={() => console.log({"id": row.id})}
              >
                Import Password Policy
              </NavDropdown.Item>)} */}
              {isCapable && isCapable.miscellaneous.setting.network_analyzer_scan && (<NavDropdown.Item
                onClick={() => startAdScanner({"import_machines": row.id})}
              >
                Import Machines
              </NavDropdown.Item>)}
              {isCapable && isCapable.miscellaneous.setting.network_analyzer_scan && (<NavDropdown.Item
                onClick={() => navigate(`/dashboard/scannedhistory/`, { state: { id: row.id, config_name: row.name } })}
              >
                Show Machine Scan Results
              </NavDropdown.Item>)}
              {/* TODO:// dont remove the commented code here */}
              {/* {isCapable && (<NavDropdown.Item
                onClick={() => console.log({"id": row.id})}
              >
                LDAP Certificate
              </NavDropdown.Item>)}
              {isCapable && (<NavDropdown.Item
                onClick={() => console.log({"id": row.id})}
              >
                Test Connection
              </NavDropdown.Item>)} */}
            </NavDropdown>
          </Nav>
        </>
      ),
      right: true,
      width: "200px",
    }
  ];

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "SSO":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={images.Authentication}
                          alt="sso icon"
                        />{" "}
                        &nbsp; Authentication
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
                
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime} direction={slideDirection} in={slideChecked}>
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {deleteConfNames.length > 0 && (
                  <CustomModal open={openDeleteConf}>
                    <ShowAlert
                      setOpenAlert={setOpenDeleteConf}
                      handleAlertAction={handleDeleteAction}
                      colName={deleteConfNames}
                      alertMsg="Below Configurations will be deleted :"
                      headingMsg="Delete Configurations"
                    />
                  </CustomModal>)}
                
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="LDAP"
                        dataTableHeadingIcon={images.Authentication}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                        <CustomDelaySkeleton
                          rowsPerPage={rowsPerPage}
                          colCount={colCount}
                          totalColumns={6}
                        />
                    }
                    fixedHeader
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalConfigurations}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    actions={<ActionComponent navigate = {navigate} />}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={confColumns}
                        actionOptions={(isCapable && isCapable.LDAP.delete) ? actionOptions:null}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        placeholder="a configuration"
                        selectedRows={selectedRows}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                        pages_url={pages_url}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={<img src={images.FirstPageIcon} alt="first page" />}
                    paginationIconLastPage={<img src={images.LastPageIcon} alt="last page" />}
                    paginationIconNext={<img src={images.NextPageIcon} alt="next" />}
                    paginationIconPrevious={<img src={images.PreviousPageIcon} alt="previous" />}
                  />
                </div>

              </div>
            </div>
          </Fade>

        </div>
      </Slide>



    </>
  );
}

export default Configurations;