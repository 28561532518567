import { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { showErrorMsg } from "../../../../utils/showMessage";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";
import GeneralButton from "../../../common/SaveButton/GeneralButton";
const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const AddFieldModal = ({
  setAddFieldModal,
  field,
  setField,
  fieldList,
  setFieldList,
}) => {
  const [labelName, setLabelName] = useState(null);
  const [defaultvalue, setDefaultValue] = useState(null);
  const [required, setRequired] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(value.map((item) => item.label));
  }, [value]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const AddTicketField = async (event) => {
    event.preventDefault();
    let fieldNameExist = false;
    for (const field of fieldList) {
      Object.keys(field).some((key) => {
        if (
          key.replace(/\s/g, "").toLowerCase() ==
          labelName.replace(/\s/g, "").toLowerCase()
        ) {
          fieldNameExist = true;
          return fieldNameExist;
        }
      });
    }
    if (fieldNameExist) {
      showErrorMsg("Field already exist");
    } else {
      const existingIds = fieldList.map((item) => item.id);
      let newId;
      if (existingIds.length >= 1) {
        newId = Math.max(...existingIds) + 1;
      } else {
        newId = 1;
      }
      fieldList.push({
        id: newId,
        [labelName]: {
          ColumnType: field,
          Options: options,
          DefaultValue: defaultvalue,
          required: required,
          modalDefault: false,
          ...([
            "SingleLineText",
            "ParagraphText",
            "Number",
            "Dropdown",
          ].includes(field) || options.length <= 3
            ? { col: 6 }
            : { col: 12 }),
        },
      });
      setFieldList([...fieldList]);
      setField(null);
      setAddFieldModal((o) => !o);
    }
  };
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "6px",
      paddingBottom: "6px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  };
  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form
          id="add-column-setting-form"
          onSubmit={AddTicketField}
          className="add_systemuser_form"
        >
          <Stack gap={1}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formPlaintextName"
            >
              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="ff-pam fw-500 profile_heading fs-24px">
                    {field.replace(/([A-Z])/g, " $1").trim()}
                  </span>
                  <CrossButton className="cursor_pointer float-end" onClick={() => {
                      setField(null);
                      setAddFieldModal((o) => !o);
                    }}></CrossButton>
                </div>
              </Col>
            </Form.Group>
            <Row>
              <Col className="mt-3" md={12}>
                <Form.Label column md={6} className="input_label">
                  Field Label <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  value={labelName}
                  className="form_input_field mt-2"
                  type="text"
                  placeholder={`Enter field name here`}
                  onChange={(event) => setLabelName(event.target.value)}
                />
              </Col>
              {["Dropdown", "RadioButton"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Option(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <CreatableSelect
                      className="form_input_field"
                      required
                      components={components}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setValue(newValue)}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter the values and press enter"
                      value={value}
                      styles={selectStyles}
                    />
                  </Col>
                  <Col className="mt-2" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>
                    <Select
                      onChange={(event) => setDefaultValue(event.value)}
                      options={value}
                      noOptionsMessage={() => "Give options to select default"}
                    />
                  </Col>
                </>
              )}
              {["SingleLineText", "ParagraphText"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>

                    <Form.Control
                      type="text"
                      value={defaultvalue}
                      className="form_input_field mt-2"
                      onChange={(event) => setDefaultValue(event.target.value)}
                      placeholder="Enter default value here"
                    />
                  </Col>
                </>
              )}
              {["Number"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>

                    <Form.Control
                      type="number"
                      value={defaultvalue}
                      className="form_input_field mt-2"
                      onChange={(event) => setDefaultValue(event.target.value)}
                      placeholder="Enter your first name"
                    />
                  </Col>
                </>
              )}
              {["Checkbox"].includes(field) && (
                <>
                  <Col className="mt-3" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Option(s) <span className="text-danger">*</span>
                    </Form.Label>
                    <CreatableSelect
                      className="form_input_field "
                      required
                      components={components}
                      inputValue={inputValue}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setValue(newValue)}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter the values and press enter"
                      value={value}
                      styles={selectStyles}
                    />
                  </Col>
                  <Col className="mt-2" md={12}>
                    <Form.Label column md={6} className="input_label">
                      Default
                    </Form.Label>
                    <Select
                      isMulti
                      className="basic-multi-select mt-1"
                      onChange={(event) => {
                        const selectedValues = event.map(
                          (option) => option.value
                        );
                        setDefaultValue(selectedValues);
                      }}
                      options={value}
                      noOptionsMessage={() => "Give options to select default"}
                      styles={selectStyles}
                    />
                  </Col>
                </>
              )}

              <Col className="mt-3" md={12}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  className="input_label ps-0 ms-2 pt-4"
                  label="Required"
                  checked={required}
                  onChange={() => setRequired((e) => !e)}
                />
              </Col>
            </Row>
            <Row className="pt-4 mb-3 justify-content-end">
              <Col sm="auto">
                <GeneralButton 
                  color='#505050' 
                  onClickEvent={() => {
                    setField(null);
                    setAddFieldModal((o) => !o);
                  }} 
                  form="add-column-setting-form" 
                  value='Cancel' 
                  variant='contained' 
                  size='medium'
                  />

              </Col>
              <Col sm="auto">
                <GeneralButton 
                  form="add-column-setting-form" 
                  className="me-1" 
                  value='Create' 
                  variant='contained' 
                  size='medium'
                  />
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
};

export default AddFieldModal;
