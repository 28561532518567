import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonSpinner from "../../../common/ButtonSpinner/ButtonSpinner";
import Stack from "react-bootstrap/Stack";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import "react-tabs/style/react-tabs.css";
import {
  ExtendAppAllocation,
  allocateUsersToApp,
  getAppAllocationInfo,
} from "../../../../api/apps";
import {
  ExtendAssetAllocation,
  allocateUsers,
  getAssetAllocationInfo,
} from "../../../../api/asset";
import {
  updateTicketDetails,
  CheckTicketDetails,
} from "../../../../api/ticketing";
import { showError, showSuccess } from "../../../../utils/showMessage";
import { useSelector } from "react-redux";
import { getToken } from "../../../../api/apps";
import {
  handleGetPolicy,
  handleSetPolicy,
  loadPolices,
} from "../../../common/TimeComponent/helper_funtions";
import Select from "react-select";
import { SystemUserResourceList } from "../../../../api/systemuser";
import { RxCross1 } from "react-icons/rx";
import {
  extendWebAppAllocationUsers,
  getWebAppAllocationInfo,
  webAppAllocateToUsers,
} from "../../../../api/web_app";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";

function ApproveTicket({
  setApprovalModal,
  approvalTicket,
  resource,
  approvalType,
  getTicketDetail,
}) {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("Request Accepted");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [ticket, setTicket] = useState();
  const serverURL = useSelector((state) => state.customer.serverUrl);
  const ws_protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const ws_endpoint = `${serverURL.replace(
    /http:|https:/gi,
    ws_protocol + ":"
  )}/`;
  const customer_id = useSelector((state) => state.user?.user?.customer || "");
  const [sessionkey, setSessionkey] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [newAllocation, setNewAllocation] = useState(false);
  const [systemuser, setSystemuser] = useState(null);
  const [systemusers, setSystemusers] = useState(null);

  useEffect(() => {
    setTicket(approvalTicket);
    getPolicyDetails();
    ApprovalDateTime(approvalTicket);
    handleTicketing();
    getSystemUsers();
  }, []);

  const getSystemUsers = async () => {
    var isresource = 0;
    if (approvalTicket.resource_type === "ASSET") isresource = 1;
    else if (approvalTicket.resource_type === "APP") isresource = 2;
    else isresource = 3;
    const { data } = await SystemUserResourceList({
      isresource: isresource,
      new: false,
      id: approvalTicket.resource_id,
    });
    if (data !== null) {
      setSystemusers(
        data.map((user) => {
          user.label = user.system_display_username;
          user.value = user.id;
          return user;
        })
      );
    }
  };

  const getPolicyDetails = async () => {
    if (approvalTicket.resource_type == "APP") {
      const { data, error } = await getAppAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        app_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices(
          [approvalTicket.resource_id],
          "apps",
          setPolicies,
          setPolicy,
          [
            {
              id: approvalTicket.requested_by.id,
              name: approvalTicket.requested_by.username,
            },
          ]
        );
        setNewAllocation(true);
      }
    } else if (approvalTicket.resource_type == "ASSET") {
      const { data, error } = await getAssetAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        asset_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices(
          [approvalTicket.resource_id],
          "assets",
          setPolicies,
          setPolicy,
          [
            {
              id: approvalTicket.requested_by.id,
              name: approvalTicket.requested_by.username,
            },
          ]
        );
        setNewAllocation(true);
      }
    } else if (approvalTicket.resource_type == "WEB_APP") {
      const { data, error } = await getWebAppAllocationInfo({
        entity_id: approvalTicket.requested_by.id,
        web_app_id: approvalTicket.resource_id,
        entity_type: "user",
      });
      if (data != null) {
        setPolicy(data.policy);
      }
      if (error !== null) {
        loadPolices(
          [approvalTicket.resource_id],
          "webapps",
          setPolicies,
          setPolicy,
          [
            {
              id: approvalTicket.requested_by.id,
              name: approvalTicket.requested_by.username,
            },
          ]
        );
        setNewAllocation(true);
      }
    }
  };

  const handleLoadPolicies = () => {
    if (approvalTicket.resource_type == "APP") {
      loadPolices([approvalTicket.resource_id], "apps", setPolicies);
    } else if (approvalTicket.resource_type == "ASSET") {
      loadPolices([approvalTicket.resource_id], "assets", setPolicies);
    } else if (approvalTicket.resource_type == "WEB_APP") {
      loadPolices([approvalTicket.resource_id], "webapps", setPolicies);
    }
  };

  const handleTicketing = async () => {
    const { tokenData, tokenError } = await getToken();
    setSessionkey(tokenData.session_key);
  };

  const ApprovalDateTime = (ticket) => {
    let TicketFromDate = new Date(ticket.access_from * 1000);
    setFrom(
      TicketFromDate.getFullYear() +
        "-" +
        (TicketFromDate.getMonth() < 9
          ? "0" + (TicketFromDate.getMonth() + 1)
          : TicketFromDate.getMonth() + 1) +
        "-" +
        (TicketFromDate.getDate() <= 9
          ? "0" + TicketFromDate.getDate()
          : TicketFromDate.getDate())
    );
    setFromTime(
      (TicketFromDate.getHours() <= 9
        ? "0" + TicketFromDate.getHours()
        : TicketFromDate.getHours()) +
        ":" +
        (TicketFromDate.getMinutes() <= 9
          ? "0" + TicketFromDate.getMinutes()
          : TicketFromDate.getMinutes())
    );

    let TicketToDate = new Date(ticket.access_until * 1000);

    setTo(
      TicketToDate.getFullYear() +
        "-" +
        (TicketToDate.getMonth() < 9
          ? "0" + (TicketToDate.getMonth() + 1)
          : TicketToDate.getMonth() + 1) +
        "-" +
        (TicketToDate.getDate() <= 9
          ? "0" + TicketToDate.getDate()
          : TicketToDate.getDate())
    );
    setToTime(
      (TicketToDate.getHours() <= 9
        ? "0" + TicketToDate.getHours()
        : TicketToDate.getHours()) +
        ":" +
        (TicketToDate.getMinutes() <= 9
          ? "0" + TicketToDate.getMinutes()
          : TicketToDate.getMinutes())
    );
  };

  const sendTicketResponse = async (user_id) => {
    const app_connection_endpoint = `${ws_endpoint}ws/ticket/${customer_id}_${user_id}/?session_key=${sessionkey}`;
    const ticketing_ws_connection = new WebSocket(app_connection_endpoint);
    ticketing_ws_connection.onopen = function (e) {
      ticketing_ws_connection.send(
        JSON.stringify({
          type: "ticketData",
          message: { user_id: user_id },
          session_key: sessionkey,
        })
      );
      ticketing_ws_connection.close();
    };
  };

  const ticketResponse = async (e) => {
    e.preventDefault();
    const { data, error } = await CheckTicketDetails(approvalTicket);
    if (data !== null) {
      if (!loading) {
        setLoading(true);
        let finalFromDate, finalToDate;
        finalFromDate = new Date(from + " " + fromTime + ":00");
        finalToDate = new Date(to + " " + toTime + ":59");
        const { data, error } = await updateTicketDetails({
          id: [approvalTicket.id],
          approval_type: approvalType,
          response: responseMessage,
          response_time: new Date(),
          access_from: Date.parse(finalFromDate) / 1000,
          access_until: Date.parse(finalToDate) / 1000,
          systemuser_id: systemuser.value,
          ...(!newAllocation
            ? { access_for_user_type: "Extension" }
            : { access_for_user_type: "Allocation" }),
          ...(!newAllocation
            ? policy
              ? { resource_policy_id: policy.id }
              : { resource_policy_id: null }
            : policy
            ? { resource_policy_id: policy[approvalTicket.requested_by.id].id }
            : { resource_policy_id: null }),
        });
        if (data != null) {
          setApprovalModal((o) => !o);
          getTicketDetail();
          showSuccess(data.message);
          sendTicketResponse(approvalTicket.requested_by.id);
        }
        if (error != null) {
          showError(error);
        }
        setLoading(false);
      }
    }
    if (error !== null) {
      showError(error);
      setApprovalModal((o) => !o);
      setLoading(false);
    }
  };
  return (
    <>
      <Container fluid className="alert_container mx-auto">
        <Form
          onSubmit={(e) => {
            ticketResponse(e);
          }}
          className="add_systemuser_form"
        >
          <Stack gap={1}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="formPlaintextName"
            >
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <h2 className="ff-pam fw-500 profile_heading fs-24px">
                    Accepting?
                  </h2>
                  <CrossButton
                    onClick={() => {
                      setApprovalModal((o) => !o);
                      setLoading(false);
                    }}
                  ></CrossButton>
                </div>
                <h2 className="pt-2 pb-3 ff-pam fw-400 profile_heading fs-14px">
                  We notice you are approving this ticket. Please help us with a
                  response for the same. <span className="text-danger">*</span>
                </h2>
                <Row>
                  <Col md={6}>
                    <Form.Label column md={4} className="input_label ">
                      Created By -
                    </Form.Label>
                    <Form.Label column md={8} className="mb-3 ">
                      {approvalTicket.requested_by.username}
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Label column md={4} className="input_label">
                      {approvalTicket.resource_type == "APP" ? (
                        <>App</>
                      ) : approvalTicket.resource_type == "ASSET" ? (
                        <>Asset</>
                      ) : (
                        <>Web App</>
                      )}{" "}
                      Name -
                    </Form.Label>
                    <Form.Label column md={8} className="mb-3">
                      {approvalTicket.resource_type == "APP" ? (
                        <>{resource.app_name}</>
                      ) : approvalTicket.resource_type == "ASSET" ? (
                        <>{resource.asset_name}</>
                      ) : (
                        <>{resource.app_name}</>
                      )}
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="date_component_ticketing mx-0 mb-2 pt-2 pb-3 justify-content-between">
                  <Col md={6}>
                    <Form.Label column md={6} className="input_label">
                      Access From <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="date"
                          value={from}
                          onChange={(event) => setFrom(event.target.value)}
                          placeholder="MyTickets"
                          isInvalid={from === null}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="time"
                          value={fromTime}
                          onChange={(event) => setFromTime(event.target.value)}
                          isInvalid={fromTime === null}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="left_border_date_ticketing" md={6}>
                    <Form.Label column md={6} className="input_label">
                      Access Until <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="date"
                          min={from}
                          value={to}
                          onChange={(event) => setTo(event.target.value)}
                          isInvalid={to === null}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          className="form_date_input_field py-2 pe-2"
                          type="time"
                          value={toTime}
                          min={to > from ? "00:00" : fromTime}
                          onChange={(event) => setToTime(event.target.value)}
                          placeholder="MyTickets"
                          isInvalid={toTime === null}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {policy && policies && (
                  <Row>
                    <Col md={6}>
                      <Form.Label column md={6} className="input_label mt-3">
                        Select Policy <span className="text-danger">*</span>
                      </Form.Label>
                      {policy &&
                        policies &&
                        [
                          {
                            id: approvalTicket.requested_by.id,
                            name: approvalTicket.requested_by.username,
                          },
                        ].map((name, index) => (
                          <div className="mt-1">
                            <Select
                              className="systemuser_select"
                              classNamePrefix="asset"
                              getOptionLabel={(option) => option.policy_name}
                              getOptionValue={(option) => option.id}
                              isSearchable={true}
                              name="systemuser_select"
                              menuPlacement={
                                [
                                  {
                                    id: approvalTicket.requested_by.id,
                                    name: approvalTicket.requested_by.username,
                                  },
                                ].length -
                                  1 ===
                                  index &&
                                [
                                  {
                                    id: approvalTicket.requested_by.id,
                                    name: approvalTicket.requested_by.username,
                                  },
                                ].length > 2
                                  ? "top"
                                  : "bottom"
                              }
                              required={true}
                              options={
                                resource.id
                                  ? policies[
                                      ticket &&
                                      approvalTicket.resource_type == "APP"
                                        ? resource.app_name
                                        : approvalTicket.resource_type ==
                                          "ASSET"
                                        ? resource.asset_name
                                        : resource.app_name
                                    ]
                                  : policies[name.name]
                              }
                              onChange={(selectedOption) => {
                                !newAllocation
                                  ? setPolicy(selectedOption)
                                  : handleSetPolicy(
                                      name.id,
                                      selectedOption,
                                      policy,
                                      setPolicy
                                    );
                              }}
                              value={
                                !newAllocation
                                  ? policy
                                  : handleGetPolicy(name.id, policy)
                              }
                              onFocus={handleLoadPolicies}
                            />
                          </div>
                        ))}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Label column md={6} className="input_label mt-3">
                      System User <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mt-1">
                      <Select
                        required
                        closeMenuOnSelect={true}
                        onChange={(e) => setSystemuser(e)}
                        options={systemusers}
                      />
                    </div>
                  </Col>
                </Row>
                <Row></Row>

                <Form.Label column md={6} className="input_label mt-3">
                  Message <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  className="form_input_field mt-1"
                  as="textarea"
                  rows={4}
                  value={responseMessage}
                  onChange={(event) => setResponseMessage(event.target.value)}
                  placeholder="Enter your response here."
                />
              </Col>
            </Form.Group>
            <Row className="pt-4 mb-3 justify-content-end">
              <Col sm="auto">
                <GeneralButton
                  onClickEvent={() => {
                    setApprovalModal((o) => !o);
                    setLoading(false);
                  }}
                  className="me-1"
                  value="Cancel"
                  color="#505050"
                  variant="outlined"
                  size="large"
                ></GeneralButton>
              </Col>
              <Col sm="auto" className="me-sm-2 p-0">
                {loading ? (
                  <GeneralButton
                    variant="contained"
                    disabled={true}
                    className="me-1"
                    value={
                      <img src={images.Loader} width="26px" height="26px" />
                    }
                    size="large"
                  ></GeneralButton>
                ) : (
                  <GeneralButton
                    className="me-1"
                    value="Confirm"
                    variant="contained"
                    size="large"
                  ></GeneralButton>
                )}
              </Col>
            </Row>
          </Stack>
        </Form>
      </Container>
    </>
  );
}
export default ApproveTicket;
