import React, { useEffect, useState } from 'react'
import { fetchMiniorangeMFAConfiguration, miniorangeMFAConfiguration, miniorangePolling } from '../../../../api/two_fa'
import images from '../../../../utils/images';
import { showError, showErrorMsg, showSuccess } from '../../../../utils/showMessage';
import './MiniorangeAuthenticator.scss';
import { RxCross1 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

const MiniorangeAuthenticator = ({ qrCode, txid, methodName, methodHeading, methodNote, setOpenModal , setConfiguredMethod, setIsMethodConfigured}) => {
    let timer;
    const [enableSubmitButton, setEnableSubmitButton] = useState(false);

    const startPolling = async () => {
        const { data, error } = await miniorangePolling(methodName, txid);
        if (data != null) {
            if (data.statusCode === 'SUCCESS') {
                clearInterval(timer);
                setEnableSubmitButton(true);
            }
            if(data.statusCode === 'ERROR') {
                clearInterval(timer);
                showErrorMsg(data.message);
                setOpenModal(o => !o);
            }
            if (data.statusCode === 'DENIED') {
                clearInterval(timer);
                showSuccess(data.message);
                setOpenModal(o => !o);
            }
        }
        if (error != null) {
            clearInterval(timer);
            showError(error);
            setOpenModal(o => !o);
        }
    }
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const {data, error} = await miniorangeMFAConfiguration({
            "txid": txid,
            "method": methodName
        });
        if (data != null) {
            setConfiguredMethod(methodName);
            setIsMethodConfigured(Object.create(null));
            showSuccess(data.message);
        }
        if (error != null) {
            showError(error);
        }
        setOpenModal(o => !o);

    }
    useEffect(() => {
        timer = setInterval(() => {
            startPolling();
        }, 3000);
        return () => clearInterval(timer);
    }, []);
    return (
        <>
            <div class="miniorange_authentication_container">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mfa_method_heading border-0 customize-column-name mb-0 me-5">{methodHeading}</h3>
                    <CrossButton onClick={() => { setOpenModal(o => !o) }}></CrossButton>
                </div>
                <div>
                    <p id="ma_mfa_method_description pb-3 " class="mfa_method_description">
                        {methodNote}
                    </p>
                </div>
                <div class="mfa_method_content">
                    <div class="p-5 d-flex flex-row align-items-center justify-content-around">
                        <div class="mfa_method_note_container d-flex flex-column justify-content-center align-items-center">
                            <p class="mfa_method_note_22">
                                Scan the QR Code using the</p>
                            <p class="mfa_method_note_22 mfa_method_note_highlight">
                                miniOrange Authenticator
                                App
                            </p>
                            <p class="mfa_method_note_22">
                                to register your device.
                            </p>
                        </div>
                        <div>
                            <img src={qrCode} id="ma_qr_code" alt="QR CODE" />
                        </div>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-end">
                    <div class="d-flex justify-content-end">
                        <button type="button" id="miniorange_authentication_cancel_button"
                            class="form_cancel_button" onClick={() => { setOpenModal(o => !o) }}>Cancel
                        </button>
                        <form method="POST" id="miniorange_authentication_configure_form" onSubmit={handleFormSubmit}>
                            <button type="submit" id="miniorange_authentication_submit_button"
                                class="btn btn-primary form_submit_button" disabled={enableSubmitButton ? false : true}>Confirm
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MiniorangeAuthenticator
