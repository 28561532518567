import React from "react";
import icon from "./icons/icon.svg";
import "./report.scss";
import arrow_first from "./icons/arrow_first.svg";
import arrow_last from "./icons/arrow_last.svg";
import arrow_right from "./icons/arrow_right.svg";
import arrow_left from "./icons/arrow_left.svg";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { changeTitle } from "../../../state/slices/header";
import { useDispatch } from "react-redux";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import CustomModal from "../../common/CustomModal/CustomModal";
import AdvanceFilter from "./AdvanceFilter";
import useWindowDimensions from "../../../utils/getHeightWidth";
import { useSelector } from "react-redux";
import { formatted_timedate } from "../../../utils/updateDateFormat";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide, Fade } from "@mui/material";
import {
  delayTime,
  slideDirection,
  fadedelayTime,
} from "../../../utils/transitionEffectParams";
import { fetchData, ActionComponent } from "./getReportsData";

const WebAppAllocationReport = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const title_heading = useSelector((state) => state.header.title);
  const [pageType, setPageType] = useState("web_app_allocation_user_audit");

  const selectPage = [
    { label: "Users", value: "web_app_allocation_user_audit" },
    { label: "Groups", value: "web_app_allocation_group_audit" },
    { label: "Roles", value: "web_app_allocation_role_audit" },
  ];
  /* customize column modal functionality starts. */
  const WebAppAllocationColumns = [
    { label: "Action By Email", value: "action_by_email" },
    { label: "Web App Name", value: "web_app_name" },
    { label: "Web App Type", value: "web_app_type" },
    { label: "IP/Host", value: "user_ip" },
    { label: "Action", value: "action" },
  ];

  const AddedColumns= {
    "web_app_allocation_user_audit": [{ label: "User Id", value: "user_id" },
    { label: "User Name", value: "user_name" }],
    "web_app_allocation_group_audit": [{ label: "Group Id", value: "group_id" },
    { label: "Group Name", value: "group_name" }],
    "web_app_allocation_role_audit": [{ label: "Role Id", value: "role_id" },
    { label: "Role Name", value: "role_name" }]
  }

  const pages_url = "audit-dashboard/web_app_allocation_report/";
  const customizeColumnDict = {
    "IP/Host": true,
    "Web App ID": false,
    Datetime: true,
  };
  const [checkedStateDict, setCheckedStateDict] = useState(customizeColumnDict);
  const [colCount, setColCount] = useState(null);
  const [pending, setPending] = useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const [allFields, setAllFields] = useState([]);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const userSpecificColumns = [
    {
      name: "User ID",
      selector: (row) => row.user_id,
      style: {},
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.user_name,
      style: {},
      sortable: true,
    },
  ];

  const groupSpecificColumns = [
    {
      name: "Group ID",
      selector: (row) => row.group_id,
      style: {},
      sortable: true,
    },
    {
      name: "Group Name",
      selector: (row) => row.group_name,
      style: {},
      sortable: true,
    },
  ];

  const roleSpecificColumns = [
    {
      name: "Role ID",
      selector: (row) => row.role_id,
      style: {},
      sortable: true,
    },
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      style: {},
      sortable: true,
    },
  ];

  const getcolumns=()=>{
    switch (pageType) {
        case "web_app_allocation_user_audit":
          return userSpecificColumns;
        case "web_app_allocation_role_audit":
          return roleSpecificColumns;
        case "web_app_allocation_group_audit":
          return groupSpecificColumns;
      }
  }

  const columns = React.useMemo(
    () => [
      {
        name: "Web App ID",
        selector: (row) => row.web_app_id,
        style: {},
        omit: !checkedStateDict["Web App ID"],
        sortable: true,
      },
      {
        name: "Web App Name",
        selector: (row) => row.web_app_name,
        style: {},
        sortable: true,
      },
      getcolumns()[0],
      getcolumns()[1],
      {
        name: "Action",
        selector: (row) => row.action,
        sortable: true,
      },
      {
        name: "Action By Email",
        selector: (row) => row.action_by_email,
        sortable: true,
      },
      {
        name: "IP/Host",
        selector: (row) => row.user_ip,
        sortable: true,
        omit: !checkedStateDict["IP/Host"],
      },
      {
        name: "User Agent",
        selector: (row) => row.user_agent,
        sortable: true,
      },
      {
        name: "Datetime",
        selector: (row) => formatted_timedate(row.datetime),
        style: {},
        omit: !checkedStateDict["Datetime"],
        sortable: true,
      },
    ],
    [checkedStateDict, pageType]
  );

  /* customize column logic ends. */

  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(
    localStorage.getItem("rowsPerPage_WebAppAllocationReport") !== null
      ? localStorage.getItem("rowsPerPage_WebAppAllocationReport")
      : 5
  );
  const [reportData, setReportData] = useState([]);
  const [advanceSearchToggle, setAdvanceSearchToggle] = useState(false);
  const [totalReportData, setTotalReportData] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const advancedFilters = useSelector((state) => state.advanceFilter);
  const advancedQuery = advancedFilters.advanceQuery[pageType];
  const selectedValuesList = advancedFilters.selectedValuesList;
  const keysToExclude = ["id","datetime", "action_by_user_id"];

  const getData = async (
    page,
    perPage,
    search,
    report_type,
    response_type,
    filterBy,
    advanceFilters,
    allFieldsData
  ) => {
    fetchData(
      page,
      perPage,
      search,
      report_type,
      response_type,
      filterBy,
      setReportData,
      setTotalReportData,
      setFilteredData,
      setLoading,
      setPending,
      setAllFields,
      allFieldsData? allFieldsData : allFields,
      keysToExclude,
      advanceFilters,
      dispatch,
      selectedValuesList
    );
  };

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery, []);
    setAdvanceSearchToggle(false)
  }, [perPage, pageType]);

  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const filter_set_data = (filteredData, count) => {
    setFilteredData(filteredData);
    setTotalReportData(count);
    setReportData(filteredData);
  };

  const handlePageChange = async (page) => {
    getData(page, perPage, search, pageType, "table", filterBy, advancedQuery);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    getData(
      page,
      newPerPage,
      search,
      pageType,
      "table",
      filterBy,
      advancedQuery
    );
    localStorage.setItem("rowsPerPage_WebAppAllocationReport", newPerPage);
    setPerPage(newPerPage);
  };

  //for searching in frontend.
  useEffect(() => {
    const result = reportData.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "all") {
        if (search === "") {
          getData(
            1,
            perPage,
            search,
            pageType,
            "table",
            filterBy,
            advancedQuery
          );
        } else {
          return (
            String(object.user_ip).toLowerCase().match(searchs.toLowerCase()) ||
            object.web_app_name.toLowerCase().match(searchs.toLowerCase()) ||
            (pageType === "web_app_allocation_user_audit" && object.user_name.toLowerCase().match(searchs.toLowerCase()) || (object.user_id).toString().match(searchs)) ||
            (pageType === "web_app_allocation_role_audit" && object.role_name.toLowerCase().match(searchs.toLowerCase()) || (object.role_id).toString().match(searchs))||
            (pageType === "web_app_allocation_group_audit" && object.group_name.toLowerCase().match(searchs.toLowerCase()) || (object.group_id).toString().match(searchs))||
            object.action_by_email.toLowerCase().match(searchs.toLowerCase()) ||
            object.datetime.toLowerCase().match(searchs.toLowerCase()) ||
            object.action.toLowerCase().match(searchs.toLowerCase())
          );
        }
      } else {
        if (search === "") {
          getData(
            1,
            perPage,
            search,
            pageType,
            "table",
            filterBy,
            advancedQuery
          );
        }
        else if (["user_id", "group_id", "role_id"].includes(filterBy)){
          return (object[filterBy]).toString().match(searchs);
        }
        else {
          return object[filterBy.toLowerCase()].match(searchs.toLowerCase());
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  const handleSearch = async (event) => {
    event.preventDefault();
    getData(1, perPage, search, pageType, "table", filterBy, advancedQuery);
  };

  return (
    <>
      {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal padding="0px !important" open={openFilterPopup}>
        <AdvanceFilter setOpenFilterPopup={setOpenFilterPopup} />
      </CustomModal>
      <Slide
        timeout={delayTime}
        className=""
        direction={slideDirection}
        in={slideChecked}
      >
        <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText="Web App Allocation Report"
                        dataTableHeadingIcon={icon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      <CustomDelaySkeleton
                        rowsPerPage={perPage}
                        colCount={colCount}
                        totalColumns={9}
                      />
                    }
                    fixedHeader
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalReportData}
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    subHeader
                    actions={
                      !advanceSearchToggle && (
                        <ActionComponent
                          getData={getData}
                          pageType={pageType}
                          search={search}
                          filter_by={filterBy}
                          download_report={false}
                          disable_buttons={filteredData.length > 0 ? true : false}
                        />
                      )
                    }
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="web app allocation report"
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        filterOptions={[...AddedColumns[pageType], ...WebAppAllocationColumns]}
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                        advanceSearchToggle={advanceSearchToggle}
                        showAdvance={true}
                        setAdvanceSearchToggle={setAdvanceSearchToggle}
                        filter_set_data={filter_set_data}
                        page={1}
                        perPage={perPage}
                        getData={getData}
                        for_userlogin={true}
                        all_fields={allFields}
                        report_type={pageType}
                        download_report={false}
                        disable_buttons={filteredData.length > 0 ? true : false}
                        selectPage={selectPage}
                        setPageType={setPageType}
                        pageType={pageType}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={arrow_first} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={arrow_last} alt="last page" />
                    }
                    paginationIconNext={<img src={arrow_right} alt="next" />}
                    paginationIconPrevious={
                      <img src={arrow_left} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};
export default WebAppAllocationReport;
