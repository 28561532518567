import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import allAssetHeadingIcon from "../../../images/apps_icon.svg";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import { changeTitle } from "../../../state/slices/header";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { showError } from "../../../utils/showMessage";
import { Button } from "react-bootstrap";
import { generateToken } from "../../../api/recordings";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { Slide,Fade } from "@mui/material";
import { delayTime,fadedelayTime, slideDirection } from "../../../utils/transitionEffectParams";
import useWindowDimensions from "../../../utils/getHeightWidth";
import {formatted_timedate} from "../../../utils/updateDateFormat";
import GetCapabilities from "../../../utils/getCapabilities";
import AdvanceFilter from "./AdvanceFilter";
import { fetchData, ActionComponent} from "./getReportsData";
import CustomModal from "../../common/CustomModal/CustomModal";


function WebAppAccessReport() {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const dispatch = useDispatch();
  const my_capabilities=GetCapabilities();
  const isCapable =my_capabilities[0]
  const { auditsUrl } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("limit_WebAppAccessReport") !== null
      ? localStorage.getItem("limit_WebAppAccessReport")
      : 5
  );
  const title_heading = useSelector((state) => state.header.title);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pages_url = "audit-dashboard/webappAccessReport";
  const customizeColumnDict={
    "User Name":true,
    "Web App Type":true,
    "User Ip":true,
    "User Agent":true,
    "Accessed Date":true
  }
  const [checkedStateDict,setCheckedStateDict] = useState(customizeColumnDict)
  const [colCount,setColCount] = useState(null);
  const [pending, setPending]= useState(true);
  const [slideChecked, setSlideChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [allFields ,setAllFields] = useState([])
  const [advanceSearchToggle, setAdvanceSearchToggle] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [totalReportData, setTotalReportData] = useState(0);
  const [filterBy, setFilterBy] = useState("all");
  const pageType= "web_app_access_audit";
  const keysToExclude = ['user_id','web_app_id', 'user_id', 'accessed_time', 'is_recorded'];
  const advancedFilters =  useSelector((state) => state.advanceFilter)
  const advancedQuery =  advancedFilters.advanceQuery[pageType]
  const selectedValuesList= advancedFilters.selectedValuesList

  const getData = async (page, perPage, search, report_type, response_type, filterBy, advanceFilters) =>{
    fetchData(page, perPage, search, report_type, response_type, filterBy, setReportData, setTotalReportData, setFilteredData, setLoading, setPending, setAllFields,allFields, keysToExclude, advanceFilters, dispatch, selectedValuesList )
  }

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const accessReportColumns = [
    { label: "User Name", value: "user_name" },
    { label: "Web App Name", value: "web_app_name" },
    { label: "Web App Type", value: "web_app_type" },
    { label: "User Ip", value: "user_ip" },
    { label: "User Agent", value: "user_agent" },
    { label: "Status", value: "status" },
  ];


  useEffect(() => {
    
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
     getData(1, rowsPerPage, search, pageType, "table", filterBy, advancedQuery);
  }, [page, rowsPerPage, dispatch]);

  const filter_set_data = (filteredData, count) => {
    setFilteredData(filteredData);
    setTotalReportData(count);
    setReportData(filteredData);
  };


  useEffect(() => {
    const result = reportData.filter((object) => {
      let searchs = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
      if (filterBy === "all") {
        if (search === "") {
          getData(1, rowsPerPage, search, pageType, "table", filterBy, advancedQuery);
        } else {
          return (
            (object.id).toString().match(searchs)||
            object.user_name.toLowerCase().match(searchs.toLowerCase()) ||
            object.web_app_name.toLowerCase().match(searchs.toLowerCase()) ||
            object.web_app_type.toLowerCase().match(searchs.toLowerCase()) ||
            object.user_ip.toLowerCase().match(searchs.toLowerCase()) ||
            object.user_agent.toLowerCase().match(searchs.toLowerCase()) ||
            object.status.toLowerCase().match(searchs.toLowerCase())
          );
        }
      } else {
        if (search === "") {
          getData(1, rowsPerPage, search, pageType, "table", filterBy, advancedQuery);
        }
        else if (["id"].includes(filterBy)){
          return (object[filterBy]).toString().match(searchs);
        }   
        else {
          return object[filterBy.toLowerCase()].match(searchs.toLowerCase());  
        }
      }
    });

    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(1);
    localStorage.setItem("limit_WebAppAccessReport", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    getData(page, rowsPerPage, search, pageType, "table", filterBy, advancedQuery);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "85px",
      sortable: true,
    },
    {
      name: "Web App Name",
      selector: (row) => row.web_app_name,
      grow: 1,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      grow: 1,
      omit: !checkedStateDict["User Name"],
      sortable: true,
    },
    {
      name: "Web App Type",
      selector: (row) => <div className="text-uppercase">{row.web_app_type}</div>,
      grow: 1,
      omit: !checkedStateDict["Web App Type"],
      sortable: true,
    },
    {
      name: "User Ip",
      selector: (row) => row.user_ip,
      grow: 1,
      omit: !checkedStateDict["User Ip"],
      sortable: true,
    },
    {
      name: "User Agent",
      selector: (row) =>row.user_agent,
      grow: 1,
      omit: !checkedStateDict["User Agent"],
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "SUCCESS" ? (
          <div className="text-success">{row.status}</div>
        ) : (
          <div className="text-danger">{row.status}</div>
        ),
      grow: 1,
      sortable: true,
    },
    {
      name: "Accessed Date",
      selector: (row) => (
        formatted_timedate(row.accessed_time)
      ),
      grow: 1,
      omit: !checkedStateDict["Accessed Date"],
      sortable: true,
    },
    {
        name: "Action",
        cell: (row) => (
            row.is_recorded ?
                <Button
                    className="border-0 bg-transparent text-primary"
                    onClick={async () => {
                        const { data, error } = await generateToken();
                        if (data !== null) {
                            const url =
                                `${auditsUrl}/audits/session_recording/replay/?session_type=WebApp&access_id=` +
                                row.id +
                                "&token_key=" +
                                data.token_key +
                                "&token_value=" +
                                data.token_value +
                                "&session_key=" +
                                data.session_key +
                                "&video_id=" +
                                -1 +
                                "&audits_url=" +
                                auditsUrl +
                                "&customer_id=" +
                                data.customer_id;;
                            window.open(url, "_blank");
                        }
                        if (error !== null) {
                            showError(error);
                        }
                    }}
                >
                    <AiOutlinePlayCircle size={20} color="#32a3ea" />
                </Button>
                : (
                    <button
                        disabled
                        type="button"
                        className="border-0 bg-transparent"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="Video not present"
                    >
                        <AiOutlinePlayCircle size={20} color="#f4646f" />
                    </button>
                )
        ),

        center: true,
        minWidth: "50px",
    },
  ];

  return (
    <>
    {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <CustomModal padding="0px !important" open={openFilterPopup}>
        <AdvanceFilter setOpenFilterPopup={setOpenFilterPopup} />
      </CustomModal>
      <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
        <div>
        <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
        <div
        className="position-relative bg-white"
        style={{ height: `${datatable_height}` }}
        >
        <DataTable
          title={
            <ListHeading
              dataTableHeadingText="Web Apps Access Report"
              dataTableHeadingIcon={allAssetHeadingIcon}
            />
          }
          columns={columns}
          data={filteredData}
          className="rdt_container"
          progressPending={pending}
          progressComponent={
              <CustomDelaySkeleton
                  rowsPerPage={rowsPerPage}
                  colCount={colCount}
                  totalColumns={9}
              />
          }
          fixedHeader
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          paginationServer
          paginationTotalRows={totalReportData}
          highlightOnHover
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          actions={
            !advanceSearchToggle && <ActionComponent getData={getData} pageType= {pageType} search= {search} filter_by= {filterBy}download_report={false} disable_buttons={filteredData.length > 0 ? true : false}/>
          }
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterOptions={accessReportColumns}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              placeholder="reports"
              pages_url={pages_url}
              checkedStateDict={checkedStateDict}
              setCheckedStateDict={setCheckedStateDict}
              advanceSearchToggle={advanceSearchToggle}
              showAdvance={true}
              setAdvanceSearchToggle={setAdvanceSearchToggle}
              filter_set_data={filter_set_data}
              page={1}
              perPage={rowsPerPage}
              getData={getData}
              for_userlogin={true}
              all_fields={allFields}
              report_type={pageType}
              download_report={false}
              disable_buttons={filteredData.length > 0 ? true : false}
            />
          }
          subHeaderAlign="center"
          paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
          paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
          paginationIconNext={<img src={NextPageIcon} alt="next" />}
          paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
        />
        </div>
      </div>
  </div>
</Fade>


      </div>
    </Slide>
    </>
  );
}

export default WebAppAccessReport;
