import React, { } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Select from 'react-select';
import '../../DatabasePolicyList/DatabasePolicy.scss';
import '../../AddDatabasePolicy/AddDatabasePolicy.scss';
const selectStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        paddingTop: "3px",
        paddingBottom: "3px",
        borderColor: "#ced4da",
        boxShadow: "none",
        fontFamily: '"DM Sans"',
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "21px",
        color: "#717171",
        ':hover': {
            borderColor: "#ced4da",
        }
    }),
    option: (styles) => ({
        ...styles,
        fontFamily: '"DM Sans"',
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "21px",
    }),
}

const GeneralSection = ({ ACTION_TYPE, apps, handleOnChangeAppsSelect,handleOnChangeAppsSystemUserSelect, appSystemUser, appDetails, setAppSystemUser, app, name, setName, sessionTimeout, setSessionTimeout, auditEvents, setAuditEvents, recordSession, setRecordSession, livestream, setLivestream, enforceMFA, setEnforceMFA }) => {
    return (
        <div id="database_policy_general">
            {["DATABASE_POLICY_ADD" , "DATABASE_POLICY_EDIT"].includes(ACTION_TYPE) && <Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
                <Form.Label column md={3} className="input_label">
                    Database App<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                    {ACTION_TYPE === "DATABASE_POLICY_ADD" && (<Select
                        className="systemuser_select"
                        classNamePrefix="asset"
                        getOptionLabel={(option) => option.app_name}
                        getOptionValue={(option) => option.id}
                        isClearable={false}
                        isSearchable={true}
                        name="systemuser_select"
                        required={true}
                        options={apps}
                        onChange={(selectedOption) => { handleOnChangeAppsSelect(selectedOption) }}
                        styles={selectStyles}
                        value={app}
                    />)}
                    {ACTION_TYPE === "DATABASE_POLICY_EDIT" && (<Form.Control
                        required
                        className="form_input_field general_input_field"
                        type="text"
                        disabled={true}
                        value={app.app_name}
                        readOnly={true}
                    />)}
                </Col>
            </Form.Group>}
            {app && ACTION_TYPE === "DATABASE_POLICY_ADD" && <Form.Group
                as={Row}
                className="mb-3 justify-content-between align-items-center"
                controlId="policy_name_input"
            >
                <Form.Label column md={3} className="input_label">
                    System User<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                    <Select
                        className="systemuser_select"
                        classNamePrefix="asset"
                        getOptionLabel={(option) => option.system_display_username}
                        getOptionValue={(option) => option.id}
                        isClearable={false}
                        isSearchable={true}
                        name="systemuser_select"
                        required={true}
                        options={app.systemusers}
                        onChange={(selectedOption) => { handleOnChangeAppsSystemUserSelect(selectedOption) }}
                        styles={selectStyles}
                        value={appSystemUser}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please select a system user
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>}
            {ACTION_TYPE === "DATABASE_POLICY_EDIT" && <Form.Group
                as={Row}
                className="mb-3 justify-content-between align-items-center"
                controlId="policy_name_input"
            >
                <Form.Label column md={3} className="input_label">
                    System User<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                    <Select
                        className="systemuser_select"
                        classNamePrefix="asset"
                        getOptionLabel={(option) => option.system_display_username}
                        getOptionValue={(option) => option.id}
                        isClearable={false}
                        isSearchable={true}
                        name="systemuser_select"
                        required={true}
                        options={appDetails}
                        onChange={(selectedOption) => { setAppSystemUser({...selectedOption}) }}
                        styles={selectStyles}
                        value={appSystemUser}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please select a system user
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>}
            <Form.Group
                as={Row}
                className="mb-3 justify-content-between align-items-center"
                controlId="policy_name_input"
            >
                <Form.Label column md={3} className="input_label">
                    Policy Name<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                    <Form.Control
                        required
                        className="form_input_field general_input_field"
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        placeholder="Enter a policy name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a policy name.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3 justify-content-between align-items-center"
                controlId="session_time_input"
            >
                <Form.Label column md={3} className="input_label">
                    Session Timeout<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                    <Form.Control
                        required
                        className="form_input_field general_input_field"
                        type="number"
                        value={sessionTimeout}
                        onChange={(event) => { if (event.target.value >= 1) { setSessionTimeout(event.target.value) } }}
                        placeholder="Enter a policy name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter session timeout
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3 justify-content-start align-items-start"
                controlId="switch"
            >
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        id="audit-events-checkbox"
                        className='policy-checkbox'
                        checked={auditEvents}
                        onChange={() => setAuditEvents(!auditEvents)}
                    />
                </Col>
                <div className="col-sm-11">
                    <Form.Label className="input_label">
                        Audit Events
                    </Form.Label>
                    <p className='policy_description'>Audit Events feature, ensures comprehensive tracking and logging of all executed commands and actions, providing a detailed audit trail for enhanced security and accountability.</p>
                </div>
            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3 justify-content-start align-items-start"
                controlId="switch"
            >
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        id="record-sessions-checkbox"
                        className='policy-checkbox'
                        checked={recordSession}
                        onChange={() => setRecordSession(!recordSession)}
                    />
                </Col>
                <div className="col-sm-11">
                    <Form.Label className="input_label">
                        Record Sessions
                    </Form.Label>
                    <p className='policy_description'>With the Record Sessions feature, end user actions can be effortlessly recorded, allowing for later review and analysis.</p>
                </div>

            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3 justify-content-start align-items-start"
                controlId="switch"
            >
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        id="livestream-checkbox"
                        className='policy-checkbox'
                        checked={livestream}
                        onChange={() => setLivestream(!livestream)}
                    />
                </Col>
                <div className="col-sm-11">
                    <Form.Label className="input_label">
                        Livestream
                    </Form.Label>
                    <p className='policy_description'>By enabling the Livestream feature, administrators gain the ability to monitor and view live user actions in real-time.</p>
                </div>
            </Form.Group>
            <Form.Group
                as={Row}
                className="mb-3 justify-content-start align-items-start"
                controlId="switch"
            >
                <Col sm={1}>
                    <Form.Check
                        type="checkbox"
                        id="enforce-mfa-checkbox"
                        className='policy-checkbox'
                        checked={enforceMFA}
                        onChange={() => setEnforceMFA(!enforceMFA)}
                    />
                </Col>
                <div className="col-sm-11">
                    <Form.Label className="input_label">
                        Enforce MFA
                    </Form.Label>
                    <p className='policy_description'>With the Enforce MFA feature, an additional layer of security is applied, requiring users to authenticate their identity through multiple factors before accessing resources.</p>

                </div>
            </Form.Group>
        </div>
    )
}

export default GeneralSection
