import {PasswordReg} from "../regex/Regex";
export const passwordPolicyRegex=(policy,email,username,first_name,last_name)=>{
    if(policy !== null){
        const can_contain_email_or_part=policy.can_contain_email_or_part
        const can_contain_firstname_or_part=policy.can_contain_firstname_or_part
        const can_contain_lastname_or_part=policy.can_contain_lastname_or_part
        const can_contain_username_or_part = policy.can_contain_username_or_part
        const max_password_length = policy.max_password_length
        const min_password_length = policy.min_password_length
        const password_special_symbols = policy.password_special_symbols
        const require_lowercase_letter = policy.require_lowercase_letter
        const require_number = policy.require_number
        const require_special_symbols = policy.require_special_symbols
        const require_uppercase_letter = policy.require_uppercase_letter
        //addition of above conditions into regex.
        let upper_case = require_uppercase_letter === true ? "(?=.*[A-Z])" : ""
        let lower_case = require_lowercase_letter === true ? "(?=.*[a-z])" : ""
        let number = require_number === true ? "(?=.*[0-9])" : ""
        let special_regex_part = "["
        let special_symbol_arr = []
        if (password_special_symbols.length>0){
            special_symbol_arr = Array.from(password_special_symbols);
        }
        for(let i=0;i<special_symbol_arr.length;i++){
            if(special_symbol_arr[i] === "+" || special_symbol_arr[i] === "^" || special_symbol_arr[i] === "|" || special_symbol_arr[i] === "-" || special_symbol_arr[i] === "]" || special_symbol_arr[i] === "\\"){
                special_regex_part += "\\";
                special_regex_part += special_symbol_arr[i]
            }
            else if(special_symbol_arr[i] === '"'){
                special_regex_part += '\x22';
            }
            else{
            special_regex_part += special_symbol_arr[i]
            }
        } 
        special_regex_part += "]"
        let special_symbols = require_special_symbols === true ? "(?=.*"+special_regex_part+")" : ""
        //email
        const email_regex = /(\S*)*(\.)?@(\S*)*\.(\S*)*/;
        let email_parts = email.match(email_regex)
        let first_email_part = ""
        let second_email_part = ""
        let last_email_part = ""
        if(email_parts !== null && email_parts !== ""){
            first_email_part = email_parts[1] !== undefined && email_parts[1] !== "" ? email_parts[1] : "";
            second_email_part = email_parts[3] !== undefined && email_parts[3] !== "" ? email_parts[3] : "";
            last_email_part = email_parts[4] !== undefined && email_parts[4] !== "" ? email_parts[4] : "" ;
        }
        let email_part = ""
        if(first_email_part !== undefined && first_email_part !== "" && second_email_part !== "" && second_email_part !== undefined && last_email_part !== undefined && last_email_part !== ""){
            email_part = can_contain_email_or_part === true ? "" : "(?!.*"+first_email_part+")"+"(?!.*"+second_email_part+")"+"(?!.*"+last_email_part+")"+"(?<!.*"+first_email_part+")"+"(?<!.*"+second_email_part+")"+"(?<!.*"+last_email_part+")";
        }

        //username, first_name, and last_name
        let username_part = "";
        let first_name_part = "";
        let last_name_part = "";
        if(username !== undefined && username !== ""){
        username_part = can_contain_username_or_part === true ? "" : "(?!.*"+username+")" + "(?<!.*"+username+")";
        }
        if(first_name !== undefined && first_name !== ""){
        first_name_part = can_contain_firstname_or_part === true ? "" : "(?!.*"+first_name+")" + "(?<!.*"+first_name+")";
        }
        if(last_name !== undefined && last_name !== ""){
        last_name_part = can_contain_lastname_or_part === true ? "" : "(?!.*"+last_name+")" + "(?<!.*"+last_name+")";
        }

        let policy_regex_pattern="^"+"(?!.* )"+special_symbols+email_part+username_part+first_name_part+last_name_part+upper_case+lower_case+number+".{"+min_password_length+","+max_password_length+"}$";
        policy_regex_pattern = new RegExp(policy_regex_pattern)
        return policy_regex_pattern;
    }
    else{
        return PasswordReg
    }
}

export const showPasswordInvalidTooltip = (policy)=>{
    if(policy !== null){
      let min_password_length = policy.min_password_length;
      let max_password_length = policy.max_password_length;
      let require_uppercase_letter = policy.require_uppercase_letter;
      let require_lowercase_letter = policy.require_lowercase_letter;
      let require_number = policy.require_number;
      let require_special_symbols = policy.require_special_symbols;
      let password_special_symbols = policy.password_special_symbols;
      let can_contain_email_or_part = policy.can_contain_email_or_part;
      let can_contain_username_or_part = policy.can_contain_username_or_part;
      let can_contain_firstname_or_part = policy.can_contain_firstname_or_part;
      let can_contain_lastname_or_part = policy.can_contain_lastname_or_part;
      let policyMessage = "Invalid password! Min length:"+
      min_password_length+", " +
      "Max length: "+max_password_length+". ";
      policyMessage += require_uppercase_letter?"Require 1 uppercase letter. ":"";
      policyMessage += require_lowercase_letter?"Require 1 lowercase letter. ":"";
      policyMessage += require_number?"Require 1 number. ":"";
      policyMessage += require_special_symbols?"Require special symbols. "+
      " Allowed special symbols are: "+password_special_symbols+".":"";
      policyMessage += can_contain_email_or_part?"":"Can not contain email in password. ";
      policyMessage += can_contain_username_or_part?"":"Can not contain username in password. ";
      policyMessage += can_contain_firstname_or_part?"":"Can not contain firstname in password. ";
      policyMessage += can_contain_lastname_or_part?"":"Can not contain lastname in password. ";
      return policyMessage;
    }
    else{
    const nonPolicyMessage = `Invalid password! 
    Min length:8 Special Char: 1 Lower Case: 1 Upper Case:1 
    Number:1.Allowed characters are a-z, A-Z, 0-9, @$!%*?&#^().<>.`
    return nonPolicyMessage
  }
  }