import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { updateMyAsset } from '../../../../api/asset';
import { mySystemUserList } from '../../../../api/systemuser';
import CrossIcon from '../../../../images/cross.svg';
import { checkIpHostPattern } from '../../../../utils/checkIpPattern';
import { showError, showSuccess } from '../../../../utils/showMessage';
import CustomModal from '../../../common/CustomModal/CustomModal';
import makeAnimated from 'react-select/animated';
import { SystemUserResourceList } from '../../../../api/systemuser';
import { Fade } from "@mui/material";
import { fadedelayTime } from "../../../../utils/transitionEffectParams";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";

import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";

const EditMyAsset = ({ asset, setOpenEditAsset, setAsset }) => {
    const [asset_id, setAssetid] = useState(null);
    const [name, setName] = useState("");
    const [ip_host, setIpHost] = useState("");
    const [port, setPort] = useState("");
    const [assetType, setAssetType] = useState(null);
    const [systemUser, setSystemUser] = useState(null);
    const [validated, setValidated] = useState(false);
    const [systemUsers, setSystemUsers] = useState([]);
    const [newsystemusers, setNewsystemusers] = useState([]);
    const [systemuseroptions, setSystemuseroptions] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [isIpCheck, setIsIpCheck] = useState(false);
    const animatedComponents = makeAnimated();
    const [ignoreCertificate, setIgnoreCertificate] = useState(false);
    const [slideChecked, setSlideChecked] = useState(false);

    useEffect(() => {
        setSlideChecked(true);
    }, []);

    const asset_types = [
        { "value": "ssh", "label": "SSH" },
        { "value": "rdp", "label": "RDP" },
        { "value": "vnc", "label": "VNC" },
    ]

    const selectStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            paddingTop: "3px",
            paddingBottom: "3px",
            borderColor: "#ced4da",
            boxShadow: "none",
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#717171",
            ':hover': {
                borderColor: "#ced4da",
            }
        }),
        option: (styles) => ({
            ...styles,
            fontFamily: '"DM Sans"',
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
        }),
    }
    useEffect(() => {
        const getAsset = async () => {
            setAssetid(asset.id);
            setName(asset.asset_name);
            setIpHost(asset.ip_host);
            setPort(asset.port);
            setAssetType(asset.asset_type);
            setIgnoreCertificate(asset.ignore_certificate);
        }
        getAsset();
    }, [asset])


    const getSystemUsersResources = async () => {
        const { data } = await SystemUserResourceList({ isresource: 1, new: true, id: 0 });
        if (data !== null) setSystemuseroptions(data.map(user => { user.label = user.system_display_username; user.value = user.id; return user; }));
    }
    const getSystemUserData = async () => {
        const { data } = await SystemUserResourceList({ isresource: 1, new: false, id: asset.id });
        if (data !== null) setSystemUsers(data.map(user => { user.label = user.system_display_username; user.value = user.id; return user; }));
        trigger === false ? setTrigger(true) : setTrigger(false);
    }

    const updateSystemUsers = (e) => {
        setNewsystemusers(e.map(user => user.value))
        setSystemUsers(e.map(asset => asset))
    }

    const updateAssetFormHandler = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setIsSubmited(true);
        if (form.checkValidity() === false && !checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
        } else if (!checkIpHostPattern(ip_host)) {
            setIsIpCheck(true);
            return;
        }
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setLoading(true);
            const { data, error } = await updateMyAsset({
                "asset_id": asset_id, "updatedAsset": {
                    ...(name !== "" && { "asset_name": name }),
                    ...({ "ip_host": ip_host }),
                    ...(port !== "" && { "port": port }),
                    ...(assetType !== "" && { "asset_type": assetType }),
                    ...({ "systemusers": newsystemusers }),
                    ...({ "ignore_certificate": ignoreCertificate })
                }
            })
            if (data !== null) {
                showSuccess(data.message);
                setLoading(false);
                setAsset(new Object());
                setOpenEditAsset(o => !o);
            }
            if (error !== null) {
                showError(error)
                setLoading(false);
            }
        }
    }
    useEffect(() => {
        getSystemUsersResources();
        getSystemUserData();
    }, [])

    useEffect(() => {
        setNewsystemusers(systemUsers.map(user => user.value))
    }, [trigger])

  return (
    <Fade timeout={fadedelayTime} in={slideChecked}>
      <div>
        <Container fluid className="main_content_container mx-auto">
          <div className="d-flex justify-content-between align-items-center  mb-4">
            <h2 className="main_content_heading">Edit Asset</h2>
            <CrossButton onClick={() => setOpenEditAsset((o) => !o)}></CrossButton>
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateAssetFormHandler}
          >
            <Stack gap={1}>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextName"
              >
                <Form.Label column md={3} className="input_label">
                  Asset Name<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    required
                    type="text"
                    className="form_input_field"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a asset name.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextAssetType"
              >
                <Form.Label column md={3} className="input_label">
                  Asset Type<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  {assetType && (
                    <Select
                      className="asset_type_select"
                      classNamePrefix="asset"
                      isClearable={true}
                      isSearchable={true}
                      defaultValue={asset_types.find(
                        (obj) => obj.value === assetType
                      )}
                      name="asset_type_edit"
                      required={true}
                      options={asset_types}
                      onChange={(selectedOption) =>
                        setAssetType(selectedOption.value)
                      }
                      styles={selectStyles}
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextPort"
              >
                <Form.Label column md={3} className="input_label">
                  Port<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="number"
                    required
                    className="form_input_field"
                    onChange={(event) => setPort(event.target.value)}
                    value={port}
                    placeholder="Port"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a port.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextIP"
              >
                <Form.Label column md={3} className="input_label">
                  IP/Host<span className="text-danger">*</span>
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    className="form_input_field"
                    onChange={(event) => {
                      setIpHost(event.target.value);
                      if (isSubmited) {
                        setIsIpCheck(!checkIpHostPattern(event.target.value));
                      }
                    }}
                    value={ip_host}
                    placeholder="IP/Host"
                    isInvalid={isIpCheck}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter valid IP/Host.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 justify-content-between"
                controlId="formPlaintextAssetType"
              >
                <Form.Label column md={3} className="input_label">
                  System User
                </Form.Label>
                  <Col md={9}>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={(e) => updateSystemUsers(e)}
                      value={systemUsers}
                      isMulti
                      options={systemuseroptions}
                      styles={selectStyles}
                    />
                  </Col>
              </Form.Group>
              {assetType == "rdp" && (
                <Form.Group
                  as={Row}
                  className="mb-3 justify-content-between"
                  controlId="switch"
                >
                  <Form.Label column md={3} className="input_label">
                    Ignore-Certificate
                  </Form.Label>
                  <Col md={9}>
                    <Form.Check
                      type="checkbox"
                      id="mfa-checkbox"
                      checked={ignoreCertificate}
                      onChange={() => setIgnoreCertificate(!ignoreCertificate)}
                    />
                  </Col>
                </Form.Group>
              )}
              <Row className="pt-3 mb-3 justify-content-end">
                <Col sm="auto">
                  <GeneralButton
                    onClickEvent={() => setOpenEditAsset((o) => !o)}
                    className="me-1"
                    value="Cancel"
                    color="#505050"
                    variant="outlined"
                    size="large"
                  ></GeneralButton>
                </Col>
                <Col sm="auto" className="me-sm-2 p-0">
                  {loading ? (
                    <GeneralButton
                      variant="contained"
                      disabled={true}
                      className="me-1"
                      value={<img src={images.Loader} width="26px" height="26px" />}
                      size="large"
                    />
                  ) : (
                    <GeneralButton
                      className="me-1"
                      value="Add"
                      variant="contained"
                      size="large"
                    />
                  )}
                </Col>
              </Row>
            </Stack>
          </Form>
        </Container>
      </div>
    </Fade>
  );
};

export default EditMyAsset;