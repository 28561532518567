import React from 'react';

const Heading = ({configurationSubNavbarName, setConfigurationSubNavbarName}) => {
    
    return (
        <>
            <div className="d-flex justify-content-start border-bottom mt-3 mb-4 align-items-center overflow-auto" >
                <p className={`m-0  me-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${configurationSubNavbarName === "Configuration" ? "active_border" : ""}`} onClick={() => { setConfigurationSubNavbarName("Configuration") }}>
                    Configuration
                </p>
                <p className={`m-0 mx-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${configurationSubNavbarName === "Import Users" ? "active_border" : ""}`} onClick={() => { setConfigurationSubNavbarName("Import Users") }}>
                    Import Users
                </p>
                <p className={`m-0 ms-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${configurationSubNavbarName === "Import Groups" ? "active_border" : ""}`} onClick={() => { setConfigurationSubNavbarName("Import Groups") }}>
                    Import Groups
                </p>
                <p className={`m-0 ms-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${configurationSubNavbarName === "Import Machines" ? "active_border" : ""}`} onClick={() => { setConfigurationSubNavbarName("Import Machines") }}>
                    Import Machines
                </p>
                <p className={`m-0 ms-3 px-2 py-2 add_policy_sub_navbar cursor_pointer ${configurationSubNavbarName === "Import Password Policy" ? "active_border" : ""}`} onClick={() => { setConfigurationSubNavbarName("Import Password Policy") }}>
                    Import Password Policy
                </p>
            </div>
        </>
    )
}

export default Heading;