import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { showError, showSuccess } from "../../../../utils/showMessage";
import Unlocked from "../../Users/icons/unlocked.svg";
import Locked from "../../Users/icons/locked.svg";
import { AiOutlineUserDelete } from "react-icons/ai";
import CustomModal from "../../../common/CustomModal/CustomModal";
import ShowAlert from "../../../common/ShowAlert/ShowAlert";
import DataTable from "react-data-table-component";
import ListHeading from "../../../common/RDTListHeading/ListHeading";
import assetHeadingIcon from "../../../../images/apps_icon.svg";
import SubHeaderComponent from "../../../common/RDTSubHeaderComponent/SubHeaderComponent";
import FirstPageIcon from "../../../../images/first_page.svg";
import LastPageIcon from "../../../../images/last_page.svg";
import NextPageIcon from "../../../../images/arrow_right.svg";
import PreviousPageIcon from "../../../../images/arrow_left.svg";
import { ExtendAppAllocation } from "../../../../api/apps";
import { GetColmn } from "../../../common/CustomColumn/customize_column";
import { changeTitle } from "../../../../state/slices/header";
import { AiOutlineUserAdd } from "react-icons/ai";
import ThreeDotIcon from "../../../../images/three_dot.svg";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import TimeCompExtend from "../../../common/TimeComponent/TimeCompExtend";
import GetCapabilities from "../../../../utils/getCapabilities";
import { notAllocatedToResourceRolesList, allocateRolesToResource, ExtendRoleAllocation } from "../../../../api/apps";
import { SystemUserResourceList } from "../../../../api/systemuser";
import useWindowDimensions from "../../../../utils/getHeightWidth";
import CustomDelaySkeleton from "../../../common/DelaySkeleton/CustomDelaySkeleton";
import { delayTime,fadedelayTime, slideDirection } from "../../../../utils/transitionEffectParams";
import { Slide } from "@mui/material";
import {Fade} from "@mui/material";
import { useSelector } from "react-redux";


const DeAllocateAppFromRole = ({ appName, ActionComponent }) => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 280 + "px";
  const my_capabilities = GetCapabilities();
  const isCapable = my_capabilities[0];
  const { app_id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_DeAllocateAppFromRole") !== null
      ? localStorage.getItem("rowsPerPage_DeAllocateAppFromRole")
      : 5
  );
  const [rolesList, setRolesList] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [action, setAction] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openViewRole, setOpenViewRole] = useState(false);
  const [toBeDeAllocate, setToBeDeAllocate] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [firstPage, setFirstPage] = useState();
  const [colName, setColName] = useState(null);
  const [colName1, setColName1] = useState(null);
  const pages_url = "dashboard/deallocateAssetsfromRole";
  const customizeColumnDict ={"Display Name":true, "ID":true,"Total Users":true}
  const [checkedStateDict,setCheckedStateDict] = useState(customizeColumnDict)
  const[openExtendUserModal,setOpenExtendUserModal]=useState(false);
  const [toBeExtend,setToBeExtend]=useState([])
  const title_heading = useSelector((state) => state.header.title);
  const [pending, setPending] = useState(true);  
  const [slideChecked, setSlideChecked]= useState(false)
  
  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const UserColumns = [
    { label: "Role Name", value: "role_name" },
    { label: "Display Name", value: "display_name" },
  ];

  const actionOptions = [{ label: "Remove", value: "remove" }];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict);
    getRolesList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage, dispatch, users]);

  const getRolesList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await notAllocatedToResourceRolesList({
      page: page,
      page_size: rowsPerPage,
      search: search,
      filter_by: filterBy,
      resource_id: app_id,
      resource_type: "app",
      allocated: 1,
    });
    if (data !== null) {
      setRolesList(data.results);
      setLastPage(data.next);
      setFirstPage(data.previous);
      setTotalUsers(data.count);
      setFilteredData(data.results);
    }
    if (error !== null) {
      showError(error);
    }
    setPending(false)
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setPage(1);
    getRolesList({ page: 1, rowsPerPage, search, filterBy });
  };

  useEffect(() => {
    dispatch(changeTitle("Apps"));
    setSlideChecked(true);
  }, []);

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = rolesList.filter((object) => {
      if (!filterBy) {
        return (
          object.role_name.toLowerCase().match(s.toLowerCase()) ||
          object.display_name.toLowerCase().match(s.toLowerCase())
        );
      } else {
        return object[filterBy.toLowerCase()].match(s.toLowerCase());
      }
    });
    setFilteredData(result);
  }, [search]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_DeAllocateAppFromRole", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleAction = async () => {
    if (action === "remove") {
      handleDeAllocate(null);
    }
  };

  const handleDeAllocate = async (row) => {
    let rowsToDeAllocate = [];
    if (row) {
      rowsToDeAllocate = row;
    } else {
      rowsToDeAllocate = selectedRows;
    }
    setToBeDeAllocate(rowsToDeAllocate);
    let temp;
    temp = rowsToDeAllocate.map((item) => item.display_name);
    setColName1(temp);
    setOpenViewRole((o) => !o);
  };

  const handleExtend = async (row) => {
    let rowsToExtend = [];
    if (row) {
      rowsToExtend = row;
    } else {
      rowsToExtend = selectedRows;
    }
    setToBeExtend(rowsToExtend);
    let temp;
    temp = rowsToExtend.map((item) => ({
      id: item.role_id,
      name: item.display_name,
    }));
    setColName(temp);
    setOpenExtendUserModal((o) => !o);
  };

  const handleDeAllocateAction = async () => {
    let ids = toBeDeAllocate.map((item) => item.role_id);
    let count = ids.length;
    const { data, error } = await allocateRolesToResource({
      ids: ids,
      resource_id: app_id,
      resource_type: "app",
      allocate: 0,
      allocation_from: 0,
      allocation_till: 0,
    });
    if (data !== null) {
      if (firstPage !== null) {
        if (
          lastPage === null &&
          (totalUsers % rowsPerPage === count || rowsPerPage === count)
        ) {
          setPage(page - 1);
        }
      }
      showSuccess(data.message);
      setUsers(Object.create(null));
      setOpenViewRole((o) => !o);
    }
    if (error !== null) {
      showError(error);
    }
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const handleExtendFunction = async (finalFromDate,finalToDate, policy, systemuser_id,allocation_type) => {
    let ids = toBeExtend.map((item) => item.role_id);
    //extend api
    const { data, error } = await ExtendRoleAllocation({
      entity_type: "app",
      entity_id: ids[0],
      resource_id: app_id,
      systemuser_id: systemuser_id,
      allocation_from: Date.parse(finalFromDate) / 1000,
      allocation_till: Date.parse(finalToDate) / 1000,
      policy_id: policy.id,
      allocation_type: allocation_type,
    });

    if (data !== null) {
      showSuccess(data.message);
    }
    if (error !== null) {
      showError(error);
    }

    setOpenExtendUserModal((o) => !o);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const columns = [
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      minWidth: "150px",
    },
    {
      name: "Display Name",
      selector: (row) => row.display_name,
      minWidth: "150px",
      omit: !checkedStateDict["Display Name"],
    },
    {
      name: "ID",
      selector: (row) => row.role_id,
      minWidth: "150px",
      omit: !checkedStateDict["ID"],
    },
    {
      name: "Total Users",
      selector: (row) => row.total_users,
      minWidth: "150px",
      omit: !checkedStateDict["Total Users"],
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Nav>
            <NavDropdown
              id=""
              title={<img src={ThreeDotIcon} alt="action" />}
              menuVariant="primary"
            >
              <NavDropdown.Item
                id="appslink"
                className=""
                onClick={async () => {
                  handleExtend([row]);
                }}
              >
                <AiOutlineUserAdd></AiOutlineUserAdd> Extend
              </NavDropdown.Item>
              <NavDropdown.Item
                id="appslink"
                className=""
                onClick={async () => {
                  handleDeAllocate([row]);
                }}
              >
                <AiOutlineUserDelete></AiOutlineUserDelete> Remove
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </>
      ),
      minWidth: "150px",
      grow: 1,
      middle: true,
    },
  ];

  return (
    <>
          {(() => {
        switch (title_heading) {
          case "Apps":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={
                            require("../../../../images/apps_icon.svg").default
                          }
                          alt="users round icon"
                        />{" "}
                        &nbsp; Apps
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
      <Slide timeout={delayTime}  direction={slideDirection} in={slideChecked}>
          <div>
          <Fade timeout={fadedelayTime} in={slideChecked}>
            <div>
              <div className="main_content_container p-3 mx-auto w-100">
                {colName1 && (
                  <CustomModal
                    open={openViewRole}
                    handleClose={() => {
                      setOpenViewRole((o) => !o);
                    }}
                  >
                    <ShowAlert
                      setOpenAlert={setOpenViewRole}
                      colName={colName1}
                      handleAlertAction={handleDeAllocateAction}
                      alertMsg={`Below roles will be deallocated for`}
                      entity_name={appName}
                      headingMsg="Deallocate Roles"
                    />
                  </CustomModal>
                )}
                {colName && (
                  <CustomModal
                    open={openExtendUserModal}
                    handleClose={() => {
                      setOpenExtendUserModal((o) => !o);
                    }}
                  >
                    <TimeCompExtend
                      setOpenAlert={setOpenExtendUserModal}
                      handleAlertAction={handleExtendFunction}
                      SystemUserResourceList={SystemUserResourceList}
                      colName={colName}
                      toBeExtend={[{ id: toBeExtend[0].role_id }]}
                      resource_id={app_id}
                      alertMsg={`Below role will be extended for app`}
                      resource_name={appName}
                      target_entity_name={appName}
                      headingMsg="Extend Role"
                      type={"role"}
                      target_type={"Roles"}
                      resource_type={"apps"}
                      show_policy={true}
                    />
                  </CustomModal>
                )}
                <div
                  className="position-relative bg-white"
                  style={{ height: `${datatable_height}` }}
                >
                  <DataTable
                    title={
                      <ListHeading
                        dataTableHeadingText={`App : ${appName}`}
                        dataTableHeadingIcon={assetHeadingIcon}
                      />
                    }
                    columns={columns}
                    data={filteredData}
                    className="rdt_container"
                    progressPending={pending}
                    progressComponent={
                      (
                          <CustomDelaySkeleton
                              rowsPerPage={rowsPerPage}
                              colCount={0}
                              totalColumns={6}
                          />
                      )
                  }
                    fixedHeader
                    paginationDefaultPage={page}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    pagination
                    paginationServer
                    paginationTotalRows={totalUsers}
                    selectableRows
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    highlightOnHover
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                    subHeader
                    subHeaderComponent={
                      <SubHeaderComponent
                        placeholder="role"
                        actions={ActionComponent}
                        filterBy={filterBy}
                        action={action}
                        setAction={setAction}
                        setFilterBy={setFilterBy}
                        handleAction={handleAction}
                        filterOptions={UserColumns}
                        actionOptions={
                          isCapable && isCapable.apps.allocate_apps
                            ? actionOptions
                            : null
                        }
                        search={search}
                        setSearch={setSearch}
                        handleSearch={handleSearch}
                        selectedRows={selectedRows}
                        pages_url={pages_url}
                        checkedStateDict={checkedStateDict}
                        setCheckedStateDict={setCheckedStateDict}
                      />
                    }
                    subHeaderAlign="center"
                    paginationIconFirstPage={
                      <img src={FirstPageIcon} alt="first page" />
                    }
                    paginationIconLastPage={
                      <img src={LastPageIcon} alt="last page" />
                    }
                    paginationIconNext={<img src={NextPageIcon} alt="next" />}
                    paginationIconPrevious={
                      <img src={PreviousPageIcon} alt="previous" />
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Slide>
    </>
  );
};

export default DeAllocateAppFromRole;