import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Select from 'react-select';
import { mySystemUserList, systemUserListWithAssetId } from '../../../../api/systemuser';
import { allAssetsList, updateWebApp } from '../../../../api/web_app';
import CrossIcon from '../../../../images/cross.svg';
import { isValidUrl } from '../../../../utils/checkIpPattern';
import { showError, showSuccess } from '../../../../utils/showMessage';
import CustomTooltip from '../../../common/Tooltip/Tooltip';
import { SystemUserResourceList } from '../../../../api/systemuser';
import makeAnimated from 'react-select/animated';
import GeneralButton from "../../../common/SaveButton/GeneralButton";
import images from "../../../../utils/images";
import { RxCross1 } from "react-icons/rx";
import CrossButton from "../../../common/ButtonSpinner/CrossButton";


const EditWebApp = ({ web_app, setOpenEditWebApp, setWebApp }) => {
  const [web_app_id, setWebAppid] = useState(web_app.id);
  const [webAppName, setWebAppName] = useState(web_app.app_name);
  const [loginPageURL, setLoginPageURL] = useState(web_app.login_page_url);
  const [webAppType, setWebAppType] = useState(web_app.app_type);
  const [validated, setValidated] = useState(false);
  const [systemUsers, setSystemUsers] = useState([]);
  const [newsystemusers, setNewsystemusers] = useState([]);
  const [systemuseroptions, setSystemuseroptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isIpCheck, setIsIpCheck] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [formField, setFormField] = useState(web_app.form_field);
  const [usernameField, setUserNameField] = useState(web_app.username_field);
  const [passwordField, setPasswordField] = useState(web_app.password_field);
  const [nextButtonField, setNextButtonField] = useState(
    web_app.next_button_field
  );
  const [domainNameField, setDomainNameField] = useState(
    web_app.domain_name_field
  );
  const [afterClickButtonField, setAfterClickButtonField] = useState(
    web_app.after_click_button
  );
  const [preClickButtonField, setPreClickButtonField] = useState(
    web_app.pre_click_button_field
  );
  const [submitButtonField, setSubmitButtonField] = useState(
    web_app.submit_button_field
  );
  const [show_loading, setShowLoading] = useState(web_app.show_loading);
  const animatedComponents = makeAnimated();
  const [rdpAsset, setRdpAsset] = useState(web_app.asset_system_user.rdp_asset_system_user?.asset);
  const [rdpAssets, setRdpAssets] = useState([]);
  const [checkDropdownValidation, setCheckDropdownValidation] = useState(true);

  const [rdpSystemUser, setRdpSystemUser] = useState(web_app.asset_system_user.rdp_asset_system_user?.systemuser);
  const [rdpSystemUsers, setRdpSystemUsers] = useState([]);

  const web_app_types = [
    { value: "SIMPLE_LOGIN", label: "SIMPLE_LOGIN" },
    { value: "LOGIN_SECOND_PAGE", label: "LOGIN_SECOND_PAGE" },
    { value: "LOGIN_WITH_DOMAIN", label: "LOGIN_WITH_DOMAIN" },
    { value: "LOGIN_AFTER_CLICK", label: "LOGIN_AFTER_CLICK" },
    { value: "LOGIN_WITH_PRE_CLICK", label: "LOGIN_WITH_PRE_CLICK" },
    {
      value: "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE",
      label: "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE",
    },
  ];
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      paddingTop: "3px",
      paddingBottom: "3px",
      borderColor: "#ced4da",
      boxShadow: "none",
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
      color: "#717171",
      ":hover": {
        borderColor: "#ced4da",
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: '"DM Sans"',
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "21px",
    }),
  }

  const selectStylesIncorrect = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        paddingTop: "3px",
        paddingBottom: "3px",
        borderColor: "danger",
        boxShadow: "none",
        fontFamily: '"DM Sans"',
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "21px",
        color: "red",
        ':hover': {
            borderColor: "red",
        }
    }),
    option: (styles) => ({
        ...styles,
        fontFamily: '"DM Sans"',
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "21px",
    }),
}


  const getSystemUsersResources = async () => {
    const { data } = await SystemUserResourceList({ isresource: 3, new: true, id: 0 });
    if (data !== null) setSystemuseroptions(data);
  }

  const getSystemUserData = async () => {
    const { data } = await SystemUserResourceList({ isresource: 3, new: false, id: web_app.id });
    if (data !== null) {
      setSystemUsers([...data]);
    }
  }

  const updateSystemUsers = (e) => {
    setNewsystemusers(e.map(user => user.id))
    setSystemUsers(e);
  }

  const fetchWebAppTypeAndFetchSystemUser = async (asset) => {
    setRdpAsset(asset);
    const { data, error } = await systemUserListWithAssetId({
      asset_id: asset.id,
    });
    if (data !== null) {
      setRdpSystemUsers(data.system_users);
    }
    if (error !== null) {
      showError(error);
    }
  };
  const getAllAssetsList = async () => {
    const { data } = await allAssetsList();
    if (data !== null) {
      if (data.assets.length > 0) {
        let asset;
        if (web_app.asset_system_user.rdp_asset_system_user?.asset !== null) {
          asset = web_app.asset_system_user.rdp_asset_system_user?.asset
        } else {
          asset = data.assets[0];
        }
        fetchWebAppTypeAndFetchSystemUser(asset);
      }
      setRdpAssets(data.assets);
    }
  }

  useEffect(() => {
    getAllAssetsList();
  }, [web_app])


  const updateWebAppFormHandler = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    setIsSubmited(true);
    if (form.checkValidity() === false && !isValidUrl(loginPageURL)) {
      setIsIpCheck(true);
    } else if (!isValidUrl(loginPageURL)) {
      setIsIpCheck(true);
      return;
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
      if(!rdpSystemUser){
        setCheckDropdownValidation(false)
      }
      setValidated(true);
    } else {
      setLoading(true);
      const { data, error } = await updateWebApp({
        "web_app_id": web_app_id, "updatedWebApp": {
          ...(webAppName !== "" && { "app_name": webAppName }),
          ...(webAppType !== "" && { "app_type": webAppType }),
          ...({ "show_loading": show_loading }),
          ...(loginPageURL !== "" && { "login_page_url": loginPageURL }),
          ...(formField !== "" && { "form_field": formField }),
          ...(usernameField !== "" && { "username_field": usernameField }),
          ...(passwordField !== "" && { "password_field": passwordField }),
          ...(preClickButtonField !== "" && (webAppType === "LOGIN_WITH_PRE_CLICK" || webAppType === "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE") && { "pre_click_button_field": preClickButtonField }),
          ...(nextButtonField !== "" && (webAppType === "LOGIN_SECOND_PAGE" || webAppType === "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE") && { "next_button_field": nextButtonField }),
          ...(submitButtonField !== "" && { "submit_button_field": submitButtonField }),
          ...(afterClickButtonField !== "" && webAppType === "LOGIN_AFTER_CLICK" && { "after_click_button_field": afterClickButtonField }),
          ...({ "systemusers": newsystemusers }),
          ...(rdpAsset !== null && { "rdp_asset": rdpAsset.id }),
          ...(rdpSystemUser !== null && { "rdp_asset_system_user": rdpSystemUser.id })
        }
      })
      if (data !== null) {
        showSuccess(data.message);
        setLoading(false);
        setWebApp(new Object());
        setOpenEditWebApp((o) => !o);
      }
      if (error !== null) {
        showError(error);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getSystemUsersResources();
    getSystemUserData();
  }, [])

  useEffect(() => {
    setNewsystemusers(systemUsers.map(user => user.id))
  }, [systemUsers])

  return (
    <Container fluid className="main_content_container mx-auto">
      <div className="d-flex justify-content-between align-items-center  mb-4">
        <h2 className="main_content_heading">Edit Web App</h2>
        <CrossButton onClick={() => setOpenEditWebApp(o => !o)}></CrossButton>
      </div>
      <Form noValidate validated={validated} onSubmit={updateWebAppFormHandler}>
        <Stack gap={1}>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="WebAppName"
          >
            <Form.Label column md={3} className="input_label">
              Web App Name<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                className="form_input_field"
                type="text"
                value={webAppName}
                onChange={(event) => setWebAppName(event.target.value)}
                placeholder="Name"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a web app name.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="AppType"
          >
            <Form.Label column md={3} className="input_label">
              App Type<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Select
                className="web_app_type_select"
                classNamePrefix="web_app"
                isClearable={false}
                isSearchable={true}
                name="web_app_type"
                defaultValue={{ label: webAppType, value: webAppType }}
                required={true}
                options={web_app_types}
                onChange={(selectedOption) =>
                  setWebAppType(selectedOption.value)
                }
                styles={selectStyles}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="AppType"
          >
            <Form.Label column md={3} className="input_label">
              Target Machine<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Select
                className="web_app_type_select"
                classNamePrefix="web_app"
                getOptionLabel={(option) => option.asset_name}
                getOptionValue={(option) => option.id}
                isClearable={false}
                isSearchable={true}
                name="target_machine"
                required={true}
                options={rdpAssets}
                value={rdpAsset}
                onChange={(selectedOption) => {fetchWebAppTypeAndFetchSystemUser(selectedOption);setRdpSystemUser(null)}}
                styles={selectStyles}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="AppType"
          >
            <Form.Label column md={3} className="input_label">
              Machine SystemUser<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Select
                className="web_app_type_select"
                classNamePrefix="web_app"
                getOptionLabel={(option) => option.system_display_username}
                getOptionValue={(option) => option.id}
                isClearable={false}
                isSearchable={true}
                name="machine_systemuser"
                required={true}
                options={rdpSystemUsers}
                onChange={(selectedOption) => {setRdpSystemUser(selectedOption);setCheckDropdownValidation(true)}}
                styles={checkDropdownValidation ? selectStyles : selectStylesIncorrect}
                value={rdpSystemUser}
              />
              {!checkDropdownValidation && <span className='text-danger fs-14px' type="invalid">
                Please select system user
              </span>}
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="LoginPageURL"
          >
            <Form.Label column md={3} className="input_label">
              Login Page URL<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                type="text"
                required
                onChange={(event) => {
                  setLoginPageURL(event.target.value);
                  if (isSubmited) {
                    setIsIpCheck(!isValidUrl(event.target.value));
                  }
                }}
                value={loginPageURL}
                placeholder="login field url"
                className="form_input_field"
                isInvalid={isIpCheck}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid IP/Host.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="FormField"
          >
            <Form.Label column md={3} className="input_label">
              Form Field
            </Form.Label>
            <Col md={9}>
              <Form.Control
                className="form_input_field"
                type="text"
                value={formField}
                onChange={(event) => setFormField(event.target.value)}
                placeholder="form field"
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="UsernameField"
          >
            <Form.Label column md={3} className="input_label">
              Username Field<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                className="form_input_field"
                type="text"
                value={usernameField}
                onChange={(event) => setUserNameField(event.target.value)}
                placeholder="username field"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a username field.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="PasswordField"
          >
            <Form.Label column md={3} className="input_label">
              Password Field<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                className="form_input_field"
                type="text"
                value={passwordField}
                onChange={(event) => setPasswordField(event.target.value)}
                placeholder="password field"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a password field.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {webAppType === "LOGIN_WITH_DOMAIN" && (
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="DomainNameField"
            >
              <Form.Label column md={3} className="input_label">
                Domain Name Field<span className="text-danger">*</span>
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  required
                  className="form_input_field"
                  type="text"
                  value={domainNameField}
                  onChange={(event) => setDomainNameField(event.target.value)}
                  placeholder="domain name field"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a domain name field.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          {webAppType === "LOGIN_AFTER_CLICK" && (
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="AfterClickButtonField"
            >
              <Form.Label column md={3} className="input_label">
                After-Click Button Field<span className="text-danger">*</span>
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  required
                  className="form_input_field"
                  type="text"
                  value={afterClickButtonField}
                  onChange={(event) =>
                    setAfterClickButtonField(event.target.value)
                  }
                  placeholder="after click button field"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a after-click button field.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          {(webAppType === "LOGIN_WITH_PRE_CLICK" ||
            webAppType === "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE") && (
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="PreClickButtonField"
            >
              <Form.Label column md={3} className="input_label">
                Pre-Click Button Field<span className="text-danger">*</span>
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  required
                  className="form_input_field"
                  type="text"
                  value={preClickButtonField}
                  onChange={(event) =>
                    setPreClickButtonField(event.target.value)
                  }
                  placeholder="pre click button field"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a pre click button field.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          {(webAppType === "LOGIN_SECOND_PAGE" ||
            webAppType === "LOGIN_WITH_PRE_CLICK_AND_SECOND_PAGE") && (
            <Form.Group
              as={Row}
              className="mb-3 justify-content-between"
              controlId="NextButtonField"
            >
              <Form.Label column md={3} className="input_label">
                Next Button Field<span className="text-danger">*</span>
              </Form.Label>
              <Col md={9}>
                <Form.Control
                  required
                  className="form_input_field"
                  type="text"
                  value={nextButtonField}
                  onChange={(event) => setNextButtonField(event.target.value)}
                  placeholder="next button field"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a next button field.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}
          <Form.Group
            as={Row}
            className="mb-3 justify-content-between"
            controlId="SubmitButtonField"
          >
            <Form.Label column md={3} className="input_label">
              Submit Button Field<span className="text-danger">*</span>
            </Form.Label>
            <Col md={9}>
              <Form.Control
                required
                className="form_input_field"
                type="text"
                value={submitButtonField}
                onChange={(event) => setSubmitButtonField(event.target.value)}
                placeholder="submit button field"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a submit button field.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {<Form.Group as={Row} className="mb-3 justify-content-between" controlId="formPlaintextAssetType">
            <Form.Label column md={3} className="input_label">
              System User
            </Form.Label>
            <Col md={9}>
              <Select
                getOptionLabel={(option) => option.system_display_username}
                getOptionValue={(option) => option.id}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={(e) => updateSystemUsers(e)}
                value={systemUsers}
                isMulti
                options={systemuseroptions}
                styles={selectStyles}
              />
            </Col>
          </Form.Group>}
          <Form.Group as={Row} className="mb-3 justify-content-between" controlId="switch">
            <Form.Label column md={3} className="input_label">
              Hide Form
            </Form.Label>
            <Col md={9} className="d-flex flex-row align-items-center">
              <Form.Check
                type="checkbox"
                id="mfa-checkbox"
                checked={show_loading}
                onChange={() => setShowLoading(!show_loading)}
              />
              <CustomTooltip
                message={
                  "The login form on the targeted website will remain concealed from the user."
                }
                placement={"top-end"}
              />
            </Col>
          </Form.Group>
          <Row className="pt-3 mb-3 justify-content-end">
            <Col sm="auto">
              <GeneralButton
                onClickEvent={() => setOpenEditWebApp((o) => !o)}
                className="me-1"
                value="Cancel"
                color="#505050"
                variant="outlined"
                size="large"
              />
            </Col>
            <Col sm="auto" className="me-sm-2 p-0">
              {loading ? (
                <GeneralButton
                  variant="contained"
                  disabled={true}
                  className="me-1"
                  value={<img src={images.Loader} width="26px" height="26px" />}
                  size="large"
                />
              ) : (
                <GeneralButton
                  className="me-1"
                  value="Update"
                  variant="contained"
                  size="large"
                />
              )}
            </Col>
          </Row>
        </Stack>
      </Form>
    </Container>
  );
};

export default EditWebApp;
