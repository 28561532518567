import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
import Images from "../../../../utils/images";

const UploadButtonComponent = ({ setOpenUpload }) => {
    const UploadButton = styled(MuiButton)({
        boxShadow: "none",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 14,
        fontStyle: "normal",
        padding: "10px 20px",
        lineHeight: "21px",
        fontFamily: ['"DM Sans"'].join(","),
    });

    return (
        <UploadButton
            onClick={() => {
                setOpenUpload((o) => !o);
            }}
            variant="outlined"
            className="mb-3"
            endIcon={<img src={Images.UpperArrowIcon} alt="icon" />}
        >
            Upload Certificate
        </UploadButton>
    );
};

export { UploadButtonComponent };