import React, { useEffect, useState, useSyncExternalStore } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMostActiveUserDetails } from "../../../api/report";
import systemUserHeadingIcon from "../../../images/users_page_icon.svg";
import PreviousPageIcon from "../../../images/arrow_left.svg";
import NextPageIcon from "../../../images/arrow_right.svg";
import FirstPageIcon from "../../../images/first_page.svg";
import LastPageIcon from "../../../images/last_page.svg";
import { changeTitle } from "../../../state/slices/header";
import { showError } from "../../../utils/showMessage";
import { GetColmn } from "../../common/CustomColumn/customize_column";
import ListHeading from "../../common/RDTListHeading/ListHeading";
import SubHeaderComponent from "../../common/RDTSubHeaderComponent/SubHeaderComponent";
import CustomDelaySkeleton from "../../common/DelaySkeleton/CustomDelaySkeleton";
import { delayTime, slideDirection,fadedelayTime } from "../../../utils/transitionEffectParams";
import { Slide,Fade } from "@mui/material";
import useWindowDimensions from "../../../utils/getHeightWidth";

const MostActiveUser = () => {
  const { width, height } = useWindowDimensions();
  let datatable_height = height - 290 + "px";
  const dispatch = useDispatch();
  const { username } = useParams();
  const [page, setPage] = useState(1);
  const title_heading = useSelector((state) => state.header.title);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage_UserApps") !== null
      ? localStorage.getItem("rowsPerPage_UserApps")
      : 5
  );
  const [totalApps, setTotalApps] = useState(0);
  const [apps, setApps] = useState([]);
  const [filterBy, setFilterBy] = React.useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const user_email = useSelector((state) => state.user?.user?.email || "");
  const pages_url = "dashboard/userApps" + user_email;
  const customizeColumnDict = {"Type":true,"No. of time Accessed":true,"Last Accessed":true}
  const [checkedStateDict,setCheckedStateDict] = useState(customizeColumnDict)
  const [colCount,setColCount] = useState(null);
  const [pending, setPending]= useState(true);
  const [slideChecked, setSlideChecked] = useState(false);

  useEffect(() => {
    setSlideChecked(true);
  }, []);

  const appColumns = [
    { label: "App", value: "app_name" },
    { label: "Asset", value: "asset_name" },
  ];

  useEffect(() => {
    GetColmn(setCheckedStateDict, pages_url, checkedStateDict, setColCount);
    getAppsList({ page, rowsPerPage, search, filterBy });
  }, [page, rowsPerPage]);

  const getAppsList = async ({ page, rowsPerPage, search, filterBy }) => {
    const { data, error } = await getMostActiveUserDetails({
      username: username,
      page: page - 1,
      limit: rowsPerPage,
      search: search,
      filterBy: filterBy,
    });
    if (data !== null) {
      setApps(data.data);
      setFilteredData(data.data);
      setTotalApps(data.totalResources);
    }
    else {
      showError(error);
    }
    setPending(false);

  };

  useEffect(() => {
    let s = search.replaceAll(/[\\\*\(\)\+\[\?]/g, "");
    const result = apps.filter((object) => {
      return (
        object.username.toLowerCase().match(s.toLowerCase()) ||
        object.resource.toLowerCase().match(s.toLowerCase()) ||
        object.type.toLowerCase().match(s.toLowerCase())
      );
    });
    setFilteredData(result);
  }, [search]);

  useEffect(() => {
    dispatch(changeTitle("Reports"));
  }, []);

  const handleSearch = (event) => {
    event.preventDefault();
    getAppsList({ page, rowsPerPage, search, filterBy });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage) => {
    setPage(1);
    localStorage.setItem("rowsPerPage_UserApps", newPerPage);
    setRowsPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      grow: 1,
      sortable: true,
    },
    {
      name: "Resource Name",
      selector: (row) => row.resource,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      omit: !checkedStateDict["Type"],
      sortable: true,
    },
    {
      name: "No. of times Accessed",
      selector: (row) => row.accessed,
      minWidth: "150px",
      omit: !checkedStateDict["No. of time Accessed"],
      center: true,
      sortable: true,
    },
    {
      name: "Last Accessed",
      selector: (row) => row.last_time_accessed,
      minWidth: "150px",
      omit: !checkedStateDict["Last Accessed"],
      center: true,
      sortable: true,
    },
  ];
  return (
    <>
    {(() => {
        switch (title_heading) {
          case "Reports":
            return (
              <div className="heading_datable bg-white">
                <span
                  style={{ color: "#50514F" }}
                  className="ff-poppins fs-24px fw-600"
                >
                  <div className="d-flex align-items-center">
                    <div className="fw-400 mt-3 mb-3 ff-poppins text-wrap">
                      <span className="fs-20px text-color-h1 ps-4 fw-600 ">
                        <img
                          src={require("./icons/icon.svg").default}
                          alt="users round icon"
                        />{" "}
                        &nbsp; Reports
                      </span>
                      <br />
                    </div>
                  </div>
                </span>
              </div>
            );
        }
      })()}
    <Slide  timeout={delayTime}  direction={slideDirection} in={slideChecked}>
      <div>
      <Fade timeout={fadedelayTime} in={slideChecked}>
  <div>
  <div className="main_content_container p-3 mx-auto w-100">
  <div
        className="position-relative bg-white"
        style={{ height: `${datatable_height}` }}
        >
<DataTable
          title={
            <ListHeading
              dataTableHeadingText={`Most Active user detail : ${username}`}
              dataTableHeadingIcon={systemUserHeadingIcon}
            />
          }
          columns={columns}
          data={filteredData}
          className="rdt_container"
          progressPending={pending}
          progressComponent={
              (
              <CustomDelaySkeleton
                  rowsPerPage={rowsPerPage}
                  colCount={colCount}
                  totalColumns={4}
              />
              )
          }
          fixedHeader
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          paginationServer
          paginationTotalRows={totalApps}
          highlightOnHover
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          subHeader
          subHeaderComponent={
            <SubHeaderComponent
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              filterOptions={appColumns}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              placeholder="resource"
              pages_url={pages_url}
              checkedStateDict={checkedStateDict}
              setCheckedStateDict={setCheckedStateDict}
            />
          }
          subHeaderAlign="center"
          paginationIconFirstPage={<img src={FirstPageIcon} alt="first page" />}
          paginationIconLastPage={<img src={LastPageIcon} alt="last page" />}
          paginationIconNext={<img src={NextPageIcon} alt="next" />}
          paginationIconPrevious={<img src={PreviousPageIcon} alt="previous" />}
        />
        </div>
        
      </div>         
  </div>
</Fade>

      
      </div>
      </Slide>
    </>
  );
};

export default MostActiveUser;
